import React, { ComponentProps, ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, StyleProp, ViewStyle, TouchableHighlight } from 'react-native';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
    Easing,
    withDelay,
    ExitAnimationsValues,
    SharedValue,
} from 'react-native-reanimated';
import { useThemeContext } from '_utils/themeContext';
import { ApiImage, FileImage } from 'types/Base';
import { Theme, useThemeStyle, WW, bytesToString } from '../../_utils';
import { screenMargin, smallestMargin, subtitleFontSize } from '../../_utils/sizes';
import CacheImage from '../CacheImage';
import HeimeText from '../HeimeText';
import Icon from '../Icon/Icon';
import InputLabel from '../InputLabel';

type FileType = {
    id: string | number;
    name: string | undefined;
    size?: number;
    onRemove: () => void;
    isUploading: boolean;
    uploadProgress: number;
} & (
    | {
          type: 'image';
          source: ApiImage | { original: string } | FileImage;
      }
    | {
          type: 'file';
          source: { original: string } | FileImage;
      }
);

interface FileAttachmentSelectorProps {
    files: FileType[];
    title?: string;
    optional?: boolean;
    style?: StyleProp<ViewStyle>;
    onUpload: () => void;
    type?: 'image' | 'file';
    filesPosition?: 'top' | 'bottom';
}

const BaseFileSelector = ({
    files,
    style,
    title,
    optional,
    onUpload,
    filesPosition = 'top',
}: FileAttachmentSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const filesOverview = useMemo(
        () =>
            files.map(({ id, type, source, name, size, onRemove, isUploading, uploadProgress }) => (
                <View key={id} style={themedStyle.fileContainer}>
                    {isUploading ? <UploadProgress progress={uploadProgress} /> : null}
                    <Icon name="paperClip" color="mediumGrey" />
                    {type === 'image' ? (
                        <>
                            <CacheImage<ApiImage | { original: string } | FileImage>
                                style={themedStyle.imagePreview}
                                source={source}
                                resizeMode="contain"
                            />
                            <View style={themedStyle.spacer} />
                        </>
                    ) : (
                        <HeimeText style={themedStyle.fileName}>{name}</HeimeText>
                    )}
                    {size ? <HeimeText>{bytesToString(size)}</HeimeText> : null}
                    <TouchableOpacity onPress={onRemove}>
                        <Icon name="cross" color="red" />
                    </TouchableOpacity>
                </View>
            )),
        [files, themedStyle.fileContainer, themedStyle.fileName, themedStyle.imagePreview, themedStyle.spacer],
    );

    return (
        <View style={style}>
            {title ? <InputLabel optional={optional}>{title}</InputLabel> : null}
            {filesPosition === 'top' ? filesOverview : null}
            <TouchableHighlight onPress={onUpload}>
                <View style={themedStyle.titleWrapper}>
                    <View style={themedStyle.addIconWrapper}>
                        <Icon name="paperClip" color="mediumGrey" />
                    </View>
                    <HeimeText maxFontSizeMultiplier={2} style={themedStyle.explainer}>
                        {t('global:attachFile')}
                    </HeimeText>
                </View>
            </TouchableHighlight>
            {filesPosition === 'bottom' ? filesOverview : null}
        </View>
    );
};

const customExiting = (opacity: SharedValue) => (values: ExitAnimationsValues) => {
    'worklet';
    const duration = 500;

    const animations = {
        width: withTiming(values.windowWidth - screenMargin * 2, { duration }),
        opacity: withDelay(duration, withTiming(0, { duration: 300 })),
    };
    const initialValues = {
        width: values.currentWidth,
        opacity: opacity.value,
    };

    return { animations, initialValues };
};

const duration = 2000;
const UploadProgress = ({ progress }: { progress: number }) => {
    const pulse = useSharedValue(0);
    const progressValue = useSharedValue(progress);
    const { theme } = useThemeContext();

    useEffect(() => {
        pulse.value = withRepeat(
            withTiming(0.7, { duration }),
            -1, // Infinite repetitions
            true, // Reverse the animation
        );
    }, [pulse]);

    useEffect(() => {
        progressValue.value = withTiming(progress, {
            duration: 300,
            easing: Easing.out(Easing.cubic),
        });
    }, [progress, progressValue]);

    const animatedStyle = useAnimatedStyle(() => {
        return { opacity: pulse.value, width: `${10 + (progressValue.value / 100) * 90}%` };
    });

    const exit = useMemo(() => customExiting(pulse), [pulse]);

    return (
        <Animated.View
            style={[animatedStyle, { position: 'absolute', height: '100%', backgroundColor: theme.main }]}
            exiting={exit as ComponentProps<typeof Animated.View>['exiting']}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        titleWrapper: {
            paddingTop: WW * 0.1,
            paddingBottom: WW * 0.1,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.mediumBackground,
        },
        explainer: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            marginTop: screenMargin,
            textAlign: 'center',
            color: theme.darkGrey,
        },
        avatarWrapper: {
            flexDirection: 'row',
            justifyContent: 'center',
        },
        imagePreview: {
            width: WW * 0.2,
            height: WW * 0.2,
        },
        addIconWrapper: {
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            backgroundColor: theme.mainBackground,
            borderRadius: 50,
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        fileContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: WW * 0.02,
            alignItems: 'center',
        },
        fileName: {
            flex: 1,
            marginLeft: WW * 0.02,
            marginRight: WW * 0.02,
        },
        spacer: { flex: 1 },
    });

export default BaseFileSelector;
