import React, { ComponentProps, ReactElement, useMemo } from 'react';
import { useImageSelector } from '_utils';
import GetFormFilesToDisplay from '_utils/GetFormFilesToDisplay';
import { FormFile } from 'types/Base';
import BaseImageSelector from './BaseImageSelector';
import FileActionSheet from './FileActionsheet';
import { FileAction, GetRemoveAction, useHandleAdd } from './fileState';
import useActionSheetState from './useActionSheetState';

interface FileImageSelector extends Omit<ComponentProps<typeof BaseImageSelector>, 'images' | 'onAdd'> {
    files: FormFile[];
    onAction: (action: FileAction) => void;
    openInitially?: boolean;
}

const FileImageSelector = ({ onAction, files, openInitially = false, ...props }: FileImageSelector): ReactElement => {
    const { visible, onShow, onClose } = useActionSheetState(openInitially);

    const handleAdd = useHandleAdd(onAction);

    const [onSelect, permission] = useImageSelector(handleAdd, onClose);

    const filesOverview = useMemo(
        () =>
            GetFormFilesToDisplay(files).map((file) => ({
                source: file.state.status === 'EXIST' ? file.state.file : { original: file.state.url },
                onRemove: () => onAction(GetRemoveAction(file)),
                isUploading: file.state.status === 'UPLOADING',
                uploadProgress: file.state.status === 'UPLOADING' ? file.state.progress : 0,
            })),
        [files, onAction],
    );

    return (
        <>
            <BaseImageSelector {...props} onAdd={onShow} images={filesOverview} />
            {visible ? (
                <FileActionSheet
                    actions={[
                        {
                            type: 'Library' as const,
                            onSelect: () => onSelect({ cropping: false, type: 'library' }),
                        },
                        { type: 'Camera' as const, onSelect: () => onSelect({ cropping: false, type: 'camera' }) },
                    ]}
                    onClose={onClose}
                />
            ) : null}
            {permission}
        </>
    );
};

export default FileImageSelector;
