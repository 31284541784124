import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import safeParse from '_utils/safeParse';
import { RichText, RichTextSchema } from 'types/Base';
import { useGetAccessTokenHeader } from '../_utils/Axios';

const useGDPRInfo = (): UseQueryResult<RichText, string | Error | AxiosError> => {
    const getAuthHeader = useGetAccessTokenHeader();

    return useQuery({
        queryKey: ['GDPRInfo'],

        queryFn: async () => {
            const result = await axios.get<unknown>('info/gdpr', {
                headers: { authorization: await getAuthHeader() },
            });
            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return safeParse(result.data, RichTextSchema);
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 24 * 7,
    });
};

export { useGDPRInfo };
