import React, { ComponentProps, ReactElement, useMemo } from 'react';
import { useBase64ImageSelector } from '_utils';
import { FileUpload, mapFileUploadsToApiImage } from 'types/Utility';
import BaseImageSelector from './BaseImageSelector';
import FileActionSheet from './FileActionsheet';
import useActionSheetState from './useActionSheetState';
import useFileAttachmentState from './useFileAttachmentState';

interface ImageAttachmentSelectorProps extends Omit<ComponentProps<typeof BaseImageSelector>, 'images' | 'onAdd'> {
    images: FileUpload[];
    onChange: (images: FileUpload[]) => void;
    openInitially?: boolean;
}

const ImageAttachmentSelector = ({
    images,
    onChange,
    openInitially = false,
    ...props
}: ImageAttachmentSelectorProps): ReactElement => {
    const { onAdd, onDelete } = useFileAttachmentState(images, onChange);
    const { visible, onClose, onShow } = useActionSheetState(openInitially);
    const [onSelect, permission] = useBase64ImageSelector(onAdd, onClose);

    const nonDeletedImages = useMemo(() => images.filter(({ status }) => status !== 'deleted'), [images]);

    return (
        <>
            <BaseImageSelector
                {...props}
                onAdd={onShow}
                images={nonDeletedImages.map((item) => ({
                    source: mapFileUploadsToApiImage([item])[0],
                    onRemove: () => onDelete(item),
                    isUploading: false,
                    uploadProgress: 0,
                }))}
            />
            {visible ? (
                <FileActionSheet
                    actions={[
                        {
                            type: 'Library' as const,
                            onSelect: () => onSelect({ type: 'library' }),
                        },
                        { type: 'Camera' as const, onSelect: () => onSelect({ type: 'camera' }) },
                    ]}
                    onClose={onClose}
                />
            ) : null}
            {permission}
        </>
    );
};

export default ImageAttachmentSelector;
