import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text } from 'react-native';
import { subtitleFontSize } from '_utils/sizes';
import { Icon, Modal, RadioButton } from '.';
import _fonts from '../_fonts';
import { AVAILABLE_LANGUAGES } from '../_localization';
import { selectLanguage } from '../_redux/selectedLanguage';
import { Theme, useThemeStyle, useAppDispatch, useGlobalState, WW } from '../_utils';

interface LanguageSelectorModalProps {
    onDismiss(): void;
}

const LanguageSelectorModal = ({ onDismiss }: LanguageSelectorModalProps): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);
    const [formSelectedLanguage, setFormSelectedLanguage] = useState<keyof typeof AVAILABLE_LANGUAGES>(
        selectedLanguage ?? 'no',
    );
    const dispatch = useAppDispatch();

    const onPressSave = () => {
        dispatch(selectLanguage(formSelectedLanguage));
        onDismiss();
    };
    return (
        <Modal
            onDismiss={onDismiss}
            header={t('languageSelectModal:header')}
            content={
                <View style={themedStyle.main}>
                    {Object.keys(AVAILABLE_LANGUAGES).map((code) => {
                        const castedCode = code as keyof typeof AVAILABLE_LANGUAGES;
                        const info = AVAILABLE_LANGUAGES[code as keyof typeof AVAILABLE_LANGUAGES];
                        const selected = code === formSelectedLanguage;
                        return (
                            <RadioButton
                                key={code}
                                style={themedStyle.check}
                                checked={selected}
                                onPress={() => {
                                    setFormSelectedLanguage(castedCode);
                                }}
                            >
                                <View style={themedStyle.iconContainer}>
                                    <Icon name={info.flag} color="main" />
                                </View>
                                <Text
                                    style={[
                                        themedStyle.titleText,
                                        code === selectedLanguage ? { fontFamily: _fonts.primaryFontBold } : {},
                                    ]}
                                >
                                    {t(info.title)}
                                </Text>
                            </RadioButton>
                        );
                    })}
                </View>
            }
            buttons={[
                { text: t('languageSelectModal:cancel').toUpperCase(), onPress: onDismiss, type: 'secondary' },
                { text: t('languageSelectModal:submit').toUpperCase(), onPress: onPressSave },
            ]}
        />
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingLeft: WW * 0.08,
            paddingRight: WW * 0.08,
        },
        titleText: {
            color: theme.darkGray,
            flex: 1,
            fontFamily: _fonts.primaryFont,
            fontSize: subtitleFontSize,
        },
        iconContainer: {
            paddingRight: WW * 0.02,
            paddingLeft: WW * 0.02,
        },
        check: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            backgroundColor: theme.mainBackground,
        },
    });

export default LanguageSelectorModal;
