import React, { ComponentProps, ReactElement, useCallback, useMemo } from 'react';
import 'react-native-get-random-values';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useUploadFile } from '_api/useFile';
import GetFormFilesToDisplay from '_utils/GetFormFilesToDisplay';
import { captureException } from '_utils/Sentry';
import { FormFile, SelectedLocalFile } from 'types/Base';
import BaseFileSelector from './BaseFileSelector';
import FileActionSheet from './FileActionsheet';
import { FileAction } from './fileState';
import useActionSheetState from './useActionSheetState';
import { useImageSelector, isTruthy, isAndroid, showToast } from '../../_utils';

interface FileAttachmentSelectorProps extends Omit<ComponentProps<typeof BaseFileSelector>, 'files' | 'onUpload'> {
    files: FormFile[];
    onAction: (action: FileAction) => void;
    openInitially?: boolean;
    type?: 'image' | 'file';
}

const FileSelector = ({
    type,
    onAction,
    files,
    openInitially = false,
    ...props
}: FileAttachmentSelectorProps): ReactElement => {
    const { visible, onShow, onClose } = useActionSheetState(openInitially);
    const { t } = useTranslation();
    const { mutateAsync } = useUploadFile();

    const handleAdd = (addedFiles: Array<SelectedLocalFile>) => {
        addedFiles.forEach(async (file) => {
            const id = nanoid();
            onAction({
                type: 'SELECT_FILE',
                id,
                name: file.name,
                url: file instanceof File ? URL.createObjectURL(file) : isAndroid() ? `file://${file.path}` : file.path,
                size: file.size,
            });
            try {
                const onProgress = (progress: number) =>
                    onAction({
                        type: 'UPDATE_PROGRESS',
                        id,
                        progress,
                    });

                const uploadResult = await mutateAsync({
                    file,
                    onProgress,
                });
                onAction({
                    type: 'UPLOAD_COMPLETE',
                    id,
                    new_id: uploadResult.success,
                });
            } catch (e) {
                captureException(e);
                showToast({
                    type: 'error',
                    header: t('global:upload_failed'),
                    text: '',
                });
                onAction({
                    type: 'REMOVE_FILE',
                    id,
                });
            }
        });
    };

    const onDelete = useCallback(
        (file: FormFile) => {
            onAction({
                type: 'REMOVE_FILE',
                id: file.id,
            });
        },
        [onAction],
    );

    const [onSelect, permission] = useImageSelector(handleAdd, onClose);

    const fileIsImage = (file: FormFile) => {
        const fileExt = file.name.toLocaleLowerCase().split('.').pop() ?? '';
        return ['webp', 'jpg', 'jpeg', 'gif', 'png'].includes(fileExt);
    };

    const filesOverview = useMemo(
        () =>
            GetFormFilesToDisplay(files).map((file) => ({
                id: file.id,
                name: file.name,
                size: 'size' in file.state ? file.state.size : undefined,
                type: fileIsImage(file) ? ('image' as const) : ('file' as const),
                source: file.state.status === 'EXIST' ? file.state.file : { original: file.state.url },
                onRemove: () => onDelete(file),
                isUploading: file.state.status === 'UPLOADING',
                uploadProgress: file.state.status === 'UPLOADING' ? file.state.progress : 100,
            })),
        [files, onDelete],
    );

    return (
        <>
            <BaseFileSelector {...props} files={filesOverview} onUpload={onShow} />
            {visible ? (
                <FileActionSheet
                    actions={[
                        type !== 'file'
                            ? {
                                  type: 'Library' as const,
                                  onSelect: () => onSelect({ cropping: false, type: 'library' }),
                              }
                            : null,
                        type !== 'file'
                            ? {
                                  type: 'Camera' as const,
                                  onSelect: () => onSelect({ cropping: false, type: 'camera' }),
                              }
                            : null,
                        type !== 'image' ? { type: 'File' as const, onAdd: handleAdd } : null,
                    ].filter(isTruthy)}
                    onClose={onClose}
                />
            ) : null}
            {permission}
        </>
    );
};

export default FileSelector;
