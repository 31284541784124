import React, { ReactElement } from 'react';
import Icon from './Icon';
import { getUrlExtension, isWeb } from '../../_utils';

interface FileIconProps {
    folder?: false;
    fileName: string;
}
interface FolderIconProps {
    folder: true | 'shared' | 'private';
    fileName?: undefined;
}

const FileIcon = ({ fileName, folder }: FileIconProps | FolderIconProps): ReactElement => {
    const fileFormat = getUrlExtension(fileName ?? '');
    const icon = folder
        ? folder === 'shared'
            ? 'sharedColoredFolder'
            : folder === 'private'
              ? 'privateColoredFolder'
              : 'coloredFolder'
        : fileFormat
          ? fileFormat === 'txt'
              ? 'txtFile'
              : fileFormat === 'pdf'
                ? 'pdfFile'
                : fileFormat === 'xml'
                  ? 'xmlFile'
                  : fileFormat === 'zip'
                    ? 'zipFile'
                    : 'docFile'
          : 'coloredFolder';
    return <Icon scale={isWeb() ? 2 : undefined} name={icon} color="black" />;
};

export default FileIcon;
