import React, { ComponentProps, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import DeleteAccountModal from './DeleteAccountModal';
import { AVAILABLE_LANGUAGES } from '../../_localization';
import useAppNavigation from '../../_navigator/hooks/useAppNavigation';
import { NavigationDestination } from '../../_navigator/navigationConfiguration/types';
import { Theme, useThemeStyle, useGlobalState, isiOS } from '../../_utils';
import { useSelectedCoopItem } from '../../_utils/hooks';
import { FullPageMenuComponent, HeaderWithNav, Icon } from '../../Components';
import LanguageSelectorModal from '../../Components/LanguageSelectorModal';
import { ArrayElement } from '../../types/Utility';

type IconProperty = ComponentProps<typeof Icon>['name'];
type MenuType = {
    icon: IconProperty;
    title: string;
    navigateTo: NavigationDestination;
};

const getMapFunction =
    (navigate: ReturnType<typeof useAppNavigation>['navigate']) =>
    ({
        navigateTo,
        ...rest
    }: MenuType): Extract<
        ArrayElement<ComponentProps<typeof FullPageMenuComponent>['items']>,
        { onPress?: () => void }
    > => ({
        ...rest,
        onPress: navigateTo
            ? () =>
                  navigateTo === 'PrivacyPolicyNotOnboarding'
                      ? navigate('PrivacyPolicyNotOnboarding', {
                            isNotOnboarding: 'true',
                        })
                      : navigate(navigateTo, undefined)
            : undefined,
        children: [],
    });
const UserMenu = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { navigate } = useAppNavigation();
    const { t } = useTranslation();
    const hasPayment = (useSelectedCoopItem()?.payment_accounts.length ?? 0) > 0;
    const { selectedLanguage } = useGlobalState((state) => state.selectedLanguage);
    const [modalOpen, setModalOpen] = useState<'languageSelector' | 'deleteAccount' | false>(false);
    const handleModalOpen = () => setModalOpen('languageSelector');
    const handleDeleteAccount = () => setModalOpen('deleteAccount');
    const handleModalClose = () => setModalOpen(false);
    const menuItems = useMemo(() => {
        const MenuObjects: MenuType[] = [];
        if (hasPayment) {
            MenuObjects.push({
                icon: 'terms',
                title: t('userMenu:terms:title'),
                navigateTo: 'TermsOfSale',
            });
        }
        MenuObjects.push({
            icon: 'privacy',
            title: t('userMenu:privacy'),
            navigateTo: 'PrivacyPolicyNotOnboarding',
        });

        const items: ComponentProps<typeof FullPageMenuComponent>['items'] = MenuObjects.map(getMapFunction(navigate));
        items.push({
            icon: 'language',
            title: t('userMenu:language', { language: t(AVAILABLE_LANGUAGES[selectedLanguage ?? 'no'].title) }),
            onPress: handleModalOpen,
            children: [],
        });
        if (isiOS()) {
            items.push({
                icon: 'trash',
                title: t('userMenu:deleteAccount'),
                onPress: handleDeleteAccount,
                children: [],
                variant: 'danger',
            });
        }
        return items;
    }, [hasPayment, navigate, selectedLanguage, t]);

    return (
        <>
            <HeaderWithNav style={themedStyle.header} title={t('moreMenu:settings')} safeArea />
            <FullPageMenuComponent items={menuItems} />
            {modalOpen === 'languageSelector' ? (
                <LanguageSelectorModal onDismiss={handleModalClose} />
            ) : modalOpen === 'deleteAccount' ? (
                <DeleteAccountModal onDismiss={handleModalClose} />
            ) : null}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        header: { backgroundColor: theme.mainBackground },
    });

export default UserMenu;
