import React, { ComponentProps, ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, StyleProp, TextStyle, ViewStyle, FlatList } from 'react-native';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
    Easing,
    ExitAnimationsValues,
    withDelay,
    SharedValue,
} from 'react-native-reanimated';
import { isTruthy, Theme, useThemeStyle, WH, WW } from '_utils';
import { smallestFontSize, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { CacheImage, Icon, HeimeText } from 'Components';
import { ApiImage, FileImage } from 'types/Base';

type Image = {
    source: ApiImage | { original: string } | FileImage;
    onRemove(): void;
    isUploading: boolean;
    uploadProgress: number;
};

interface ImageAttachmentSelectorProps {
    images: Image[];
    onAdd(): void;
    title?: string;
    optional?: boolean;
    titleStyles?: StyleProp<TextStyle>;
    style?: StyleProp<ViewStyle>;
}

const ImageAttachmentSelector = ({
    images,
    onAdd,
    style,
    title,
    optional = false,
    titleStyles,
}: ImageAttachmentSelectorProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const renderImage = (item: Image, size: 'big' | 'small', index: number) => (
        <View style={themedStyle.imageContainer}>
            <CacheImage
                resizeMode={'contain'}
                key={index}
                style={size === 'big' ? themedStyle.imageBig : themedStyle.imageSmall}
                source={item.source}
            />
            {!item.isUploading ? (
                <TouchableOpacity onPress={item.onRemove} style={themedStyle.removeIcon}>
                    <Icon name="trash" color="error" />
                </TouchableOpacity>
            ) : null}
            <UploadProgress
                height={size === 'big' ? bigImageHeight : smallImageHeight}
                progress={item.uploadProgress}
                isVisible={item.isUploading}
            />
        </View>
    );

    const renderAddImage = (size: 'big' | 'small') => (
        <View style={themedStyle.imageContainer}>
            <TouchableOpacity
                activeOpacity={1}
                onPress={onAdd}
                style={[themedStyle.avatarWrapper, size === 'big' ? themedStyle.imageBig : themedStyle.imageSmall]}
            >
                <View style={themedStyle.addIconWrapper}>
                    <Icon name="addImage" color="secondaryLight" />
                </View>
                {size === 'big' ? <HeimeText style={themedStyle.addText}>{t('global:addImage')}</HeimeText> : null}
            </TouchableOpacity>
        </View>
    );

    return (
        <View style={style}>
            {title && (
                <View style={themedStyle.titleWrapper}>
                    <HeimeText style={[themedStyle.title, titleStyles]}>{title}</HeimeText>
                    {optional && (
                        <HeimeText style={[themedStyle.optional, titleStyles]}>{t('global:optional')}</HeimeText>
                    )}
                </View>
            )}
            {images.length > 0 ? renderImage(images[0], 'big', 0) : renderAddImage('big')}
            <FlatList
                numColumns={3}
                data={[...[...images].slice(1), images.length > 0 ? { type: 'addImage' as const } : null].filter(
                    isTruthy,
                )}
                renderItem={({ item, index }: { item: Image | { type: 'addImage' }; index: number }) => {
                    if ('type' in item && item.type === 'addImage') {
                        return renderAddImage('small');
                    } else if (!('type' in item)) {
                        return renderImage(item, 'small', index);
                    }
                    return null;
                }}
            />
        </View>
    );
};

const customExiting = (height: number, opacity: SharedValue) => (values: ExitAnimationsValues) => {
    'worklet';
    const duration = 500;

    const animations = {
        height: withTiming(height, { duration }),
        originY: withTiming(values.currentOriginY - height + values.currentHeight, { duration }),
        opacity: withDelay(duration, withTiming(0, { duration: 300 })),
    };
    const initialValues = {
        height: values.currentHeight,
        originY: values.currentOriginY,
        opacity: opacity.value,
    };

    return { animations, initialValues };
};

const duration = 2000;
const UploadProgress = ({ progress, isVisible, height }: { progress: number; isVisible: boolean; height: number }) => {
    const pulse = useSharedValue(0);
    const progressValue = useSharedValue(progress);
    const { theme } = useThemeContext();

    useEffect(() => {
        pulse.value = withRepeat(
            withTiming(0.7, { duration }),
            -1, // Infinite repetitions
            true, // Reverse the animation
        );
    }, [pulse]);

    useEffect(() => {
        progressValue.value = withTiming(progress, {
            duration: 300,
            easing: Easing.out(Easing.cubic),
        });
    }, [progress, progressValue]);

    const animatedStyle = useAnimatedStyle(() => {
        return { opacity: pulse.value, height: `${10 + (progressValue.value / 100) * 90}%` };
    });

    const exit = useMemo(() => customExiting(height, pulse), [height, pulse]);

    if (!isVisible) {
        return null;
    }
    return (
        <Animated.View
            style={[
                animatedStyle,
                {
                    position: 'absolute',
                    width: '100%',
                    bottom: 0,
                    opacity: 1,
                    backgroundColor: theme.main,
                    zIndex: 100,
                },
            ]}
            exiting={exit as ComponentProps<typeof Animated.View>['exiting']}
        />
    );
};

const bigImageHeight = WW * 0.7;
const smallImageHeight = WW * 0.28;

const styles = (theme: Theme) =>
    StyleSheet.create({
        titleWrapper: { flexDirection: 'row' },
        title: {
            fontWeight: 'bold',
            fontSize: smallestFontSize,
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            color: theme.black,
        },
        optional: {
            fontSize: smallestFontSize,
            color: theme.secondaryText,
            marginLeft: WW * 0.01,
            marginRight: WW * 0.01,
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
        },
        imageSmall: {
            width: WW * 0.28,
            height: smallImageHeight,
            marginTop: WW * 0.04,
            marginRight: WW * 0.04,
        },
        imageBig: {
            width: WW * 0.92,
            height: bigImageHeight,
        },
        avatarWrapper: {
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.mediumBackground,
        },
        addIconWrapper: {
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            backgroundColor: theme.mainBackground,
            borderRadius: 50,
        },
        addText: {
            fontSize: subtitleFontSize,
            color: theme.black,
            marginTop: smallestMargin,
        },
        removeIcon: {
            position: 'absolute',
            right: WW * 0.05,
            top: WW * 0.05,
            borderRadius: 50,
            backgroundColor: theme.mainBackground,
            paddingTop: WW * 0.015,
            paddingBottom: WW * 0.015,
            paddingLeft: WW * 0.015,
            paddingRight: WW * 0.015,
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
        },
    });

export default ImageAttachmentSelector;
