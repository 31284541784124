import { ComponentProps, memo } from 'react';
import { ScrollView, TouchableOpacity, useColorScheme } from 'react-native';
import { isWeb } from '_utils';
import { useThemeContext } from '_utils/themeContext';
import Icon from 'Components/Icon/Icon';

const RichTextToolbar = ({
    buttons,
}: {
    buttons: Array<{
        active: boolean;
        icon: ComponentProps<typeof Icon>['name'];
        disabled?: boolean;
        onPress: () => void;
    }>;
}) => {
    const { theme } = useThemeContext();

    const colorScheme = useColorScheme();

    const colors =
        colorScheme === 'dark' && !isWeb()
            ? ({
                  backgroundColorActive: theme.mainBackground,
                  iconColorActive: 'darkGray',
                  iconColorInactive: 'mainBackground',
              } as const)
            : ({
                  backgroundColorActive: theme.lightGreen,
                  iconColorActive: 'main',
                  iconColorInactive: 'darkGray',
              } as const);

    return (
        <ScrollView horizontal style={{ flex: 1 }} contentContainerStyle={{ flex: 1, gap: 5, alignItems: 'center' }}>
            {buttons.map(({ active, icon, disabled, onPress }) => (
                <TouchableOpacity
                    key={icon}
                    style={{
                        padding: 5,
                        backgroundColor: active ? colors.backgroundColorActive : undefined,
                        borderRadius: 24,
                        opacity: disabled ? 0.5 : 1,
                    }}
                    onPress={disabled ? undefined : onPress}
                    disabled={disabled}
                >
                    <Icon
                        name={icon}
                        color={active ? colors.iconColorActive : colors.iconColorInactive}
                        style={{ width: 24, height: 24 }}
                    />
                </TouchableOpacity>
            ))}
        </ScrollView>
    );
};

export default memo(RichTextToolbar);
