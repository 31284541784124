import React, { ComponentProps, useEffect } from 'react';
import { Path, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileImageSelector from 'Components/FileSelectors/FileImageSelector';
import { FileAction, fileReducer } from 'Components/FileSelectors/fileState';
import { FormFile } from 'types/Base';

interface FormFileImageSelectorProps<FormType>
    extends Omit<ComponentProps<typeof FileImageSelector>, 'files' | 'onAction'> {
    name: Path<FormType>;
}

const FormFileImageSelector = <FormType extends Record<string, Array<FormFile>>>({
    name,
    ...fileSelectorProps
}: FormFileImageSelectorProps<FormType>) => {
    const { t } = useTranslation();
    const { field } = useController<FormType>({ name });
    const { getValues, setError } = useFormContext<FormType>();

    const handleChange = (action: FileAction) => {
        const newState = fileReducer(getValues(name), action);
        field.onChange(newState, { shouldValidate: true });
    };

    useEffect(() => {
        (field.value as Array<FormFile>).map((file) => {
            if (file.state.status === 'UPLOADING') {
                setError('block_submit' as typeof name, { message: t('global:uploading_file') });
            }
        });
    }, [field.value, setError, t]);

    return <FileImageSelector {...fileSelectorProps} files={field.value} onAction={handleChange} />;
};

export default FormFileImageSelector;
