import { useCallback } from 'react';
import 'react-native-get-random-values';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useUploadFile } from '_api/useFile';
import { isAndroid, showToast } from '_utils';
import { captureException } from '_utils/Sentry';
import { FormFile, SelectedLocalFile } from 'types/Base';

export type FileAction =
    | { type: 'SELECT_FILE'; id: string; url: string; name: string; size: number }
    | { type: 'UPDATE_PROGRESS'; id: string; progress: number }
    | { type: 'UPLOAD_COMPLETE'; id: string; new_id: string }
    | { type: 'REMOVE_FILE'; id: string };

export const fileReducer = (state: FormFile[], action: FileAction): FormFile[] => {
    switch (action.type) {
        case 'SELECT_FILE':
            return [
                ...state,
                {
                    id: action.id,
                    name: action.name,
                    state: { status: 'UPLOADING', progress: 0, url: action.url, size: action.size },
                },
            ];
        case 'UPDATE_PROGRESS':
            return state.map((file) =>
                file.id === action.id ? { ...file, state: { ...file.state, progress: action.progress } } : file,
            );
        case 'UPLOAD_COMPLETE':
            return state.map((file) => {
                if (file.id !== action.id) {
                    return file;
                }
                if (file.state.status === 'UPLOADING') {
                    return {
                        ...file,
                        state: { status: 'UPLOADED', url: file.state.url, size: file.state.size },
                        id: action.new_id,
                    };
                } else {
                    throw new Error('Unexpected state: ' + file.state.status);
                }
            });
        case 'REMOVE_FILE':
            return state
                .filter((file) => file.id !== action.id || file.state.status === 'EXIST')
                .map((file) => (file.id === action.id ? { ...file, state: { status: 'REMOVED' } } : file));
        default:
            throw new Error('Unexpected action:' + (action as { type: unknown }).type);
    }
};

export const useHandleAdd = (onAction: (action: FileAction) => void) => {
    const { t } = useTranslation();
    const { mutateAsync } = useUploadFile();

    const handleAdd = useCallback(
        (addedFiles: Array<SelectedLocalFile>) =>
            addedFiles.map(async (file) => {
                const id = nanoid();
                onAction({
                    type: 'SELECT_FILE',
                    id,
                    name: file.name,
                    url:
                        file instanceof File
                            ? URL.createObjectURL(file)
                            : isAndroid()
                              ? `file://${file.path}`
                              : file.path,
                    size: file.size,
                });
                try {
                    const onProgress = (progress: number) =>
                        onAction({
                            type: 'UPDATE_PROGRESS',
                            id,
                            progress,
                        });
                    const uploadResult = await mutateAsync({
                        file,
                        onProgress,
                    });
                    onAction({
                        type: 'UPLOAD_COMPLETE',
                        id,
                        new_id: uploadResult.success,
                    });
                } catch (e) {
                    captureException(e);
                    showToast({
                        type: 'error',
                        header: t('global:upload_failed'),
                        text: '',
                    });
                    onAction({
                        type: 'REMOVE_FILE',
                        id,
                    });
                }
            }),
        [mutateAsync, onAction, t],
    );

    return handleAdd;
};

export const GetRemoveAction = (file: FormFile) => ({
    type: 'REMOVE_FILE' as const,
    id: file.id,
});
