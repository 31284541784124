import { FormFile } from 'types/Base';

type NonRemovedFormFile = FormFile & { state: { status: Exclude<FormFile['state']['status'], 'REMOVED'> } };

const isRemovedFile = (file: FormFile): file is NonRemovedFormFile => {
    return file.state.status !== 'REMOVED';
};

const GetFormFilesToDisplay = (files: Array<FormFile>): Array<NonRemovedFormFile> => files.filter(isRemovedFile);

export default GetFormFilesToDisplay;
