import React, { ReactElement, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, StyleSheet, View } from 'react-native';
import { TimeZone } from '_constants';
import { flattenIniniteResult } from '_utils/misc';
import { ProductHasPrice } from 'types/Category';
import { OwnReservation } from 'types/Reservation';
import {
    AmountSelector,
    BottomButtons,
    ExplainerModal,
    InfoButton,
    LicensePlateModal,
    ReasonModal,
    TimeExplainer,
    TimeSelector,
} from './components';
import isSlotTaken from './components/isSlotTaken';
import useBookedModal from './useBookedModal';
import useMutateReservation from './useMutateReservation';
import { useGetProduct } from '../../_api/useCategories';
import { useGetProductReservations } from '../../_api/useReservations';
import { Theme, useThemeStyle } from '../../_utils';
import { screenMargin } from '../../_utils/sizes';
import { Loader, HeaderWithNav, QuerySectionedView } from '../../Components';
import { UnixTimeCode } from '../../types/Utility';

export interface ProductDetailProps {
    route: {
        params: {
            categoryId?: number;
            productId: number;
            initialDate?: UnixTimeCode;
            existingReservation?: OwnReservation;
            skipPayment?: boolean;
        };
    };
}

const monthsToShow = Array(2)
    .fill(0)
    .map((_, i) => ({ index: i, id: i, showEarlyHours: i !== 0 }));

const ProductReserve = ({ route }: ProductDetailProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const existingReservation = route.params.existingReservation;
    const [modalOpen, setModalOpen] = useState<'reason' | 'info' | 'license_plate' | null>(null);
    const [amount, setAmount] = useState(existingReservation?.quantity ?? 1);
    const [timeSelection, setTimeSelection] = useState<{ from: null | Date; to: Date | null }>(
        existingReservation ? getSelectionFromReservation(existingReservation) : { from: null, to: null },
    );
    const [isSkippingPayment, setIsSkippingPayment] = useState(route.params.skipPayment ?? false);
    const [reason, setReason] = useState(route.params.existingReservation?.reason ?? '');
    const [viewingDate, setViewingDate] = useState<Date>(
        route.params.initialDate ? moment.unix(route.params.initialDate).toDate() : new Date(),
    );
    const [haveGoneBack, setHaveGoneBack] = useState(false);

    const {
        data: product,
        isLoading,
        isError,
        error,
        refetch,
        isRefetching,
    } = useGetProduct(route.params.productId, route.params.categoryId);
    const reservationsFromDate = moment(viewingDate).startOf('month');
    const {
        refetch: refetchReservations,
        isRefetching: isRefetchingReservations,
        data: bookings,
    } = useGetProductReservations(route.params.productId, reservationsFromDate);
    const { mutate, isMutating } = useMutateReservation(
        route.params.productId,
        existingReservation,
        timeSelection,
        isSkippingPayment,
        reason,
        amount,
    );

    const [bookedModal, openBookedModal] = useBookedModal(route.params.productId, reservationsFromDate);

    const productSettings = useMemo(() => {
        const [startHour, startMinute] = (product?.booking_start ?? '').split(':').map((num) => parseInt(num, 10));
        const [endHour, endMinute] = (product?.booking_end ?? '').split(':').map((num) => parseInt(num, 10));

        return {
            checkinHour: startHour ?? 15,
            checkinMinute: startMinute ?? 0,
            checkoutHour: endHour ?? 12,
            checkoutMinute: endMinute ?? 0,
        };
    }, [product?.booking_end, product?.booking_start]);

    if (isError) {
        throw error;
    }

    const isShortTerm = product?.booking_time ?? false;

    const handleRefetch = () => {
        refetch();
        refetchReservations();
    };
    const handleSelectionChange = (from: Date | null, to: Date | null) => {
        setTimeSelection({ from, to });
    };

    const handleToggleSkipPayment = () => {
        setIsSkippingPayment((curr) => !curr);
    };

    const handleClearSelection = () => {
        if (existingReservation && moment().isAfter(moment.unix(existingReservation.start_at))) {
            handleSelectionChange(timeSelection.from, null);
        } else {
            handleSelectionChange(null, null);
        }
    };

    const renderItem = ({ item: { index, showEarlyHours } }: { item: { index: number; showEarlyHours: boolean } }) => {
        const itemDate = moment(viewingDate)
            .add(isShortTerm ? { day: index } : { month: index })
            .startOf('day');
        if (!product) {
            return <></>;
        }

        const handleChangeViewingDate = (newDate: Date, isStep: boolean, skipAnimation: boolean) => {
            if (!haveGoneBack && moment().isAfter(newDate)) {
                setHaveGoneBack(true);
                if (isStep) {
                    if (!skipAnimation) {
                        LayoutAnimation.configureNext(
                            LayoutAnimation.create(
                                200,
                                LayoutAnimation.Types.easeInEaseOut,
                                LayoutAnimation.Properties.scaleY,
                            ),
                        );
                    }
                    if (moment().subtract({ day: 1 }).isSame(newDate, 'day')) {
                        return;
                    }
                }
            }
            if (!skipAnimation) {
                LayoutAnimation.configureNext(
                    LayoutAnimation.create(
                        150,
                        LayoutAnimation.Types.easeInEaseOut,
                        LayoutAnimation.Properties.opacity,
                    ),
                );
            }
            setViewingDate(
                moment(newDate)
                    .subtract(isShortTerm ? { day: index } : { month: index })
                    .toDate(),
            );
        };

        return (
            <TimeSelector
                productId={product.id}
                isShortTerm={isShortTerm}
                onSelectionChange={handleSelectionChange}
                viewingDate={itemDate.toDate()}
                onChangeViewingDate={handleChangeViewingDate}
                maxBooking={product.max_booking}
                selection={timeSelection}
                bookingToEdit={route.params.existingReservation}
                productSettings={productSettings}
                haveGoneBack={haveGoneBack}
                defaultShowEarlyHours={showEarlyHours}
                loadReservationsFrom={reservationsFromDate}
                amount={amount}
                capacity={product.capacity}
            />
        );
    };

    const handleReserve = () => {
        if (
            product?.requires_license_plate ||
            product?.can_provide_booking_reason ||
            (isSkippingPayment && product?.can_provide_booking_reason_if_skipping_payment)
        ) {
            setModalOpen(product?.requires_license_plate ? 'license_plate' : 'reason');
        } else {
            mutate();
        }
    };

    const userIsOnLocalTime = moment().tz(TimeZone).utcOffset() === moment().utcOffset();
    const localTimeString = userIsOnLocalTime
        ? ''
        : ` ${t('serviceExpanded:localTime')} ${moment().tz(TimeZone).format('Z')}`;

    const disableAmountIncrease = useMemo(() => {
        const productMaxQuantity = product?.max_booked_per_booking
            ? product?.max_booked_per_booking
            : (product?.capacity ?? 1);
        if (amount >= productMaxQuantity) {
            return true;
        }

        if (timeSelection.from === null || timeSelection.to === null) {
            return false;
        }

        return isSlotTaken({
            start: timeSelection.from.valueOf() / 1000,
            end: timeSelection.to.valueOf() / 1000,
            capacity: productMaxQuantity,
            bookings: flattenIniniteResult(bookings).filter((booking) => booking.id !== existingReservation?.id),
            amount: amount + 1,
        });
    }, [
        amount,
        bookings,
        existingReservation?.id,
        product?.capacity,
        product?.max_booked_per_booking,
        timeSelection.from,
        timeSelection.to,
    ]);

    return (
        <View style={themedStyle.main}>
            <HeaderWithNav
                title={t('navigation:reservations').toLocaleUpperCase()}
                safeArea
                action={<InfoButton onPress={() => setModalOpen('info')} />}
            />
            {isLoading || !product ? (
                <Loader />
            ) : (
                <>
                    <QuerySectionedView
                        sections={[{ data: monthsToShow }]}
                        onRefresh={handleRefetch}
                        isRefreshing={isRefetching || isRefetchingReservations}
                        renderItem={renderItem}
                        top={
                            <View style={themedStyle.container}>
                                {product.capacity > 1 &&
                                (!product.max_booked_per_booking || product.max_booked_per_booking > 1) ? (
                                    <AmountSelector
                                        amount={amount}
                                        onSetAmount={setAmount}
                                        maxAmount={product.capacity}
                                        disableIncrease={disableAmountIncrease}
                                        onDisabledIncreasePress={
                                            disableAmountIncrease && amount !== product.max_booked_per_booking
                                                ? () => openBookedModal(timeSelection as { from: Date; to: Date })
                                                : undefined
                                        }
                                    />
                                ) : null}
                                {!product.booking_time ? (
                                    <TimeExplainer
                                        from={`${product.booking_start ?? ''} ${localTimeString}`}
                                        to={`${product.booking_end ?? ''} ${localTimeString}`}
                                    />
                                ) : null}
                            </View>
                        }
                    />
                    <BottomButtons
                        canSkipPayment={ProductHasPrice(product) && product.can_skip_payment}
                        isSkippingPayment={isSkippingPayment}
                        toggleSkipPayment={handleToggleSkipPayment}
                        selection={timeSelection}
                        onClearSelection={handleClearSelection}
                        onReserve={handleReserve}
                        isReserving={isMutating}
                        strategy={product.paymentStrategy}
                        quantity={amount}
                        bookingBeingUpdated={
                            existingReservation ? getSelectionFromReservation(existingReservation) : null
                        }
                        isShortTerm={product.booking_time}
                    />
                    {modalOpen === 'reason' ? (
                        <ReasonModal
                            reason={reason}
                            setReason={setReason}
                            onComplete={mutate}
                            onClose={() => setModalOpen(null)}
                            required={
                                product?.requires_booking_reason ||
                                (isSkippingPayment && product?.requires_booking_reason_if_skipping_payment)
                            }
                        />
                    ) : null}
                    {modalOpen === 'license_plate' ? (
                        <LicensePlateModal
                            reason={reason}
                            setReason={setReason}
                            onComplete={mutate}
                            onClose={() => setModalOpen(null)}
                        />
                    ) : null}
                    {modalOpen === 'info' ? (
                        <ExplainerModal
                            onClose={() => setModalOpen(null)}
                            is_long_term={!product.booking_time}
                            has_max_booking={product.max_booking !== 0}
                            max_booking={product.max_booking}
                            max_booked_per_booking={product.max_booked_per_booking}
                            hasTimeBasedPricing={product.paymentStrategy?.type === 'rate_per_hour_with_night_price'}
                        />
                    ) : null}
                    {bookedModal}
                </>
            )}
        </View>
    );
};

const getSelectionFromReservation = (reservation: OwnReservation) => {
    return {
        from: new Date(reservation.start_at * 1000),
        to: new Date(reservation.end_at * 1000),
    };
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: { backgroundColor: theme.mainBackground, display: 'flex', height: '100%' },
        container: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            marginBottom: screenMargin / 2,
        },
    });

export default ProductReserve;
