import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { OwnReservation } from 'types/Reservation';
import { UnixTimeCode } from 'types/Utility';
import { useEditReservation, useReserveProduct, ReserveResult } from '../../_api/useReservations';
import { useAppNavigation } from '../../_navigator';
import { isAppError, isWeb, openURL, showToast } from '../../_utils';

const useMutateReservation = (
    productId: number,
    existingReservation: OwnReservation | undefined,
    timeSelection: { from: Date | null; to: Date | null },
    skip_payment: boolean,
    reason: string,
    amount: number,
): { mutate: () => void; isMutating: boolean } => {
    const { t } = useTranslation();
    const { mutate: reserve, isPending: isReserving } = useReserveProduct();
    const { navigate, goBack } = useAppNavigation();

    const handleSuccess = useCallback(
        (
            { success }: ReserveResult,
            data:
                | { start_at: UnixTimeCode; end_at: UnixTimeCode }
                | [number, { start_at: UnixTimeCode; end_at: UnixTimeCode }],
        ) => {
            if (success) {
                if (success.redirect) {
                    if (isWeb()) {
                        window.location.href = success.redirect;
                    } else {
                        if (success.transaction_id) {
                            // Reset tab state first
                            const transaction_id = success.transaction_id;
                            goBack();
                            setTimeout(() => {
                                navigate('Transaction', { transaction_id });
                            }, 0);
                        }
                        openURL(success.redirect, t);
                    }
                } else {
                    let accData: { start_at: UnixTimeCode; end_at: UnixTimeCode } = 'start_at' in data ? data : data[1];

                    // Reset tab state first
                    goBack();
                    setTimeout(() => {
                        navigate('ReservationFinal', {
                            start: accData.start_at,
                            end: accData.end_at,
                            product_id: productId,
                            booking_id: success.booking_id,
                            quantity: amount,
                        });
                    }, 0);
                }
            }
        },
        [amount, goBack, navigate, productId, t],
    );

    const handleError = useCallback(
        (e: unknown) => {
            if (isAppError<{ end_at: string; start_at: string }>(e)) {
                const requestError = e.response?.data.errors;
                if (
                    requestError?.end_at?.[0]?.includes('taken') ||
                    requestError?.start_at?.[0]?.includes('taken') ||
                    requestError?.start_at?.[0]?.includes('opptatt') ||
                    requestError?.end_at?.[0]?.includes('opptatt')
                ) {
                    showToast({
                        header: t('reservations:alreadyReserved_title'),
                        text: t('reservations:alreadyReserved_description'),
                        type: 'error',
                    });
                    return;
                }
            }
            if (existingReservation) {
                showToast({
                    header: t('reservations:errorUpdate'),
                    text: '',
                    type: 'error',
                });
            } else {
                showToast({
                    header: t('reservations:errorCreate'),
                    text: '',
                    type: 'error',
                });
            }
        },
        [existingReservation, t],
    );

    const handleReserve = useCallback((): void => {
        if (!timeSelection.from || !timeSelection.to) {
            return;
        }
        reserve(
            [
                productId,
                {
                    start_at: moment(timeSelection.from).unix(),
                    end_at: moment(timeSelection.to).unix(),
                    skip_payment,
                    reason,
                    quantity: amount,
                },
            ],
            {
                onSuccess: handleSuccess,
                onError: handleError,
            },
        );
    }, [
        timeSelection.from,
        timeSelection.to,
        reserve,
        productId,
        skip_payment,
        reason,
        amount,
        handleSuccess,
        handleError,
    ]);

    const { mutate: edit, isPending: isEditing } = useEditReservation();
    const handleEdit = useCallback((): void => {
        if (!timeSelection.from || !timeSelection.to || !existingReservation) {
            return;
        }
        edit(
            {
                start_at: moment(timeSelection.from).unix(),
                end_at: moment(timeSelection.to).unix(),
                skip_payment,
                reason,
                bookingId: existingReservation.id,
                productId: existingReservation.booked.id,
                quantity: amount,
            },
            {
                onSuccess: handleSuccess,
                onError: handleError,
            },
        );
    }, [
        timeSelection.from,
        timeSelection.to,
        existingReservation,
        edit,
        skip_payment,
        reason,
        amount,
        handleSuccess,
        handleError,
    ]);

    return useMemo(
        () => ({ mutate: existingReservation ? handleEdit : handleReserve, isMutating: isReserving || isEditing }),
        [existingReservation, handleEdit, handleReserve, isEditing, isReserving],
    );
};

export default useMutateReservation;
