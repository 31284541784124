import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import FormFileImageSelector from 'Components/FileSelectors/FormFileImageSelector';
import FormRichText from 'Components/Form/FormRichText/FormRichText';
import _fonts from '../../../../_fonts';
import { Theme, useThemeStyle, WW } from '../../../../_utils';
import { CooperativeGroupDropdown, FormInput, PrimaryButton } from '../../../../Components';
import { FormValues } from '../SharingNew';

const AddProduct = ({
    onSubmit,
    isLoading,
}: {
    onSubmit: (values: FormValues) => void;
    isLoading?: boolean;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { handleSubmit, formState, watch } = useFormContext<FormValues>();

    const isEditing = watch('isEditing');

    const disableButton = !formState.isValid;

    return (
        <>
            <Text style={themedStyle.title}>{t('sharingNew:addProduct:title')}</Text>
            <FormFileImageSelector
                name="files"
                title={t('sharingNew:addProduct:image')}
                optional={true}
                titleStyles={themedStyle.imageSelectorTitle}
            />
            <FormInput
                placeholder={t('sharingNew:addProduct:enterTitle')}
                name="title"
                label={t('sharingNew:addProduct:textTitle')}
                autoFocus
            />
            <FormRichText
                name="richtext_content"
                label={t('sharingNew:addProduct:textDesc')}
                placeholder={t('sharingNew:addProduct:enterDesc')}
            />
            <CooperativeGroupDropdown isCreatingNew={!isEditing} />
            <PrimaryButton
                style={themedStyle.button}
                status={isLoading ? 'loading' : disableButton ? 'disabled' : null}
                text={t('sharingNew:publish').toUpperCase()}
                onPress={handleSubmit(onSubmit)}
            />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.02,
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            fontSize: WW * 0.05,
        },
        imageSelectorTitle: {
            fontSize: WW * 0.04,
        },
        button: {
            marginTop: WW * 0.04,
            marginBottom: WW * 0.04,
        },
    });
export default AddProduct;
