import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileViewer from 'react-native-file-viewer';
import Orientation from 'react-native-orientation-locker';
import { ModalLoader } from 'Components';
import { isWeb, isiOS, openURL, showToast } from '..';
import { DocumentDirectoryPath, downloadFile, exists } from '../../_dependencies/fileSystem';
import { useAppNavigation } from '../../_navigator';
import { Document, File, Folder } from '../../types/files';
import getUrlExtension from '../getUrlExtension';
import { captureException } from '../Sentry';

const handleOpenFile = ({ file, fileName, onDismiss }: { file: string; fileName: string; onDismiss?: () => void }) => {
    if (isiOS()) {
        Orientation.unlockAllOrientations();
    }
    return FileViewer.open(file, {
        displayName: fileName,
        showOpenWithDialog: true,
        showAppsSuggestions: true,
        onDismiss: () => {
            Orientation.lockToPortrait();
            onDismiss?.();
        },
    });
};

const useDocumentsView = (): [ReactElement | null, (item: Document) => void] => {
    const [downloadingStatus, setDownloading] = useState<false | 'downloading' | 'dismissedDownload'>(false);
    const { t } = useTranslation();
    const { push } = useAppNavigation();

    const handlePress = async (item: Document) => {
        if ((item as Folder).files !== undefined) {
            push('FolderSelected', { document: item as Folder });
        } else {
            const file = item as File;
            if (isWeb()) {
                openURL(file.url, t);
                return;
            }
            const extension = getUrlExtension(file.name) || getUrlExtension(file.url);
            const localFile = `${DocumentDirectoryPath}/${file.name.replace(`.${extension}`, '')}.${
                file.last_modified
            }.${extension}`;
            const fileExists = await exists(localFile);

            if (fileExists) {
                try {
                    await handleOpenFile({ file: localFile, fileName: file.name });
                } catch (e) {
                    captureException(e);
                    openURL(file.url, t); // Fallback
                }
            } else {
                setDownloading('downloading');
                const options = {
                    fromUrl: file.url,
                    toFile: localFile,
                };
                try {
                    const result = await downloadFile(options).promise;
                    if (result.statusCode === 200) {
                        // Rerendering closes the fileview
                        try {
                            await handleOpenFile({
                                file: localFile,
                                fileName: file.name,
                                onDismiss: () => {
                                    setDownloading(false);
                                },
                            });
                        } catch (e) {
                            openURL(file.url, t); // Fallback
                            setDownloading(false);
                            captureException(e);
                        }
                    } else {
                        throw new Error(`Downloading file returned ${result.statusCode} for url: ${file.url}`);
                    }
                } catch (e) {
                    captureException(e);
                    setDownloading(false);
                    showToast({ header: t('documents:error'), type: 'error', text: '' });
                }
            }
        }
    };
    const handleDismissLoader = () => {
        setDownloading('dismissedDownload');
    };

    return [downloadingStatus === 'downloading' ? <ModalLoader onDismiss={handleDismissLoader} /> : null, handlePress];
};

export default useDocumentsView;
