import React, { ComponentProps, ReactElement, useContext, useEffect, useState } from 'react';
import { ActivityIndicator, StyleProp, StyleSheet, Text, TextStyle, TouchableHighlight, ViewStyle } from 'react-native';
import { screenMargin, smallestMargin } from '_utils/sizes';
import Icon from './Icon/Icon';
import _fonts from '../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../_utils';
import { ThemeContext } from '../_utils/themeContext';

interface PrimaryButtonProps {
    status?: 'disabled' | 'loading' | null;
    onPress(): void;
    text: string;
    style?: StyleProp<ViewStyle>;
    danger?: boolean;
    bottomAction?: boolean | 'modal';
    icon?: ComponentProps<typeof Icon>['name'];
    textStyle?: StyleProp<TextStyle>;
}

const PrimaryButton = ({
    status,
    onPress,
    text,
    style,
    danger,
    bottomAction,
    icon,
    textStyle,
}: PrimaryButtonProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const disabled = status === 'disabled';
    const disablePress = disabled || status === 'loading';
    const [hidingUnderlay, setHidingUnderlay] = useState(true);

    useEffect(() => {
        if (status) {
            setHidingUnderlay(true);
        }
    }, [status]);

    return (
        <TouchableHighlight
            // To reset touched state
            key={status}
            disabled={disabled}
            style={[
                themedStyle.main,
                disabled && themedStyle.disabled,
                status === 'loading' && themedStyle.loading,
                danger && themedStyle.danger,
                bottomAction
                    ? [themedStyle.bottomAction, bottomAction === 'modal' ? themedStyle.bottomActionModal : undefined]
                    : undefined,
                {
                    borderStyle: 'solid',
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                },
                style,
            ]}
            onPress={disablePress ? undefined : onPress}
            underlayColor={theme.secondaryBackground}
            onHideUnderlay={() => setHidingUnderlay(true)}
            onShowUnderlay={() => setHidingUnderlay(false)}
            accessibilityRole="button"
        >
            {status === 'loading' ? (
                <ActivityIndicator color={theme.mainText} />
            ) : (
                <>
                    <Text
                        style={[
                            themedStyle.text,
                            textStyle,
                            disabled && themedStyle.textDisabled,
                            !hidingUnderlay && themedStyle.hidingUnderlay,
                        ]}
                    >
                        {text}
                    </Text>
                    {icon ? (
                        <Icon
                            name={icon}
                            color={disabled ? 'secondaryText' : hidingUnderlay ? 'mainText' : 'main'}
                            style={themedStyle.icon}
                        />
                    ) : null}
                </>
            )}
        </TouchableHighlight>
    );
};

const fontSize = WW * 0.05;

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            paddingTop: WH * 0.015,
            paddingLeft: WH * 0.015,
            paddingRight: WH * 0.015,
            paddingBottom: WH * 0.015,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.main,
            borderColor: theme.main,
            display: 'flex',
            gap: smallestMargin,
            flexDirection: 'row',
        },
        disabled: {
            backgroundColor: theme.shadow,
            borderColor: theme.shadow,
        },
        loading: {
            backgroundColor: theme.secondaryText,
            borderColor: theme.secondaryText,
        },
        danger: {
            backgroundColor: theme.dangerBackground,
            borderColor: theme.dangerBackground,
        },
        text: {
            color: theme.mainText,
            textAlign: 'center',
            fontFamily: _fonts.primaryFontBold,
            fontSize: fontSize,
        },
        textDisabled: {
            color: theme.secondaryLight,
        },
        bottomAction: {
            marginRight: screenMargin,
            marginLeft: screenMargin,
        },
        bottomActionModal: {
            marginBottom: screenMargin,
        },
        hidingUnderlay: {
            color: theme.main,
        },
        icon: {
            width: 24,
            height: 24,
        },
    });

export default PrimaryButton;
