import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useContextMenuFocusEffect } from '_navigator';
import { showToast, Theme, useThemeStyle, WW } from '_utils';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { HeaderWithNav, HeimeText, Icon } from 'Components';
import DocumentList from 'Components/DocumentList';
import { Folder } from 'types/files';

const FolderSelected = ({ route }: { route: { params: { document: Folder } } }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { theme } = useThemeContext();
    const document = route.params.document;
    useContextMenuFocusEffect(['reportsNew']);

    const isPrivateFolder = 'source' in document && document.source === 'private';

    const handlePrivatePress = () => {
        showToast({
            type: 'info',
            text: t('documents:privateFolder'),
        });
    };

    return (
        <View style={themedStyle.container}>
            <HeaderWithNav
                title={
                    isPrivateFolder ? (
                        <TouchableOpacity
                            onPress={handlePrivatePress}
                            style={{
                                gap: smallestMargin,
                                alignItems: 'center',
                                flexDirection: 'row',
                                flex: 2,
                                paddingRight: screenMargin,
                            }}
                        >
                            <HeimeText
                                style={{
                                    color: theme.main,
                                    fontSize: WW * 0.04,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {document.name}
                            </HeimeText>
                            <Icon name="lock" color={'main'} />
                        </TouchableOpacity>
                    ) : (
                        document.name
                    )
                }
                action={isPrivateFolder ? <View /> : undefined}
                safeArea
            />
            <DocumentList data={document} searchedText={''} />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: theme.mainBackground,
        },
    });

export default FolderSelected;
