import React, { ReactElement } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { FormProvider, Path, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Activity } from '_api/useActivities';
import useSurveyAfterNavigation from '_utils/hooks/useSurveyAfterNavigation';
import { findInitialCooperativeGroupId } from 'types/Cooperative';
import {
    ActivityAbout,
    ActivityDateTime,
    ActivityLocation,
    ActivityPhoto,
    ActivityCost,
    ActivityAlert,
    ActivityAddDetailMenu,
    ActivityDescription,
    ActivityGroups,
    ActivityCooperativeGroup,
    ActivityArrangers,
} from './components';
import ActivityPreview from './components/ActivityPreview';
import { schema, type FormValues, mapToDefault } from './schema';
import useActivityNewNavigation from './useActivityNewNavigation';
import { useAppNavigation } from '../../../_navigator';
import { Theme, useConfirmDiscard, useGlobalState, useThemeStyle } from '../../../_utils';
import { useSelectedCoopItem } from '../../../_utils/hooks';

interface ActivityNewProps {
    route: { params?: { activity?: Activity; groups?: number[]; template?: string } };
}

const ActivityNew = ({ route }: ActivityNewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { goBack: internalGoBack } = useAppNavigation();
    const coop = useSelectedCoopItem();

    const formValues = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: mapToDefault(
            route.params?.activity,
            route.params?.groups,
            findInitialCooperativeGroupId(coop?.cooperative_groups ?? []),
            coop?.activity_default_account,
            coop?.payment_accounts.find(Boolean),
            route.params?.template,
        ),
        resolver: zodResolver(schema),
    });

    const { activityNewAbortQuestionAskedAt } = useGlobalState((state) => state.addProfileModal);
    const isEditing = Boolean(route.params?.activity);
    const willAskAboutCreationStop =
        isEditing &&
        (!activityNewAbortQuestionAskedAt ||
            moment.unix(activityNewAbortQuestionAskedAt).isBefore(moment().subtract(1, 'month')));

    // We do not show the discard if we want to ask the user why
    const { goBack: discardGoBack, content: discardContent } = useConfirmDiscard(
        formValues.formState.isDirty && !willAskAboutCreationStop,
        isEditing,
        internalGoBack,
    );

    const isSubmitting = formValues.formState.isSubmitting;
    const { content: surveyContent } = useSurveyAfterNavigation(
        'activityNew',
        !isSubmitting && !isEditing,
        discardGoBack,
    );

    const { page, handleNextPage, handleGoBack, goToPage } = useActivityNewNavigation(
        route.params?.activity ? 'preview' : 'create',
        discardGoBack,
    );

    const handleEditProperty = (property: Path<FormValues> | 'more') => {
        switch (property) {
            case 'more': {
                goToPage('more');
                return;
            }
            case 'files': {
                goToPage('files');
                return;
            }
            case 'name': {
                goToPage('create');
                return;
            }
            case 'description': {
                goToPage('description');
                return;
            }
            case 'start_at':
            case 'end_at':
            case 'last_confirm_at':
            case 'last_cancel_at': {
                goToPage('dateTime');
                return;
            }
            case 'quantity':
            case 'paymentStrategy.price': {
                goToPage('cost');
                return;
            }
            case 'message_type': {
                goToPage('alert');
                return;
            }
            case 'location': {
                goToPage('location');
                return;
            }
            case 'user_groups': {
                goToPage('groups');
                return;
            }
            case 'cooperative_group_id': {
                goToPage('cooperative_group');
                return;
            }
            case 'arrangers': {
                goToPage('arrangers');
                return;
            }
        }
    };

    return (
        <View style={themedStyle.main}>
            <FormProvider {...formValues}>
                {page[0] === 'create' ? <ActivityAbout nextPage={handleNextPage} goBack={handleGoBack} /> : undefined}
                {page[0] === 'preview' && <ActivityPreview goBack={handleGoBack} onEditProperty={handleEditProperty} />}
                {page[0] === 'files' && <ActivityPhoto goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'description' && (
                    <ActivityDescription goBack={handleGoBack} goToPreview={handleNextPage} />
                )}
                {page[0] === 'dateTime' && <ActivityDateTime goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'cost' && <ActivityCost goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'alert' && <ActivityAlert goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'location' && <ActivityLocation goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'groups' && <ActivityGroups goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'cooperative_group' && <ActivityCooperativeGroup goBack={handleGoBack} />}
                {page[0] === 'arrangers' && <ActivityArrangers goBack={handleGoBack} goToPreview={handleNextPage} />}
                {page[0] === 'more' && (
                    <ActivityAddDetailMenu goBack={handleGoBack} onEditProperty={handleEditProperty} />
                )}
            </FormProvider>
            {discardContent}
            {surveyContent}
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            backgroundColor: theme.mainBackground,
            height: '100%',
        },
    });

export default ActivityNew;
