import React, { ReactElement, useContext } from 'react';
import moment from 'moment';
import { StyleSheet, Text, View } from 'react-native';
import { useGetMessage } from '_api/useMessages';
import { useOwnProfile } from '_api/useProfile';
import RichTextView from 'Components/RichText/RichTextView';
import { SharingMessage } from 'types/message';
import _fonts from '../../../../_fonts';
import { fromNowFormat, useThemeStyle, WH, WW } from '../../../../_utils';
import { screenMargin } from '../../../../_utils/sizes';
import { Theme, ThemeContext } from '../../../../_utils/themeContext';
import { FullWidthPictureCarousel, HeimeText, QueryItemView, UserListItem } from '../../../../Components';

interface ProductDetail {
    message: SharingMessage;
    apartments: string | undefined;
    onEdit: () => void;
}

const ProductDetail = ({ message, apartments, onEdit }: ProductDetail): ReactElement => {
    const themedStyle = useThemeStyle(styles);

    const { refetch, isRefetching } = useGetMessage(message.id);
    const isOwn = message.creator_id === useOwnProfile()?.data?.id;

    const { theme } = useContext(ThemeContext);
    return (
        <QueryItemView onRefresh={refetch} isRefreshing={isRefetching}>
            <FullWidthPictureCarousel onEditPlaceholder={isOwn ? onEdit : undefined} pictures={message.files} />
            <View style={themedStyle.container}>
                <View style={themedStyle.separator} />
                <HeimeText style={themedStyle.productTitle} maxFontSizeMultiplier={2}>
                    {message.title}
                </HeimeText>
                <Text style={themedStyle.timeText}>{fromNowFormat(moment.unix(message.created_at).toDate())}</Text>
                <View style={themedStyle.separator} />
                {message.richtext_content ? (
                    <RichTextView content={[message.richtext_content]} style={themedStyle.productDescription} />
                ) : null}
                <UserListItem
                    key={message.creator_id}
                    id={message.creator_id}
                    desc={apartments}
                    descColor={theme.secondary}
                />
            </View>
        </QueryItemView>
    );
};
const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { paddingLeft: screenMargin, paddingRight: screenMargin },
        separator: {
            height: WH * 0.02,
        },
        productTitle: {
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.06,
            color: theme.darkGray,
        },
        timeText: {
            fontSize: WW * 0.03,
            color: theme.secondaryLight,
            fontFamily: _fonts.primaryFont,
        },
        productDescription: {
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            color: theme.secondaryText,
        },
    });
export default ProductDetail;
