import React, { ComponentProps, ReactElement, useCallback, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFocusEffect } from '@react-navigation/native';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { z } from 'zod';
import { Survey, useAnswerQuestion, useSurvey } from '_api/useSurveys';
import { Theme, useThemeStyle, fileIsImage, useConfirmDiscard, showToast } from '_utils';
import { screenMargin, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import {
    AttachmentsView,
    HeimeText,
    QueryItemView,
    PrimaryButton,
    CheckBox,
    RadioButton,
    FormInput,
    FormFileAttachmentSelector,
} from 'Components';
import { RoutePropsSchema, ArrayElement, fileSchema, RoutePropNumberNullable, StaleFile } from 'types/Utility';
import { useSurveyContext } from '../SurveyContext';
import { SurveyScreenProps, useSurveyNavigation } from '../SurveyNavigation';

const routeSchema = RoutePropsSchema(z.object({ questionId: z.string(), apartmentId: RoutePropNumberNullable }));

const getFormSchema = (survey: Survey, question: Survey['questions'][number]) => {
    let questionType = z.object({});
    switch (question.type) {
        case 'TEXT':
        case 'TEXTAREA':
            questionType = textSchema(question.required);
            break;
        case 'CHECKBOX':
            questionType = checkboxSchema(question.required);
            break;
        case 'RADIO':
            questionType = radioSchema(question.required);
            break;
        case 'NUMBER':
            questionType = textSchema(question.required);
            break;
        case 'FILES':
            questionType = filesSchema(question.required);
            break;
    }
    if (survey.recipients_type === 'APARTMENTS') {
        return questionType.merge(apartmentSchema);
    } else {
        return questionType.merge(noApartmentSchema);
    }
};

const string = z.string();

const textSchema = (required: boolean) =>
    z.object({
        value: required ? string.min(1) : string.optional(),
    });

const stringArray = z.array(z.string());
const checkboxSchema = (required: boolean) =>
    z.object({
        options: required ? stringArray.min(1) : stringArray,
    });

const stringArray1 = z.array(z.string()).max(1);
const radioSchema = (required: boolean) =>
    z.object({
        options: required ? stringArray1.min(1) : stringArray1,
    });

const filesSchema = (required: boolean) =>
    z.object({ files: required ? z.array(fileSchema).min(1) : z.array(fileSchema) });

const apartmentSchema = z.object({ apartmentId: z.number() });
const noApartmentSchema = z.object({ apartmentId: z.nullable(z.number()) });

export type FormValues = (
    | z.infer<ReturnType<typeof textSchema>>
    | z.infer<ReturnType<typeof checkboxSchema>>
    | z.infer<ReturnType<typeof radioSchema>>
    | z.infer<ReturnType<typeof filesSchema>>
) &
    (z.infer<typeof apartmentSchema> | z.infer<typeof noApartmentSchema>);
const SurveyAnswer = (props: SurveyScreenProps<'SurveyAnswer'>): ReactElement => {
    const {
        route: {
            params: { questionId, apartmentId },
        },
    } = routeSchema.parse(props);
    const themedStyle = useThemeStyle(styles);
    const { push } = useSurveyNavigation();
    const { t } = useTranslation();
    const {
        state: { surveyId },
        openSurveyQuestion,
    } = useSurveyContext();
    const { data, refetch, isRefetching } = useSurvey(surveyId);
    const { goBack } = useSurveyNavigation();
    const { mutate, isPending: isAnsweringQuestion } = useAnswerQuestion(surveyId, questionId);

    if (!data) {
        throw new Error('Unexpected null data');
    }

    const questionIndex = data?.questions.findIndex((item) => item.id === questionId);
    const selectedQuestion = data?.questions[questionIndex];

    const potentialAnswer = data.responses
        .find(
            (response) =>
                response.status === 'DRAFT' &&
                (data.recipients_type !== 'APARTMENTS' || response.apartment_id === apartmentId),
        )
        ?.answers.find((answer) => answer.question_id === questionId);

    const getFormValues = useCallback(
        () => ({
            value: potentialAnswer?.value ?? '',
            options: potentialAnswer?.options.map((i) => i.id) ?? [],
            files:
                potentialAnswer?.attachments.map((file, index): StaleFile => ({ ...file, status: 'stale', index })) ??
                [],
            apartmentId: apartmentId,
        }),
        [apartmentId, potentialAnswer?.attachments, potentialAnswer?.options, potentialAnswer?.value],
    );

    const form = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(getFormSchema(data, selectedQuestion)),
        defaultValues: getFormValues(),
    });

    const { isValid, isDirty } = form.formState;

    const { content } = useConfirmDiscard(form.formState.isDirty && !isAnsweringQuestion, true, goBack);
    useFocusEffect(
        useCallback(() => {
            form.reset(getFormValues());
            openSurveyQuestion(questionIndex, goBack);
        }, [form, getFormValues, goBack, openSurveyQuestion, questionIndex]),
    );

    const handleContinue = (values: FormValues) => {
        const onSuccess = () => {
            const appartmentRoute = apartmentId ? apartmentId + '' : null;
            if (questionIndex === data.questions.length - 1) {
                push('SurveyComplete', { apartmentId: appartmentRoute });
                return;
            } else {
                push('SurveyAnswer', {
                    questionId: data.questions[questionIndex + 1].id,
                    apartmentId: appartmentRoute,
                });
            }
        };
        if (!isDirty) {
            onSuccess();
            return;
        }

        mutate(
            {
                options: [],
                apartment_id: apartmentId,
                files: [],
                ...values,
                value: 'value' in values && values.value !== undefined ? values.value + '' : '',
            },
            {
                onError: () => {
                    showToast({
                        type: 'error',
                        header: t('surveys:answerError'),
                        text: '',
                        position: 'bottom',
                    });
                },
                onSuccess,
            },
        );
    };

    const { files, pictures } = useMemo(() => {
        return {
            files: selectedQuestion.attachments.filter(
                (file, index) => !fileIsImage({ ...file, status: 'stale', index }),
            ),
            pictures: selectedQuestion.attachments.filter((file, index) =>
                fileIsImage({ ...file, status: 'stale', index }),
            ),
        };
    }, [selectedQuestion.attachments]);

    return (
        <View style={themedStyle.container}>
            <QueryItemView onRefresh={refetch} isRefreshing={isRefetching}>
                <View style={themedStyle.row}>
                    <HeimeText style={themedStyle.questionText}>{t('surveys:question')}</HeimeText>
                    <HeimeText style={[themedStyle.questionText, themedStyle.questionCount]}>
                        {questionIndex + 1}/{data.questions.length}
                    </HeimeText>
                </View>
                <View style={themedStyle.questionContainer}>
                    <AttachmentsView pictures={pictures} files={files} size={'large'} />
                    <HeimeText maxFontSizeMultiplier={2} style={themedStyle.question}>
                        {selectedQuestion?.question}
                    </HeimeText>
                    {selectedQuestion ? (
                        <HeimeText maxFontSizeMultiplier={2} linkify style={themedStyle.description}>
                            {selectedQuestion?.description}
                        </HeimeText>
                    ) : null}
                    <FormProvider {...form}>
                        <Answer
                            type={selectedQuestion.type}
                            options={[
                                ...selectedQuestion.options,
                                ...(potentialAnswer?.options.filter(
                                    (item) => !selectedQuestion.options.find((option) => option.id === item.id),
                                ) ?? []),
                            ]}
                        />
                    </FormProvider>
                </View>
            </QueryItemView>
            <PrimaryButton
                text={t('surveys:nextQuestion')}
                onPress={form.handleSubmit(handleContinue)}
                status={isAnsweringQuestion ? 'loading' : isValid ? null : 'disabled'}
                style={themedStyle.bottomButton}
            />
            {content}
        </View>
    );
};

const Answer = ({ type, options }: Pick<ArrayElement<Survey['questions']>, 'type' | 'options'>) => {
    const form = useFormContext();
    const themedStyle = useThemeStyle(styles);
    const selectedOptions = form.watch('options');

    switch (type) {
        case 'TEXT':
            return <FormInput name="value" />;
        case 'TEXTAREA':
            return <FormInput name="value" multiline numberOfLines={3} />;
        case 'NUMBER':
            return <FormInput name="value" type="number" numberAsString />;
        case 'CHECKBOX':
            return (
                <View style={themedStyle.optionsContainer}>
                    {options.map(({ id, value }) => (
                        <FormCheckbox
                            key={id}
                            title={value}
                            value={id}
                            name="options"
                            checked={selectedOptions.includes(id)}
                            titleStyle={themedStyle.optionTitle}
                        />
                    ))}
                </View>
            );
        case 'RADIO':
            return (
                <View style={themedStyle.optionsContainer}>
                    {options.map(({ id, value }) => (
                        <FormRadioButton
                            key={id}
                            title={value}
                            value={id}
                            name={'options'}
                            checked={selectedOptions.includes(id)}
                        />
                    ))}
                </View>
            );
        case 'FILES':
            return <FormFileAttachmentSelector name="files" />;
    }
    return null;
};

const FormCheckbox = ({
    name,
    value,
    ...props
}: Omit<ComponentProps<typeof CheckBox>, 'onPress'> & { name: 'options'; value: string }) => {
    const { field } = useController<FormValues, 'options'>({ name: name });

    const handlePress = () => {
        if (props.checked) {
            field.onChange(field.value.filter((selValue) => selValue !== value));
        } else {
            field.onChange([...field.value, value]);
        }
    };

    return <CheckBox {...props} onPress={handlePress} />;
};

const FormRadioButton = ({
    name,
    value,
    ...props
}: Omit<ComponentProps<typeof RadioButton>, 'onPress'> & { name: 'options'; value: string }) => {
    const { field } = useController<FormValues, 'options'>({ name: name });

    const handlePress = () => {
        field.onChange([value]);
    };

    return <RadioButton {...props} onPress={handlePress} />;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            height: '100%',
            backgroundColor: theme.lightBackground,
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingBottom: screenMargin,
        },
        questionText: {
            fontSize: subtitleFontSize,
            color: theme.greyMedium,
            marginBottom: smallestMargin,
        },
        questionCount: { paddingLeft: smallestMargin },
        questionContainer: {
            paddingTop: screenMargin,
            paddingRight: screenMargin,
            paddingBottom: screenMargin,
            paddingLeft: screenMargin,
            borderWidth: 1,
            borderColor: theme.lightGreen,
        },
        question: {
            fontSize: titleFontSize,
            color: theme.black,
            lineHeight: titleFontSize * 1.5,
            marginBottom: smallestMargin,
            fontWeight: 'bold',
        },
        description: { fontSize: subtitleFontSize, color: theme.black, lineHeight: subtitleFontSize * 1.5 },
        bottomButton: {
            marginTop: 'auto',
        },
        row: { flexDirection: 'row' },
        optionsContainer: { marginTop: screenMargin },
        optionTitle: { fontSize: subtitleFontSize, color: theme.black },
    });

export default SurveyAnswer;
