import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, FlatList } from 'react-native';
import FormFileImageSelector from 'Components/FileSelectors/FormFileImageSelector';
import { FormFile } from 'types/Base';
import _fonts from '../../../../_fonts';
import { Theme, useThemeStyle, WH, WW } from '../../../../_utils';
import { HeaderWithNav, PrimaryButton } from '../../../../Components';

const ActivityPictures = ({ goBack, goToPreview }: { goBack(): void; goToPreview(): void }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { watch } = useFormContext<{ files: Array<FormFile> }>();

    const hasImages = watch('files').length > 0;

    return (
        <>
            <HeaderWithNav title={t('newActivity:add_photo')} onPress={goBack} />
            <FlatList
                contentContainerStyle={themedStyle.content}
                data={[{}]}
                ListHeaderComponent={
                    <>
                        <Text style={themedStyle.title}>{t('newActivity:add_photo')}</Text>
                        <Text style={themedStyle.text}>{t('newActivity:first_image_will')}</Text>
                    </>
                }
                renderItem={() => {
                    return (
                        <FormFileImageSelector name="files" title={t('newActivity:image')} openInitially={!hasImages} />
                    );
                }}
                keyExtractor={() => 'image_selector'}
            />
            <PrimaryButton text={t('newActivity:continue').toUpperCase()} onPress={goToPreview} bottomAction="modal" />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        content: {
            marginLeft: WW * 0.04,
            marginRight: WW * 0.04,
        },
        title: {
            marginTop: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
            fontSize: WH * 0.035,
            color: theme.black,
        },
        text: {
            color: theme.main,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
        },
    });

export default ActivityPictures;
