import React, { ReactElement } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { CooperativeGroupDropdown, FormCheckbox, FormInput, HeimeText, PrimaryButton, FormToggle } from 'Components';
import FormFileSelector from 'Components/FileSelectors/FormFileSelector';
import FormRichText from 'Components/Form/FormRichText/FormRichText';
import { MessageType, isNoticeMessage } from 'types/message';
import ApartmentGroupsSelector from './ApartmentGroupsSelector';
import { useGetMessages } from '../../../../_api/useMessages';
import _fonts from '../../../../_fonts';
import { Theme, useIsCoopAdmin, useThemeStyle, WW } from '../../../../_utils';
import { flattenIniniteResult } from '../../../../_utils/misc';
import { screenMargin, titleFontSize } from '../../../../_utils/sizes';
import { FormValues } from '../SharingNew';

const RequestHelp = ({
    onSubmit,
    isLoading,
    title,
}: {
    onSubmit: (values: FormValues) => void;
    isLoading?: boolean;
    title: string;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);

    const { t } = useTranslation();
    const { handleSubmit, formState, watch } = useFormContext<FormValues>();
    const ownId = useOwnProfile().data?.id;

    const disableButton = !formState.isValid;
    const [isEditing, cooperative_group_id] = watch(['isEditing', 'cooperative_group_id']);
    const sentNotification = watch('send_notification');

    const isAdmin = useIsCoopAdmin();
    const hasSentNotification = flattenIniniteResult(useGetMessages([MessageType.Requests]).data).some(
        (item) =>
            isNoticeMessage(item) &&
            item.creator_id === ownId &&
            item.send_notification &&
            moment.unix(item.created_at).isAfter(moment().subtract(1, 'day')),
    );

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <HeimeText style={themedStyle.title}>{title}</HeimeText>
            <FormInput
                placeholder={t('sharingNew:requestHelp:enterTitle')}
                name="title"
                label={t('sharingNew:requestHelp:textTitle')}
            />
            <View style={themedStyle.spacer} />
            {isEditing ? (
                <HeimeText>
                    {sentNotification
                        ? t('sharingNew:requestHelp:sent_notification')
                        : t('sharingNew:requestHelp:no_notification')}
                </HeimeText>
            ) : isAdmin || !hasSentNotification ? (
                <FormCheckbox name="send_notification" label={t('sharingNew:requestHelp:send_notification')} />
            ) : (
                <HeimeText>{t('sharingNew:requestHelp:noAccess')}</HeimeText>
            )}
            <FormRichText
                name="richtext_content"
                label={t('sharingNew:requestHelp:textDesc')}
                placeholder={t('sharingNew:requestHelp:enterDesc')}
            />
            <View style={themedStyle.spacer} />
            <FormCheckbox name="allow_comments" label={t('sharingNew:requestHelp:allow_comments')} />
            <FormFileSelector name="files" title={t('sharingNew:requestHelp:attachments')} />
            <CooperativeGroupDropdown isCreatingNew={!isEditing} />
            {isAdmin ? (
                <ApartmentGroupsSelector cooperative_group_id={cooperative_group_id} isCreatingNew={!isEditing} />
            ) : null}
            <FormToggle
                label={t('sharingNew:requestHelp:never_expires')}
                description={t('sharingNew:requestHelp:never_expires_description')}
                name="never_expires"
            />
            <PrimaryButton
                style={themedStyle.button}
                status={isLoading ? 'loading' : disableButton ? 'disabled' : null}
                text={t('sharingNew:publish').toUpperCase()}
                onPress={handleSubmit(onSubmit)}
            />
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        title: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.02,
            fontFamily: _fonts.primaryFontBold,
            color: theme.black,
            fontSize: titleFontSize,
        },
        text: {
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            color: theme.error,
        },
        button: {
            marginTop: WW * 0.04,
            marginBottom: WW * 0.04,
        },
        spacer: { marginTop: screenMargin },
        durationContainer: { width: '50%', minWidth: 200 },
    });
export default RequestHelp;
