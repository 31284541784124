import { useEffect, useMemo, useState } from 'react';
import { Keyboard } from 'react-native';

const useActionSheetState = (openInitially: boolean) => {
    const [isSheetVisible, setIsSheetVisible] = useState(false);

    useEffect(() => {
        if (openInitially) {
            setTimeout(() => setIsSheetVisible(openInitially), 100);
        }
    }, [openInitially]);

    useEffect(() => {
        if (isSheetVisible) {
            Keyboard.dismiss();
        }
    }, [isSheetVisible]);

    return useMemo(
        () => ({
            visible: isSheetVisible,
            onClose: () => setIsSheetVisible(false),
            // We toggle in case we end up in a weird state
            onShow: () => setIsSheetVisible((curr) => !curr),
        }),
        [isSheetVisible],
    );
};

export default useActionSheetState;
