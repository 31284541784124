import React, { ComponentProps, ReactElement, useEffect } from 'react';
import { Path, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFile } from 'types/Base';
import FileSelector from './FileSelector';
import { FileAction, fileReducer } from './fileState';

interface FormFileSelectorProps<FormType> extends Omit<ComponentProps<typeof FileSelector>, 'files' | 'onAction'> {
    name: Path<FormType>;
}

const FormFileSelector = <FormType extends Record<string, Array<FormFile>>>({
    name,
    ...fileSelectorProps
}: FormFileSelectorProps<FormType>): ReactElement => {
    const { field } = useController<FormType>({ name });
    const { setError, getValues } = useFormContext();
    const { t } = useTranslation();

    const handleChange = (action: FileAction) => {
        const newState = fileReducer(getValues(name) as FormFile[], action);
        field.onChange(newState, { shouldValidate: true });
    };

    useEffect(() => {
        (field.value as Array<FormFile>).map((file) => {
            if (file.state.status === 'UPLOADING') {
                setError('block_submit', { message: t('global:uploading_file') });
            }
        });
    }, [field.value, setError, t]);

    return <FileSelector {...fileSelectorProps} {...field} files={field.value as FormFile[]} onAction={handleChange} />;
};

export default FormFileSelector;
