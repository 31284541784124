import Echo from 'laravel-echo';
import Pusher, { PresenceChannel } from 'pusher-js/react-native';
import { API_URL } from '../_constants';

export interface ExtendedPrescenseChannel extends PresenceChannel {
    error(func: (error: Error) => void): ExtendedPrescenseChannel;
    notification(
        func: (notification: {
            type: string;
            viewed: boolean;
            text: string;
            by: string;
            uri: {
                args: (string | number)[];
                route: string;
            };
            room_id: number;
        }) => void,
    ): ExtendedPrescenseChannel;
}
export let SOCKET: Echo;

let PusherClient: null | Pusher;

export function init(TOKEN: string, lang: 'en' | 'no' | 'da' | 'sv'): void {
    PusherClient && PusherClient.disconnect();

    const broadcast_auth_path = `${API_URL(lang)}broadcasting/auth`;
    const broadcast_auth_headers = {
        headers: {
            Authorization: `Bearer ${TOKEN}`,
        },
    };

    Pusher.logToConsole = false;

    PusherClient = new Pusher('zns8tgs6r9', {
        cluster: 'mt1',
        wsHost: 'aawss.frontsoftware.no',
        // wsHost: 'aawss.dev.frontsoftware.no',
        wsPort: 443, //from .env
        authEndpoint: broadcast_auth_path,
        auth: { ...broadcast_auth_headers },
        forceTLS: true,
        enableStats: false,
        enabledTransports: ['ws', 'wss'],
    });

    SOCKET = new Echo({
        broadcaster: 'pusher',
        client: PusherClient,
    });
}

export function disconnectSocket(): void {
    PusherClient && PusherClient.disconnect();
    PusherClient = null;
}
