import moment from 'moment';
import { z } from 'zod';
import { Activity } from '_api/useActivities';
import FileImageToFormFileState from '_utils/FileImageToFormFile';
import { parseNullableUnixTimeCodeToDate } from '_utils/misc';
import { optionalNumberInput } from '_utils/zod';
import { FormFileSchema } from 'types/Base';
import { Cooperative } from 'types/Cooperative';
import { ArrayElement } from 'types/Utility';

export const schema = z.object({
    id: z.onumber(),

    name: z.string().min(1).max(250),
    start_at: z.date().optional(),
    end_at: z.date().optional().nullable(),

    description: z.string(),
    user_groups: z.array(z.number()),

    last_cancel_at: z.date().nullable(),
    last_confirm_at: z.date().nullable(),

    location: z.string(),

    files: z.array(FormFileSchema),

    payment_account_id: z.string().nullable(),
    paymentStrategy: z.object({
        price: optionalNumberInput,
        mva: optionalNumberInput,
        bookKeepingAccount: z.string(),
    }),
    quantity: optionalNumberInput,
    no_refund: z.boolean(),

    message_type: z.number().min(0).max(5),
    users_type: z.boolean(),
    users: z.array(z.number()),

    cooperative_group_id: z.number().nullable(),

    originallyNoQuantity: z.boolean().optional(),

    arrangers: z.array(z.number()),
    template: z.string().optional(),
});

export type FormValues = z.infer<typeof schema>;

export const mapToDefault = (
    initial: Activity | undefined,
    initialGroups: number[] | undefined,
    initialCooperativeGroup: number | null,
    initialBookKeepingAccount: string | undefined,
    initialPaymentAccount: ArrayElement<Cooperative['payment_accounts']> | undefined,
    template: string | undefined,
): FormValues => {
    if (initial) {
        return {
            id: initial.id,
            name: initial.title,
            description: initial.description ?? '',
            user_groups: initial.user_groups?.map(({ id }) => id) ?? [],
            end_at: moment.unix(initial.end_at).toDate(),
            start_at: moment.unix(initial.start_at).toDate(),
            location: initial.location ?? '',
            files: initial.pictures.map(FileImageToFormFileState),
            paymentStrategy: { ...initial.paymentStrategy },
            quantity: initial.no_quantity ? '' : initial.max_quantity,
            payment_account_id: initial.payment_account_id,
            message_type: initial.notify_type,
            users_type: initial.users_type === 1,
            users: initial.users.map(({ id }) => id) ?? [],
            no_refund: initial.no_refund,
            last_confirm_at: parseNullableUnixTimeCodeToDate(initial.last_confirm_at),
            last_cancel_at: parseNullableUnixTimeCodeToDate(initial.last_cancel_at),
            cooperative_group_id: initial.cooperative_group_id,
            originallyNoQuantity: initial.no_quantity,
            arrangers: initial.arrangers.map(({ id }) => id),
            template,
        };
    }
    return {
        name: '',
        description: '',
        user_groups: initialGroups ?? [],
        location: '',
        files: [],
        paymentStrategy: {
            price: 0,
            mva: initialPaymentAccount?.pays_MVA ? 25 : 0,
            bookKeepingAccount: initialBookKeepingAccount ?? '',
        },
        payment_account_id: initialPaymentAccount?.id ?? null,
        quantity: '',
        message_type: (initialGroups?.length ?? 0) > 0 ? 5 : 1,
        users_type: false,
        users: [],
        no_refund: true,
        last_confirm_at: null,
        last_cancel_at: null,
        cooperative_group_id: initialCooperativeGroup ?? null,
        arrangers: [],
        template,
    };
};
