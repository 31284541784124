import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableHighlight, StyleSheet } from 'react-native';
import Animated, { RotateInDownLeft, RotateOutUpRight, runOnJS } from 'react-native-reanimated';
import { useThemeStyle, WH, WW } from '_utils';
import { Theme, ThemeContext } from '_utils/themeContext';
import { Icon } from 'Components';

const PlusButton = ({ onPress, isOpen }: { onPress: () => void; isOpen: boolean }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const [state, setState] = useState<'open' | 'isOpening' | 'isClosing' | 'closed'>('open');

    const changeState = (newState: 'open' | 'isOpening' | 'isClosing' | 'closed') => {
        setState(newState);
    };

    useEffect(() => {
        if (isOpen && state === 'closed') {
            setState('isOpening');
        }

        if (!isOpen && state === 'open') {
            setState('isClosing');
        }
    }, [isOpen, state]);

    useEffect(() => {
        // Sometimes state is not updated so we need to cleanup to a known state
        if (['isOpening', 'isClosing'].includes(state)) {
            const timeout = setTimeout(() => {
                setState(isOpen ? 'open' : 'closed');
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [isOpen, state]);

    const exitCross = RotateOutUpRight.duration(100).withCallback((finished: boolean) => {
        'worklet';
        if (finished) {
            runOnJS(changeState)('closed');
        }
    });

    const exitPlus = RotateOutUpRight.duration(150).withCallback((finished: boolean) => {
        'worklet';
        if (finished) {
            runOnJS(changeState)('open');
        }
    });

    return (
        <View style={themedStyle.floatingContainer}>
            <TouchableHighlight
                style={themedStyle.floatingButton}
                underlayColor={theme.greyMedium}
                onPress={onPress}
                accessibilityLabel={isOpen ? t('global:close') : t('navigation:plusExplainer')}
            >
                {['isOpening', 'isClosing'].includes(state) ? (
                    <View />
                ) : state === 'open' ? (
                    <Animated.View entering={RotateInDownLeft.duration(150)} exiting={exitCross}>
                        <Icon name="cross" color="mainText" scale={1.4} />
                    </Animated.View>
                ) : (
                    <Animated.View entering={RotateInDownLeft.duration(100)} exiting={exitPlus}>
                        <Icon name="plusSquare" color="mainText" scale={1.4} />
                    </Animated.View>
                )}
            </TouchableHighlight>
        </View>
    );
};

const buttonSize = WW * 0.85 * 0.2;

const styles = (theme: Theme) =>
    StyleSheet.create({
        floatingContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: 80,
            // To make whole button clickable
            zIndex: 2,
        },
        floatingButton: {
            backgroundColor: theme.main,
            borderRadius: WH * 0.5,
            width: buttonSize,
            height: buttonSize,
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default PlusButton;
