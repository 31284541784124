import React, { ComponentProps, ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import ImageAttachmentSelector from './ImageAttachmentSelector';
import { FileUpload } from '../../types/Utility';
import ControlledInput from '../ControlledInput';

interface FormImageAttachmentSelectorProps<FormType>
    extends Omit<ComponentProps<typeof ControlledInput>, 'images' | 'onChange'> {
    name: Path<FormType>;
}

const FormImageAttachmentSelector = <FormType extends FieldValues = Record<string, string>>({
    name,
    ...imageSelectorProps
}: FormImageAttachmentSelectorProps<FormType>): ReactElement => {
    const { field } = useController<FormType>({ name });

    const handleChange = (values: FileUpload[]) => {
        field.onChange(values, { shouldValidate: true });
    };

    return <ImageAttachmentSelector {...imageSelectorProps} {...field} images={field.value} onChange={handleChange} />;
};

export default FormImageAttachmentSelector;
