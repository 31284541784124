import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useDeleteAccount } from '_api/useProfile';
import { showToast } from '_utils';
import { track } from '_utils/Amplitude';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { useLogout } from 'Auth';
import { HeimeText, Icon, Modal } from 'Components';

interface DeleteAccountModalProps {
    onDismiss(): void;
}

const DeleteAccountModal = ({ onDismiss }: DeleteAccountModalProps): ReactElement => {
    const { t } = useTranslation();
    const { mutate: deleteAccount, isPending } = useDeleteAccount();
    const { theme } = useThemeContext();
    const logout = useLogout();

    useEffect(() => {
        track('Opened delete account modal');
    }, []);

    const handleDelete = () => {
        track('Deleted account');
        deleteAccount(null, {
            onSuccess: () => {
                logout();
                setTimeout(() => {
                    showToast({
                        type: 'success',
                        header: t('userMenu:deleteAccountModal:deleted'),
                        text: '',
                    });
                }, 100);
            },
        });
    };

    return (
        <Modal
            style={{ margin: smallestMargin }}
            onDismiss={() => {
                track('Closed delete account modal');
                onDismiss();
            }}
            header={t('userMenu:deleteAccountModal:header')}
            content={
                <View style={{ gap: screenMargin, marginBottom: screenMargin }}>
                    <HeimeText variant="subtitle">{t('userMenu:deleteAccountModal:content_warning')}</HeimeText>
                    <HeimeText variant="subtitle">{t('userMenu:deleteAccountModal:content_consequenses')}</HeimeText>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                        <Icon style={{ width: 40, height: 40 }} name="alertTriangle" color={'red'} />
                        <HeimeText variant="subtitle" style={{ color: theme.red, fontWeight: 'bold' }}>
                            {t('userMenu:deleteAccountModal:content_danger')}
                        </HeimeText>
                    </View>
                </View>
            }
            buttons={[
                { text: t('userMenu:deleteAccountModal:back'), onPress: onDismiss },
                {
                    text: t('userMenu:deleteAccountModal:approve'),
                    onPress: handleDelete,
                    type: 'danger',
                    isLoading: isPending,
                },
            ]}
        />
    );
};

export default DeleteAccountModal;
