import React, { ComponentProps, ReactElement, useMemo, useEffect, useState, useRef } from 'react';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import DocumentPicker from '_dependencies/documentPicker';
import { readFileAndConvertToBase64 } from '_dependencies/fileSystem';
import { isWeb, isiOS } from '_utils';
import { captureException } from '_utils/Sentry';
import BottomSheet from 'Components/Modal/Bottomsheet';
import { SelectedLocalFile } from 'types/Base';
import { FileToBeUploaded } from 'types/Utility';
import { ActionSheet } from './Components';

interface FileActionSheetProps {
    onClose(): void;
    actions: Array<
        | { type: 'FileB64'; onChange(images: Array<FileToBeUploaded>): void }
        | {
              type: 'File';
              onAdd(images: Array<SelectedLocalFile>): void;
          }
        | { type: 'Library'; onSelect(): void }
        | { type: 'Camera'; onSelect(): void }
        | {
              type: 'Facebook';
              onSelect(): void;
          }
        | { type: 'Remove'; onSelect(): void }
    >;
}

const FileActionSheet = ({ onClose, actions }: FileActionSheetProps): ReactElement => {
    const { t } = useTranslation();
    const [showingLoader, setShowingLoader] = useState(false);
    const ref = useRef<BottomSheetModal>(null);

    useEffect(() => {
        // We are having problems with bottomsheet hiding behind the keyboard
        if (isiOS()) {
            Keyboard.dismiss();
        }
    }, []);

    const actionItems = useMemo(
        (): ComponentProps<typeof ActionSheet>['actionItems'] =>
            actions
                .map((action) => {
                    switch (action.type) {
                        case 'Library':
                            return {
                                icon: 'image',
                                label: t('global:add_photo'),
                                onPress: action.onSelect,
                            };
                        case 'FileB64':
                            return {
                                icon: 'file',
                                label: t('global:add_file'),
                                onPress: showBase64AttachmentPicker({
                                    setShowingLoader,
                                    onChange: action.onChange,
                                    onClose,
                                }),
                            };
                        case 'File':
                            return {
                                icon: 'file',
                                label: t('global:add_file'),
                                onPress: showAttachmentPicker({
                                    setShowingLoader,
                                    onChange: action.onAdd,
                                    onClose,
                                }),
                            };
                        case 'Facebook':
                            return {
                                image: 'facebook',
                                label: t('global:add_facebook'),
                                onPress: action.onSelect,
                            };
                        case 'Remove':
                            return {
                                icon: 'trash',
                                label: t('global:remove_photo'),
                                onPress: action.onSelect,
                                textColor: 'red',
                            };
                        case 'Camera':
                            return isWeb()
                                ? null
                                : {
                                      icon: 'camera',
                                      label: t('global:take_photo'),
                                      onPress: action.onSelect,
                                  };
                    }
                    return null;
                })
                .filter(Boolean) as ComponentProps<typeof ActionSheet>['actionItems'],

        [actions, t, onClose],
    );

    useEffect(() => {
        if (actionItems.length === 1) {
            setTimeout(() => actionItems[0].onPress(), 50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this once
    }, []);

    useEffect(() => {
        ref.current?.present();
        let intervalCleanup = setInterval(() => {
            // Sometimes we end up in a sad state where the bottomsheet is not visible
            ref.current?.present();
        }, 500);
        let cleanupVar = ref.current;
        return () => {
            cleanupVar?.close?.();
            clearInterval(intervalCleanup);
        };
    }, []);

    const handleChange = (index: number) => {
        if (index === -1) {
            onClose();
        }
    };

    return (
        <BottomSheet
            onChange={handleChange}
            ref={ref}
            enableDynamicSizing
            content={
                <ActionSheet
                    onTitlePress={actionItems[0].onPress}
                    title={t('onboarding:upload_new_photo')}
                    isLoading={showingLoader}
                    actionItems={actionItems}
                    onCancel={onClose}
                />
            }
        />
    );
};

const showBase64AttachmentPicker =
    ({
        setShowingLoader,
        onChange,
        onClose,
    }: {
        setShowingLoader(val: boolean): void;
        onChange(images: Array<FileToBeUploaded>): void;
        onClose(): void;
    }) =>
    () => {
        DocumentPicker.pick({
            presentationStyle: 'formSheet',
            copyTo: 'cachesDirectory',
        })
            .then(async (result) => {
                const promises = Promise.all<FileToBeUploaded>(
                    result.map((file, index) => {
                        return new Promise(async (resolve) => {
                            if (file.copyError) {
                                captureException(file.copyError);
                            }
                            setShowingLoader(true);
                            const base64 = await readFileAndConvertToBase64(file);
                            resolve({
                                name: file.name ?? 'file' + Math.round(Math.random() * 10000000),
                                uri: base64,
                                status: 'added',
                                index: index,
                                size: file.size ?? undefined,
                            });
                        });
                    }),
                );
                const newFiles = await promises;
                onChange(newFiles);
                if (result.length > 0) {
                    onClose();
                }
            })
            .catch((e) => {
                captureException(e);
            })
            .finally(() => {
                setShowingLoader(false);
            });
    };

const showAttachmentPicker =
    ({
        setShowingLoader,
        onChange,
        onClose,
    }: {
        setShowingLoader(val: boolean): void;
        onChange(images: Array<SelectedLocalFile>): void;
        onClose(): void;
    }) =>
    () => {
        DocumentPicker.pick({
            presentationStyle: 'formSheet',
            copyTo: 'cachesDirectory',
        })
            .then(async (result) => {
                const mapped = result.map((file) => {
                    if (file instanceof File) {
                        return file;
                    }
                    if (file.copyError) {
                        captureException(file.copyError);
                    }
                    setShowingLoader(true);
                    return {
                        path: (file.fileCopyUri ?? file.uri).replace('file://', ''),
                        contentType: file.type ?? '',
                        name: file.name ?? 'file' + Math.round(Math.random() * 10000000),
                        size: file.size ?? 0,
                    };
                });
                onChange(mapped);
                if (result.length > 0) {
                    onClose();
                }
            })
            .catch((e) => {
                captureException(e);
            })
            .finally(() => {
                setShowingLoader(false);
            });
    };

export default FileActionSheet;
