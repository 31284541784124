import React, { ReactElement, useMemo, useState, useContext, useEffect } from 'react';
import { t } from 'i18next';
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import _fonts from '_fonts';
import {
    useThemeStyle,
    WW,
    WH,
    useKeyboardIsVisible,
    fileIsImage,
    isAndroid,
    useBase64ImageSelector,
    isWeb,
} from '_utils';
import { screenMargin, smallestFontSize, smallestMargin } from '_utils/sizes';
import { Theme, ThemeContext } from '_utils/themeContext';
import HeimeText from 'Components/HeimeText';
import { FileToBeUploaded, StaleFile, isStaleFile } from 'types/Utility';
import platformProps from './platformProps';
import CacheImage from '../CacheImage';
import FileActionSheet from '../FileSelectors/FileActionsheet';
import Icon from '../Icon/Icon';

interface MessageInputProps {
    onSend(arg: { content: string; files: Array<FileToBeUploaded | StaleFile> }): void;
    onFocus?: () => void;
    onBlur?: () => void;
    setState: (arg: { attachments: Array<FileToBeUploaded | StaleFile>; text: string }) => void;
    state: { attachments: (FileToBeUploaded | StaleFile)[]; text: string };
    itemRef?: React.RefObject<TextInput>;
    isEditing: boolean;
    onEditingEnd: () => void;
    isEditingText: string;
    initialActionSheetOpen?: boolean;
    onActionSheetOpen?: (arg: boolean) => void;
}

const MessageInput = ({
    onSend,
    onFocus,
    onBlur,
    state,
    setState,
    itemRef,
    isEditing,
    isEditingText,
    onEditingEnd,
    initialActionSheetOpen = false,
    onActionSheetOpen,
}: MessageInputProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const [actionSheetOpen, setActionSheetOpen] = useState<boolean>(initialActionSheetOpen);
    const [textSelection, setTextSelection] = useState<number>(0);
    const keyboardIsVisible = useKeyboardIsVisible();
    const handleActionSheetToggle = () => {
        onActionSheetOpen?.(!actionSheetOpen);
        setActionSheetOpen(!actionSheetOpen);
    };
    const handleSetAttachments = (files: Array<FileToBeUploaded>): void => {
        setState({ attachments: files as Array<FileToBeUploaded>, text: state.text });
    };
    const [onSelect, permission] = useBase64ImageSelector(handleSetAttachments, handleActionSheetToggle);

    const isValidMessage = useMemo(() => {
        return state.attachments.length || state.text.trim() !== '' ? true : false;
    }, [state.attachments.length, state.text]);

    const handleSendMessage = async () => {
        if (isValidMessage) {
            onSend({ content: state.text, files: state.attachments });
            setState({ attachments: [], text: '' });
        }
    };

    useEffect(() => {
        if (isEditing && !isWeb()) {
            itemRef?.current?.focus();
        }
    }, [isEditing, itemRef]);

    return (
        <View>
            {isEditing ? (
                <View style={themedStyle.editingWrapper}>
                    <HeimeText style={themedStyle.editingText}>{isEditingText}</HeimeText>
                    <TouchableOpacity
                        style={themedStyle.editingCancel}
                        accessibilityHint={t('chat:stop_editing')}
                        onPress={onEditingEnd}
                    >
                        <Icon color="black" name="cross" />
                    </TouchableOpacity>
                </View>
            ) : null}
            <View style={themedStyle.inputsWrapper}>
                {!isValidMessage ? (
                    <TouchableOpacity
                        style={themedStyle.attachButton}
                        onPress={() => {
                            handleActionSheetToggle();
                        }}
                        accessibilityLabel={t('chat:add_attachment')}
                    >
                        <Icon name="attachment" color="secondaryLight" />
                    </TouchableOpacity>
                ) : null}
                <View style={themedStyle.messageWrapper}>
                    {state.attachments[0] ? (
                        fileIsImage(state.attachments[0]) ? (
                            <CacheImage
                                accessibilityLabel={state.attachments[0].name}
                                style={themedStyle.attachment}
                                source={{
                                    original: isStaleFile(state.attachments[0])
                                        ? state.attachments[0].original
                                        : state.attachments[0].uri,
                                }}
                            />
                        ) : (
                            <View style={themedStyle.attachment} accessibilityLabel={state.attachments[0].name}>
                                <Icon name="attachedFile" color="black" />
                            </View>
                        )
                    ) : null}
                    <TextInput
                        value={state.text}
                        maxFontSizeMultiplier={2}
                        multiline
                        accessibilityLabel={t('chat:write_message')}
                        scrollEnabled
                        onSelectionChange={(event) => {
                            setTextSelection(event.nativeEvent.selection.start);
                        }}
                        onKeyPress={({ nativeEvent }) => {
                            if (nativeEvent.key === 'Backspace') {
                                if (state.text === '' || textSelection === 0) {
                                    handleSetAttachments([]);
                                }
                            }
                        }}
                        style={[
                            themedStyle.input,
                            keyboardIsVisible ? { maxHeight: WH * 0.3 } : { maxHeight: WH * 0.6 },
                        ]}
                        onChangeText={(message) => {
                            setState({ ...state, text: message });
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        ref={itemRef}
                        autoCorrect={!isAndroid()}
                        selectionColor={theme.main}
                        {...platformProps}
                    />
                    <TouchableOpacity
                        activeOpacity={isValidMessage ? 0 : 1}
                        style={[
                            themedStyle.send,
                            { backgroundColor: isValidMessage ? theme.darkGreen : theme.secondaryLight },
                        ]}
                        onPress={handleSendMessage}
                        accessibilityLabel={t('chat:send_message')}
                    >
                        <Icon name="send" color="mainBackground" />
                    </TouchableOpacity>
                </View>
                {actionSheetOpen ? (
                    <FileActionSheet
                        actions={[
                            { type: 'Camera', onSelect: () => onSelect({ type: 'camera' }) },
                            { type: 'Library', onSelect: () => onSelect({ type: 'library' }) },
                            { type: 'FileB64', onChange: handleSetAttachments },
                        ]}
                        onClose={handleActionSheetToggle}
                    />
                ) : null}
                {permission}
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        inputsWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        attachButton: {
            backgroundColor: theme.lightGrey,
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            borderRadius: WW * 0.06,
            marginRight: WW * 0.02,
        },
        messageWrapper: {
            flexDirection: 'row',
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            backgroundColor: theme.lightGrey,
            borderRadius: WW * 0.1,
            flex: 1,
            fontSize: smallestFontSize,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: WW * 0.18,
        },
        input: {
            fontFamily: _fonts.primaryFont,
            color: theme.main,
            flex: 1,
            fontSize: WW * 0.05,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            paddingLeft: WW * 0.01,
            paddingRight: WW * 0.12,
        },
        send: {
            position: 'absolute',
            right: WW * 0.02,
            bottom: WW * 0.02,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.secondaryLight,
            paddingTop: WW * 0.03,
            paddingBottom: WW * 0.03,
            paddingLeft: WW * 0.03,
            paddingRight: WW * 0.03,
            width: WW * 0.1,
            height: WW * 0.1,
            marginLeft: WW * 0.02,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            borderRadius: WW * 0.1,
        },
        attachment: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.mainBackground,
            width: WW * 0.1,
            height: WW * 0.1,
            borderRadius: WW * 0.1,
        },
        editingWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        editingText: {
            fontSize: smallestFontSize,
            color: theme.main,
        },
        editingCancel: {
            paddingTop: smallestMargin,
            paddingBottom: smallestMargin,
            paddingLeft: smallestMargin,
            paddingRight: smallestMargin,
        },
    });

export default MessageInput;
