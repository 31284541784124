import React, { ReactElement } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import useAppNavigation from '_navigator/hooks/useAppNavigation';
import { Theme, useDocumentsView, WW } from '_utils';
import useThemeStyle from '_utils/hooks/useThemeStyle';
import { smallestMargin, subtitleFontSize } from '_utils/sizes';
import { FileImageExtended, FileLink } from 'types/Base';
import { UnixTimeCode } from 'types/Utility';
import CacheImage from './CacheImage';
import HeimeText from './HeimeText';
import FileIcon from './Icon/FileIcon';

type AttachmentType = (Omit<FileLink, 'ext'> | FileImageExtended) & { last_modified: UnixTimeCode };
interface AttachmentsViewProps {
    files: Array<AttachmentType>;
    pictures: Array<AttachmentType>;
    size: 'small' | 'large';
}

const AttachmentsView = ({ files, pictures, size }: AttachmentsViewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { navigate } = useAppNavigation();

    const [loader, handleDocumentPress] = useDocumentsView();
    const handleImagePress = (file: AttachmentType) => {
        navigate('ImagePreview', {
            images: pictures,
            selectedIndex: pictures.findIndex((p) => p.id === file.id),
        });
    };

    return (
        <>
            {files.map((file) => (
                <TouchableOpacity
                    key={file.id}
                    style={themedStyle.row}
                    onPress={() => {
                        handleDocumentPress({
                            ...file,
                            url: file.original,
                            size: '0',
                        });
                    }}
                >
                    <FileIcon fileName={file.name} />
                    <HeimeText style={themedStyle.fileText}>{file.name}</HeimeText>
                </TouchableOpacity>
            ))}
            <View
                style={[themedStyle.imageRow, size === 'large' ? themedStyle.imageRowLarge : themedStyle.imageRowSmall]}
            >
                {pictures.map((file) => (
                    <TouchableOpacity
                        key={file.id}
                        onPress={() => {
                            handleImagePress(file);
                        }}
                        style={[themedStyle.image, size === 'large' ? themedStyle.imageLarge : themedStyle.imageSmall]}
                    >
                        <CacheImage<AttachmentType>
                            source={file}
                            style={[
                                themedStyle.image,
                                size === 'large' ? themedStyle.imageLarge : themedStyle.imageSmall,
                            ]}
                            resizeMode={size === 'small' ? 'contain' : 'cover'}
                        />
                    </TouchableOpacity>
                ))}
            </View>
            {loader}
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: smallestMargin,
        },
        boardRow: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        fileText: {
            color: theme.hyperText,
            fontSize: subtitleFontSize,
            marginLeft: smallestMargin,
        },
        imageSmall: {
            width: WW * 0.2,
            height: WW * 0.2,
        },
        imageLarge: {
            width: '100%',
            height: WW * 0.4,
        },
        image: {
            marginBottom: smallestMargin,
            marginRight: smallestMargin,
        },
        imageRow: {
            alignItems: 'center',
            marginTop: smallestMargin,
        },
        imageRowSmall: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        imageRowLarge: {
            flexDirection: 'column',
        },
    });

export default AttachmentsView;
