import React, { ComponentProps, ReactElement, useContext, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { Theme, WW } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, titleFontSize } from '_utils/sizes';
import { ThemeContext } from '_utils/themeContext';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';

interface ToastMessageProps {
    header: string;
    text: string;
    type: 'success' | 'error' | 'info';
}

const options: Record<
    ToastMessageProps['type'],
    { icon: ComponentProps<typeof Icon>['name']; color: keyof Theme; backgroundColor: keyof Theme }
> = {
    success: {
        icon: 'checkCircle',
        color: 'main',
        backgroundColor: 'lightGreen',
    },
    error: {
        icon: 'alertCircle',
        color: 'red',
        backgroundColor: 'errorLight',
    },
    info: {
        icon: 'infoFilled',
        color: 'secondary',
        backgroundColor: 'lightMustard',
    },
};

const ToastMessage = ({ header, text, type }: ToastMessageProps): ReactElement => {
    const { icon, color, backgroundColor } = options[type];
    const { theme } = useContext(ThemeContext);
    const themedStyle = useMemo(() => styles(theme), [theme]);

    return (
        <View style={[themedStyle.container, { backgroundColor: theme[backgroundColor] }]}>
            <Icon name={icon} color={color} />
            <View style={themedStyle.textContainer}>
                {header ? (
                    <HeimeText style={[themedStyle.headerText, { color: theme[color] }]}>{header}</HeimeText>
                ) : null}
                {text ? <HeimeText style={themedStyle.text}>{text}</HeimeText> : null}
            </View>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            marginTop: screenMargin,
            marginRight: screenMargin,
            marginBottom: screenMargin,
            marginLeft: screenMargin,
            paddingTop: screenMargin,
            paddingRight: screenMargin,
            paddingBottom: screenMargin,
            paddingLeft: screenMargin,
            borderRadius: 10,
            alignItems: 'center',
            width: WW * 0.9,
        },
        textContainer: { marginLeft: screenMargin, flex: 1, gap: smallestMargin },
        headerText: {
            fontSize: titleFontSize,
            fontWeight: 'bold',
        },
        text: {
            fontSize: smallestFontSize,
            color: theme.chatMessageText,
        },
    });

export default ToastMessage;
