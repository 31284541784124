import { useIsFocused } from '@react-navigation/native';
import {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { ApiImage } from 'types/Base';
import { getFileObjectFunc, useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import {
    FileUpload,
    isFileToBeDeleted,
    isFileToBeUploaded,
    LaravelPagingResponse,
    UnixTimeCode,
} from '../types/Utility';

export enum StatusType {
    'New' = 0,
    'Processing' = 1,
    'Done' = 2,
    'Waiting' = 3,
}

export interface Support {
    id: number;
    creator: number;
    title: string;
    content: string;
    pictures: ApiImage[];
    status: StatusType;
    is_public: boolean;
    created_at: UnixTimeCode;
}

export const invalidateSupportsQueries = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({
        queryKey: ['supports'],
    });
    queryClient.invalidateQueries({
        queryKey: ['support'],
    });
};

const useGetSupports = (
    filter: 'open' | 'closed' | null,
    publicAndOwn: boolean,
    search: string | null,
): UseInfiniteQueryResult<InfiniteData<Support[]>, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useLaravelInfinteQuery(
        ['supports', selectedCoopId, filter, publicAndOwn, search],
        async ({ pageParam = 1 }) => {
            return await axios.get<LaravelPagingResponse<Support[]>>(`cooperatives/${selectedCoopId}/supports`, {
                headers: { authorization: await getAuthHeader() },
                params: {
                    page: pageParam,
                    filter,
                    own_and_public_only: publicAndOwn,
                    search,
                },
            });
        },
        {
            gcTime: Infinity,
            staleTime: 5 * 1000 * 60,
            placeholderData: () => {
                if (search === null) {
                    return undefined;
                }
                const relatedQuerydata = queryClient.getQueriesData<InfiniteData<LaravelPagingResponse<Support[]>>>({
                    queryKey: ['supports', selectedCoopId, filter, publicAndOwn],
                });
                const foundItemIds = new Set();
                const foundItems: Support[] = [];
                relatedQuerydata?.forEach(([, result]) => {
                    result?.pages?.forEach(({ data }) =>
                        data?.forEach((support) => {
                            if (foundItemIds.has(support.id)) {
                                return;
                            }
                            const loweredSearch = search.toLowerCase();

                            if (support.content.toLowerCase().includes(loweredSearch)) {
                                foundItemIds.add(support.id);
                                foundItems.push(support);
                            } else if (support.title.toLowerCase().includes(loweredSearch)) {
                                foundItemIds.add(support.id);
                                foundItems.push(support);
                            }
                        }),
                    );
                });
                if (foundItems.length === 0) {
                    return undefined;
                }
                const sorted = foundItems.sort((a, b) => b.created_at - a.created_at);
                return { pages: [{ data: sorted, meta: {} } as LaravelPagingResponse<Support[]>], pageParams: [] };
            },
        },
    );
};

const useGetSupport = (supportId: number): UseQueryResult<Support, string | Error> => {
    const isFocused = useIsFocused();
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['support', selectedCoopId, supportId],

        queryFn: async () => {
            const result = await axios.get<Support>(`cooperatives/${selectedCoopId}/supports/${supportId}`, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data) {
                throw new Error('Result returned with no data');
            }

            return result.data;
        },

        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 1,

        initialData: () => {
            const relatedQueries = queryClient
                .getQueryCache()
                .getAll()
                .filter(
                    (item) =>
                        !item.isStale() && item.queryKey?.[0] === 'supports' && item.queryKey?.[1] === selectedCoopId,
                );

            let result: Support | undefined;
            relatedQueries?.forEach((query) =>
                (query.state.data as InfiniteData<LaravelPagingResponse<Support[]>>)?.pages?.forEach(({ data }) =>
                    data?.forEach((support: Support) => {
                        if (supportId === support.id) {
                            result = support;
                        }
                    }),
                ),
            );
            return result;
        },

        initialDataUpdatedAt: moment().subtract({ minutes: 2 }).valueOf(),
        enabled: isFocused,
    });
};

export interface MutateSupportBody {
    title: string;
    content: string;
    is_public: boolean;
    pictures?: FileUpload[];
}

const useCreateSupport = (): UseMutationResult<{ success: number }, string | Error, MutateSupportBody> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (support: MutateSupportBody) => {
            const body = {
                ...support,
                pictures: support.pictures?.filter(isFileToBeUploaded).map(getFileObjectFunc(support.title)) ?? [],
            };
            const result = await axios.post<{ success: number }>(`cooperatives/${selectedCoopId}/supports`, body, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data.success) {
                throw new Error('Support creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['supports'],
            });
        },
    });
};

const useEditSupport = (): UseMutationResult<{ success: number }, string | Error, [number, MutateSupportBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([supportId, support]: [number, MutateSupportBody]) => {
            const body = {
                ...support,
                pictures: support.pictures?.filter(isFileToBeUploaded).map(getFileObjectFunc(support.title)),
            };
            const resultPromise = axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/supports/${supportId}`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            const imagesToDelete = support.pictures?.filter(isFileToBeDeleted).map(async ({ index }) => {
                return await axios.delete<{ success: boolean }>(
                    `cooperatives/${selectedCoopId}/supports/${supportId}/attach/${index}`,
                    {
                        headers: { authorization: await getAuthHeader() },
                    },
                );
            });

            await Promise.all(imagesToDelete ?? []);
            const result = await resultPromise;

            if (!result.data.success) {
                throw new Error('Support edit return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e, [supportId]) => {
            queryClient.invalidateQueries({
                queryKey: ['supports'],
            });
            queryClient.invalidateQueries({
                queryKey: ['support', selectedCoopId, supportId],
            });
        },
    });
};

interface MutateStatusBody {
    status: StatusType;
}

const useSetSupportStatus = (): UseMutationResult<{ success: number }, string | Error, [number, MutateStatusBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([supportId, body]: [number, MutateStatusBody]) => {
            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/supports/${supportId}/status`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Support edit return unsuccessful result');
            }
            return result.data;
        },
        onSettled: (_r, _e, [supportId]) => {
            queryClient.invalidateQueries({
                queryKey: ['supports'],
            });
            queryClient.invalidateQueries({
                queryKey: ['support', selectedCoopId, supportId],
            });
        },
    });
};

export { useGetSupports, useGetSupport, useCreateSupport, useEditSupport, useSetSupportStatus };
