import React, { ComponentProps, ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';
import FileAttachmentSelector from './FileAttachmentSelector';
import { FileUpload } from '../../types/Utility';

interface FormFileAttachmentSelectorProps<FormType>
    extends Omit<ComponentProps<typeof FileAttachmentSelector>, 'files' | 'onChange'> {
    name: Path<FormType>;
}

const FormFileAttachmentSelector = <FormType extends FieldValues = Record<string, string>>({
    name,
    ...imageSelectorProps
}: FormFileAttachmentSelectorProps<FormType>): ReactElement => {
    const { field } = useController<FormType>({ name });

    const handleChange = (values: FileUpload[]) => {
        field.onChange(values, { shouldValidate: true });
    };

    return <FileAttachmentSelector {...imageSelectorProps} {...field} files={field.value} onChange={handleChange} />;
};

export default FormFileAttachmentSelector;
