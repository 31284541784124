import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import RichTextView from 'Components/RichText/RichTextView';
import { RichText } from 'types/Base';
import { Cooperative } from 'types/Cooperative';
import { useGetTermOfSale } from '../_api/useTermsOfSale';
import { SafeAreaView } from '../_dependencies/safeArea';
import { useAppNavigation } from '../_navigator';
import { Theme, useThemeStyle, WH, WW } from '../_utils';
import { useSelectedCoopItem } from '../_utils/hooks';
import { titleFontSize, screenMargin } from '../_utils/sizes';
import { Container, FullPageMenuComponent, HeimeText, Loader, PrimaryButton } from '../Components';
import HeaderWithNav from '../Components/HeaderWithNav';
import { ArrayElement } from '../types/Utility';

const TermsOfSale = (): ReactElement | null => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { goBack } = useAppNavigation();
    const unfilteredAccounts = useSelectedCoopItem()?.payment_accounts;
    const accounts = useMemo(() => {
        const returned: typeof unfilteredAccounts = [];
        (unfilteredAccounts ?? [])?.forEach((account) => {
            if (
                !returned.find(
                    (item) =>
                        item.id !== account.id &&
                        account.organization_name === item.organization_name &&
                        account.organization_number === item.organization_number,
                )
            ) {
                returned.push(account);
            }
        });
        return returned;
    }, [unfilteredAccounts]);

    const { data, isLoading, isError, error } = useGetTermOfSale();
    const [selectedAccount, setSelectedAccount] = useState<ArrayElement<Cooperative['payment_accounts']> | null>(null);

    if (isLoading) {
        return <Loader />;
    }
    if (isError) {
        throw error;
    }
    if (!data) {
        return null;
    }

    const getHandleSelectAccount = (account: ArrayElement<Cooperative['payment_accounts']>) => () =>
        setSelectedAccount(account);
    const handleResetAccount = () => setSelectedAccount(null);

    return (
        <SafeAreaView style={themedStyle.full} edges={['left', 'bottom', 'right']}>
            {accounts.length === 0 ? (
                <>
                    <HeaderWithNav title={t('userMenu:terms:title')} />
                    <HeimeText style={themedStyle.message}>{t('userMenu:terms:noAccounts')}</HeimeText>
                    <PrimaryButton bottomAction text={t('global:back')} onPress={() => goBack()} />
                </>
            ) : selectedAccount || accounts.length === 1 ? (
                <TermsOfSaleSelected
                    page={data}
                    paymentAccount={selectedAccount ?? accounts[0]}
                    onGoBack={accounts.length > 1 ? handleResetAccount : goBack}
                />
            ) : (
                <>
                    <HeaderWithNav title={t('userMenu:terms:title')} />
                    <FullPageMenuComponent
                        items={accounts.map((acc) => ({
                            id: acc.id,
                            title: acc.organization_name ?? '',
                            onPress: getHandleSelectAccount(acc),
                            children: [],
                        }))}
                    />
                    <PrimaryButton bottomAction text={t('global:back')} onPress={() => goBack()} />
                </>
            )}
        </SafeAreaView>
    );
};

interface TermsOfSaleSelectedProps {
    page: RichText;
    paymentAccount: ArrayElement<Cooperative['payment_accounts']>;
    onGoBack(): void;
}
const TermsOfSaleSelected = ({ page, paymentAccount, onGoBack }: TermsOfSaleSelectedProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const content = useMemo((): RichText => {
        const find = '{{orgInfo}}';
        const replace = `${paymentAccount.organization_name ?? ''}, ${paymentAccount.address ?? ''}${paymentAccount.postcode ?? ''} ${paymentAccount.city ?? ''}, ${t('userMenu:terms:orgNum')} ${paymentAccount.organization_number ?? ''}`;

        const replaceText = (richText: RichText): RichText => {
            if (richText.type === 'text') {
                return {
                    ...richText,
                    text: richText.text.replace(find, replace),
                };
            } else if (richText.type === 'hardBreak') {
                return richText;
            }
            return {
                ...richText,
                content: richText.content?.map(replaceText) ?? [],
            };
        };

        return replaceText(page);
    }, [
        page,
        paymentAccount.address,
        paymentAccount.city,
        paymentAccount.organization_name,
        paymentAccount.organization_number,
        paymentAccount.postcode,
        t,
    ]);

    return (
        <>
            <HeaderWithNav title={t('userMenu:terms:title')} onPress={onGoBack} />
            <ScrollView style={themedStyle.main}>
                <Container style={themedStyle.marginBottom}>
                    <RichTextView content={[content]} style={themedStyle.content} />
                </Container>
            </ScrollView>
            <PrimaryButton bottomAction text={t('global:back')} onPress={() => onGoBack()} />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        full: { height: '100%', display: 'flex', backgroundColor: theme.white },
        main: {
            flex: 1,
            paddingLeft: WW * 0.05,
            paddingRight: WW * 0.05,
        },
        message: {
            fontSize: titleFontSize,
            textAlign: 'center',
            marginTop: screenMargin * 3,
            marginBottom: screenMargin,
            marginRight: screenMargin,
            marginLeft: screenMargin,
            fontWeight: 'bold',
        },
        marginBottom: {
            marginBottom: WH * 0.04,
            backgroundColor: theme.white,
        },
        content: {
            marginBottom: WH * 0.02,
            color: theme.darkGrey,
        },
    });

export default TermsOfSale;
