import React, { ReactElement, useEffect, useState } from 'react';
import { LayoutChangeEvent, StyleProp, PixelRatio, Image as RNImage, ImageStyle, StyleSheet } from 'react-native';
import { ResizeMode, Source } from 'react-native-fast-image';
import type { ApiImage, FileImage } from 'types/Base';

export interface CacheImageProps<SourceType extends ApiImage | FileImage> {
    source: (SourceType & { priority?: Source['priority'] }) | number;
    resizeMode?: ResizeMode | undefined;
    style?: StyleProp<ImageStyle>;
    onLoad?: (args: { width: number; height: number }) => void;
    accessibilityLabel?: string;
}

const isNotLocalFile = <Key extends ApiImage | FileImage>(source: Key | number): source is Key =>
    typeof source === 'object';

const CacheImage = <SourceType extends ApiImage | FileImage>({
    source,
    style,
    resizeMode,
    onLoad,
    accessibilityLabel,
}: CacheImageProps<SourceType>): ReactElement => {
    const [size, setSize] = useState<keyof Exclude<typeof source, number> | null>(null);
    const [loaded, setLoaded] = useState<{
        [P in keyof Exclude<typeof source, number>]?: true | undefined;
    }>(() => ({}));

    const isNewFileImage = typeof source === 'object' && 'blurhash' in source;

    useEffect(() => {
        if (typeof source !== 'number' && !source[(size as keyof typeof source) ?? 'original']) {
            setSize('original');
        }
    }, [size, source]);

    const handleLayout = (event: LayoutChangeEvent) => {
        if (!isNotLocalFile(source)) {
            return;
        }
        const { width: layoutWidth } = event.nativeEvent.layout;
        const width = PixelRatio.getPixelSizeForLayoutSize(layoutWidth);

        if (width > 2048) {
            setSize('original');
        } else if (width > 1024) {
            setSize((isNewFileImage ? 'url2048' : '2048x0') as keyof SourceType);
        } else if (width > 512) {
            setSize((isNewFileImage ? 'url1024' : '1024x0') as keyof SourceType);
        } else if (width > 128) {
            setSize((isNewFileImage ? 'url512' : '512x0') as keyof SourceType);
        } else if (width > 64) {
            setSize((isNewFileImage ? 'url128' : '128x0') as keyof SourceType);
        } else {
            setSize((isNewFileImage ? 'url64' : '64x0') as keyof SourceType);
        }
    };

    useEffect(() => {
        if (onLoad) {
            if (!isNotLocalFile(source)) {
                const image = new Image();
                image.onload = function () {
                    const img = this as unknown as { width: number; height: number };
                    onLoad({ width: img.width, height: img.height });
                };
                image.src = source + '';
            } else {
                const sourceUrl = source[(size ?? 'original') as keyof typeof source];
                if (loaded[size ?? 'original'] && sourceUrl && onLoad) {
                    RNImage.getSize(sourceUrl as string, (width, height) => {
                        if (onLoad) {
                            onLoad({ width, height });
                        }
                    });
                }
            }
        }
    }, [loaded, onLoad, size, source]);

    if (loaded[size ?? 'original'] || !isNotLocalFile(source)) {
        const imgSource = !isNotLocalFile(source)
            ? source
            : {
                  uri: source[(size ?? 'original') as keyof typeof source] as string,
                  priority: source.priority,
              };

        return (
            <RNImage
                accessibilityLabel={accessibilityLabel}
                onLayout={handleLayout}
                resizeMode={resizeMode}
                source={imgSource}
                style={style}
            />
        );
    }

    const smallest = {
        uri: findLoadedImage(loaded, source) ?? '',
    };

    const handleLoadedHidden = () => {
        if (size) {
            setLoaded({ ...loaded, [size]: true });
        }
    };
    return (
        <>
            {smallest.uri ? (
                <RNImage
                    accessibilityLabel={accessibilityLabel}
                    onLayout={handleLayout}
                    resizeMode={resizeMode}
                    source={smallest}
                    style={style}
                />
            ) : null}
            {size ? (
                <RNImage
                    onLoad={handleLoadedHidden}
                    resizeMode={resizeMode}
                    source={{
                        uri: source[size as keyof typeof source] as string,
                    }}
                    style={styles.hiddenImage}
                />
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    hiddenImage: { display: 'none' } as const,
    blurhashContainer: { width: '100%', height: '100%' },
});

export default CacheImage;
function findLoadedImage<SourceType extends ApiImage | FileImage>(
    loaded: Record<string, true | undefined>,
    source: SourceType,
): string | null {
    if (loaded.original) {
        return source.original;
    }
    if (!('blurhash' in source)) {
        if (loaded['2048x0'] && '2048x0' in source && source['2048x0']) {
            return source['2048x0'];
        }
        if (loaded['1024x0'] && '1024x0' in source && source['1024x0']) {
            return source['1024x0'];
        }
        if (loaded['512x0'] && '512x0' in source && source['512x0']) {
            return source['512x0'];
        }
        if (loaded['128x0'] && '128x0' in source && source['128x0']) {
            return source['128x0'];
        }
        if (loaded['64x0'] && '64x0' in source && source['64x0']) {
            return source['64x0'];
        }
        return source.original;
    } else {
        if (loaded.url2048 && source.url2048) {
            return source.url2048;
        }
        if (loaded.url1024 && source.url1024) {
            return source.url1024;
        }
        if (loaded.url512 && source.url512) {
            return source.url512;
        }
        if (loaded.url128 && source.url128) {
            return source.url128;
        }
        if (loaded.url64 && source.url64) {
            return source.url64;
        }
        return source.original;
    }
}
