import { useIsFocused } from '@react-navigation/native';
import {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { ApiImage } from 'types/Base';
import { MessageFile } from 'types/Chat';
import { getChatsAllQueryKey } from './useChats';
import { useOwnProfile } from './useProfile';
import { getFileObjectFunc, useLaravelInfinteQuery } from './utility';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { getUsernameFromProfile, mapInfiniteDataItem } from '../_utils/misc';
import { useSelectedCoop } from '../SelectedCoop';
import { OwnUserProfile } from '../types/User';
import {
    FileUpload,
    GenericCollectionItem,
    isFileToBeDeleted,
    isFileToBeUploaded,
    LaravelPagingResponse,
} from '../types/Utility';
export interface Group {
    id: number;
    is_admin: boolean;
    name: string;
    description: string;
    users: GenericCollectionItem[];
    admins: GenericCollectionItem[];
    cooperative_group_id: number | null;
    pictures: ApiImage[];
    chat_room_id: number;
    isMember: boolean;
}

export const invalidateGroupsQueries = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({
        queryKey: ['groups'],
    });
    queryClient.invalidateQueries({
        queryKey: ['group'],
    });
};

const useGetGroups = (): UseInfiniteQueryResult<InfiniteData<Group[]>, string | Error> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    return useLaravelInfinteQuery(
        ['groups', selectedCoopId],
        async ({ pageParam = 1, getAuthHeader }) =>
            await axios.get<LaravelPagingResponse<Group[]>>(`cooperatives/${selectedCoopId}/user_groups`, {
                headers: { authorization: await getAuthHeader() },
                params: { page: pageParam },
            }),
        {
            gcTime: Infinity,
            staleTime: 1000 * 60 * 5,
            enabled: isFocused,
        },
    );
};

const useGetGroup = (groupId: number): UseQueryResult<Group, string | Error> => {
    const isFocused = useIsFocused();
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['group', selectedCoopId, groupId],

        queryFn: async () => {
            const result = await axios.get<Group>(`cooperatives/${selectedCoopId}/user_groups/${groupId}`, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data) {
                throw new Error('Result returned with no data');
            }

            return result.data;
        },

        gcTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 1,

        initialData: () => {
            const relatedQueries = queryClient
                .getQueryCache()
                .getAll()
                .filter(
                    (item) =>
                        !item.isStale() && item.queryKey?.[0] === 'groups' && item.queryKey?.[1] === selectedCoopId,
                );

            let result: Group | undefined;
            relatedQueries?.forEach((query) =>
                (query.state.data as InfiniteData<LaravelPagingResponse<Group[]>>)?.pages?.forEach(({ data }) =>
                    data?.forEach((group: Group) => {
                        if (groupId === group.id) {
                            result = group;
                        }
                    }),
                ),
            );
            return result;
        },

        initialDataUpdatedAt: moment().subtract({ minutes: 2 }).valueOf(),
        enabled: isFocused,
    });
};

const useGetGroupFiles = (groupId: number): UseInfiniteQueryResult<InfiniteData<MessageFile[]>, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();

    return useLaravelInfinteQuery(
        ['group', selectedCoopId, groupId, 'files'],
        async () =>
            await axios.get<LaravelPagingResponse<MessageFile[]>>(
                `cooperatives/${selectedCoopId}/user_groups/${groupId}/files`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            ),
        {
            gcTime: 1000 * 60 * 60,
            staleTime: 1000 * 60 * 1,
        },
    );
};

export interface MutateGroupBody {
    name: string;
    description: string;
    admins?: number[];
    pictures?: FileUpload[];
    cooperative_group_id: number | null;
}

const useCreateGroup = (): UseMutationResult<{ success: number }, string | Error, MutateGroupBody> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (group: MutateGroupBody) => {
            const body = {
                ...group,
                active: true,
                pictures: group.pictures?.filter(isFileToBeUploaded).map(getFileObjectFunc(group.name)) ?? [],
                cooperative_groups: [],
            };

            const result = await axios.post<
                { success: number },
                AxiosResponse<{ success: number }>,
                Omit<MutateGroupBody, 'pictures'> & { pictures: { data: string; name: string }[] }
            >(`cooperatives/${selectedCoopId}/user_groups`, body, {
                headers: { authorization: await getAuthHeader() },
            });

            if (!result.data.success) {
                throw new Error('Group creation return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['groups'],
            });
        },
    });
};

const updateGroupQueries = (
    queryClient: QueryClient,
    selectedCoopId: number,
    groupId: number,
    updateFunc: (group: Group) => Group,
): void => {
    queryClient.setQueryData<InfiniteData<LaravelPagingResponse<Group[]>> | undefined>(
        ['groups', selectedCoopId],
        (data) => mapInfiniteDataItem(data, (group) => (group.id === groupId ? updateFunc(group) : group)),
    );
    queryClient.invalidateQueries({
        queryKey: ['groups', selectedCoopId],
    });

    queryClient.setQueryData<Group | undefined>(['group', selectedCoopId, groupId], (group) =>
        group ? updateFunc(group) : group,
    );
    queryClient.invalidateQueries({
        queryKey: ['group', selectedCoopId, groupId],
    });
};

const useJoinGroup = (): UseMutationResult<{ success: number }, string | Error, number> => {
    const queryClient = useQueryClient();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const { data: profile } = useOwnProfile() as { data: OwnUserProfile };
    return useMutation({
        mutationFn: async (groupId: number) => {
            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/user_groups/${groupId}/join`,
                undefined,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Group Join return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (res, groupId) => {
            const updateGroup = (group: Group): Group => ({
                ...group,
                users: [...group.users, { id: profile?.id, name: getUsernameFromProfile(profile) }],
            });
            updateGroupQueries(queryClient, selectedCoopId, groupId, updateGroup);
        },
    });
};

const useLeaveGroup = (): UseMutationResult<{ success: number }, string | Error, number> => {
    const queryClient = useQueryClient();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const { data: profile } = useOwnProfile();
    return useMutation({
        mutationFn: async (groupId: number) => {
            const result = await axios.post<{ success: number }>(
                `cooperatives/${selectedCoopId}/user_groups/${groupId}/leave`,
                undefined,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Group Leave return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (res, groupId) => {
            const updateGroup = (group: Group): Group => ({
                ...group,
                users: group.users.filter((item) => item.id !== profile?.id),
            });
            updateGroupQueries(queryClient, selectedCoopId, groupId, updateGroup);
            queryClient.resetQueries({ queryKey: getChatsAllQueryKey(selectedCoopId) });
        },
    });
};

const useEditGroup = (): UseMutationResult<{ success: number }, string | Error, [number, MutateGroupBody]> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ([groupId, group]: [number, MutateGroupBody]) => {
            const body = {
                ...group,
                name: group.name,
                description: group.description,
                pictures: group.pictures?.filter(isFileToBeUploaded).map(getFileObjectFunc(group.name)),
                removePictures: group.pictures?.filter(isFileToBeDeleted).map((item) => item.id),
                active: true,
            };

            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/user_groups/${groupId}`,
                body,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Group edit return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: (res, [groupId, mutateBody]) => {
            const updateGroup = (group: Group): Group => ({
                ...group,
                name: mutateBody.name,
                description: mutateBody.description ?? group.description,
                pictures: mutateBody.pictures
                    ? [
                          ...mutateBody.pictures.filter(isFileToBeUploaded).map(
                              ({ uri }, index) => ({
                                  original: uri,
                                  id: index,
                              }),
                              ...group.pictures.filter(
                                  (item) =>
                                      !(mutateBody.pictures ?? [])
                                          .filter(isFileToBeDeleted)
                                          .map((arg) => arg.id)
                                          .includes(item.id),
                              ),
                          ),
                      ]
                    : group.pictures,
            });
            updateGroupQueries(queryClient, selectedCoopId, groupId, updateGroup);
            queryClient.invalidateQueries({
                queryKey: ['groups'],
            });
            queryClient.invalidateQueries({
                queryKey: ['group'],
            });
        },
    });
};

//Not used, No UI for this functionality in Figma
const useRemoveGroup = (): UseMutationResult<{ success: boolean }, string | Error, number> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (groupId: number) => {
            const result = await axios.delete<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/user_groups/${groupId}`,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Group deletion return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['groups', selectedCoopId],
            });
        },
    });
};

export {
    useGetGroups,
    useGetGroup,
    useCreateGroup,
    useJoinGroup,
    useLeaveGroup,
    useEditGroup,
    useRemoveGroup,
    useGetGroupFiles,
};
