import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { FileLink } from 'types/Base';
import ImageViewing from './image-viewing/ImageViewing';
import { useGetUser } from '../../_api/useUsers';
import { useAppNavigation } from '../../_navigator';
import { Theme, useThemeStyle, WW } from '../../_utils';
import { getUsernameFromProfile } from '../../_utils/misc';
import { smallestFontSize, subtitleFontSize } from '../../_utils/sizes';
import { HeaderWithNav, HeimeText } from '../../Components';

type ImageType = Omit<FileLink, 'ext' | 'id'> & { sender_id?: number | null; id: string | number };
interface ImagePreviewProps {
    route: { params: { images: ImageType[]; selectedIndex: number } };
}

const ImagePreview = ({ route }: ImagePreviewProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const images = route.params.images ?? [];
    const initialSelectedIndex = route.params.selectedIndex ?? 0;
    const { goBack } = useAppNavigation();

    return (
        <ImageViewing
            images={images}
            imageIndex={initialSelectedIndex}
            visible
            onRequestClose={() => goBack()}
            animationType="slide"
            backgroundColor="white"
            presentationStyle="formSheet"
            HeaderComponent={({ imageIndex }) => (
                <HeaderWithNav
                    style={themedStyle.navHeader}
                    title={t('chatInfo:of', { current: imageIndex + 1, length: images.length })}
                />
            )}
            FooterComponent={({ imageIndex }) => <Footer imageIndex={imageIndex} images={images} />}
        />
    );
};

const Footer = ({ imageIndex, images }: { imageIndex: number; images: ImageType[] }): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const user = useGetUser(images[imageIndex]?.sender_id ?? undefined);
    const { t } = useTranslation();
    return images[imageIndex].last_modified ? (
        <View style={themedStyle.bottomContainer}>
            <View style={themedStyle.content}>
                <HeimeText style={themedStyle.title}>{user ? getUsernameFromProfile(user) : ''}</HeimeText>
                <HeimeText style={themedStyle.subTitle}>
                    {moment
                        .unix(images[imageIndex].last_modified)
                        .format(`dddd, MMMM Do YYYY [${t('global:clock')}] h:mm a`)}
                </HeimeText>
            </View>
        </View>
    ) : (
        <></>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        navHeader: { backgroundColor: theme.mainBackground },
        contentContainer: {
            flexGrow: 1,
            flexShrink: 0,
            justifyContent: 'center',
            alignItems: 'center',
        },
        bottomContainer: {
            paddingTop: WW * 0.04,
            paddingBottom: WW * 0.04,
            paddingLeft: WW * 0.04,
            paddingRight: WW * 0.04,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: WW * 0.06,
            marginBottom: WW * 0.06,
        },
        content: { alignItems: 'center' },
        title: {
            fontSize: subtitleFontSize,
            fontWeight: 'bold',
            color: theme.black,
        },
        subTitle: {
            fontSize: smallestFontSize,
            color: theme.secondaryText,
        },
    });

export default ImagePreview;
