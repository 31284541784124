export default {
    enterPhone: {
        title: 'Skriv in ditt telefonnummer',
        subText: 'Vi skickar en kod via sms om du inte har satt en egen kod i Heime',
        inputPlaceholder: 'Skriv in telefonnummer',
        errorPhoneNotFound:
            'Tyvärr, telefonnumret du skrev in är inte kopplat till ett bostadsområde i Heime. Vänligen kontakta oss',
        errorGeneric: 'Det uppstod ett fel, vänligen försök igen',
        errorNoNetwork: 'Nätverksfel, har du internetanslutning?',
        button: 'Fortsätt',
        repeatNumber: 'Upprepa telefonnummer',
        repeatNumberSubText:
            "Vi hittade inte ditt telefonnummer i vårt system. Vänligen skriv in ditt telefonnummer igen för att bekräfta att '{{enteredNumber}}' är ditt telefonnummer.",
    },
    enterPin: {
        back: 'TILLBAKA',
        titlePin: 'Vänligen skriv in din Heime pinkod',
        titleSMS: 'Skriv in koden du fick via sms',
        subTextPin: 'Glömt pin kod?',
        subTextSMS: 'Inte mottagit sms?',
        sendMeSms: 'Skicka sms',
        clickableTextSMS: 'Skicka igen',
        wrongCodeSentSMS: 'Du skrev in fel kod flera gånger, vi har skickat dig ett sms med en ny kod.',
        wrongCode: 'Du skrev in fel kod, försök igen.',
        error: 'Ett fel inträffade',
        button: 'Fortsätt',
        noNetwork: 'Nätverksfel, har du internetanslutning?',
    },
    selectNumber: {
        title: 'Du skrev två olika telefonnummer, välj det som är korrekt',
        select: 'Välj',
    },
    confirmUser: {
        title: 'Bor eller äger du en bostad i ett Heime-grannskap?',
        subTitle: "Om du borde ha tillgång till Heime, tryck på 'Bekräfta'",
        negative: 'Nej',
        positive: 'Bekräfta',
        modalTitle: 'Kontakta oss',
        modalText:
            'Endast användare kopplade till aktiva grannskap får tillgång till Heime. Om du är intresserad av att använda Heime, kan du kontakta oss via nedanstående kanaler.',
    },
    createRegistration: {
        title: 'Fyll i din information',
        subtitle:
            'Vi behöver denna information om dig för att kunna koppla dig till ditt grannskap och verifiera att du har tillgång. Genom att skicka in denna information godkänner du att vi lagrar och behandlar den.',
        firstNameLabel: 'Förnamn',
        lastNameLabel: 'Efternamn',
        phoneLabel: 'Telefonnummer',
        emailLabel: 'E-post',
        projectLabel: 'Grannskap',
        apartmentLabel: 'Adress / Lägenhetsnummer',
        send: 'Skicka in',
        project_error: 'Du måste beskriva grannskapet',
        apartment_error: 'Du måste beskriva bostaden',
        projectPlaceholder: 'Namnet på försäljningsprojektet, bostadsrättsföreningen eller adressen',
        apartmentPlaceholder: 'Gatunamn husnummer lägenhetsnummer',
        createError: 'Kunde inte registrera dig',
        createErrorText: 'Ett fel inträffade, försök igen senare. Om det inte fungerar, kontakta Heime',
        updateError: 'Kunde inte uppdatera registreringen',
        updateErrorText: 'Ett fel inträffade, försök igen senare. Om det inte fungerar, kontakta Heime',
    },
    registrationSelected: {
        status: 'Status:',
        waiting: 'Väntar',
        approved: 'Godkänd',
        rejected: 'Avvisad',
        titlePending: 'Din registrering har mottagits',
        subTextPending:
            'Din registrering kommer att behandlas av styrelsen/administratörer i ditt grannskap. Du kommer att få ett sms när det har skett en uppdatering. Det kan ta upp till 5 arbetsdagar. Detta görs för att säkerställa att endast dina grannar får tillgång till grannskapet',
        titleApproved: 'Din registrering är godkänd',
        subTextApproved: 'Du kan nu logga in på Heime och få tillgång till ditt grannskaps innehåll',
        titleRejected: 'Din registrering blev inte godkänd',
        subTextRejected:
            'Din registrering för {{neighborhood}}, {{apartment}} blev inte godkänd, vänligen kontakta styrelsen/administratörer eller Heime om detta är fel',
        update: 'Uppdatera din information',
        cancel: 'Avbryt registrering',
        confirmCancel: 'Är du säker på att du vill avbryta registreringen?',
        proceed: 'Fortsätt',
        contact: 'Kontakta oss',
        tryAgain: 'Försök igen',
    },
    chooseCoop: {
        title: 'Välj ett grannskap',
        noCoops: 'Du är inte kopplad till något grannskap i Heime, kontakta support.',
    },
    home: {
        notices: 'Anslag',
        activities: 'Aktiviteter',
        services: 'Bokningar',
        sharing: 'Grannsamverkan',
        next: 'Vad händer framöver?',
        noActivities: 'Inga aktiviteter planerade.',
        createActivitySuggestion: 'Vad sägs om att skapa en aktivitet?',
    },
    neighbor: {
        sharingForum: 'Saker till utlåning',
        chat: 'Chatt',
        myNeighbors: 'Mina grannar',
        resourcePersons: 'Hjälpsamma grannar',
        title: 'Grannsamverkan',
    },
    profile: {
        title: 'Min profil',
        titleNotOwnProfile: 'Användarprofil',
        phone: 'Telefon:',
        email: 'E-post:',
        groups: 'Grupper',
        groupMembers: 'Medlemmar',
        birthDate: 'Ålder och födelsedag:',
        edit: 'Redigera profil',
        setUpPin: 'Ställ in PIN',
        sendFeedback: 'Ge stjärna',
        back: 'Chatta med grannen',
        chat: 'Chatt',
        message: 'Chatt',
        call: 'Ring',
        about_me: 'Om mig:',
        updateInfoModal: {
            namesDescription: 'Har redan beskrivit sig för grannarna',
            title: 'Låt grannarna lära känna dig bättre',
            description: 'Ladda upp ett foto och skriv lite om dig själv',
            namesDescription_no_about: 'Har redan beskrivit sig för grannarna',
            description_no_about: 'Skriv lite om dig själv så att grannarna kan komma ihåg något om dig',
            namesDescription_no_avatar: 'Har redan laddat upp ett foto',
            title_no_avatar: 'Ge postlådan ett ansikte',
            description_no_avatar: 'Ladda upp ett foto på dig själv. Du kan även välja ett foto från Facebook',
            checkbox: 'Fråga mig aldrig igen',
            continue: 'Lägg till info',
            back: 'Senare',
            and_more: ' och fler ',
        },
        owningApartmentWith: 'Äger {{code}} tillsammans med:',
        owningWith: 'Äger tillsammans med:',
        rentingApartmentFrom: 'Hyr {{code}} från:',
        rentingFrom: 'Hyr från:',
        rentingApartmentTo: 'Hyr {{code}} till:',
        rentingTo: 'Hyr till:',
        livingApartmentWith: 'Bor i {{code}} tillsammans med:',
        livingWith: 'Bor tillsammans med:',
        otherApartment: 'Kopplad till bostad {{code}}:',
        other: 'Kopplad till bostad:',
        rentingApartmentWith: 'Hyr {{code}} tillsammans med:',
        rentingWith: 'Hyr tillsammans med:',
        profileCompletion: 'Fyll i din profil',
        profileCompletionDescription: 'Fyll i resten av detaljerna så att grannarna kan lära känna dig bättre',
        profileCompletionModalHeader: 'Fyll i din profil',
        profileCompletionModalDescription: 'Fyll i resten av detaljerna så att grannarna kan lära känna dig bättre',
        profileCompletionModalAvatar: 'Lägg till profilbild',
        profileCompletionModalAboutMe: 'Skriv lite om dig själv',
        profileCompletionModalEmail: 'Lägg till e-post',
        profileCompletionModalBirthday: 'Lägg till födelsedag',
        profileCompletionModalJoinGroup: 'Gå med i en grupp',
        profileCompletionModalButton: 'Stäng',
        parkingSpots: 'Parkeringsplats:',
        parkingSpots_plural: 'Parkeringsplatser:',
        deletedUser_explainer: 'Denna användare finns inte längre i grannskapet',
        deletedUser_explainer_description:
            "Detaljer om denna användare är endast tillgängliga för grannskapsadministratörer som du. Andra grannar ser bara denna användare som '{{name}}'",
    },
    myHome: {
        title: 'Min bostad',
        noResult: 'Du är inte kopplad till en bostad i detta grannskap',
        info: 'Info',
        documents: 'Dokument',
        editInfo: 'Redigera info',
        project_name: 'Bostadsnamn:',
        legal_name: 'Sektionnummer / Andelsnummer:',
        address: 'Adress:',
        type: 'Bostadstyp:',
        apartment: 'Lägenhet',
        detached_house: 'Enebolig',
        semi_detached_house: 'Radhus',
        room: 'Rum',
        floor: 'Våning: ',
        area: 'Yta: ',
        living_area: 'Primäryta:',
        bedrooms: 'Antal sovrum:',
        area_value: '{{area}} m²',
        parking_spot: 'Parkeringsplats:',
        apartment_groups: 'Medlem i dessa bostadsgrupper:',
        tenants: 'Användare',
        addTenant: 'Lägg till användare',
        role_owner: 'Ägare',
        role_livesWith: 'Bor med',
        role_renting: 'Hyresgäst',
        role_other: 'Annat',
        editUser: 'Redigera användare',
        removeUser: 'Ta bort användare',
        removeTenantConfirmHeader: 'Är du säker på att du vill ta bort användaren?',
        removeTenantConfirm: 'Ta bort',
        removeTenantCancel: 'Avbryt',
        removeTenantConfirmText:
            'Genom att ta bort användaren från bostaden kommer användaren inte längre ha tillgång till grannskapet.',
        removeTenantConfirmText2:
            'Genom att ta bort användaren från bostaden kommer användaren förlora tillgången till bostaden i Heime.',
    },
    editHome: {
        title: 'Redigera info',
        save: 'Spara ändringar',
        codeExists: '{{param}} finns redan, för att byta bostad vänligen kontakta {{coopType}}',
    },
    tenantEdit: {
        addUser: 'Lägg till användare',
        addExplainer: 'Börja med att skriva in användarens telefonnummer',
        phone: 'Telefonnummer',
        phonePlaceholder: 'Skriv in telefonnummer',
        continue: 'Fortsätt',
        role: 'Roll',
        owner: 'Ägare',
        livesWith: 'Bor med',
        renting: 'Hyresgäst',
        other: 'Annat',
        addDetails: 'Lägg till mer information',
        notify: 'Vill du meddela användaren?',
        notifyNone: 'Nej',
        notifySms: 'Ja, via sms',
        notifyEmail: 'Ja, via e-post',
        phoneTooLong: 'Telefonnumret är för långt',
    },
    navigation: {
        home: 'Hem',
        groups: 'Grupper',
        chat: 'Chatt',
        more: 'Mer',
        bellExplainer: 'Öppna notifikationer',
        profileExplainer: 'Öppna din profil',
        plusExplainer: 'Tryck här för att skapa något',
        reservations: 'Reservera',
        go_home: 'Gå tillbaka till hemskärmen',
        go_back: 'Gå tillbaka',
    },
    moreMenu: {
        myProfile: 'Min profil',
        myHome: 'Min bostad',
        services: {
            title: 'Reserveringar',
            doReservation: 'Gör en reserving',
            yourReservations: 'Dina reservationer',
        },
        resourcePersons: 'Hjälpsam granne',
        neighbors: 'Grannar',
        board: {
            title: '{{boardNounCapitalized}}',
            members: 'Kontakter',
            documents: 'Dokument',
            messageToBoard: 'Meddelanden till {{boardNoun}}',
        },
        terms: 'Försäljningsvillkor',
        settings: 'Inställningar',
        helpfulNeighbors: 'Hjälpsamma grannar',
        Products: 'Saker att låna',
        contact: 'Support',
        selectCoop: 'Byt grannskap',
        logOut: 'Logga ut',
        memberBenefits: 'Boendeavtal',
        surveys: 'Undersökningar',
    },
    footer: {
        label: 'Support:',
    },
    contacts: {
        title: 'KONTAKTA OSS',
        phoneTitle: 'Telefonnummer:',
        emailTitle: 'E-post:',
        messageTitle:
            'Om du har frågor som rör ditt grannskap rekommenderar vi att du kontaktar {{boardNoun}} i ditt grannskap.',
        messageLineOne: 'Har du hittat ett fel eller har du förslag på vad vi kan göra?\n',
        messageLineTwo:
            'Vi vill gärna höra från dig! Du kan skicka ett e-postmeddelande till oss genom att klicka på e-postadressen.\n',
        boardButton: 'Se info om {{boardNoun}}',
        support: 'Öppettider telefon: \nMån-Fre 09:00 - 16:00',
    },
    activities: {
        tabOne: 'Kommande',
        tabTwo: 'Anmälda',
        tabThree: 'Tidigare',
        signedUp: 'Anmäld',
        add_activity: 'SKAPA AKTIVITET',
        title: 'Aktiviteter',
        signed_up: 'Anmäld',
        not_signed_up: 'Inte anmäld',
        empty_future:
            'Det finns inga planerade aktiviteter än.\nSkapa en aktivitet eller registrera ditt intresse bland förslagen.',
        empty_futureOnlyAttending: 'Du är inte anmäld till några aktiviteter.',
        empty_previous: 'Det finns inga tidigare aktiviteter.',
        empty_button_suggestion: 'Se förslag',
        empty_button_create: 'Skapa aktivitet',
    },
    activity: {
        signedUp: 'Anmälan slutförd',
        signUpSelf: 'Anmäl dig själv',
        Kr: 'KR',
        cancelModal: {
            header: 'Är du säker på att du vill avboka aktiviteten?',
            header_plural: 'Är du säker på att du vill ta bort aktiviteten?',
            description: 'Deltagarna kommer att få ett meddelande om att aktiviteten har avbokats.',
            checkbox: 'Återbetala deltagare',
            back: 'NEJ, GÅ TILLBAKA',
            approve: 'JA, AVBOKA',
            approve_plural: 'JA, TA BORT',
        },
        error: 'Ett fel inträffade',
        suggestions: {
            press_here_title: 'Förslag på aktiviteter',
            press_here_description: 'Tryck här för att se och anmäla ditt intresse för aktiviteter',
            press_here_title_other_neighborhood: 'Få inspiration från andra grannskap',
            press_here_description_other_neighborhood: 'Tryck här för att se populära aktiviteter',
            images_banner_cta: 'Se förslag',
            images_banner_top: 'Hitta din nästa aktivitet',
            images_banner_title: 'Tryck här för aktiviteter',
            images_banner_cta_other_neighborhood: 'Se aktiviteter',
            images_banner_top_other_neighborhood: 'Inspiration till aktiviteter',
            images_banner_title_other_neighborhood: 'Tryck här för att se aktiviteter från andra grannskap',
            direct_press_create: 'Skapa ny aktivitet',
            header: 'Förslag på aktiviteter',
            subtitle: 'Vilken aktivitet skulle du vilja delta i?',
            template_example: 'Exempel på beskrivning',
            coffee_title: '☕ Kaffesnack',
            coffee_description:
                'Kom och delta i ett trevligt kaffemöte i gemensamhetsutrymmet, hemma eller ute i en park.',
            coffee_arrange_description:
                'För att arrangera ett kaffemöte räcker det att ha en plats att mötas. Ni kan bestämma om någon ska brygga kaffe, och om grannarna ska ta med egen kopp. Längden på mötet kan vara från en halvtimme till att grannarna kan komma förbi under en tre-timmarsperiod.',
            coffee_arrange_description_example:
                'Låt oss träffas för en trevlig pratstund 👨 💬 👩 Jag kommer att vara i gemensamhetsrummet. Ta med din termos med kaffe och gott humör! ☕ 🫖',
            coffee_arrange_location_example: 'Gemensamhetsrummet',
            christmas_lighting_title: '🎄 Julgransbelysning',
            christmas_lighting_description:
                'Upplev julmagin tillsammans i grannskapet. Samlas runt granen och njut av stämningen.',
            christmas_lighting_arrange_description:
                'För att arrangera en julgransbelysning kan ni köpa ett julträd, fråga bostadsrättsföreningen om bidrag eller skapa en insamling. Någon behöver sätta upp ljus och ställa upp granen. Om ni vill kan ni även ta med julkakor och glögg. Barnen kan sjunga julsånger och gå runt granen efter att ljusen är tända.',
            christmas_lighting_arrange_description_example:
                'Låt oss få igång julmagin! Vi behöver ljus 💡 musik 🎶 och gott humör! 🥳 Någon måste ordna med ljus och gran 🎄 Andra kan fixa med julkakor och glögg. Säg till mig vad du kan ordna. Välkomna! 😌',
            christmas_lighting_arrange_location_example: 'Mellan byggnaderna',
            bbq_title: '🔥 Grillfest',
            bbq_description: 'Det blir fyr på grillen och trevlig stämning. Ta med något gott att äta och dricka.',
            bbq_arrange_description:
                'För att arrangera en grillfest behöver ni en grill. Ni kan skapa en insamling för att köpa mat och dryck, eller så kan grannarna ta med egen mat. En högtalare med lite musik hjälper också!',
            bbq_arrange_description_example:
                'Hej! Jag grillar idag 🍖 någon som är intresserad av att vara med? Jag har en riktigt bra grill. Ta med det du vill grilla 🥩🌽🌶️',
            bbq_arrange_location_example: 'Gårdsplan',
            quiz_title: '🧠 Quiz',
            quiz_description: 'Testa dina kunskaper med grannarna. Skapa lagnamn och sätt igång tävlingen.',
            quiz_arrange_description:
                'För att arrangera en quiz behöver ni några frågor. Du kan söka på internet efter en quiz, eller skapa en egen. Det är bra att ha en quizmaster. Det rekommenderas att dela upp deltagarna i grupper och ge dem uppgiften att skapa ett lagnamn.',
            quiz_arrange_description_example:
                'Låt oss träffas för en trevlig quiz. Jag ordnar med quizen. Ta med det du vill ha för snacks',
            quiz_arrange_location_example: 'Gemensamhetsrummet',
            sport_title: "⚽ Sport på tv'n",
            sport_description: 'Samlas framför tv:n för att titta på en sporthändelse tillsammans med grannarna.',
            sport_arrange_description:
                'För att arrangera en sportkväll behöver ni en tv, en sportkanal och en sportevenemang att titta på. Kanske en lokal fotbollsmatch, Premier League, friidrott eller något annat?',
            sport_arrange_description_example:
                'Jag ska titta på sport ikväll. Jag går ner 15 minuter innan och ställer in rätt kanal. Ta med det du vill ha för snacks och kom med!',
            sport_arrange_location_example: 'TV-rummet',
            common_dinner_title: '🍽️ Gemensam middag',
            common_dinner_description:
                'Laga middag tillsammans och njut av en gemensam middag som skapar god stämning.',
            common_dinner_arrange_description:
                'För att arrangera en gemensam middag kan ni göra en plan för vad som ska serveras och vem som tar med vad. Det kan vara en trerätters middag, en tapaskväll eller en grillfest. Ni kan skapa en insamling för att köpa mat och dryck, eller så kan grannarna ta med egna ingredienser.',
            common_dinner_arrange_description_example:
                'Kom och delta i en trevlig gemensam middag. Jag ordnar med middag men tar gärna emot hjälp. Ta med det du vill dricka själv',
            common_dinner_arrange_location_example: 'Gemensamhetsrummet',
            knitting_title: '🧶 Stickning',
            knitting_description:
                'Kom och sticka tillsammans. Ta med ditt stickprojekt och njut av att vara tillsammans.',
            knitting_arrange_description:
                'För att arrangera en stickning behöver ni en plats att sitta och sticka. Ni kan skapa en insamling för att köpa kaffe och te, eller så kan grannarna ta med det de själva vill ha.',
            knitting_arrange_description_example:
                'Hej! Jag behöver inspiration till mitt nästa stickprojekt 🧶 så låt oss träffas för en trevlig stickningssession. Ta med ditt stickprojekt och så får vi ta det därifrån',
            knitting_arrange_location_example: 'Gemensamhetsrummet',
            walk_title: '👟 Gemensam promenad',
            walk_description:
                'Ta en promenad i grannskapet tillsammans med grannarna. Få frisk luft och trevligt sällskap.',
            walk_arrange_description:
                'För att arrangera en gemensam promenad behöver ni en rutt att gå och en tid att mötas. Kom ihåg att tänka på tempot under promenaden och meddela detta. Det kan vara roligt att planera ett stopp vid ett café eller en park på vägen.',
            walk_arrange_description_example:
                'Jag bjuder in till en trevlig promenad med grannarna i området. Klä dig efter vädret så tar vi en promenad ☁️☀️☔',
            walk_arrange_location_example: 'Vid huvudentrén',
            bathing_title: '🏊 Simning',
            bathing_description: 'Hoppa i vattnet. En helårsaktivitet för vissa, en sommaraktivitet för andra.',
            bathing_arrange_description:
                'För att arrangera simning behöver ni ett ställe att simma på och en tid att mötas. Kan även kombineras med bastu om det finns i närheten.',
            bathing_arrange_description_example: 'Låt oss träffas för ett trevligt bad.',
            bathing_arrange_location_example: 'Vid bryggan',
            party_title: '🎉 Grannfest',
            party_description: 'Fira tillsammans med grannarna, med eller utan anledning.',
            party_arrange_description:
                'För att arrangera en grannfest behöver ni en plats att vara och en tid att mötas. Ni kan skapa en insamling för att köpa mat och dryck, eller så kan grannarna ta med egen mat. Musik och underhållning kan också vara en del av festen. Det kan vara bra att samla ett litet gäng som ansvarar för att städa efter festen.',
            party_arrange_description_example:
                'Låt oss samlas 💃🕺 Det var länge sedan jag såg så många av er och jag vill därför bjuda in alla att träffas. Kom som du är och ta med det du vill 🥤🍻🍷🍿 Vi samlas i trevligt sällskap. PS: Vi måste städa efter oss och alla bidrag till detta uppskattas 🙇',
            party_arrange_location_example: 'Gemensamhetsrummet',
            community_effort_title: '🤝 Dugnadsarbete',
            community_effort_description: 'Få saker gjort i grannskapet. Samla ett gäng och ta ett gemensamt ansvar.',
            community_effort_arrange_description:
                'För att arrangera en dugnad behöver ni en arbetslista och ett tidpunkt att mötas. Det är en bra idé att kombinera detta med lite trevlig samvaro före, efter eller under dugnaden.',
            community_effort_arrange_description_example:
                'Hej alla! Delar av gemensamhetsområdet skulle behöva lite kärlek ❤️✨ Jag kommer att räfsa löv på parkeringsplatsen 🍂 Hör av dig om du vill hjälpa mig.',
            community_effort_arrange_location_example: 'Gemensamhetsområdet',
            workout_title: '💪 Träning eller idrott',
            workout_description:
                'Träna tillsammans med grannarna, det är alltid bra att ha någon som drar med sig andra.',
            workout_arrange_description:
                'Hitta en idrott eller träningsaktivitet ni kan göra tillsammans, antingen utomhus eller inomhus. Beskriv gärna nivån eller specificera att alla kan komma som de är. Några förslag är att lyfta vikter, spela fotboll eller volleyboll, spela tennis eller frisbee, eller att ha ett gemensamt yogapass.',
            workout_arrange_description_example:
                'Låt oss träffas för ett träningspass 💪 Finns det någon här som har ett bra träningsprogram de kan tänka sig att dela med sig av? 💪 Jag har också hittat något jag kan följa, och det verkar kunna justeras efter nivå. 🧑‍🎓 Jag tar gärna emot hjälp för att få till det 🙇',
            workout_arrange_location_example: 'Mellan byggnaderna',
            boardgames_title: '🎲 Brettspelkväll',
            boardgames_description: 'Spela gamla klassiker eller helt nya spel.',
            boardgames_arrange_description:
                'Föreslå dina egna brettspel eller fråga grannarna om deras spel. Avsätt några timmar en kväll och hitta ett lämpligt ställe att spela.',
            boardgames_arrange_description_example:
                'Hej! Jag vill spela brettspel, vill du vara med? 🎲 Jag har Trivial Pursuit. Om du har andra alternativ så är de mer än välkomna 🥳 ',
            boardgames_arrange_location_example: 'Gemensamhetsrummet',
            other_title: '💡 Annat',
            other_description: 'Har du en annan idé för en aktivitet? Tryck här.',
            other_arrange_description:
                'Du kan skapa alla typer av aktiviteter i Heime. Om du är osäker på intresset bland grannarna kan du fråga i en intressegrupp eller sätta upp en anslagstavla.',
            interest: 'Jag är intresserad av att delta',
            interested_ask_neighbors: 'Fråga grannarna om intresse',
            arrange: 'Jag vill arrangera detta',
            interested: 'Din intresse är noterat',
            one_interested: '{{fname}} är intresserad',
            two_interested: '{{fname1}} och {{fname2}} är intresserade',
            three_or_four_interested: '{{names}} och {{fname}} är intresserade',
            many_interested: '{{fname}} och {{count}} andra är intresserade',
        },
        survey_activityNew: {
            header: 'Varför slutförde du inte aktiviteten?',
            content: 'Hjälp oss att förbättra processen. Det du har skrivit i aktiviteten kommer inte att sparas.',
            survey_label: 'Feedback',
            survey_placeholder: 'Vad var otydligt, svårt eller varför avbröt du?',
            allow_contact: 'Jag kan kontaktas för uppföljning',
            submit: 'Skicka feedback',
            cancel: 'Hoppa över, gå ut',
        },
        survey_activitySuggestion: {
            header: 'Varför är du inte intresserad av aktiviteten?',
            content: 'Vi skulle gärna vilja ha feedback för att kunna förbättra aktiviteterna i ditt område.',
            survey_label: 'Feedback',
            survey_placeholder: 'Beskriv varför du inte vill anmäla ditt intresse',
            allow_contact: 'Jag kan kontaktas för uppföljning',
            submit: 'Skicka feedback',
            cancel: 'Hoppa över',
        },
    },
    activityDetail: {
        you_booked: 'Du har {{count}} biljett',
        you_booked_plural: 'Du har {{count}} biljetter',
        activity_details: 'Aktivitetsdetaljer',
        press_to_add: 'Tryck här för att lägga till en beskrivning',
        related_groups: 'Relaterade grupper',
        date: 'Datum',
        start_end_time: 'Start- och sluttid',
        location: 'Plats',
        no_location: 'Ingen plats',
        cost: 'Kostnad',
        alert: 'Notis',
        organizer: 'Du • Arrangör',
        nonOrganizer: 'Arrangör',
        attendeesTitle: 'Deltagare',
        attendees: '{{count}} Deltagare',
        attendees_plural: '{{count}} Deltagare',
        tickets: '{{count}} biljett',
        tickets_plural: '{{count}} biljetter',
        signup: 'Anmäld {{quantity}} st',
        no_cost: 'Gratis',
        kr: 'NOK',
        noticeOptions: {
            '0': 'Ingen',
            '1': 'Alla grannar',
            '3': 'Bara grannar som har anmält sig',
            '4': 'Bara grannar som inte har anmält sig',
            '5': 'Alla medlemmar i valda grupper',
        },
        quantity: 'Max antal deltagare',
        quantityExplainer: 'Max {{count}} deltagare',
        quantityExplainer_plural: 'Max {{count}} deltagare',
        no_quantity: 'Inget tak',
        cancellation: 'Avbokningsfrist: {{datetime}}',
        no_cancellation: 'Ingen avbokningsfrist',
        registrationDeadline: 'Anmälningsfrist: {{datetime}}',
        no_registrationDeadline: 'Ingen anmälningsfrist',
        contact: 'Arrangör',
        edit_activity: 'Redigera aktivitet',
        buy_for: 'Köp {{count}} biljett ({{totalAmount}} NOK)',
        buy_for_plural: 'Köp {{count}} biljetter ({{totalAmount}} NOK)',
        buy_more_for: 'Köp {{count}} biljett ({{totalAmount}} NOK) för',
        buy_more_for_plural: 'Köp {{count}} biljetter ({{totalAmount}} NOK) för',
        signup_for: 'Anmäl {{count}} st.',
        signup_more_for: 'Anmäl {{count}} st. till',
        cancel_for: 'Avboka {{count}} biljett',
        cancel_for_plural: 'Avboka {{count}} biljetter',
        sign_off: 'Avanmäld {{count}} st',
        noChanges: 'Inga ändringar',
        cantCancel: 'Avbokningsfristen har gått ut',
        noSpots: 'Det finns inga fler platser på denna aktivitet',
        noSpotsToAdd: 'Det finns inte plats för att anmäla fler',
        signUpTimePast: 'Anmälningsfristen har passerat',
        invalidNum: 'Valt antal är ogiltigt',
        notRefunded: 'Du kommer inte att få någon återbetalning för avbokade biljetter',
        willRefund: 'Du kommer att få återbetalning för biljetterna vid avbokning',
        sentAlertModal: {
            send_alert: 'Skicka varning till deltagare',
            title: 'Titel',
            message: 'Meddelande',
            send: 'Skicka',
            continue: 'Fortsätt',
            success: 'Varning har skickats till deltagare',
        },
        delete_activity: 'Ta bort aktiviteten',
        cancel_activity: 'Avbryt aktiviteten',
        send_out_alert: 'Skicka varning till anmälda',
        signup_yourself: 'Anmäl dig själv',
        change_own_signup: 'Ändra din egen anmälan',
        cancel: 'Avbryt',
        publish: 'Publicera',
        publishEdit: 'Spara',
        start_time: 'Starttid',
        end_time: 'Sluttid',
        no_groups: 'Inga grupper',
        errorLoadingAttendees: 'Det uppstod ett fel vid inläsning av deltagare',
        addDetails: 'Lägg till detaljer',
        paying_now: 'Betalas nu',
        arrangers: 'Arrangörer',
        arrangers_you_and: 'Du och {{count}} annan',
        arrangers_you_and_plural: 'Du och {{count}} andra',
        add_arranger: 'Lägg till medarrangör',
    },
    newActivity: {
        title_create_activity: 'Skapa en aktivitet',
        preview: 'Förhandsvisning av ny aktivitet',
        previewEdit: 'Förhandsvisning av ändringar',
        change_details: 'Ändra detaljer',
        imageTitle: 'Lägg till bild',
        moreMenu: {
            addPicture: 'Redigera bild(er)',
            addDescription: 'Redigera beskrivning',
            addMaxAttendees: 'Sätt upp max antal deltagare',
            addCost: 'Lägg till pris för deltagande',
            addRelatedGroups: 'Lägg till relaterad(e) grupp(er)',
            addLatestPossibleSignupTime: 'Sätt upp anmälningsfrist',
            addLatestCancellationTime: 'Sätt upp avbokningsfrist',
            alerts: 'Bestäm varning för grannar',
            addArrangers: 'Lägg till medarrangörer',
        },
        receive_alert: 'Välj vem som ska få varning',
        send_out_alert:
            'Vi skickar ut ett varningsmeddelande vid skapandet och 24 timmar före start / anmälningsfrist.',
        groups: 'Grupper',
        about_activity: 'Beskriv aktiviteten',
        title: 'Namn',
        enter_title: 'Ge aktiviteten en beskrivande titel',
        location: 'Plats',
        enter_location: 'Skriv uppmötandeplats',
        time: 'Tidpunkt för aktiviteten',
        start: 'Start',
        end: 'Slut',
        enter_description: 'Skriv en beskrivning, gärna med praktisk information. Exempelvis betalning och uppmötande.',
        description: 'Beskrivning',
        add_group: 'Lägg till grupper',
        add_group_explainer:
            'Det kommer att skickas ett meddelande i gruppchatten om aktiviteten, och du kommer att kunna varna endast gruppmedlemmarna.',
        when_will_activity: 'Tidpunkter för aktivitet',
        choose_start: 'Starttid för aktiviteten',
        choose_end: 'Sluttid för aktiviteten',
        signuptime: 'Anmälningsfrist',
        enableSignUpTime: 'Har anmälningsfrist',
        registration: 'Anmälningsfrist',
        cancellation: 'Avbokningsfrist',
        cancellationSame: 'Samma som anmälningsfrist',
        choose_location: 'Beskriv platsen för aktiviteten',
        add_photo: 'Lägg till bilder',
        first_image_will: 'Den första bilden du lägger till kommer att bli huvudbilden',
        image: 'Bilder',
        indicate_cost: 'Lägg in pris och kapacitet',
        explainNoCost:
            'Ditt grannskap är inte registrerat i Vipps och du kan därför inte sätta ett pris för evenemanget.',
        paid_event: 'Deltagande kostar pengar',
        price: 'Pris (kr)',
        priceMVA: 'Totalpris (kr) (inkl. moms)',
        mva: 'Moms (%)',
        bookKeepingAccount: 'Bokföringskonto',
        tax_rate: 'Vippskonto',
        attendees_limit: 'Max antal deltagare',
        enter_zero: 'Genom att inte skriva något kommer det vara obegränsat antal deltagare',
        paymentExplainer: 'Pengarna för deltagande kommer att överföras till det konto som valts ovan.',
        refundCancellations: 'Återbetala deltagare som avbokar sin biljett',
        continue: 'Gå till förhandsvisning',
        see_preview: 'Se förhandsvisning',
        cooperative_group_id: 'Tillgänglig för',
        noCooperativeGroup: 'Endast',
        cooperative_group: 'Bestäm om aktiviteten ska vara tillgänglig för fler grannskap eller endast ditt eget',
        no_groups: 'Du är inte medlem i några grupper',
        arrangers: 'Arrangörer',
        add_arranger: 'Lägg till arrangörer',
        add_arrangers_explainer:
            'Lägg till några som kan hjälpa dig med att arrangera aktiviteten. De kommer kunna redigera och avboka aktiviteten samt skicka meddelanden till anmälda.',
        no_users: 'Inga användare i detta grannskap',
        no_users_search: 'Inga användare hittades',
        search_users: 'Sök efter användare',
        activity_about_description:
            'Genom att gå till förhandsvisning kan du ändra fler inställningar och lägga till bilder.',
        confirm_template: 'Vill du använda mallen?',
        confirm_template_content:
            'Genom att använda en mall kommer det du redan har skrivit i aktiviteten att skrivas över.',
        confirm_template_cancel: 'Avbryt',
        confirm_template_confirm: 'Ja',
    },
    chatAll: {
        createNewChat: 'Skapa ny chatt',
        searchPlaceholder: 'Sök efter chatt',
        selectMembers: 'Välj medlemmar',
        selectMembersSelected: 'Skapa chatt ({{count}})',
        createChat: 'Skapa chatt',
        noChats: 'Du har inga chattar',
        sentByYou: 'Du',
        sentMessage: 'skickade ett meddelande',
        title: 'Chattar',
    },
    sharingAll: {
        noSearchResult: 'Inga träffar',
        viewAll: 'Visa alla',
        messageType: {
            '2': 'Anslag',
            '0': 'Föremål',
            '1': 'Hjälpsamma grannar',
        },
        noMessageType: {
            Requests: 'Inga anslag här just nu. Anslag till grannar kommer att dyka upp här.',
            ByBoard: 'Inga anslag från {{boardNoun}} här. Anslag från {{boardNoun}} kommer att arkiveras här.',
            Products:
                'Inga föremål här än. Föremål att dela kommer att dyka upp här, varför inte lägga ut något själv?',
            Helpers: 'Inga hjälpsamma grannar här än. När du anmäler dig som hjälpsam granne kommer du att synas här.',
            neighborSharing:
                'Inga grannar som delar här än. När du anmäler dig som hjälpsam granne eller lägger ut ett föremål för lån kommer det att synas här.',
        },
        neighborSharingTitle: 'Grannar som delar',
        fromBoard: 'Från {{boardNoun}}',
        new: 'Ny',
        stars: '⭐️ {{count}} stjärnor',
        no_star: '⭐️ Ge stjärna',
        postRequest: 'Skapa nytt anslag',
        postProduct: 'Lägg ut ett föremål',
        offerHelp: 'Erbjud din hjälp',
        myProducts: 'Mina föremål',
        myRequests: 'Mina anslag',
        yourProducts: 'Dina föremål',
        yourRequests: 'Dina anslag',
        requests: { plural: 'Anslag', singular: 'Ett anslag' },
        products: { plural: 'Föremål', singular: 'Ett föremål' },
        searchResults: 'Sökeresultat',
        you: 'Du',
        dot: ' • ',
        errorSaving_header: 'Det gick inte att spara',
        errorSaving_description: 'Det gick inte att spara inställningarna, försök igen senare',
        save: 'Spara',
        userSettings: 'Notifikationsinställningar',
        userSettingsShort: 'Notifikationer',
        settingsAllNotifications: 'Ta emot notifikationer om viktiga anslag',
        settingsOnlyBoard: 'Ta emot endast notifikationer om viktiga anslag från {{boardNoun}}',
        settingsNoneNotifications: 'Mottaga inga notifikationer om anslag',
        follow: 'Följ',
        unfollow: 'Sluta följa',
        comment: '1 kommentar',
        comments: '{{count}} kommentarer',
        attachment: '1 bilaga',
        attachments: '{{count}} bilagor',
        created: 'Skapad av',
        fromBoardBy: 'av',
        all: 'Aktiva',
        onlyFor: 'Endast synlig för',
        open_settings: 'Öppna inställningar för notifikationer på anslag',
    },
    sharingNew: {
        newMessage: 'Skapa nytt meddelande',
        itemTitle: 'Titel',
        itemText: 'Meddelande',
        imageSelector: 'Lägg till bild',
        sendButton: 'Skicka meddelande',
        titleEdit: {
            0: 'Redigera föremål',
            1: 'Redigera hjälpsam granne',
            2: 'Redigera anslag',
            3: 'Redigera anslag',
        },
        title: {
            0: 'Nytt föremål',
            1: 'Bli en hjälpsam granne',
            2: 'Nytt anslag',
            3: 'Nytt anslag',
        },
        chooseType: 'Välj typ',
        messageCategory: {
            '0': {
                title: 'Delbart föremål',
                desc: 'Något du kan låna ut, t.ex en stege, symaskin, skridskor eller socker!',
            },
            '1': {
                title: 'Bli en hjälpsam granne',
                desc: 'Berätta för dina grannar vad du kan hjälpa dem med',
            },
            '2': {
                title: 'Anslag',
                desc: 'Lägg ut ett anslag till dina grannar, du kan till exempel be om hjälp eller bara informera dem om något',
            },
            '3': {
                title: 'Anslag från {{boardNoun}}',
                desc: 'Lägg ut ett anslag till dina grannar, anslaget kommer att markeras som "från {{boardNoun}}"',
            },
            '4': {
                title: 'Samling',
                desc: 'Om du ska sätta dig i gemensamma utrymmen är det trevligt att bjuda in grannarna',
            },
        },
        addProduct: {
            title: 'Beskriv föremålet',
            image: 'Bild',
            textTitle: 'Titel',
            enterTitle: 'Skriv en beskrivande titel',
            textDesc: 'Beskrivning',
            enterDesc:
                'Beskriv föremålet och hur det kan lånas. Till exempel: Står lätt tillgängligt i förrådet eller om du måste kontaktas först',
        },
        requestHelp: {
            title: 'Anslag',
            textTitle: 'Titel',
            enterTitle: 'Skriv in titel',
            textDesc: 'Beskrivning',
            enterDesc: 'Skriv in beskrivning',
            send_notification: 'Skicka notifikation till grannar',
            sent_notification: 'Notifikation skickad',
            no_notification: 'Ingen notifikation skickad',
            noAccess: 'Notifikation ej tillgänglig: Du kan bara göra detta en gång per dygn',
            allow_comments: 'Tillåt kommentarer på anslaget',
            attachments: 'Bilagor',
            never_expires: 'Permanent anslag',
            never_expires_description:
                'Anslaget kommer automatiskt att förnyas var 30:e dag så att det inte försvinner',
        },
        helpNeighbour: {
            title: 'Berätta vad du kan hjälpa med',
            textTitle: 'Titel',
            enterTitle: 'Beskriv kort med stikkord vad du kan hjälpa med',
            textDesc: 'Förklara varför och hur du kan hjälpa',
            enterDesc: 'Skriv mer utförligt. Skriv till exempel erfarenheter eller exempel på hjälp du kan ge.',
            textLimit: '({{count}} / 40)',
        },
        activity: {
            explainer: 'En samling passar bättre som en aktivitet, vill du skapa en?',
            action: 'Gå till skapa aktivitet',
        },
        publish: 'publicera',
        saveError: 'Det gick inte att spara',
        publishError: 'Det gick inte att skapa',
        publishErrorNotification:
            'Du kan inte skapa fler anslag med notifikation. Du kan endast skapa ett anslag med notifikation per dygn.',
        chooseApartmentGroups: 'Välj bostäder',
        chooseApartmentGroupsDesc: 'Genom att välja inga bostäder blir anslaget synligt för alla i grannskapet',
        apartment_groups: 'Begränsa till bostäder',
    },
    profileEdit: {
        title: 'Redigera profil',
        submit: 'Spara ändringar',
        continue: 'Fortsätt',
        public: 'Offentlig',
        first_name: 'Förnamn',
        last_name: 'Efternamn',
        email: 'E-post',
        phone: 'Telefonnummer',
        own_code: 'Personlig PIN',
        birthday: 'Födelsedag',
        about_me: 'Lite om mig',
        enterPhone: 'Skriv in telefonnummer',
        enterFirstName: 'Skriv in förnamn',
        enterSurname: 'Skriv in efternamn',
        enterEmail: 'Skriv in e-post',
        hideEmailFromProfile: 'Dölj e-post från min profil',
        hideBirthdayFromProfile: 'Dölj födelsedag från min profil',
        hidePhoneFromProfile: 'Dölj telefonnummer från min profil',
        aboutMePlaceHolder:
            'T.ex: Jobbar i butik. Älskar att vara ute, men lite slår ullfilt och ett glas vin. Kan ett korttrick.',
        updateMobileModal: {
            header: 'Är du säker på att du har angett rätt telefonnummer?',
            description: 'Om du gör ett misstag kommer du inte kunna logga in på applikationen.',
            back: 'Nej, gå tillbaka',
            submit: 'Ja, säkert',
        },
        upload_image: 'Ladda upp profilbild',
        delete_image: 'Ta bort profilbild',
        facebookLoginErrorHeader: 'Kunde inte logga in med Facebook',
        facebookLoginCancelledHeader: 'Inloggning med Facebook avbröts',
        facebookLoginErrorDescription: 'Vill du ladda upp bild kan du trycka på samma knapp igen',
        facebookPictureDownloadErrorHeader: 'Kunde inte ladda ner profilbild från Facebook',
        facebook_no_picture: 'Hittade ingen profilbild på Facebook',
        facebookTrackingPermissionErrorHeader: 'Fick inte rätt behörigheter för att hämta profilbild från Facebook',
        facebookTrackingPermissionErrorDescription:
            'Gå till inställningar och ge behörighet för Heime under "Integritet" => "Spårning" för att ladda upp profilbild från Facebook',
        uploading_file: 'Vänta tills bilden är uppladdad',
        change_picture: 'Ändra profilbild',
        about_me_title: 'Lägg till "om mig"',
        aboutMeTips: 'Tips på vad du kan skriva',
        aboutMeExamples: 'Exempel från dina grannar',
        about_me_tip_0: 'Var dig själv',
        about_me_tip_description_0:
            'Skriv det du vill själv, det finns inget rätt eller fel sätt att skriva "om mig"-sektionen på',
        about_me_tip_1: 'Vad jobbar du med?',
        about_me_tip_description_1:
            'Många skriver vad de jobbar med i vardagen, eller kanske något de har jobbat med tidigare?',
        about_me_tip_2: 'Har du alltid bott i området?',
        about_me_tip_description_2:
            'Du kan skriva var du har bott tidigare eller var du kommer ifrån, kanske har du flyttat hit nyligen?',
        about_me_tip_3: 'Har du några hobbyer eller intressen?',
        about_me_tip_description_3:
            'Skriv gärna vad du gillar att göra, kanske finns det någon i området med samma intressen?',
        about_me_tip_4: 'Presentera dig själv',
        about_me_tip_description_4: 'Kanske har du ett smeknamn, eller något som gör att du är lätt att komma ihåg?',
        about_me_tip_5: 'Svårt att skriva?',
        about_me_tip_description_5: 'Det är inte alltid lätt, men stikkord funkar bra',
        about_me_tip_6: 'Vem kan se det som står här?',
        about_me_tip_description_6: 'Det är endast dina grannar som kan se vad du skriver om dig själv',
    },
    reports: {
        header: 'Rapporter',
        search: 'Sök efter rapport',
        reportToBoard: 'Skicka in ny rapport',
        noReports: 'Inga rapporter här än.',
        noSearchResult: 'Inga träffar på din sökning',
        yoursAndPublic: 'Dina och offentliga',
        open: 'Öppen',
        closed: 'Stängd',
        disabled: 'Denna funktion är avstängd av styrelsen. De kan kontaktas via mail på',
    },
    reportSelected: {
        reportDetails: 'Rapport',
        error: 'Ett fel uppstod',
        editReport: 'Redigera rapport',
        deleteReport: 'Ta bort rapport',
        comments: 'Kommentarer',
        addComment: 'Lägg till kommentar',
        statusTypeNew: 'Ny',
        statusTypeProcessing: 'Under behandling',
        statusTypeDone: 'Löst',
        statusTypeWaiting: 'Väntar',
        commentModal: {
            addComment: 'Lägg till kommentar',
            message: 'Meddelande',
            enterMessage: 'Skriv in meddelande',
            send: 'Skicka',
            error: 'Ett fel uppstod vid lagring av kommentar',
            publicComment:
                'Kommentaren kommer att synas för alla grannar eftersom rapporten är markerad som "offentlig"',
            privateComment:
                'Kommentaren kommer bara att synas för {{user}} och andra i {{boardNoun}}, de kommer också att få en notifikation om kommentaren',
            privateCommentOwn:
                'Kommentaren kommer endast att synas för dig och {{boardNoun}}, {{boardNoun}} kommer också att få en notifikation om kommentaren',
        },
        changeStatusModal: {
            errorUpdating: 'Kunde inte uppdatera status',
            changeStatus: 'Ändra status',
            save: 'Spara',
        },
        noComments: 'Inga kommentarer här än.',
        changeStatus: 'Ändra status',
    },
    reportNew: {
        header: { edit: 'Redigera rapport', new: 'Ny rapport' },
        image: 'Bild',
        title: 'Titel',
        error: 'Ett fel uppstod',
        enterTitle: 'Skriv in titel',
        desc: 'Beskrivning',
        enterDesc: 'Skriv in beskrivning',
        isPublic: 'är synlig för grannarna',
        publicContent: 'Genom att göra rapporten synlig för grannarna, kan andra användare i appen se din rapport.',
        publish: 'Skicka in',
        saveChanges: 'Spara ändringar',
    },
    transaction: {
        waiting: 'Väntar på bekräftelse',
        toVipps: 'Gå till Vipps för att avbryta',
        pay: 'Betala i Vipps',
        title: 'Betalningsstatus',
        cancelled: 'Betalningen avbröts',
        cancelledExplainerBooking:
            'Du har inte blivit debiterad, din bokning är inte heller bekräftad. Du kan försöka igen.',
        cancelledExplainerActivity: 'Du har inte blivit debiterad, din anmälan är inte bekräftad. Du kan försöka igen.',
        back: 'Tillbaka',
        success: 'Betalningen genomfördes',
        timeUntilStart: 'Om {{formattedDuration}}',
        started: 'Redan startad',
        has_been_reservedPost: ' har reserverats',
        reservations: 'Gå till reservation',
        explainLocks:
            'Produkten du har reserverat låses upp genom Heime-appen. För att låsa upp går du till din reservation under "Dina reservationer" efter att den har startat och trycker på "Lås upp".',
        has_been_signedup: 'Du är anmäld till ',
        quantity_pre: 'Du har ',
        quantity_post_single: ' biljett',
        quantity_post_multiple: ' biljetter',
        toActivity: 'Gå till aktiviteten',
        bookingConfirmed: 'Bokningen är bekräftad',
    },
    board: {
        title: 'Kontakter',
        search: 'Sök',
        members: '{{count}} Medlem',
        members_plural: '{{count}} Medlemmar',
        call: 'Ring',
        mail: 'E-post',
        boardResponsibility: 'Ansvar:',
        reportToBoard: 'Skicka in meddelande till {{boardNoun}}',
        noResult: 'Inga träffar',
        noContent: 'Inga kontakter',
    },
    documents: {
        title: 'Dokument',
        search: 'Sök',
        folder: 'Mapp',
        files: '{{count}} fil',
        files_plural: '{{count}} filer',
        notFound: 'Inga träffar',
        noResult: 'Det finns inga dokument',
        error: 'Kunde inte ladda ner filen',
        privateFolder: 'Denna mapp är endast synlig och tillgänglig för naboalgsadministratörer',
    },
    neighbours: {
        alphabetic: 'alfabetiskt',
        apartment: 'adress',
        searchPlaceholder: 'Sök efter namn',
        noUserFound: 'Ingen användare hittad',
        noApartment: 'Ingen adress',
    },
    reservations: {
        title: 'Dina reservationer',
        back: 'Tillbaka',
        noReservations:
            'Du har inga registrerade reservationer, om du vill hyra något kan du trycka på knappen längst ner.',
        services: 'Tjänster',
        today: 'Idag',
        selectTimeRange: 'VÄLJ TID',
        clearDates: 'ÅTERSTÄLL',
        reserver: 'RESERVERA',
        booked: 'Bokad:',
        total: 'Totalt:',
        errorUpdate: 'Kunde inte uppdatera reservationen',
        errorCreate: 'Kunde inte skapa reservationen',
        alreadyReserved_title: 'Redan reserverad',
        alreadyReserved_description: 'Produkten är redan reserverad under denna tidsperiod',
        successUpdate: 'Reservationen uppdaterades',
        started: 'Startad',
        openLock: 'Öppna låset',
        locks: { singular: 'Åtkomst via app', plural: 'Åtkomst via app' },
        until: 'Tills',
        left: 'kvar',
        onGoing: 'Startad',
        quantity: '{{quantity}} st.',
        checkIn: 'Checka in',
    },
    services: {
        title: 'Reservationer',
        myReservations: 'Dina reservationer',
        oneOngoing: 'En aktiv reservation',
        ongoingReservations: 'aktiva reservationer',
        oneReservation: 'En reservation',
        reservations: 'reservationer',
        empty: 'Det finns inga tjänster eller något att reservera här',
    },
    expandedReservation: {
        unlock: 'Lås upp',
        canUnlockIn: 'Kan öppnas om ',
        remote_unlock: 'Fjärröppna låset',
        expired: 'Reservationen har löpt ut',
        reservation_has_locks: 'Produkten har ett lås',
        cancel: 'Avbryt',
        errorCancel: 'Kunde inte avbryta reservationen',
        change: 'Ändra',
        lockModal: {
            lockUnlocked: 'Låset är öppet',
            lockboxUnlocked: 'Nyckelskåpet är upplåst, du kan nu öppna det',
            get_keys: 'Ta ut nycklarna och stäng nyckelskåpet',
            leave_keys: 'Lämna tillbaka nycklarna och stäng nyckelskåpet',
            lockOpened: 'Låset öppet',
            lock: 'Tryck för att låsa',
            attemptingConnect: 'Letar efter låset',
            lockConnecting: 'Letar efter låset',
            noConnect: 'Kunde inte hitta låset, är det i närheten?',
            requestingUnlock: 'Öppnar låset',
            unlocking: 'Låser upp',
            retry: 'Försök igen',
            unlockFailed: 'Kunde inte öppna låset',
            unlocked: 'Låset är öppet',
            lockingIn: 'Låses om ',
            sec: 'sek',
            lockClosed: 'Låset är stängt',
            pinClosedCanClose: 'Kopplat bort, verifiera att låset är stängt',
            unlockAgain: 'Öppna igen',
            lockDisconnected: 'Låset är frånkopplat',
            closeLock: 'Stäng låset för att fortsätta',
            noPermission: 'Behöver åtkomst till Bluetooth och platsinformation',
            openSettings: 'Öppna inställningar',
            close: 'Avbryt',
            noBluetooth: 'Bluetooth är inte aktiverat',
            noWeb: 'Ladda ner appen för att öppna låset',
            pressLock: 'Väck låset genom att trycka på knappen',
            wakeLock: 'Väck låset för att fortsätta',
            noConnectRememberToWake: 'Kunde inte hitta låset, har du väckt det?',
            noBluetoothPermission: 'Behöver åtkomst till Bluetooth för att öppna låset',
            noLocationPermission: 'Behöver åtkomst till platsinformation för att öppna låset',
            pressLockExplainer:
                'Du måste trycka på knappen på låset som hänger på väggen eller i närheten av det du har reserverat. Annars kan inte appen kommunicera med det.',
            closeFailed: 'Kunde inte stänga låset',
            closing: 'Stänger låset',
            lockClosing: 'Låser...',
            waiting: 'Väntar på låset',
            remote_unlock: 'Du kan låsa upp låset var som helst',
            remote_unlocking: 'Har begärt att låset ska låsa upp',
            lockDone: 'Låset öppnat',
            missingSecretKeyOrTokenHeader: 'Saknar information för att öppna låset',
            missingSecretKeyOrTokenText:
                'Anslut telefonen till internet, tryck försök igen, och vänta en minut innan du försöker igen.',
        },
        bluetoothDenied: 'Heime behöver tillåtelse att använda Bluetooth för att ansluta till låset',
        bluetoothDisabledAndroid: 'Du måste manuellt slå på Bluetooth, klicka här för att öppna inställningar',
        bluetoothDisabledIOS: 'Du måste manuellt slå på Bluetooth',
        noWeb: 'Låset kan inte öppnas från webben, du måste ladda ner appen',
        cancelModal: {
            header: 'Är du säker på att du vill avbryta reservationen?',
            refundable: 'Du kommer att få återbetalning för reservationen',
            hours: '{{count}} timme',
            hours_plural: '{{count}} timmar',
            days: '{{count}} dygn',
            days_plural: '{{count}} dygn',
            nonRefundableHours:
                'Du kommer inte få återbetalning för reservationen eftersom den startar om mindre än {{hours}}',
            nonRefundableDaysHours:
                'Du kommer inte få återbetalning för reservationen eftersom den startar om mindre än {{days}} och {{hours}}',
            nonRefundableDays:
                'Du kommer inte få återbetalning för reservationen eftersom den startar om mindre än {{days}}',
            nonRefundable: 'Du kommer inte få återbetalning för reservationen',
            back: 'GÅ TILLBAKA',
            approve: 'AVBRYT',
        },
        okNoCancel: 'OK',
        noCancel:
            'Du kan inte avbryta reservationen eftersom den redan har börjat. Du kan fortfarande ändra den för att avsluta tidigare',
        cannotCancel: 'Kan inte avbryta',
        productInfo: 'Se mer information',
        bookingDetails: 'Start och slut',
        starting: 'Reservationen startar',
        ending: 'Reservationen slutar',
        ends: 'Till',
        successCancelHeader: 'Reservationen är avbokad',
        changedClockNegative:
            'Klockan på din telefon visar en annan tid än vår klocka, ställ in klockan korrekt och starta om appen för att kunna öppna låset',
        serverAndPhoneOutOfSync:
            'Klockan på din telefon visar en annan tid än vår klocka. Ställ in klockan och starta om appen för att kunna öppna låset',
        noDataToCompare:
            'På grund av brist på internet kan vi inte kontrollera tidpunkten för reservationen. Anslut till internet och försök igen',
        tryAgain: 'Försök igen',
        more_options: 'Fler alternativ',
        pin_code: 'PIN-kod',
        copied_pin_code: 'PIN-kod kopierad',
        copy_pin_explainer: 'Tryck för att kopiera PIN-koden',
        reason: 'Anledning',
        quantity: 'Antal - {{count}} stycken',
        previous_visitor: 'Tidigare användare',
        previous_visitor_action: 'Tryck för förklaring',
        previous_visitor_info:
            'Om det är något som inte stämmer med produkten kan du kontakta personen som hade produkten innan dig, och samordna hur felet kan åtgärdas',
        checkOut: 'Checka ut',
        checkIn: 'Checka in',
        booked: 'Bokad',
        all_day: 'Hela dagen',
        lock: '1 lås',
        lock_plural: '{{count}} lås',
        users: 'Användare på reservationen',
        users_plural: 'Användare på reservationen',
        you: 'Du',
        edit: 'Redigera',
        edit_users: 'Redigera användare',
        edit_users_subtitle:
            'Lägg till eller ta bort användare från reservationen, de kommer att få samma tillgång som du',
        roommates: 'Anslutna till samma bostad',
        neighbours: 'Grannar',
        noUsers: 'Inga användare hittades',
        noUsersToAdd: 'Inga användare att lägga till',
        continue: 'Fortsätt',
        remove_user: 'Tryck för att ta bort användare',
        users_edited: 'Användare med åtkomst har uppdaterats',
    },
    serviceExpanded: {
        nAvailablePlural: 'Antal: {{amount}}',
        shortTerm: 'Timebaserat',
        longTerm: 'Dögnbaserat',
        price_type: {
            rate_per_booking: '',
            rate_per_night: '',
            rate_per_hour: '',
            rate_per_booking_capacity: '',
            rate_per_hour_per_capacity: 'per timme',
            rate_per_night_per_capacity: 'per natt',
            rate_per_hour_with_night_price:
                'Mellan {{nighttime_end_hour}}-{{nighttime_start_hour}}: {{daytime_price}} kr, resten av dygnet: {{nighttime_price}} kr',
        },
        price_type_short: {
            rate_per_booking: 'reservationspris',
            rate_per_night: 'natt',
            rate_per_hour: 'timme',
            rate_per_booking_capacity: 'styck',
            rate_per_hour_per_capacity: 'styck',
            rate_per_night_per_capacity: 'styck',
        },
        maxBooking_hour: 'max en timme',
        maxBooking_hour_plural: 'max {{count}} timmar',
        maxBooking_day: 'max en dag',
        maxBooking_day_plural: 'max {{count}} dagar',
        hours: '1 timme',
        hours_plural: '{{count}} timmar',
        days: '1 dag',
        days_plural: '{{count}} dagar',
        noImage: 'bild ej vald',
        name: 'Namn',
        description: 'Beskrivning',
        reserve: 'Boka',
        chooseStart: 'Välj incheckningsdatum',
        chooseEnd: 'Välj utcheckningsdatum',
        update: 'Uppdatera',
        price: 'Pris',
        kr: 'kr',
        ammount: 'Antal',
        i_want_this_one: 'Jag vill ha denna',
        max_booking_period: 'Bokningsperiod',
        who_booked: 'Vem har bokat under denna period?',
        close: 'Stäng',
        availableAllDay: 'Tillgänglig hela dagen',
        availableSome: 'Lediga tider',
        notAvailable: 'Inte tillgänglig',
        availableCount: '{{count}} stycken tillgängliga',
        availableSomeCapacityMultiple: 'Lediga tider ({{min}} - {{max}} stycken)',
        availableSomeCapacitySingle: 'Lediga tider ({{count}} stycken)',
        timeNow: 'Nu',
        n_available: '{{count}} stycken',
        available: 'Tillgänglig',
        checkin: 'Incheckning',
        checkout: 'Utcheckning',
        selectAmount: 'Hur många vill du boka?',
        reserveTime: 'Välj tid',
        reserveDays: 'Välj datum',
        reserveDescriptionTime:
            'Välj en eller flera perioder du vill boka. Tryck på första perioden och sedan sista perioden.',
        reserveDescriptionDays: 'Välj först incheckningsdatum, välj sedan utcheckningsdatum. Se tider ovan.',
        maxBookingStart: 'Du kan boka för ',
        durationLabel: 'Bokad:',
        sumLabel: 'Totalt:',
        clear: 'ÅTERSTÄLL',
        today: 'Idag',
        clickOnBusy: 'Du kan trycka på en upptagen tid för att se vem som har bokat',
        maxBookedPerBooking: 'Det kan bokas max {{count}} stycken per reservation.',
        skipPaymentExplainer:
            'Det är möjligt att boka denna produkt utan att betala om du inte behöver produkten för dig själv',
        skipPayment: 'Gemensam bokning',
        paymentReservation: 'Privat bokning',
        isSkippingPayment: 'Tillgänglig för grannar',
        reserveReasonModalHeader: 'Ange anledning',
        reserveReasonModalLabel: 'Anledning till bokning',
        reserveReasonModalButton: 'Boka',
        reserveReasonModalTooLong: 'Anledningen får inte vara längre än 255 tecken',
        reserveLicensePlateModalHeader: 'Skriv in registreringsnummer',
        reserveLicensePlateModalText: 'Skriv in registreringsnumret på fordonet som ska parkera',
        reserveLicensePlateModalButton: 'Bekräfta',
        reserveLicensePlateModalDropdown: 'Visa fler registreringsnummer',
        reserveLicensePlateModalManage: 'Redigera sparade registreringsnummer',
        reserveLicensePlateModalCreateNew: 'Lägg till nytt registreringsnummer',
        reserveLicensePlateModalTooLong: 'Registreringsnumret får inte vara längre än 20 tecken',
        showEarlier: 'Tidigare tider',
        localTime: 'Lokal tid',
        quantityLabel: 'Antal',
        pieces: '{{count}} stycken',
        quantity: 'Antal {{quantity}}',
        open_explainer: 'Tryck för att få en förklaring',
        not_confirmed: 'Inte betald',
        change_not_confirmed: 'Ändring är inte betald',
        timeBasedPricing: '. Observera att priset kan variera beroende på vilken tid du bokar för',
        hasBooking: 'Du har redan en bokning av {{product}}',
        booking_details: 'Se bokning',
    },
    notifications: {
        title: 'Aviseringar',
        ago: 'sedan',
        updateApp: {
            android: 'Play',
            ios: 'App',
            title: 'Ny version av Heime',
            content:
                'Vi rekommenderar att du uppdaterar din app för att undvika problem. För att uppdatera, gå till "{{store}} Store" och klicka på "Uppdatera".',
            buttonText: 'Uppdatera',
        },
        newChatMessage: {
            title: '{{name}} har skickat dig ett meddelande',
            buttonText: 'Gå till chatten',
        },
        activityInvite: {
            buttonText: 'Gå till aktivitet',
            content: 'En ny aktivitet har skapats',
        },
        tfHoursBeforeActivity: {
            title: 'Påminnelse om {{name}}',
            content: 'Det är mindre än 24 timmar kvar till aktiviteten',
            buttonText: 'Gå till aktivitet',
        },
        tfHoursBeforeSignUpTerm: {
            title: 'Påminnelse om {{name}}',
            content: 'Det är mindre än 24 timmar kvar till anmälningsfristen går ut',
            buttonText: 'Gå till aktivitet',
        },
        activityInvitedUsers: {
            buttonText: 'Gå till aktivitet',
        },
        activityCustomNotify: {
            title: 'Meddelande relaterat till {{name}}',
            content: 'Meddelande från arrangör: {{name}}',
            buttonText: 'Gå till aktivitet',
        },
        cancelledActivity: {
            title: '{{name}} är inställd',
        },
        supportCreated: {
            title: 'Nytt meddelande till {{boardNoun}}, från: {{name}}',
            buttonText: 'Gå till meddelandet',
        },
        supportStatus: {
            title: '{{boardNounCapitalized}} har ändrat status på meddelandet "{{title}}" till "{{name}}"',
            buttonText: 'Gå till meddelandet',
        },
        userGroupCreated: {
            buttonText: 'Gå till gruppen',
        },
        lowBattery: {
            title: 'Lås "{{lockName}}" har låg batterinivå ({{percentage}} %)',
            content: 'Låset tillhör {{productsName}}.',
            contentcable: 'Det kan laddas via en USB-kabel med Micro-B-kontakt.',
            contentcabinet: 'Batteriet är av typen CR123A, kan köpas på järnhandel.',
            contentmasterLock5441: 'Det använder ett CR123A-batteri. Batterier kan köpas på järnhandel.',
            contentmasterLock4401: 'Det använder ett CR2-batteri. Batterier kan köpas på järnhandel.',
            contentmasterLock4400: 'Det använder ett CR2-batteri. Batterier kan köpas på järnhandel.',
            contentdanalock: 'Det använder 4 stycken CR123-batterier, och kan köpas på järnhandel.',
            contentPost: 'För att uppdatera batteristatusen måste du öppna låset med Heime-appen.',
        },
        newMessageComment: {
            title: 'Ny kommentar på inlägget "{{title}}"',
            buttonText: 'Gå till inlägg',
            contentFile: 'En fil',
            contentImage: 'En bild',
        },
        newSurvey: {
            title: 'Ny undersökning publicerad: "{{title}}"',
            buttonText: 'Gå till undersökningen',
        },
        reminderSurvey: {
            title: 'Påminnelse om undersökningen: "{{title}}"',
            buttonText: 'Gå till undersökningen',
        },
        unhandledCooperativeUserRegistration: {
            title: 'Det finns en ny registrering som väntar på tillgång till Heime',
            title_plural: 'Det finns {{count}} nya registreringar som väntar på tillgång till Heime',
            buttonText: 'Öppna adminpanelen',
        },
        arrangerAdded: {
            title: 'Du har blivit tillagd som arrangör på "{{title}}"',
            buttonText: 'Gå till aktivitet',
        },
        arrangerRemoved: {
            title: 'Du har blivit borttagen som arrangör på "{{title}}"',
            buttonText: 'Gå till aktivitet',
        },
        activityRateUs: {
            title: 'Tack för deltagandet, vill du utvärdera Heime?',
            content: '',
            buttonText: 'Utvärdera Heime',
        },
        messageExpired: {
            prolonged: 'Inlägget har förlängts',
            reactivated: 'Inlägget har återaktiverats',
            month_from_now: 'Inlägget kommer att inaktiveras om en månad',
            prolong_error: 'Kunde inte förlänga inlägget',
            title_no_date: 'Inlägget är satt som permanent',
            description_no_date: 'Inlägget "{{title}}" utgår aldrig, detta kan ändras genom att redigera inlägget',
            title_expired: 'Ditt inlägg är inte aktivt',
            title_expired_author: 'Ditt inlägg är markerat som inaktivt av {{actor_name}}',
            description_expired: 'Ditt inlägg "{{title}}" är inte aktivt. Du kan aktivera det igen',
            title_expires_future: 'Ditt inlägg kommer att inaktiveras om {{timeString}}',
            title_expires_future_author: 'Ditt inlägg är satt att inaktiveras om {{timeString}} av {{actor_name}}',
            description_prolong: 'Ditt inlägg "{{title}}" kommer att inaktiveras. Du kan förlänga detta med 1 månad',
            description_no_prolong:
                'Ditt inlägg "{{title}}" kommer att inaktiveras. Vi meddelar dig här när detta sker',
            seeMessage: 'Gå till inlägg',
            prolong: 'Förläng',
            reactivate: 'Återaktivera',
        },
        bookingNotification: {
            buttonText: 'Gå till bokning',
            start_future_title: 'Din bokning av {{name}} startar om {{timeString}}',
            start_past_title: 'Din bokning av {{name}} startade för {{timeString}} sedan',
            end_future_title: 'Din bokning av {{name}} slutar om {{timeString}}',
            end_past_title: 'Din bokning av {{name}} slutade för {{timeString}} sedan',
            buttonText_start_procedure: 'Starta incheckning',
            buttonText_end_procedure: 'Starta utcheckning',
            start_future_procedure_title: '{{name}} är klart för incheckning om {{timeString}}',
            start_past_procedure_title: '{{name}} är klart för incheckning',
            end_future_procedure_title: 'Du måste vara klar med utcheckning {{name}} om {{timeString}}',
        },
        userAddedToBooking: {
            title: '{{actor_name}} har lagt till dig i bokningen av {{product_name}}',
            description_future: 'Bokningen börjar om {{timeString}}',
            description_started_past: 'Bokningen började för {{timeString}} sedan',
            description_ended_past: 'Bokningen avslutades för {{timeString}} sedan',
            buttonText: 'Gå till bokning',
        },
        userRemovedFromBooking: {
            title: '{{actor_name}} har tagit bort dig från bokningen av {{product_name}}',
        },
        others: '{{others}} och {{last}}',
        no_notifications_found: 'Du har inga aviseringar',
    },
    chat: {
        messageModal: {
            header: 'Den här länken kommer från en annan användare och kan vara osäker. Vill du fortfarande gå till den?',
            back: 'NEJ, TILLBAKA',
            approve: 'JA, BESÖK',
        },
        empty_message: 'Inga meddelanden här än. Skriv ett för att starta chatten.',
        today: 'Idag',
        add_file: 'Lägg till fil',
        add_image: 'Lägg till bild',
        goToGroup: 'Gå till gruppen',
        shareProducts: 'Dela föremål: ',
        askForHelp: 'Be om hjälp: ',
        helpRequest: 'Inlägg: ',
        starReceived: 'Stjärna mottagen från: ',
        starSended: 'Stjärna skickad till: ',
        addAll: 'TILL ALLA',
        removeAll: 'ÅTERSTÄLL',
        search: 'Sök efter granne',
        onlyYou: 'Bara du',
        too_many_users_header: 'Mycket användare i chatten',
        too_many_users_text:
            'Det är väldigt många grannar i den här chatten; för att undvika många aviseringar rekommenderar vi att du istället lägger upp ditt meddelande som ett inlägg. Där kan du ladda upp filer och bilder samt ta emot kommentarer från andra grannar. Du hittar inlägg genom att gå tillbaka och sedan trycka på knappen längst ner i mitten.',
        too_many_users_button: 'OK',
        pressInfo: 'Tryck här för info',
        add_attachment: 'Lägg till bilaga',
        write_message: 'Skriv meddelande här',
        send_message: 'Skicka meddelande och eventuellt bilaga',
        from: 'Från :senderName',
        to_profile: 'Gå till avsändarens profil',
        deleteMessageSuccess: 'Meddelandet har raderats',
        deleteMessageError: 'Kunde inte ta bort meddelandet',
        deleteModal: {
            header: 'Är du säker på att du vill ta bort meddelandet?',
            back: 'Gå tillbaka',
            approve: 'Ta bort',
        },
        edited: '(Redigerat) ',
        deleted_message: 'Raderat meddelande',
        edit: 'Redigera',
        delete: 'Ta bort',
        copy: 'Kopiera',
        copiedToClipboard: 'Meddelandet kopierat',
        editing_message: 'Redigera meddelande',
        stop_editing: 'Sluta redigera meddelande',
    },
    chatInfo: {
        members: 'Medlemmar',
        media: 'Bilder',
        files: 'Filer',
        no_media_message: 'Inga bilder här än. Bilder som skickats i chatten kommer att listas här.',
        no_files_message: 'Inga filer här än. Filer som skickats i chatten kommer att listas här.',
        nMembers: '{{count}} medlem',
        nMembers_plural: '{{count}} medlemmar',
        of: '{{current}} av {{length}}',
        admin: 'Du • Admin',
        nonAdmin: 'Admin',
        you: 'Du',
        leaveChat: 'Lämna chatten',
        makeAdmin: 'Gör admin',
        leaveModal: {
            header: 'Är du säker på att du vill lämna chatten?',
            back: 'NEJ, GÅ TILLBAKA',
            approve: 'JA, LÄMNA',
        },
        addMembers: 'Lägg till användare',
        addMembers_plural: 'Lägg till användare ({{count}})',
        addMember: 'Lägg till användare',
        noUsers: 'Inga användare hittades',
        noUsersChat: 'Inga användare att chatta med',
        noUsersToAdd: 'Inga användare att lägga till',
    },
    global: {
        resetApp: 'Starta om appen',
        swipe_down_to_fetch_data: 'Dra ner för att hämta senaste',
        warning: 'Varning',
        are_you_sure_delete_activity: 'Är du säker på att du vill ta bort aktiviteten?',
        yes: 'Ja',
        no: 'Nej',
        cancel: 'Avbryt',
        ask_me_later: 'Fråga mig senare',
        permissions: {
            camera_permission: 'Kameraåtkomst',
            must_have_permission_camera: 'Appen måste ha tillgång till kameran',
            camera_settings_permission:
                'För att kunna ladda upp ett foto behöver Heime åtkomst till kameran. Om du har valt nej till detta tidigare kan du ändra detta i telefonens inställningar',
            bluetoothLocationTitle: 'Bluetooth och platsåtkomst',
            bluetoothLocationDescription:
                'Åtkomst används enbart för att låsa upp låset. Genom att låsa upp låset kan vi teoretiskt veta att du är vid det vid öppningstiden.',
        },
        back: 'Tillbaka',
        task_failed_header: 'Ett fel inträffade',
        task_failed_description: 'Felet har sparats, försök gärna igen',
        goBack: 'Gå tillbaka',
        confirm: 'Bekräfta',
        confirmWithLabel: 'Bekräfta {{label}}',
        show_more: 'Visa mer',
        show_less: 'Visa mindre',
        optional: ' (Valfri)',
        cameraPermission: {
            title: 'Kameraåtkomst',
            message: 'Appen behöver tillgång för att ladda upp önskade bilder',
            neutral: 'Fråga senare',
            positive: 'Ok',
            negative: 'Inte tillåt',
        },
        selectImage: 'Välj bild',
        and: 'och ',
        xOthers: 'en till',
        xOthers_plural: '{{count}} andra',
        enLang: 'English',
        noLang: 'Norsk',
        daLang: 'Dansk',
        svLang: 'Svenska',
        cancelModal: {
            cancelChanges: 'Är du säker på att du vill förkasta ändringarna?',
            cancelCreation: 'Är du säker på att du vill förkasta?',
            content: 'Det du har skrivit kommer inte att sparas',
            contentEdited: 'Det du har ändrat kommer inte att sparas',
            back: 'NEJ, GÅ TILLBAKA',
            confirmCancel: 'JA, FÖRKASTA',
        },
        search: 'Sök',
        comments: 'Kommentarer',
        noComments: 'Inga kommentarer',
        attachFile: 'Tryck här för att lägga till fil eller bild',
        add_photo: 'Välj en bild',
        take_photo: 'Ta ett foto',
        add_file: 'Välj en fil',
        choose: 'Välj',
        crop_image: 'Redigera bild',
        add_facebook: 'Hämta profilbild från Facebook',
        remove_photo: 'Ta bort bild',
        clock: 'kl',
        addImage: 'Tryck här för att lägga till bild',
        networkError_header: 'Nätverksfel',
        networkError_description: 'Kunde inte skicka, försök igen',
        error_sending_header: 'Det uppstod ett fel vid sändning',
        error_sending_description: 'Försök igen',
        error_saving_header: 'Kunde inte spara ändringarna',
        error_saving_description: 'Försök igen',
        tooManyRequests_header: 'Låst ute från servern',
        tooManyRequests_text: 'Du har gjort för många förfrågningar, vänta 5 minuter innan du försöker igen',
        noSearchResult: 'Inga resultat för din sökning',
        close: 'Stäng',
        loading: 'Laddar in information',
        more_choices: 'Tryck för fler alternativ',
        select_language: 'Välj språk',
        upload_failed: 'Kunde inte ladda upp bild',
        permission_denied: 'Du måste ge åtkomst till Heime för att kunna välja en bild',
        open_settings: 'Öppna inställningar',
        deleted_user_with_apartment_owner: 'Tidigare ägare av {{apartment}}',
        deleted_user_with_apartment_renting: 'Tidigare hyresgäst i {{apartment}}',
        deleted_user_with_apartment_other: 'Tidigare användare i {{apartment}}',
        deleted_user_with_apartment_livesWith: 'Tidigare boende i {{apartment}}',
        deleted_user_no_apartment: 'Raderad användare',
        open_admin_error: 'Kunde inte öppna adminpanelen direkt',
        open_admin_error_text:
            'Gå till app.heime.com/admin i din webbläsare för att hantera förfrågan. Länken har också lagts till i din urklippstavla',
        open_url_error_header: 'Kunde inte öppna länken',
        open_url_error_text: 'Gå till {{url}} i din webbläsare, länken har också lagts till i din urklippstavla',
        open_contact_error_header: 'Kunde inte öppna kontakten',
        open_contact_error_text: 'Använd {{url}} för att kontakta, detta har också lagts till i din urklippstavla',
        keyboard_done: 'Klar',
        enter_url: 'Skriv in din länk här',
        insert_link: 'Sätt in',
        uploading_file: 'Vänta tills filen har laddats upp',
    },
    globalError: {
        header: 'Ett fel inträffade',
        networkHeader: 'Nätverksfel, kontrollera din internetanslutning',
        subText: 'Tyvärr, vi har noterat felet, vänligen försök igen. Om det inte hjälper, vänligen kontakta oss.',
        subTextNetwork: '',
        button: 'Försök igen',
        too_big: '{{name}} kan inte vara så lång',
        title: 'Titel',
        phone: 'Telefonnummer',
    },
    networkAlert: {
        header: 'Ingen internetanslutning',
        description:
            'Det verkar som om du inte har internet, Heime behöver internet för att fungera. Se till att Wi-Fi eller mobildata är påslaget och försök igen.',
    },
    updateApp: {
        android: 'Play',
        ios: 'App',
        header: 'Ny version av Heime',
        description_recommended:
            'Vi rekommenderar att du uppdaterar appen för att undvika fel. För att uppdatera, gå till "{{store}} Store" och välj sedan "Uppdatera".',
        description_force_soon:
            'Om du inte uppdaterar kommer du så småningom bli tvungen att uppdatera när den gamla versionen slutar fungera.',
        description_force:
            'Din version av Heime-appen är så gammal att den troligen inte fungerar längre, så du måste uppdatera den. För att uppdatera, gå till "{{store}} Store" och välj sedan "Uppdatera".',
        goToButton: 'Gå till {{store}} Store',
        later: 'Senare',
    },
    onboarding: {
        setupPin: 'Sätt upp PIN-kod',
        step: 'Steg {{step}}',
        description: 'Beskriv varför du kan/vill hjälpa:',
        descriptionPlaceholder:
            'Här kan du skriva lite om vad du kan hjälpa till med och varför: T.ex. Jag har en bil och är bra med djur.',
        helpfulNeighbourHeader: 'Vad kan du hjälpa dina grannar med?',
        helpfulNeighbourDesciptions: 'Tryck på de saker du skulle vilja hjälpa en granne med.',
        stepHeader: [
            '',
            'Integritetspolicy.',
            'Motta aviseringar.',
            'Bekräfta info',
            'Beskriv dig själv',
            'Bli medlem i en grupp.',
        ],
        privacyPolicyHeader: 'Integritetspolicy',
        privacyPolicyDesciptions: 'Vänligen läs och godkänn vår integritetspolicy',
        privacyPolicyContent:
            'Heime är en tjänsteleverantör som arbetar för att skapa bra grannskap. För att göra detta använder vi vissa personuppgifter om dig.\n\nHuvudsakligen används de för att informera om dig till dina grannar. Du kommer alltid att ha möjlighet att redigera detta och bestämma hur mycket dina grannar kan se.\n\nHeime säljer inte dina data.',
        privacyPolicyAgree: 'Innan du använder Heime måste du godkänna',
        privacyPolicyLowerCase: 'Heimes integritetspolicy',
        privacyPolicyAgreePost: 'Tryck vidare för att godkänna',
        continue: 'Fortsätt',
        notificationHeader: 'Motta aviseringar',
        notificationDesciptions:
            'Genom att aktivera aviseringar kommer du att få meddelanden om viktiga meddelanden från {{boardNoun}}, nya aktiviteter och chattmeddelanden.',
        turnOnNotification: 'TILLÅT AVISERINGAR',
        changeSettings: 'ÄNDRA INSTÄLLNINGAR',
        profileCreation: 'Berätta för dina grannar vem du är',
        pleaseAllowNotification: 'Vänligen aktivera aviseringar för Heime i dina systeminställningar',
        skipForNow: 'HOPPA ÖVER',
        downloadAccountPhoto: 'Ladda upp profilbild',
        photoAdded: 'Bild uppladdad',
        enterFirstName: 'Fyll i förnamn',
        firstName: 'Förnamn',
        enterSurname: 'Fyll i efternamn',
        lastName: 'Efternamn',
        enterEmail: 'Fyll i e-post',
        email: 'E-post',
        phone: 'Telefon',
        birthday: 'Födelsedatum',
        hideFromProfile: 'Visa inte för grannar',
        aboutMePlaceHolder:
            'T.ex: Jobbar i butik. Gillar att vara ute, men inget slår ett ullteppe och ett glas vin. Kan ett korttrick.',
        aboutMe: 'Om mig',
        lastName_error: 'Du måste ange ett efternamn',
        firstName_error: 'Du måste ange ett förnamn',
        phone_error: 'Du måste ange ett telefonnummer',
        invalidEmail: 'E-postadressen du angav är ogiltig',
        email_error: 'Du måste ange e-post',
        createPin: 'Skapa egen kod',
        repeatPin: 'Upprepa koden',
        enterPinDesciption: 'För att logga in snabbare kan du skapa en egen kod i Heime. Skriv in den önskade koden',
        repeatPinDesciption: 'Skriv in samma kod igen för att bekräfta den',
        phone_length: 'Telefonnumret kan inte vara för långt',

        profile_confirm_title: 'Bekräfta din kontaktinformation',
        profile_confirm_subtitle:
            'Informationen används för att visa din kontaktinfo till {{boardNoun}}. Du kan välja om detta också ska visas för dina grannar.',
        createProfile: 'Skapa profil',
        upload_photo: 'Välj en bild',
        upload_facebook: 'Använd bild från Facebook',
        add_photo: 'Ge din brevlåda ett ansikte, så kan du börja känna igen dina grannar',
        describe_yourself: 'Berätta lite om dig själv, text och bild visas endast för grannar i ditt bostadsområde',
        upload_new_photo: 'Ladda upp ny bild',
    },
    group: {
        header: 'Grupper',
        title: 'Gå med i några grupper',
        subTitle:
            'För vissa aktiviteter får endast gruppmedlemmar meddelanden. Gå med i de grupper som verkar intressanta.',
        join: 'Gå med',
        members: { plural: 'Medlemmar', singular: 'En medlem' },
        continue: 'Fortsätt',
        skip: 'Hoppa över',
        create_group: 'Skapa grupp',
        my_groups: 'Mina grupper',
        all_groups: 'Alla grupper',
        noGroups: {
            own: 'Du är inte medlem i några grupper',
            all: 'Det finns inga grupper',
            allWithRequest: 'Det finns inga grupper, du borde skapa en!',
        },
        adminModal: {
            header: 'Är du säker på att du vill utse «{{user}}» som administratör?',
            yes: 'Ja',
            no: 'Nej',
        },

        groupSelected: {
            title: 'Grupper',
            open_chat: 'Öppna chatt',
            create_activity: 'Skapa aktivitet',
            join_group: 'Gå med i grupp',
            admin: 'Admin',
            member: 'Medlem',
            members: 'Medlemmar',
            you: 'Du',
            set_admin: 'Utnämn {{name}} till administratör',
            leave_group: 'Lämna gruppen',
            add_image: 'Lägg till bild',
            add_file: 'Ladda upp ett dokument',
            images: 'Bilder',
            no_images: 'Det har inte delats några bilder i den här gruppen än.',
            files: 'Filer',
            no_files: 'Det har inte delats några filer i den här gruppen än.',
            add_files: 'Ladda upp filer',
            files_description:
                'Bilder och filer som laddas upp här kommer också att skickas i chatten. De kommer endast vara synliga för gruppens medlemmar.',
            upload: 'Ladda upp',
        },
        createGroup: {
            title_create: 'Skapa grupp',
            title_edit: 'Redigera gruppinformation',
            button_create: 'Skapa grupp',
            button_edit: 'Spara ändringar',
            image: 'Bild',
            title: 'Titel',
            description: 'Beskrivning',
            created: 'Grupp skapad',
            updated: 'Grupp uppdaterad',
            updateError: 'Ett fel inträffade vid redigering',
            createError: 'Ett fel inträffade vid skapande',
            alertError: 'Ett fel inträffade vid att skicka avisering',
            fillAll: 'Vänligen fyll i alla fält',
        },
    },
    sharingSelected: {
        editItem: 'Redigera',
        deleteItem: 'Ta bort',
        prolong: 'Förläng aktiv period',
        reactivate: 'Aktivera inlägg igen',
        deactivate: 'Deaktivera inlägg',
        deactivated: 'Inlägget är deaktiverat',
        deactivated_description:
            'Inlägget kommer inte längre visas för grannar i inläggslistan. Men det kommer fortfarande att skicka meddelanden om nya kommentarer. Och kan fortfarande sökas efter.',
        deactivated_failed: 'Kunde inte deaktivera inlägget',
        error: 'Ett fel inträffade',
        messageTheBoard: 'Kontakta {{boardNoun}}',
        deleteModal: {
            header: 'Är du säker på att du vill ta bort detta?',
            back: 'NEJ, TILLBAKA',
            approve: 'JA, TA BORT',
        },
        messageType: {
            Helpers: 'Hjälpsam granne',
            Products: 'Föremål',
            Requests: 'Inlägg',
            ByBoard: 'Inlägg från {{boardNoun}}',
        },
        productDetails: {
            button: 'Kontakta grannen',
        },
        requestDetails: {
            titleModal: 'Skriv ett meddelande till grannen',
            button: 'Kontakta grannen',
            expired: 'Inlägget har löpt ut och är inte längre aktivt',
            expiredDescription:
                'Skaparen av inlägget kan förlänga inläggets varaktighet, men alla intresserade kommer fortfarande att ta emot meddelanden om nya kommentarer.',
        },
        contactNeighbor: {
            title: 'Kontakta grannen',
            button: 'Skicka meddelande',
            messageLabel: 'Meddelande',
            placeHolderMessage: 'Skriv meddelande',
        },
        helperProfile: {
            stars: '⭐️ {{count}} stjärna',
            stars_plural: '⭐️ {{count}} stjärnor',
            no_star: '⭐️ Ge stjärna',
            button: 'Be {{name}} om hjälp',
            sendStar: 'Skicka en stjärna',
            willBeAvailable: 'Kommer vara tillgänglig om {{count}} timme',
            willBeAvailable_plural: 'Kommer vara tillgänglig om {{count}} timmar',
            howCanIHelp: 'Hur kan jag hjälpa',
            feedback: 'Feedback',
            noFeedback: 'Ingen feedback hittades',
            comment: 'Kommentar',
        },
        attachments: 'Bifogade filer',
        deleteCommentSuccess: 'Kommentaren har raderats',
        deleteCommentError: 'Kunde inte radera kommentaren',
        deleteCommentModal: {
            header: 'Är du säker på att du vill radera kommentaren?',
            back: 'Gå tillbaka',
            approve: 'Radera',
        },
        edited: '(Redigerad)',
        deleted_comment: 'Raderad kommentar',
        edit: 'Redigera',
        delete: 'Radera',
        copy: 'Kopiera',
        copiedToClipboard: 'Kommentar kopierad',
        editing_comment: 'Redigerar kommentar',
        stop_editing: 'Sluta redigera kommentar',
    },
    userMenu: {
        terms: {
            title: 'Försäljningsvillkor',
            noAccounts: 'Inga betalningskonton är kopplade till ditt nabolag',
            orgNum: 'Org nr.',
        },
        privacy: 'Integritetspolicy',
        language: 'Språk',
        deleteAccount: 'Radera ditt konto',
        deleteAccountModal: {
            header: 'Är du säker på att du vill radera ditt konto från Heime?',
            content_danger: 'Denna åtgärd kan inte ångras',
            content_warning:
                'Vi rekommenderar att du istället lägger till nya boende, hyresgäster eller ägare under din bostad. När de sedan loggar in kan de ta bort dig från bostaden',
            content_consequenses:
                'Om du raderar ditt konto förlorar du åtkomst till alla områden. Du kommer inte längre kunna logga in på Heime eller ta emot nödvändiga aviseringar eller information.',
            back: 'Avbryt',
            approve: 'Radera permanent',
            deleted: 'Ditt konto har raderats',
        },
    },
    languageSelectModal: {
        header: 'Välj ditt språk',
        cancel: 'Avbryt',
        submit: 'Spara',
    },
    notFoundErrorScreen: {
        Activity: {
            header: 'Hittade inte aktiviteten',
            subtext: 'Antingen har den blivit avbruten/raderad eller så är det ett fel från vår sida',
            button: 'Gå tillbaka',
        },
        Group: {
            header: 'Hittade inte gruppen',
            subtext: 'Antingen har den blivit raderad eller så är det ett fel från vår sida',
            button: 'Gå tillbaka',
        },
        Chat: {
            header: 'Hittade inte chatten',
            subtext: 'Antingen är du inte med i den längre eller så är det ett fel från vår sida',
            button: 'Gå tillbaka',
        },
        Product: {
            header: 'Hittade inte produkten',
            subtext: 'Antingen har den blivit raderad eller så är det ett fel från vår sida',
            button: 'Gå tillbaka',
        },
        Profile: {
            header: 'Hittade inte användaren',
            subtext: 'Antingen har den blivit raderad eller så är det ett fel från vår sida',
            button: 'Gå tillbaka',
        },
        Reservation: {
            header: 'Hittade inte bokningen',
            subtext: 'Antingen har den blivit avbokad, är i det förflutna, eller så är det ett fel från vår sida',
            button: 'Gå tillbaka',
        },
        Support: {
            header: 'Hittade inte meddelandet',
            subtext: 'Antingen har det blivit raderat, eller så är det ett fel från vår sida',
            button: 'Gå tillbaka',
        },
        MemberBenefit: {
            header: 'Hittade inte erbjudandet',
            subtext: 'Det har tagits bort',
            button: 'Gå tillbaka',
        },
        Message: {
            header: 'Hittade inget',
            subtext: 'Antingen har det utgått eller blivit raderat',
            button: 'Gå tillbaka',
        },
        Survey: {
            header: 'Hittade inte undersökningen',
            subtext: 'Den har antingen stängts eller tagits bort',
            button: 'Gå tillbaka',
        },
    },
    memberBenefits: {
        empty: 'Det finns inga registrerade bostadsförmånen',
        noResult: 'Hittade inga bostadsförmånen med det namnet',
        url: 'Webbplats',
    },
    memberBenefit: {
        title: 'Bostadsförmån',
        promo_code: 'Rabattkod',
        visit_website: 'Besök webbplatsen',
        showProof: 'Visa medlemsbevis',
        memberProof: 'Medlemsbevis',
        copied: 'Rabattkod kopierad',
    },
    surveys: {
        title: 'Undersökningar',
        empty: 'Det finns inga registrerade undersökningar',
        reciptientApartment:
            'Undersökningen kan endast besvaras en gång per bostad. Eventuella användare kopplade till samma bostad kan se och redigera dina svar.',
        reciptientUsers: 'Undersökningen kan endast besvaras en gång per användare.',
        questions: '{{questions}} frågor ({{requiredQuestions}} obligatoriska)',
        addResponse: 'Svara på undersökningen',
        continueResponding: 'Fortsätt svara',
        question: 'Fråga',
        nextQuestion: 'Gå vidare',
        noMoreQuestions:
            "Du har svarat på alla frågor. Nu kan du antingen granska dina svar eller skicka in undersökningen. Du kan också gå tillbaka och ändra dina svar. För att skicka in undersökningen tryck på 'Skicka in svar'",
        finishLater: 'Avsluta senare',
        sendSurvey: 'Skicka in svar',
        answerError: 'Det uppstod ett fel vid sparande av svaret',
        started: 'Det har redan påbörjats ett svar, du kan fortsätta på det.',
        noAnswer: 'Inget svar',
        sentIn: 'Dina svar har redan skickats in',
        selectApartment: 'Välj bostad som du vill svara på undersökningen för',
        noApartment:
            'Du är inte kopplad till någon bostad, du kan inte svara på denna undersökning. Om detta är ett fel kan du kontakta {{boardNoun}} eller Heime',
        archived:
            'Undersökningen är arkiverad och kan inte besvaras längre. Om du har skickat in svar kan du se dem här',
    },
    plusMenu: {
        recommended: 'Rekommenderat',
        other: 'Andra',
        activityNew: {
            title: 'Skapa aktivitet',
            description:
                'Samla grannar och gör något tillsammans. Några förslag är kaffe träff, gemensamma arbeten eller grillfest.',
        },
        groupNew: {
            title: 'Skapa en grupp',
            description:
                'Hitta ett gemensamt intresse och dela erfarenheter eller saker i grupper. Här får ni en egen chatt för diskussion, planering av aktiviteter och delning av prylar.',
        },
        noticeNew: {
            title: 'Dela ett meddelande',
            description: 'Meddelanden kan vara allt från information till grannar, eller en fråga du behöver svar på.',
        },
        noticeByBoard: {
            title: 'Dela ett meddelande från {{boardNoun}}',
            description: 'Markera ett meddelande som kommer från {{boardNoun}}.',
        },
        helpfulNeighbour: {
            title: 'Bli en hjälpsam granne',
            description:
                'Erbjud en hjälpande hand till grannarna. Några förslag är hundpassning, barnvakt, IT-hjälp eller hantverk.',
        },
        sharingNew: {
            title: 'Dela en sak',
            description:
                'Vi har alla något i förrådet som kan glädja andra. Genom att ta en bild och lägga upp den kan grannarna kontakta dig för att låna det.',
        },
        reportsNew: {
            title: 'Kontakta {{boardNoun}}',
            description:
                'Här kan ni skicka meddelanden till {{boardNoun}}, och de kommer att bli notifierade. Ni kommer att kunna kommentera på meddelandet, och {{boardNoun}} kan uppdatera meddelandets status.',
        },
        reservations: {
            title: 'Gör en reservation',
            description: 'Om du vill reservera något, kan du göra det här.',
        },
        chatNew: {
            title: 'Chatta privat med granne',
            description:
                'Här kan du välja grannar du vill prata med och dela bilder. Meddelandena som skickas är helt privata.',
        },
    },
    smallButtonNav: {
        board: 'Kontakter',
        documents: 'Filer',
        myHouse: 'Min bostad',
        neighbours: 'Grannar',
        groups: 'Grupper',
        myProfile: 'Min profil',
        memberBenefits: 'Bostadsförmåner',
        reports: 'Meddelanden till {{boardNoun}}',
        surveys: 'Undersökningar',
        chat: 'Chatta',
    },
    typeSpecific: {
        joint_ownership: {
            coopTypeDefiniteArticle: 'bostadsföreningen',
            boardNounDefiniteArticle: 'styrelsen',
        },
        rental_project: {
            coopTypeDefiniteArticle: 'uthyrningsprojektet',
            boardNounDefiniteArticle: 'uthyraren',
        },
        suburban_housing: {
            coopTypeDefiniteArticle: 'grannföreningen',
            boardNounDefiniteArticle: 'styrelsen',
        },
    },
    managePlates: {
        header: 'Redigera sparade registreringsnummer',
        save: 'Spara',
        noPlates: 'Inga registreringsnummer sparade',
        deleted_confirmation: 'Registreringsnumret har raderats',
        plate: 'Registreringsnummer',
        name: 'Namn',
        edit: 'Redigera',
        delete: 'Radera',
        undo: 'Ångra',
    },
    rateUs: {
        header: 'Betygsätt Heime',
        title: 'Betygsätt Heime',
        subtitle: 'Ge oss ett betyg, det hjälper oss att bli bättre. Välj mellan 1-5 stjärnor',
        cancel: 'Senare',
        rate: 'Betygsätt nu',
        submit: 'Skicka in',
        goToStore_ios: 'Gå till App Store',
        goToStore_android: 'Gå till Play Store',
        feedback_title: 'Ge oss din feedback',
        feedback_subtitle: 'Vad kan vi göra bättre? Vi uppskattar din feedback',
        feedback_label: 'Feedback',
        feedback_placeholder: 'Skriv din feedback här',
        feedback_success: 'Tack för din feedback',
    },
    procedure: {
        check_in_start: 'Incheckning',
        check_out_start: 'Utcheckning',
        start_check_in_title: '{{name}} är inställd med en incheckningsprocess',
        start_check_out_title: '{{name}} är inställd med en utcheckningsprocess',
        start_procedure_explainer:
            'Det är obligatoriskt och består av {{steps}} steg. Detta görs för att säkerställa ansvaret mellan de som har använt produkten. Du behöver vara ansluten till internet under hela processen.',
        start_procedure_check_out_explainer: 'Du måste slutföra utcheckningen innan reservationen är klar',
        start_procedure_check_out_warning:
            'När utcheckningen är genomförd kommer du inte längre ha tillgång till reservationen.',
        start_check_in_action: 'Starta incheckning',
        start_check_out_action: 'Starta utcheckning',
        later: 'Senare',
        continue: 'Fortsätt',
        skip: 'Hoppa över',
        check_in_header: 'Incheckning - {{index}} av {{steps}}',
        check_out_header: 'Utcheckning - {{index}} av {{steps}}',
        completion_error: 'Kunde inte slutföra incheckningen. Försök igen.',
        answer_error: 'Kunde inte spara ditt svar. Försök igen.',
        approve_cleaning_header: 'Godkänn föregående reservation',
        approve_cleaning_text:
            'Det är endast nu du kan godkänna den föregående reservationen. Om du inte godkänner nu, kommer det att noteras. Du kan efter incheckningen hitta kontaktinformation till den som reserverade före dig. Vid godkännande kommer du vara ansvarig för städning och röjning efter din reservation.',
        approve_cleaning_disapproved:
            'Det har noterats att du inte godkänner städningen som har genomförts. Det rekommenderas att du kontaktar den som reserverade före dig och kommer överens om en lösning. \nFör att se vem detta var, slutför incheckningsprocessen och titta under "mina reservationer". Det kan också vara bra att ta några bilder som dokumentation.',
        checklist_header: 'Vänligen bekräfta att alla punkter har genomförts',
        information_header: 'Vänligen bekräfta att du har läst informationen nedan',
        open_door_check_in_masterLock5441: 'Öppna boxen och ta ut nyckeln',
        open_door_check_in_other: 'Öppna låset',
        open_door_check_out_open_to_close: 'Öppna låset och verifiera att det är stängt',
        open_door_check_out_danalock_relay: 'Öppna låset',
        open_door_check_out_masterLock5441: 'Öppna boxen och lägg tillbaka nyckeln',
        open_door_check_out_danalock: 'Stäng låset',
        open_door_check_in_permission_text_both:
            'Heime behöver åtkomst till Bluetooth och din plats för att öppna låset. Tryck lås upp nedan för att ge åtkomst och låsa upp.',
        open_door_check_out_permission_text_both:
            'Heime behöver åtkomst till Bluetooth och din plats för att öppna och stänga låset. Tryck lås upp nedan för att ge åtkomst och låsa upp.',
        open_door_check_in_unlock: 'Lås upp',
        open_door_check_in_permission_text_both_settings:
            'Heime behöver åtkomst till Bluetooth och din plats för att öppna låset. Tryck nedan för att ändra inställningarna',
        open_door_check_out_permission_text_both_settings:
            'Heime behöver åtkomst till Bluetooth och din plats för att öppna / stänga låset. Tryck nedan för att ändra inställningarna',
        open_door_open_settings: 'Ändra inställningar',
        open_door_check_in_permission_text_ble:
            'Heime behöver åtkomst till Bluetooth för att öppna låset. Tryck nedan för att ändra inställningarna',
        open_door_check_out_permission_text_ble:
            'Heime behöver åtkomst till Bluetooth för att öppna / stänga låset. Tryck nedan för att ändra inställningarna',
        open_door_check_in_permission_text_location:
            'Heime behöver åtkomst till din plats för att öppna låset. Tryck nedan för att ändra inställningarna',
        open_door_check_out_permission_text_location:
            'Heime behöver åtkomst till plats för att öppna / stänga låset. Tryck nedan för att ändra inställningarna',
        open_door_bluetooth_disabled: 'Bluetooth är avstängt. Tryck nedan för att slå på Bluetooth',
        open_door_enable_bluetooth: 'Slå på Bluetooth',
        open_door_check_in_select_lock: 'Välj lås att öppna',
        open_door_check_out_select_lock: 'Välj lås att öppna / stänga',
        open_door_check_in_open_lock: 'Öppna låset',
        open_door_check_in_unlock_press: 'Tryck på knappen nedan för att öppna',
        open_door_check_in_get_key_press: 'För att få nyckeln, lås upp nyckelboxen',
        open_door_check_out_unlock_press: 'Tryck på knappen nedan för att öppna',
        open_door_check_out_open_lock: 'Öppna låset',
        open_door_check_out_lock_press: 'Tryck på knappen nedan för att låsa',
        open_door_check_out_close_lock: 'Lås',
        open_door_check_out_return_key_press: 'För att lägga tillbaka nyckeln, tryck på knappen nedan',
        open_door_not_supported: 'För att öppna dörren',
        pictures_not_supported: 'För att ta bilder',
        pictures_give_camera_access: 'Ge åtkomst',
        pictures_open_settings: 'Öppna inställningar',
        pictures_camera_access: 'Heime behöver åtkomst till kameran',
        pictures_camera_access_denied: 'Heime har inte åtkomst till din kamera',
        pictures_camera_access_explanation:
            "I detta steg ska du ta bilder på produkten. Vi behöver åtkomst till din kamera. Tryck ge åtkomst och sedan 'tillåt'",
        pictures_camera_access_denied_explanation:
            'För att utföra detta steg, behöver vi åtkomst till din kamera. Gå till inställningarna på din telefon och ge Heime åtkomst till kameran',
        pictures_flash_off: 'Stäng av blixt',
        pictures_flash_on: 'Slå på blixt',
        pictures_take_picture: 'Ta bild',
        pictures_no_camera: 'Det verkar som att den här enheten inte har en kamera',
        pictures_uploading: 'Laddar upp bilden',
        pictures_approve_or_retake: 'Godkänn bilden eller ta en ny',
        pictures_approve: 'Godkänn',
        pictures_n_taken: 'Tagna bilder',
        skip_confirm: 'Är du säker på att du vill hoppa över?',
        skip_confirm_text:
            'Genom att hoppa över, friskriver du eventuellt ansvar den föregående användaren hade, och du är ansvarig för hur produkten ser ut efter bokningen',
        skip_confirm_yes: 'Ja, hoppa över',
        skip_confirm_no: 'Nej, gå tillbaka',
        not_supported: 'Denna funktionalitet stöds inte här',
        not_supported_use_mobile: 'vänligen använd mobilappen',
        reservation_ends: 'Reservationen slutar om {{time}}',
        reservation_ends_explainer: 'Du kommer inte kunna slutföra efter detta tidspunkt',
        reservation_ended: 'Reservationen har avslutats',
        reservation_ended_explainer:
            'Det innebär att du inte kan slutföra processen. För att få förnyad åtkomst måste du göra en ny reservation eller kontakta den som har reserverat efter dig.',
        go_back: 'Gå tillbaka',
        completion_success_check_in: 'Du har checkat in',
        completion_success_text_check_in: 'Dina svar har noterats och du kan börja använda produkten',
        completion_success_check_out: 'Du har checkat ut',
        completion_success_text_check_out: 'Dina svar har noterats, du kan nu betrakta din reservation som avslutad',
    },
    countryCode: {
        af: 'Afghanistan',
        eg: 'Egypten',
        ax: 'Åland',
        al: 'Albanien',
        dz: 'Algeriet',
        as: 'Amerikanska Samoa',
        vi: 'Amerikanska Jungfruöarna',
        um: 'USA:s yttre småöar',
        ad: 'Andorra',
        ao: 'Angola',
        ai: 'Anguilla',
        aq: 'Antarktis',
        ag: 'Antigua och Barbuda',
        gq: 'Ekvatorialguinea',
        ar: 'Argentina',
        am: 'Armenien',
        aw: 'Aruba',
        az: 'Azerbajdzjan',
        et: 'Etiopien',
        au: 'Australien',
        bs: 'Bahamas',
        bh: 'Bahrain',
        bd: 'Bangladesh',
        bb: 'Barbados',
        by: 'Vitryssland',
        be: 'Belgien',
        bz: 'Belize',
        bj: 'Benin',
        bm: 'Bermuda',
        bt: 'Bhutan',
        bo: 'Bolivia',
        bq: 'Bonaire, Sint Eustatius och Saba',
        ba: 'Bosnien och Hercegovina',
        bw: 'Botswana',
        bv: 'Bouvetön',
        br: 'Brasilien',
        vg: 'Brittiska Jungfruöarna',
        io: 'Brittiska territoriet i Indiska oceanen',
        bn: 'Brunei',
        bg: 'Bulgarien',
        bf: 'Burkina Faso',
        bi: 'Burundi',
        cv: 'Kap Verde',
        cl: 'Chile',
        cn: 'Kina',
        ck: 'Cooköarna',
        cr: 'Costa Rica',
        ci: 'Elfenbenskusten',
        cw: 'Curaçao',
        dk: 'Danmark',
        de: 'Tyskland',
        dm: 'Dominica',
        do: 'Dominikanska republiken',
        dj: 'Djibouti',
        ec: 'Ecuador',
        sv: 'El Salvador',
        er: 'Eritrea',
        ee: 'Estland',
        fk: 'Falklandsöarna',
        fo: 'Färöarna',
        fj: 'Fiji',
        fi: 'Finland',
        fr: 'Frankrike',
        gf: 'Franska Guyana',
        pf: 'Franska Polynesien',
        tf: 'Franska sydterritorierna',
        ga: 'Gabon',
        gm: 'Gambia',
        ge: 'Georgien',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gd: 'Grenada',
        gr: 'Grekland',
        gl: 'Grönland',
        gp: 'Guadeloupe',
        gu: 'Guam',
        gt: 'Guatemala',
        gg: 'Guernsey',
        gn: 'Guinea',
        gw: 'Guinea-Bissau',
        gy: 'Guyana',
        ht: 'Haiti',
        hm: 'Heard Island och McDonaldöarna',
        hn: 'Honduras',
        in: 'Indien',
        id: 'Indonesien',
        iq: 'Irak',
        ir: 'Iran',
        ie: 'Irland',
        is: 'Island',
        im: 'Man',
        il: 'Israel',
        it: 'Italien',
        jm: 'Jamaica',
        jp: 'Japan',
        ye: 'Jemen',
        je: 'Jersey',
        jo: 'Jordanien',
        ky: 'Caymanöarna',
        kh: 'Kambodja',
        cm: 'Kamerun',
        ca: 'Kanada',
        kz: 'Kazakhstan',
        qa: 'Qatar',
        ke: 'Kenya',
        kg: 'Kirgizistan',
        ki: 'Kiribati',
        cc: 'Kokosöarna',
        co: 'Colombia',
        km: 'Komorerna',
        cg: 'Kongo-Brazzaville',
        cd: 'Kongo-Kinshasa',
        xk: 'Kosovo',
        hr: 'Kroatien',
        cu: 'Kuba',
        kw: 'Kuwait',
        la: 'Laos',
        ls: 'Lesotho',
        lv: 'Latvia',
        lb: 'Libanon',
        lr: 'Liberia',
        ly: 'Libyen',
        li: 'Liechtenstein',
        lt: 'Litauen',
        lu: 'Luxemburg',
        mg: 'Madagaskar',
        mw: 'Malawi',
        my: 'Malaysia',
        mv: 'Maldiverna',
        ml: 'Mali',
        mt: 'Malta',
        ma: 'Marocko',
        mh: 'Marshallöarna',
        mq: 'Martinique',
        mr: 'Mauretanien',
        mu: 'Mauritius',
        yt: 'Mayotte',
        mx: 'Mexiko',
        fm: 'Mikronesien',
        mc: 'Monaco',
        mn: 'Mongoliet',
        me: 'Montenegro',
        ms: 'Montserrat',
        mz: 'Moçambique',
        mm: 'Myanmar (Burma)',
        na: 'Namibia',
        nr: 'Nauru',
        np: 'Nepal',
        nc: 'Ny-Kaledonien',
        nz: 'Nya Zeeland',
        ni: 'Nicaragua',
        nl: 'Nederländerna',
        ne: 'Niger',
        ng: 'Nigeria',
        nu: 'Niue',
        kp: 'Nordkorea',
        mp: 'Nordmarianerna',
        mk: 'Nordmakedonien',
        nf: 'Norfolkön',
        no: 'Norge',
        om: 'Oman',
        at: 'Österrike',
        pk: 'Pakistan',
        ps: 'De palestinska territorierna',
        pw: 'Palau',
        pa: 'Panama',
        pg: 'Papua Nya Guinea',
        py: 'Paraguay',
        pe: 'Peru',
        ph: 'Filippinerna',
        pn: 'Pitcairnöarna',
        pl: 'Polen',
        pt: 'Portugal',
        pr: 'Puerto Rico',
        md: 'Moldavien',
        re: 'Réunion',
        rw: 'Rwanda',
        ro: 'Rumänien',
        ru: 'Ryssland',
        sb: 'Salomonöarna',
        zm: 'Zambia',
        ws: 'Samoa',
        sm: 'San Marino',
        st: 'São Tomé och Príncipe',
        sa: 'Saudiarabien',
        se: 'Sverige',
        ch: 'Schweiz',
        sn: 'Senegal',
        rs: 'Serbien',
        sc: 'Seychellerna',
        sl: 'Sierra Leone',
        zw: 'Zimbabwe',
        sg: 'Singapore',
        sx: 'Sint Maarten',
        sk: 'Slovakien',
        si: 'Slovenien',
        so: 'Somalia',
        hk: 'Hongkong SAR Kina',
        mo: 'Macao SAR Kina',
        es: 'Spanien',
        sj: 'Svalbard och Jan Mayen',
        lk: 'Sri Lanka',
        bl: 'Saint-Barthélemy',
        sh: 'St. Helena',
        kn: 'Saint Kitts och Nevis',
        lc: 'Saint Lucia',
        mf: 'Saint-Martin',
        pm: 'Saint-Pierre och Miquelon',
        vc: 'Saint Vincent och Grenadinerna',
        za: 'Sydafrika',
        sd: 'Sudan',
        gs: 'Sydgeorgien och Sydsandwichöarna',
        kr: 'Sydkorea',
        ss: 'Sydsudan',
        sr: 'Surinam',
        sz: 'Eswatini',
        sy: 'Syrien',
        tj: 'Tadzjikistan',
        tw: 'Taiwan',
        tz: 'Tanzania',
        th: 'Thailand',
        tl: 'Östtimor',
        tg: 'Togo',
        tk: 'Tokelau',
        to: 'Tonga',
        tt: 'Trinidad och Tobago',
        td: 'Tchad',
        cz: 'Tjeckien',
        tn: 'Tunisien',
        tr: 'Turkiet',
        tm: 'Turkmenistan',
        tc: 'Turks- och Caicosöarna',
        tv: 'Tuvalu',
        ug: 'Uganda',
        ua: 'Ukraina',
        hu: 'Ungern',
        uy: 'Uruguay',
        uz: 'Usbekistan',
        vu: 'Vanuatu',
        va: 'Vatikanstaten',
        ve: 'Venezuela',
        ae: 'Förenade Arabemiraten',
        us: 'USA',
        gb: 'Storbritannien',
        vn: 'Vietnam',
        wf: 'Wallis och Futuna',
        cx: 'Christmasön',
        eh: 'Västsahara',
        cf: 'Centralafrikanska republiken',
        cy: 'Cypern',
        ac: 'Ascension',
        ta: 'Tristan da Cunha',
    },
};
