import React, { ComponentProps, ReactElement, useMemo } from 'react';
import BaseFileSelector from './BaseFileSelector';
import FileActionSheet from './FileActionsheet';
import useActionSheetState from './useActionSheetState';
import useFileAttachmentState from './useFileAttachmentState';
import { fileIsImage, useBase64ImageSelector, isTruthy } from '../../_utils';
import { FileUpload, isFileToBeUploaded, isStaleFile } from '../../types/Utility';

interface FileAttachmentSelectorProps extends Omit<ComponentProps<typeof BaseFileSelector>, 'files' | 'onUpload'> {
    files: FileUpload[];
    onChange: (files: FileUpload[]) => void;
    openInitially?: boolean;
    type?: 'image' | 'file';
}

const FileAttachmentSelector = ({
    type,
    onChange,
    files,
    openInitially = false,
    ...props
}: FileAttachmentSelectorProps): ReactElement => {
    const { onAdd, onDelete } = useFileAttachmentState(files, onChange);
    const { visible, onShow, onClose } = useActionSheetState(openInitially);

    const [onSelect, permission] = useBase64ImageSelector(onAdd, onClose);

    const filesOverview = useMemo(
        () =>
            [...files.filter(isStaleFile), ...files.filter(isFileToBeUploaded)].map((file) => ({
                id: file.name ?? file.index,
                name: file.name,
                size: isFileToBeUploaded(file) ? file.size : undefined,
                type: fileIsImage(file) ? ('image' as const) : ('file' as const),
                source: isStaleFile(file) ? file : { original: file.uri },
                onRemove: () => onDelete(file),
                isUploading: false,
                uploadProgress: 0,
            })),
        [files, onDelete],
    );

    return (
        <>
            <BaseFileSelector {...props} files={filesOverview} onUpload={onShow} />
            {visible ? (
                <FileActionSheet
                    actions={[
                        type !== 'file'
                            ? {
                                  type: 'Library' as const,
                                  onSelect: () => onSelect({ type: 'library' }),
                              }
                            : null,
                        type !== 'file'
                            ? {
                                  type: 'Camera' as const,
                                  onSelect: () => onSelect({ type: 'camera' }),
                              }
                            : null,
                        type !== 'image' ? { type: 'FileB64' as const, onChange: onChange } : null,
                    ].filter(isTruthy)}
                    onClose={onClose}
                />
            ) : null}
            {permission}
        </>
    );
};

export default FileAttachmentSelector;
