import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { screenMargin } from '_utils/sizes';
import { ApiImage, FileImage } from 'types/Base';
import { useGetUser } from '../../../../../_api/useUsers';
import _fonts from '../../../../../_fonts';
import Images from '../../../../../_images';
import { useThemeStyle, WW } from '../../../../../_utils';
import { getUsernameFromProfile } from '../../../../../_utils/misc';
import { Theme } from '../../../../../_utils/themeContext';
import { CacheImage, Icon, ListItem } from '../../../../../Components';

interface ProductItemProps {
    title: string;
    picture: ApiImage | FileImage | null;
    creator: number;
    isNew?: boolean;
    onPress(): void;
}

const ProductItem = ({ title, picture, creator, isNew, onPress }: ProductItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const user = useGetUser(creator);
    return (
        <TouchableOpacity onPress={onPress} style={themedStyle.main}>
            <Text numberOfLines={1} style={themedStyle.title}>
                {title}
            </Text>
            <View>
                {picture ? (
                    <CacheImage source={picture ? picture : Images.defaultAvatar} style={themedStyle.picture} />
                ) : (
                    <View style={themedStyle.picture}>
                        <View style={themedStyle.noImageWrapper}>
                            <Icon name="image" color="secondaryLight" />
                        </View>
                    </View>
                )}
                {isNew && (
                    <View style={themedStyle.newContainer}>
                        <Text style={themedStyle.newText}>{t('sharingAll:new')}</Text>
                    </View>
                )}
            </View>

            <ListItem
                numberOfLines={undefined}
                containerStyle={themedStyle.userContainer}
                containerWrapper={themedStyle.userContentWrapper}
                avatar={<CacheImage source={user?.avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />}
                title={getUsernameFromProfile(user)}
                titleStyle={themedStyle.userText}
            />
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            width: WW / 2 - screenMargin * 1.5,
            minHeight: WW * 0.65,
            marginTop: screenMargin,
        },
        title: {
            fontFamily: _fonts.primaryFont,
            color: theme.black,
            marginBottom: WW * 0.02,
            fontSize: WW * 0.04,
        },
        picture: {
            width: WW * 0.43,
            height: WW * 0.43,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.mediumBackground,
        },
        userContentWrapper: {
            flex: 2,
            justifyContent: 'center',
            marginLeft: WW * 0.04,
            marginRight: WW * 0,
        },
        userContainer: {
            borderBottomWidth: 0,
        },
        avatar: {
            width: WW * 0.12,
            height: WW * 0.12,
            borderRadius: (WW * 0.12) / 2,
            overflow: 'hidden',
        },
        userText: {
            fontSize: WW * 0.04,
            color: theme.darkGray,
            marginRight: WW * 0.04,
            fontFamily: _fonts.primaryFontBold,
        },
        noImageWrapper: {
            transform: [{ scale: 2 }],
        },
        newContainer: {
            alignSelf: 'flex-start',
            borderRadius: WW * 0.04,
            backgroundColor: theme.lightGreen,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
            marginLeft: WW * 0.02,
            marginRight: WW * 0.02,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            position: 'absolute',
            top: 0,
            left: 0,
        },
        newText: {
            fontFamily: _fonts.primaryFont,
            color: theme.darkGreen,
            fontSize: WW * 0.03,
        },
    });

export default ProductItem;
