import { z } from 'zod';

export const ApiImageSchemaNoId = z.object({
    original: z.string(),
    '64x0': z.string().optional(),
    '128x0': z.string().optional(),
    '512x0': z.string().optional(),
    '1024x0': z.string().optional(),
    '2048x0': z.string().optional(),
});

export const ApiImageSchema = ApiImageSchemaNoId.merge(
    z.object({
        id: z.number(),
    }),
);

export const ApiImageSchemaWithName = ApiImageSchema.merge(
    z.object({
        name: z.string(),
    }),
);

export const FileLinkSchema = ApiImageSchema.merge(
    z.object({
        id: z.number(),
        name: z.string(),
        ext: z.union([z.string(), z.boolean()]),
        last_modified: z.number(),
    }),
);

export const FileImageSchema = z.object({
    original: z.string(),
    url64: z.string().nullable().optional(),
    url128: z.string().nullable().optional(),
    url512: z.string().nullable().optional(),
    url1024: z.string().nullable().optional(),
    url2048: z.string().nullable().optional(),
    blurhash: z.string().nullable().optional(),
});

export const FileImageExtendedSchema = FileImageSchema.merge(
    z.object({
        name: z.string(),
        id: z.string(),
    }),
);

export const FileUploadStateSchema = z.union([
    z.object({
        status: z.literal('EXIST'),
        file: z.union([ApiImageSchema, FileImageSchema]),
    }),
    z.object({
        status: z.literal('UPLOADING'),
        progress: z.number(),
        url: z.string(),
        size: z.number(),
    }),
    z.object({
        status: z.literal('UPLOADED'),
        url: z.string(),
        size: z.number(),
    }),
    z.object({
        status: z.literal('REMOVED'),
    }),
]);

export const FormFileSchema = z.object({
    id: z.string(),
    name: z.string(),
    state: FileUploadStateSchema,
});

export type FormFile = z.infer<typeof FormFileSchema>;

export type FileImage = z.infer<typeof FileImageSchema>;
export type FileImageExtended = z.infer<typeof FileImageExtendedSchema>;

export type ApiImage = z.infer<typeof ApiImageSchema>;
export type ApiImageWithName = z.infer<typeof ApiImageSchemaWithName>;

export type FileLink = z.infer<typeof FileLinkSchema>;

/**
 * For android and ios we have the object with properties, while on web we have a File
 */
export type SelectedLocalFile = { path: string; contentType: string; name: string; size: number } | File;

// Start richtext

const textSchema = z.object({
    type: z.literal('text'),
    text: z.string(),
    marks: z
        .array(
            z.union([
                z.object({
                    type: z.literal('bold'),
                }),
                z.object({
                    type: z.literal('italic'),
                }),
                z.object({
                    type: z.literal('underline'),
                }),
                z.object({
                    type: z.literal('strike'),
                }),
                z.object({
                    type: z.literal('link'),
                    attrs: z.object({
                        href: z.string(),
                    }),
                }),
            ]),
        )
        .optional(),
});

const ContentRichTextSchema = z.object({
    type: z.union([z.literal('doc'), z.literal('paragraph'), z.literal('bulletList'), z.literal('listItem')]),
});

const OrderedListRichTextSchema = z.object({
    type: z.literal('orderedList'),
    attrs: z.object({
        start: z.coerce.number(),
    }),
});

const HardBreakRichTextSchema = z.object({
    type: z.literal('hardBreak'),
    marks: z.array(z.any()).optional(),
});

export type RichText =
    | (z.infer<typeof ContentRichTextSchema | typeof OrderedListRichTextSchema> & {
          content?: RichText[];
      })
    | z.infer<typeof textSchema>
    | z.infer<typeof HardBreakRichTextSchema>;

export const RichTextSchema: z.ZodType<RichText> = z.union([
    ContentRichTextSchema.merge(z.object({ content: z.lazy(() => RichTextSchema.array().optional()) })).strict(),
    OrderedListRichTextSchema.merge(z.object({ content: z.lazy(() => RichTextSchema.array().optional()) })).strict(),
    textSchema.strict(),
    HardBreakRichTextSchema.strict(),
]);

// End richtext
