import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import safeParse from '_utils/safeParse';
import { RichText, RichTextSchema } from 'types/Base';
import { useGetAccessTokenHeader } from '../_utils/Axios';

const useGetTermOfSale = (): UseQueryResult<RichText, string | Error> => {
    const getAuthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: ['termOfSales'],

        queryFn: async () => {
            const result = await axios.get<unknown>('info/termsOfSale', {
                headers: { authorization: await getAuthHeader() },
            });
            if (!result.data) {
                throw new Error('Result returned with no data');
            }
            return safeParse(result.data, RichTextSchema);
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 24 * 7,
    });
};

export { useGetTermOfSale };
