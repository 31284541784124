import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Theme, useThemeStyle, WH } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { CheckBox, Loader, PrimaryButton, SecondaryButton } from 'Components';
import { Product } from 'types/Category';
import getPrice from './PriceStrategies';

interface BottomButtonsProps {
    onClearSelection(): void;
    onReserve(): void;
    selection: { from: Date | null; to: Date | null };
    quantity: number;
    strategy: Product['paymentStrategy'];
    isReserving: boolean;
    bookingBeingUpdated: { from: Date; to: Date } | null;
    isShortTerm: boolean;
    canSkipPayment: boolean;
    isSkippingPayment: boolean;
    toggleSkipPayment(): void;
}

const BottomButtons = ({
    selection,
    onClearSelection,
    onReserve,
    quantity,
    strategy,
    isReserving,
    bookingBeingUpdated,
    isShortTerm,
    canSkipPayment,
    isSkippingPayment,
    toggleSkipPayment,
}: BottomButtonsProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const paddingBottom = useBottomSpacer();
    const durationAsString = useMemo(() => {
        const duration = moment.duration(moment(selection.to).diff(selection.from));
        const hours = Math.ceil(duration.asHours());
        const days = Math.floor(hours / 24);
        const daysFormatted = t('serviceExpanded:days', { count: days });

        let hoursFormatted = t('serviceExpanded:hours', { count: hours });
        if (!days) {
            return hoursFormatted;
        }
        if (hours - 24 * days) {
            hoursFormatted = t('serviceExpanded:hours', { count: hours - 24 * days }).toLocaleLowerCase();
            return [daysFormatted, hoursFormatted].join(` ${t('global:and')}`);
        }
        return daysFormatted;
    }, [selection.from, selection.to, t]);

    const skipPayment = useMemo(() => {
        if (!canSkipPayment) {
            return null;
        }

        return (
            <CheckBox
                title={t('serviceExpanded:isSkippingPayment')}
                checked={isSkippingPayment}
                onPress={toggleSkipPayment}
                titleStyle={{ fontSize: subtitleFontSize }}
                style={{ marginBottom: screenMargin }}
            />
        );
    }, [canSkipPayment, isSkippingPayment, t, toggleSkipPayment]);

    const disableReserve =
        bookingBeingUpdated !== null &&
        selection.from &&
        selection.to &&
        moment(bookingBeingUpdated.from).isSame(selection.from, 'second') &&
        moment(bookingBeingUpdated.to).isSame(selection.to, 'second');

    const buttons = useMemo(() => {
        if (isReserving) {
            return <Loader bgColor="white" />;
        }
        const text = isShortTerm
            ? t('serviceExpanded:reserveTime')
            : selection.from === null
              ? t('serviceExpanded:chooseStart')
              : t('serviceExpanded:chooseEnd');
        const buttonRow = (
            <View style={themedStyle.buttonContainer}>
                {selection.from !== null ? (
                    <SecondaryButton
                        style={[themedStyle.button, themedStyle.leftButton]}
                        text={t('serviceExpanded:clear')}
                        onPress={onClearSelection}
                    />
                ) : null}
                {selection.to === null ? (
                    <PrimaryButton style={themedStyle.button} text={text} status={'disabled'} onPress={() => {}} />
                ) : (
                    <PrimaryButton
                        style={themedStyle.button}
                        text={(bookingBeingUpdated
                            ? t('serviceExpanded:update')
                            : t('serviceExpanded:reserve')
                        ).toUpperCase()}
                        onPress={onReserve}
                        status={disableReserve ? 'disabled' : null}
                    />
                )}
            </View>
        );
        if (selection.from === null || selection.to == null) {
            return (
                <View style={themedStyle.checkoutContainer}>
                    {skipPayment}
                    {buttonRow}
                </View>
            );
        }

        const duration = Math.ceil(moment(selection.to).diff(selection.from, isShortTerm ? 'hours' : 'days', true));
        // TODO consider duration!
        const price = getPrice(
            {
                quantity,
                start: selection.from,
                end: selection.to,
            },
            strategy,
        );

        return (
            <View style={themedStyle.checkoutContainer}>
                {skipPayment}
                <View style={themedStyle.descriptionContainer}>
                    <View style={themedStyle.infoContainer}>
                        {quantity > 1 ? (
                            <View style={themedStyle.descriptionText}>
                                <Text style={themedStyle.label}>{t('serviceExpanded:quantityLabel')}</Text>
                                <Text style={themedStyle.descriptionValue}>
                                    {t('serviceExpanded:pieces', { count: quantity })}
                                </Text>
                            </View>
                        ) : null}
                        <View style={themedStyle.descriptionText}>
                            <Text style={themedStyle.label}>{t('serviceExpanded:durationLabel')}</Text>
                            <Text style={themedStyle.descriptionValue}>
                                {isShortTerm ? durationAsString : t('serviceExpanded:days', { count: duration })}
                            </Text>
                        </View>
                    </View>
                    {price > 0 && !isSkippingPayment ? (
                        <View style={themedStyle.descriptionText}>
                            <Text style={themedStyle.label}>{t('serviceExpanded:sumLabel')}</Text>
                            <Text style={themedStyle.descriptionValue}>
                                {price}
                                {t('serviceExpanded:kr')}
                            </Text>
                        </View>
                    ) : null}
                </View>
                {buttonRow}
            </View>
        );
    }, [
        isReserving,
        isShortTerm,
        t,
        selection.from,
        selection.to,
        themedStyle.buttonContainer,
        themedStyle.button,
        themedStyle.leftButton,
        themedStyle.checkoutContainer,
        themedStyle.descriptionContainer,
        themedStyle.infoContainer,
        themedStyle.descriptionText,
        themedStyle.label,
        themedStyle.descriptionValue,
        onClearSelection,
        bookingBeingUpdated,
        onReserve,
        strategy,
        quantity,
        disableReserve,
        skipPayment,
        durationAsString,
        isSkippingPayment,
    ]);
    return <View style={[themedStyle.actionContainer, { paddingBottom }]}>{buttons}</View>;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        actionContainer: {
            backgroundColor: theme.white,
            minHeight: WH * 0.07,
            paddingTop: screenMargin,
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
        },
        checkoutContainer: { display: 'flex' },
        descriptionContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: screenMargin,
        },
        infoContainer: {
            flexDirection: 'row',
            gap: smallestMargin,
        },
        descriptionText: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
        label: { fontSize: WH * 0.01, color: theme.secondaryText },
        descriptionValue: { fontSize: WH * 0.02, fontWeight: 'bold', color: theme.main, marginLeft: 4 },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'space-between',
        },
        leftButton: { marginRight: screenMargin },
        button: { flexShrink: 0, flexGrow: 1 },
    });

export default BottomButtons;
