export default {
    enterPhone: {
        title: 'Indtast dit telefonnummer',
        subText: 'Vi sender dig en kode via sms, hvis du ikke har oprettet din egen kode i Heime',
        inputPlaceholder: 'Indtast telefonnummer',
        errorPhoneNotFound:
            'Beklager, det indtastede telefonnummer er ikke tilknyttet en boligforening i Heime. Kontakt os venligst',
        errorGeneric: 'Beklager, der opstod en fejl. Prøv igen',
        errorNoNetwork: 'Netværksfejl. Har du internetforbindelse?',
        button: 'Gå videre',
        repeatNumber: 'Gentag telefonnummer',
        repeatNumberSubText:
            "Vi fandt ikke dit telefonnummer i vores system. Indtast venligst dit telefonnummer igen for at bekræfte, at '{{enteredNumber}}' er korrekt.",
    },
    enterPin: {
        back: 'TILBAGE',
        titlePin: 'Indtast venligst din Heime pinkode',
        titleSMS: 'Indtast koden, du modtog på sms',
        subTextPin: 'Glemt pinkode?',
        subTextSMS: 'Modtog du ikke en sms?',
        sendMeSms: 'Send mig en sms',
        clickableTextSMS: 'Send igen',
        wrongCodeSentSMS: 'Du indtastede en forkert kode flere gange. Vi har sendt dig en ny kode via sms.',
        wrongCode: 'Du indtastede en forkert kode. Prøv igen.',
        error: 'Der opstod en fejl',
        button: 'Fortsæt',
        noNetwork: 'Netværksfejl. Har du internetforbindelse?',
    },
    selectNumber: {
        title: 'Du indtastede to forskellige telefonnumre. Vælg det korrekte',
        select: 'Vælg',
    },
    confirmUser: {
        title: 'Bor eller ejer du bolig i et Heime-nabolag?',
        subTitle: "Hvis du skal have adgang til Heime, tryk 'Bekræft'",
        negative: 'Nej',
        positive: 'Bekræft',
        modalTitle: 'Kontakt os',
        modalText:
            'Kun brugere, der er tilknyttet aktive nabolag, får adgang til Heime. Er du interesseret i at bruge Heime, kan du kontakte os via nedenstående kanaler.',
    },
    createRegistration: {
        title: 'Udfyld dine oplysninger',
        subtitle:
            'Vi har brug for disse oplysninger for at kunne tilknytte dig dit nabolag og verificere din adgang. Ved at indsende oplysningerne accepterer du, at vi gemmer og behandler dem.',
        firstNameLabel: 'Fornavn',
        lastNameLabel: 'Efternavn',
        phoneLabel: 'Telefonnummer',
        emailLabel: 'E-mail',
        projectLabel: 'Nabolag',
        apartmentLabel: 'Adresse / Bolignummer',
        send: 'Send',
        project_error: 'Du skal angive dit nabolag',
        apartment_error: 'Du skal angive din bolig',
        projectPlaceholder: 'Navnet på projektet, ejerforeningen, boligforeningen eller adressen',
        apartmentPlaceholder: 'Gadenavn husnummer bolignummer',
        createError: 'Kunne ikke registrere dig',
        createErrorText: 'Der opstod en fejl. Prøv igen senere. Kontakt Heime, hvis det ikke virker.',
        updateError: 'Kunne ikke opdatere registreringen',
        updateErrorText: 'Der opstod en fejl. Prøv igen senere. Kontakt Heime, hvis det ikke virker.',
    },
    registrationSelected: {
        status: 'Status:',
        waiting: 'Afventer',
        approved: 'Godkendt',
        rejected: 'Afvist',
        titlePending: 'Din registrering er modtaget',
        subTextPending:
            'Din registrering bliver behandlet af bestyrelsen/administratorerne i dit nabolag. Du modtager en sms, når der er en opdatering. Dette kan tage op til 5 hverdage. Det sikrer, at kun dine naboer får adgang.',
        titleApproved: 'Din registrering er godkendt',
        subTextApproved: 'Du kan nu logge ind i Heime og få adgang til indholdet i dit nabolag.',
        titleRejected: 'Din registrering blev ikke godkendt',
        subTextRejected:
            'Din registrering for {{neighborhood}}, {{apartment}} blev ikke godkendt. Kontakt bestyrelsen/administratorerne eller Heime, hvis dette er en fejl.',
        update: 'Opdater dine oplysninger',
        cancel: 'Annuller registrering',
        confirmCancel: 'Er du sikker på, at du vil annullere registreringen?',
        proceed: 'Fortsæt',
        contact: 'Kontakt os',
        tryAgain: 'Prøv igen',
    },
    chooseCoop: {
        title: 'Vælg et nabolag',
        noCoops: 'Du er ikke tilknyttet nogen nabolag i Heime. Kontakt support.',
    },
    home: {
        notices: 'Opslag',
        activities: 'Aktiviteter',
        services: 'Reservationer',
        sharing: 'Nabodeling',
        next: 'Hvad sker der fremover?',
        noActivities: 'Der er ingen planlagte aktiviteter.',
        createActivitySuggestion: 'Hvad med at oprette en?',
    },
    neighbor: {
        sharingForum: 'Ting til udlån',
        chat: 'Chat',
        myNeighbors: 'Mine naboer',
        resourcePersons: 'Hjælpsomme naboer',
        title: 'Nabodeling',
    },
    profile: {
        title: 'Min profil',
        titleNotOwnProfile: 'Brugerprofil',
        phone: 'Telefon:',
        email: 'E-mail:',
        groups: 'Grupper',
        groupMembers: 'Medlemmer',
        birthDate: 'Alder og fødselsdag:',
        edit: 'Rediger profil',
        setUpPin: 'Opsæt PIN',
        sendFeedback: 'Giv feedback',
        back: 'Chat med naboen',
        chat: 'Chat',
        message: 'Chat',
        call: 'Ring',
        about_me: 'Om mig:',
        updateInfoModal: {
            namesDescription: 'Har allerede beskrevet sig selv for naboerne',
            title: 'Lad naboerne lære dig bedre at kende',
            description: 'Upload et billede og skriv lidt om dig selv',
            namesDescription_no_about: 'Har allerede beskrevet sig selv til naboerne',
            description_no_about: 'Skriv lidt om dig selv, så naboerne kan huske noget ved dig',
            namesDescription_no_avatar: 'Har allerede lastet op et billede',
            title_no_avatar: 'Giv postkassen et ansigt',
            description_no_avatar: 'Last op et billede af dig selv. Du kan også vælge et billede fra Facebook',
            checkbox: 'Spørg mig aldrig igen',
            continue: 'Tilføj info',
            back: 'Senere',
            and_more: ' og mere ',
        },
        owningApartmentWith: 'Ejer {{code}} sammen med:',
        owningWith: 'Ejer sammen med:',
        rentingApartmentFrom: 'Lejer {{code}} af:',
        rentingFrom: 'Lejer af:',
        rentingApartmentTo: 'Lejer {{code}} til:',
        rentingTo: 'Lejer til:',
        livingApartmentWith: 'Bor i {{code}} sammen med:',
        livingWith: 'Bor sammen med:',
        otherApartment: 'Koblet til bolig {{code}}:',
        other: 'Koblet til bolig:',
        rentingApartmentWith: 'Lejer {{code}} sammen med:',
        rentingWith: 'Lejer sammen med:',
        profileCompletion: 'Fuldfør din profil',
        profileCompletionDescription: 'Udfyld resten af detaljerne, så dine naboer kan lære dig bedre at kende',
        profileCompletionModalHeader: 'Fuldfør din profil',
        profileCompletionModalDescription: 'Udfyld resten af detaljerne, så dine naboer kan lære dig bedre at kende',
        profileCompletionModalAvatar: 'Tilføj profilbillede',
        profileCompletionModalAboutMe: 'Skriv lidt om dig selv',
        profileCompletionModalEmail: 'Tilføj e-mail',
        profileCompletionModalBirthday: 'Tilføj fødselsdag',
        profileCompletionModalJoinGroup: 'Deltag i en gruppe',
        profileCompletionModalButton: 'Luk',
        parkingSpots: 'Parkeringsplads:',
        parkingSpots_plural: 'Parkeringspladser:',
        deletedUser_explainer: 'Denne bruger er ikke længere i nabolaget',
        deletedUser_explainer_description:
            "Detaljerne om denne bruger er kun tilgængelige for nabolagsadministratorer som dig. Andre naboer ser kun denne bruger som '{{name}}'",
    },
    myHome: {
        title: 'Min bolig',
        noResult: 'Du er ikke tilknyttet en bolig i dette nabolag',
        info: 'Info',
        documents: 'Dokumenter',
        editInfo: 'Rediger info',
        project_name: 'Bolignavn:',
        legal_name: 'Sektionsnummer / Andelsnummer:',
        address: 'Adresse:',
        type: 'Boligtype:',
        apartment: 'Lejlighed',
        detached_house: 'Enfamiliehus',
        semi_detached_house: 'Rækkehus',
        room: 'Rum',
        floor: 'Etage: ',
        area: 'Areal: ',
        living_area: 'Primærareal:',
        bedrooms: 'Antal soveværelser:',
        area_value: '{{area}} m²',
        parking_spot: 'Parkeringsplads:',
        apartment_groups: 'Medlem af disse boliggrupper:',
        tenants: 'Brugere',
        addTenant: 'Tilføj bruger',
        role_owner: 'Ejer',
        role_livesWith: 'Bor med',
        role_renting: 'Lejer',
        role_other: 'Andet',
        editUser: 'Rediger bruger',
        removeUser: 'Fjern bruger',
        removeTenantConfirmHeader: 'Er du sikker på, at du vil fjerne brugeren?',
        removeTenantConfirm: 'Fjern',
        removeTenantCancel: 'Annuller',
        removeTenantConfirmText:
            'Ved at fjerne brugeren fra boligen, vil brugeren ikke længere have adgang til nabolaget.',
        removeTenantConfirmText2: 'Ved at fjerne brugeren fra boligen, vil brugeren miste adgang til boligen i Heime.',
    },
    editHome: {
        title: 'Rediger info',
        save: 'Gem ændringer',
        codeExists: '{{param}} findes allerede. For at skifte bolig, kontakt venligst {{coopType}}',
    },
    tenantEdit: {
        addUser: 'Tilføj bruger',
        addExplainer: 'Start med at indtaste brugerens telefonnummer',
        phone: 'Telefonnummer',
        phonePlaceholder: 'Indtast telefonnummer',
        continue: 'Fortsæt',
        role: 'Rolle',
        owner: 'Ejer',
        livesWith: 'Bor med',
        renting: 'Lejer',
        other: 'Andet',
        addDetails: 'Tilføj flere oplysninger',
        notify: 'Vil du give brugeren besked?',
        notifyNone: 'Nej',
        notifySms: 'Ja, med sms',
        notifyEmail: 'Ja, med e-mail',
        phoneTooLong: 'Telefonnummeret er for langt',
    },
    navigation: {
        home: 'Hjem',
        groups: 'Grupper',
        chat: 'Chat',
        more: 'Mere',
        bellExplainer: 'Åbn notifikationer',
        profileExplainer: 'Åbn din profil',
        plusExplainer: 'Tryk her for at oprette noget',
        reservations: 'Reservationer',
        go_home: 'Gå tilbage til startskærmen',
        go_back: 'Gå tilbage',
    },
    moreMenu: {
        myProfile: 'Min profil',
        myHome: 'Min bolig',
        services: {
            title: 'Reservationer',
            doReservation: 'Foretag en reservation',
            yourReservations: 'Dine reservationer',
        },
        resourcePersons: 'Hjælpsom nabo',
        neighbors: 'Naboer',
        board: {
            title: '{{boardNounCapitalized}}',
            members: 'Kontakter',
            documents: 'Dokumenter',
            messageToBoard: 'Beskeder til {{boardNoun}}',
        },
        terms: 'Salgsbetingelser',
        settings: 'Indstillinger',
        helpfulNeighbors: 'Hjælpsomme naboer',
        Products: 'Ting til udlån',
        contact: 'Support',
        selectCoop: 'Skift nabolag',
        logOut: 'Log ud',
        memberBenefits: 'Beboerfordele',
        surveys: 'Spørgeskemaer',
    },
    footer: {
        label: 'Support:',
    },
    contacts: {
        title: 'KONTAKT OS',
        phoneTitle: 'Telefonnummer:',
        emailTitle: 'E-mail:',
        messageTitle: 'Hvis du har spørgsmål om nabolaget, anbefaler vi, at du kontakter {{boardNoun}} i dit nabolag.',
        messageLineOne: 'Har du fundet en fejl eller har andre forslag til forbedringer?\n',
        messageLineTwo:
            'Vi vil meget gerne høre fra dig! Du kan sende en e-mail til os ved at klikke på e-mailadressen.\n',
        boardButton: 'Se info om {{boardNoun}}',
        support: 'Telefonens åbningstider: \nMan-Fre 09:00 - 16:00',
    },
    activities: {
        tabOne: 'kommende',
        tabTwo: 'tilmeldt',
        tabThree: 'Tidligere',
        signedUp: 'Tilmeldt',
        add_activity: 'OPRET AKTIVITET',
        title: 'Aktiviteter',
        signed_up: 'Tilmeldt',
        not_signed_up: 'Ikke tilmeldt',
        empty_future:
            'Der er ingen planlagte aktiviteter endnu.\nOpret en aktivitet, eller registrer din interesse blandt forslagene',
        empty_futureOnlyAttending: 'Du er ikke tilmeldt nogen aktiviteter.',
        empty_previous: 'Der er ingen tidligere aktiviteter.',
        empty_button_suggestion: 'Se forslag',
        empty_button_create: 'Opret aktivitet',
    },
    activity: {
        signedUp: 'Tilmelding fuldført',
        signUpSelf: 'Tilmeld dig selv',
        Kr: 'KR',
        cancelModal: {
            header: 'Er du sikker på, at du vil aflyse aktiviteten?',
            header_plural: 'Er du sikker på, at du vil slette aktiviteten?',
            description: 'Deltagere vil modtage en notifikation om, at aktiviteten er aflyst',
            checkbox: 'Refundér deltagere',
            back: 'NEJ, GÅ TILBAGE',
            approve: 'JA, AFLYS',
            approve_plural: 'JA, SLET',
        },
        error: 'Der opstod en fejl',
        suggestions: {
            press_here_title: 'Forslag til aktiviteter',
            press_here_description: 'Tryk her for at se og vise interesse for aktiviteter',
            press_here_title_other_neighborhood: 'Få inspiration fra andre nabolag',
            press_here_description_other_neighborhood: 'Tryk her for at se populære aktiviteter',
            images_banner_cta: 'Se forslag',
            images_banner_top: 'Find din næste aktivitet',
            images_banner_title: 'Tryk her for aktiviteter',
            images_banner_cta_other_neighborhood: 'Se aktiviteter',
            images_banner_top_other_neighborhood: 'Inspiration til aktiviteter',
            images_banner_title_other_neighborhood: 'Tryk her for at se aktiviteter fra andre nabolag',
            direct_press_create: 'Opret ny aktivitet',
            header: 'Forslag til aktiviteter',
            subtitle: 'Hvilken aktivitet kunne du tænke dig at deltage i?',
            template_example: 'Eksempel på beskrivelse',
            coffee_title: '☕ Kaffesnak',
            coffee_description: 'Deltag i en hyggelig kaffesnak i fællesområdet, hjemme eller i en park.',
            coffee_arrange_description:
                'For at arrangere en kaffesnak behøver I bare et sted at mødes. I kan beslutte, om nogen skal lave en kande kaffe, og om naboerne skal medbringe deres egen kop. Varigheden kan være alt fra en halv time til, at naboerne kan kigge forbi i løbet af en 3-timers periode.',
            coffee_arrange_description_example:
                'Lad os mødes til en hyggelig snak 👨 💬 👩 Jeg sidder i fællesrummet. Tag en termokande kaffe og godt humør med! ☕ 🫖 ',
            coffee_arrange_location_example: 'Fællesrummet',
            christmas_lighting_title: '🎄 Tænd juletræet',
            christmas_lighting_description:
                'Oplev julemagien sammen i nabolaget. Saml jer omkring juletræet og nyd stemningen.',
            christmas_lighting_arrange_description:
                'For at arrangere tænding af juletræet kan I enten købe et træ, spørge boligforeningen om midler, eller lave en indsamling. Nogen skal hænge lys op og sætte træet op. Hvis I vil, kan I også tage julekager og gløgg med. Børnene kan også lede julesange rundt om træet efter tændingen.',
            christmas_lighting_arrange_description_example:
                'Lad os starte julemagien! Vi har brug for lys 💡 musik 🎶 og godt humør! 🥳 Nogen skal klare lys og træ 🎄 Andre kan stå for julekager og gløgg. Giv besked om, hvad du kan hjælpe med. Vel mødt! 😌',
            christmas_lighting_arrange_location_example: 'Mellem bygningerne',
            bbq_title: '🔥 Grillfest',
            bbq_description: 'Grillen er tændt, og stemningen er i top. Medbring noget lækkert at spise og drikke.',
            bbq_arrange_description:
                'For at arrangere en grillfest behøver I en grill. I kan lave en indsamling for at købe mad og drikke, eller lade naboerne medbringe deres egne ting. En højtaler med lidt musik er også en god idé!',
            bbq_arrange_description_example:
                'Hej! Jeg griller i dag 🍖 nogen interesseret i at være med? Jeg har en grill. Tag selv det med, du ønsker at grille 🥩🌽🌶️',
            bbq_arrange_location_example: 'Gårdspladsen',
            quiz_title: '🧠 Quiz',
            quiz_description: 'Test din viden sammen med naboerne. Find på lagnavne og sæt gang i konkurrencen.',
            quiz_arrange_description:
                'For at arrangere en quiz skal I have nogle spørgsmål. Du kan finde en quiz online eller lave din egen, og det er godt at have en quizmaster. Det anbefales at opdele deltagerne i grupper og give dem opgaven at finde på et lagnavn.',
            quiz_arrange_description_example:
                'Lad os mødes til en hyggelig quiz. Jeg sørger for spørgsmål. Tag selv snacks med.',
            quiz_arrange_location_example: 'Fællesstuen',
            sport_title: "⚽ Sport på tv'en",
            sport_description: 'Saml jer foran tv-skærmen for at se en sportsbegivenhed sammen med naboerne.',
            sport_arrange_description:
                'For at arrangere en sportsaften skal I bruge et tv, en sportskanal og en begivenhed at se. Måske en lokal fodboldkamp, Premier League, atletik eller noget andet?',
            sport_arrange_description_example:
                'Jeg skal se noget sport i aften. Jeg går ned 15 min før og sætter den rigtige kanal på. Tag snacks med og vær med!',
            sport_arrange_location_example: 'Tv-stuen',
            common_dinner_title: '🍽️ Fællesspisning',
            common_dinner_description: 'Lav mad sammen og nyd et måltid i fællesskab.',
            common_dinner_arrange_description:
                'For at arrangere en fællesspisning kan I lave en plan for, hvad der skal serveres, og hvem der tager hvad med. Det kan være en 3-retters menu, tapas eller grill. I kan også lave en indsamling for at købe ind, eller hver nabo kan tage noget med.',
            common_dinner_arrange_description_example:
                'Deltag i en hyggelig fællesspisning. Jeg sørger for maden, men tager gerne imod hjælp. Tag selv drikkevarer med.',
            common_dinner_arrange_location_example: 'Fællesstuen',
            knitting_title: '🧶 Strikning',
            knitting_description: 'Mød op for at strikke sammen. Tag dit strikketøj med og hyg dig med andre.',
            knitting_arrange_description:
                'For at arrangere strikning skal I have et sted at sidde og strikke. I kan lave en indsamling for at købe kaffe og te, eller naboerne kan tage det med, de selv ønsker.',
            knitting_arrange_description_example:
                'Hej! Jeg mangler inspiration til næste strikkeprojekt 🧶 Lad os mødes til en hyggelig strikkesession. Tag dit strikketøj med!',
            knitting_arrange_location_example: 'Fællesrummet',
            walk_title: '👟 Fælles gåtur',
            walk_description: 'Gå en tur i nabolaget sammen med naboerne. Få frisk luft og godt selskab.',
            walk_arrange_description:
                'For at arrangere en fælles gåtur skal I finde en rute og aftale et tidspunkt. Husk at tage hensyn til tempoet og informér om det. Det kan være sjovt at planlægge en pause på en café eller i en park undervejs.',
            walk_arrange_description_example:
                'Inviterer til en hyggelig gåtur med naboerne i lokalområdet. Klæd dig efter vejret, så går vi sammen ☁️☀️☔',
            walk_arrange_location_example: 'Ved hovedindgangen',
            bathing_title: '🏊 Badning',
            bathing_description: 'Hop i vandet. En helårsaktivitet for nogle, en sommeraktivitet for andre.',
            bathing_arrange_description:
                'For at arrangere badning skal I finde et sted at bade og aftale et tidspunkt. Det kan også kombineres med en sauna, hvis der findes en i nærheden.',
            bathing_arrange_description_example: 'Lad os mødes til en hyggelig badetur.',
            bathing_arrange_location_example: 'Ved kajen',
            party_title: '🎉 Nabofest',
            party_description: 'Fejr sammen med naboerne, med eller uden en særlig anledning.',
            party_arrange_description:
                'For at arrangere en nabofest skal I finde et sted at mødes og aftale et tidspunkt. I kan lave en indsamling for at købe mad og drikke, eller lade naboerne tage deres egne ting med. Musik og underholdning kan også arrangeres. Det er en god idé at samle en lille gruppe, der hjælper med oprydningen bagefter.',
            party_arrange_description_example:
                'Lad os samles 💃🕺 Det er længe siden, jeg har set mange af jer, så jeg inviterer jer alle til at mødes. Kom som du er, og tag det med, du ønsker 🥤🍻🍷🍿 Vi hygger os i godt selskab. PS: Oprydning bagefter værdsættes meget 🙇',
            party_arrange_location_example: 'Fællesrummet',
            community_effort_title: '🤝 Arbejdsdag',
            community_effort_description: 'Få noget fra hånden i nabolaget. Saml en gruppe og tag fat sammen.',
            community_effort_arrange_description:
                'For at arrangere en arbejdsdag skal I have en opgaveliste og aftale et mødetidspunkt. Det er en god idé at kombinere det med lidt hygge før/efter/under arbejdsdagen.',
            community_effort_arrange_description_example:
                'Hej alle sammen! Fællesområdet kunne trænge til lidt kærlighed ❤️✨ Jeg vil rive blade på parkeringspladsen 🍂 Meld dig gerne, hvis du vil hjælpe.',
            community_effort_arrange_location_example: 'Fælleshaven',
            workout_title: '💪 Træning eller sport',
            workout_description: 'Træn sammen med naboerne – det er altid rart at motivere hinanden.',
            workout_arrange_description:
                'Find en sport eller træning, I kan lave sammen, enten ude eller inde. Beskriv gerne niveauet, eller specificér, at alle kan deltage. Forslag kan være vægtløftning, fodbold, volleyball, tennis, frisbee eller en fælles yoga-session.',
            workout_arrange_description_example:
                'Lad os mødes til en træning 💪 Er der nogen, der har et godt program, de vil dele? 💪 Ellers har jeg noget, der kan justeres efter niveau. 🧑‍🎓 Jeg tager gerne imod hjælp til at komme i gang. 🙇',
            workout_arrange_location_example: 'Mellem bygningerne',
            boardgames_title: '🎲 Brætspilsaften',
            boardgames_description: 'Spil gamle klassikere eller helt nye spil.',
            boardgames_arrange_description:
                'Foreslå dine egne brætspil eller spørg naboerne, hvad de har. Sæt nogle timer af og find et passende sted at spille.',
            boardgames_arrange_description_example:
                'Hej! Jeg vil spille brætspil, vil du være med? 🎲 Jeg har Trivial Pursuit, men andre spil er meget velkomne 🥳',
            boardgames_arrange_location_example: 'Fællesrummet',
            other_title: '💡 Andet',
            other_description: 'Har du en anden idé til en aktivitet? Tryk her.',
            other_arrange_description:
                'Du kan oprette alle slags aktiviteter i appen. Hvis du er i tvivl om interessen blandt naboerne, kan du undersøge det med en interessegruppe eller opslag.',
            interest: 'Jeg er interesseret i at deltage',
            interested_ask_neighbors: 'Spørg naboer om interesse',
            arrange: 'Jeg vil arrangere dette',
            interested: 'Din interesse er noteret',
            one_interested: '{{fname}} er interesseret',
            two_interested: '{{fname1}} og {{fname2}} er interesseret',
            three_or_four_interested: '{{names}} og {{fname}} er interesseret',
            many_interested: '{{fname}} og {{count}} andre er interesseret',
        },
        survey_activityNew: {
            header: 'Hvorfor fuldførte du ikke aktiviteten?',
            content: 'Hjælp os med at forbedre processen. Det du har skrevet i aktiviteten vil ikke blive lagret.',
            survey_label: 'Feedback',
            survey_placeholder: 'Hvad var uforståeligt, svært, eller hvorfor afbrød du?',
            allow_contact: 'Jeg kan kontaktes for opfølgning',
            submit: 'Send feedback',
            cancel: 'Hopp over, gå ud',
        },
        survey_activitySuggestion: {
            header: 'Hvorfor er du ikke interesseret i aktiviteten?',
            content: 'Vi vil gerne have feedback for at kunne forbedre aktiviteterne i dit nabolag.',
            survey_label: 'Feedback',
            survey_placeholder: 'Beskriv hvorfor du ikke ønsker at melde interesse',
            allow_contact: 'Jeg kan kontaktes for opfølgning',
            submit: 'Send feedback',
            cancel: 'Hopp over',
        },
    },
    activityDetail: {
        you_booked: 'Du har {{count}} billet',
        you_booked_plural: 'Du har {{count}} billetter',
        activity_details: 'Aktivitetsdetaljer',
        press_to_add: 'Klik her for at tilføje en beskrivelse',
        related_groups: 'Relaterede grupper',
        date: 'Dato',
        start_end_time: 'Start- og sluttid',
        location: 'Sted',
        no_location: 'Ingen sted',
        cost: 'Pris',
        alert: 'Varsling',
        organizer: 'Dig • Arrangør',
        nonOrganizer: 'Arrangør',
        attendeesTitle: 'Deltagere',
        attendees: '{{count}} Deltager',
        attendees_plural: '{{count}} Deltagere',
        tickets: '{{count}} billet',
        tickets_plural: '{{count}} billetter',
        signup: 'Tilmeldt {{quantity}} stk',
        no_cost: 'Gratis',
        kr: 'NOK',
        noticeOptions: {
            0: 'Ingen',
            1: 'Alle naboer',
            3: 'Kun naboer, der har tilmeldt sig',
            4: 'Kun naboer, der ikke har tilmeldt sig',
            5: 'Alle medlemmer i udvalgte grupper',
        },
        quantity: 'Maks antal deltagere',
        quantityExplainer: 'Maks {{count}} deltager',
        quantityExplainer_plural: 'Maks {{count}} deltagere',
        no_quantity: 'Ingen begrænsning',
        cancellation: 'Aflysningsfrist: {{ datetime }}',
        no_cancellation: 'Ingen aflysningsfrist',
        registrationDeadline: 'Tilmeldingsfrist: {{ datetime }}',
        no_registrationDeadline: 'Ingen tilmeldingsfrist',
        contact: 'Arrangør',
        edit_activity: 'Rediger aktivitet',
        buy_for: 'Køb {{count}} billet ({{totalAmount}} NOK)',
        buy_for_plural: 'Køb {{count}} billetter ({{totalAmount}} NOK)',
        buy_more_for: 'Køb {{count}} billet ({{totalAmount}} NOK) til',
        buy_more_for_plural: 'Køb {{count}} flere billetter ({{totalAmount}} NOK) til',
        signup_for: 'Tilmeld {{count}} stk.',
        signup_more_for: 'Tilmeld {{count}} stk. til',
        cancel_for: 'Afbestil {{count}} billet',
        cancel_for_plural: 'Afbestil {{count}} billetter',
        sign_off: 'Afmeld {{count}} stk',
        noChanges: 'Ingen ændringer',
        cantCancel: 'Aflysningsfristen er udløbet',
        noSpots: 'Der er ikke flere pladser på denne aktivitet',
        noSpotsToAdd: 'Der er ikke plads til at tilmelde flere',
        signUpTimePast: 'Tilmeldingsfristen er udløbet',
        invalidNum: 'Det valgte nummer er ugyldigt',
        notRefunded: 'Du vil ikke blive refunderet for afbestilte billetter',
        willRefund: 'Du vil blive refunderet for billetterne ved afbestilling',
        sentAlertModal: {
            send_alert: 'Send besked til deltagerne',
            title: 'Titel',
            message: 'Besked',
            send: 'Send',
            continue: 'Fortsæt',
            success: 'Besked blev sendt til deltagerne',
        },
        delete_activity: 'Slet aktiviteten',
        cancel_activity: 'Aflys aktiviteten',
        send_out_alert: 'Send besked til tilmeldte',
        signup_yourself: 'Tilmeld dig selv',
        change_own_signup: 'Ændre din egen tilmelding',
        cancel: 'Annuller',
        publish: 'Publicer',
        publishEdit: 'Gem',
        start_time: 'Starttid',
        end_time: 'Sluttid',
        no_groups: 'Ingen grupper',
        errorLoadingAttendees: 'Der opstod en fejl ved indlæsning af deltagere',
        addDetails: 'Tilføj detaljer',
        paying_now: 'Betales nu',
        arrangers: 'Arrangører',
        arrangers_you_and: 'Du og {{count}} anden',
        arrangers_you_and_plural: 'Du og {{count}} andre',
        add_arranger: 'Tilføj medarrangør',
    },
    newActivity: {
        title_create_activity: 'Opret en aktivitet',
        preview: 'Forhåndsvisning af ny aktivitet',
        previewEdit: 'Forhåndsvisning af ændringer',
        change_details: 'Ændre detaljer',
        imageTitle: 'Tilføj billede',
        moreMenu: {
            addPicture: 'Rediger billede(r)',
            addDescription: 'Rediger beskrivelse',
            addMaxAttendees: 'Angiv maks antal deltagere',
            addCost: 'Tilføj pris for deltagelse',
            addRelatedGroups: 'Tilføj relaterede grupper',
            addLatestPossibleSignupTime: 'Angiv tilmeldingsfrist',
            addLatestCancellationTime: 'Angiv aflysningsfrist',
            alerts: 'Bestem varsling til naboer',
            addArrangers: 'Tilføj medarrangører',
        },
        receive_alert: 'Vælg hvem der skal modtage besked',
        send_out_alert: 'Vi sender en besked ved oprettelse og 24 timer før start / tilmeldingsfrist.',
        groups: 'Grupper',
        about_activity: 'Beskriv aktiviteten',
        title: 'Navn',
        enter_title: 'Giv aktiviteten et beskrivende navn',
        location: 'Sted',
        enter_location: 'Indtast mødested',
        time: 'Tidspunkt for aktiviteten',
        start: 'Start',
        end: 'Slut',
        enter_description: 'Skriv en beskrivelse, gerne med praktisk information. F.eks. betaling og opmødetid',
        description: 'Beskrivelse',
        add_group: 'Tilføj grupper',
        add_group_explainer:
            'Der vil komme en besked i gruppechatten om aktiviteten, og du vil kunne vælge kun at underrette gruppemedlemmer',
        when_will_activity: 'Tidspunkter for aktiviteten',
        choose_start: 'Starttidspunkt for aktiviteten',
        choose_end: 'Sluttidspunkt for aktiviteten',
        signuptime: 'Tilmeldingsfrist',
        enableSignUpTime: 'Har til- og afmeldingsfrist',
        registration: 'Tilmeldingsfrist',
        cancellation: 'Afmeldingsfrist',
        cancellationSame: 'Samme som tilmeldingsfrist',
        choose_location: 'Beskriv lokationen for aktiviteten',
        add_photo: 'Tilføj billeder',
        first_image_will: 'Det første billede du tilføjer, vil blive hovedbilledet',
        image: 'Billeder',
        indicate_cost: 'Angiv pris og kapacitet',
        explainNoCost: 'Dit nabolag er ikke registreret i Vipps, så du kan ikke sætte en pris på arrangementet',
        paid_event: 'Deltagelse koster penge',
        price: 'Pris (NOK)',
        priceMVA: 'Totalpris (NOK) (inkl. moms)',
        mva: 'Moms (%)',
        bookKeepingAccount: 'Bogføringskonto',
        tax_rate: 'Vippskonto',
        attendees_limit: 'Maks antal deltagere',
        enter_zero: 'Ved at lade feltet stå tomt, kan der være ubegrænset antal deltagere',
        paymentExplainer: 'Pengene for deltagelse vil blive overført til den konto, der er valgt ovenfor',
        refundCancellations: 'Refunder deltagere der aflyser deres billet',
        continue: 'Gå til forhåndsvisning',
        see_preview: 'Se forhåndsvisning',
        cooperative_group_id: 'Tilgængelig for',
        noCooperativeGroup: 'Kun',
        cooperative_group: 'Bestem om aktiviteten skal være tilgængelig for flere nabolag eller kun dit eget',
        no_groups: 'Du er ikke medlem af nogen grupper',
        arrangers: 'Arrangører',
        add_arranger: 'Tilføj arrangører',
        add_arrangers_explainer:
            'Tilføj personer der kan hjælpe med at arrangere aktiviteten. De vil kunne redigere og aflyse aktiviteten samt sende besked til tilmeldte.',
        no_users: 'Ingen brugere i dette nabolag',
        no_users_search: 'Ingen brugere fundet',
        search_users: 'Søg efter brugere',
        activity_about_description:
            'Ved at gå til forhåndsvisning kan du ændre flere indstillinger og tilføje billeder.',
        confirm_template: 'Vil du bruge skabelonen?',
        confirm_template_content: 'Ved at bruge en skabelon overskrives det du allerede har skrevet i aktiviteten',
        confirm_template_cancel: 'Annuller',
        confirm_template_confirm: 'Ja',
    },
    chatAll: {
        createNewChat: 'Opret ny chat',
        searchPlaceholder: 'Søg efter chat',
        selectMembers: 'Opret ny chat',
        selectMembersSelected: 'Opret chat ({{count}})',
        createChat: 'Opret chat',
        noChats: 'Du har ingen chats',
        sentByYou: 'Du',
        sentMessage: 'sendte en besked',
        title: 'Chats',
    },
    sharingAll: {
        noSearchResult: 'Ingen resultater',
        viewAll: 'se alle',
        messageType: {
            2: 'Opslag',
            0: 'Genstande',
            1: 'Hjælpsomme naboer',
        },
        noMessageType: {
            Requests: 'Ingen opslag her lige nu. Opslag til naboer vil dukke op her.',
            ByBoard: 'Ingen opslag fra {{boardNoun}} her. Opslag fra {{boardNoun}} vil blive arkiveret her.',
            Products:
                'Ingen genstande her endnu. Genstande til deling vil dukke op her, hvad med at lægge noget op selv?',
            Helpers: 'Ingen hjælpsomme naboer her endnu. Hvis du melder dig som hjælpsom nabo, vil du dukke op her.',
            neighborSharing:
                'Ingen naboer der deler her endnu. Hvis du melder dig som hjælpsom nabo eller lægger en genstand op til udlån, vil det dukke op her.',
        },
        neighborSharingTitle: 'Naboer der deler',
        fromBoard: 'Fra {{boardNoun}}',
        new: 'Ny',
        stars: '⭐️ {{count}} stjerner',
        no_star: '⭐️ Giv stjerne',
        postRequest: 'Opret nyt opslag',
        postProduct: 'Læg en genstand op',
        offerHelp: 'Tilbyd din hjælp',
        myProducts: 'Mine genstande',
        myRequests: 'Mine opslag',
        yourProducts: 'Dine genstande',
        yourRequests: 'Dine opslag',
        requests: { plural: 'Opslag', singular: 'Et opslag' },
        products: { plural: 'Genstande', singular: 'En genstand' },
        searchResults: 'Søgeresultater',
        you: 'Du',
        dot: ' • ',
        errorSaving_header: 'Der opstod en fejl',
        errorSaving_description: 'Kunne ikke gemme indstillingerne, prøv igen senere',
        save: 'Gem',
        userSettings: 'Notifikationsindstillinger',
        userSettingsShort: 'Notifikationer',
        settingsAllNotifications: 'Modtag notifikationer om vigtige opslag',
        settingsOnlyBoard: 'Modtag kun notifikationer om vigtige opslag fra {{boardNoun}}',
        settingsNoneNotifications: 'Modtag ikke notifikationer om opslag',
        follow: 'Følg',
        unfollow: 'Følg ikke',
        comment: '1 kommentar',
        comments: '{{count}} kommentarer',
        attachment: '1 vedhæftning',
        attachments: '{{count}} vedhæftninger',
        created: 'Oprettet af',
        fromBoardBy: 'ved',
        all: 'Aktive',
        onlyFor: 'Kun synlig for',
        open_settings: 'Åbn indstillinger for notifikationer om opslag',
    },
    sharingNew: {
        newMessage: 'Lav ny besked',
        itemTitle: 'Titel',
        itemText: 'Besked',
        imageSelector: 'Tilføj billede',
        sendButton: 'Send besked',
        titleEdit: {
            0: 'Rediger genstand',
            1: 'Rediger hjælpsom nabo',
            2: 'Rediger opslag',
            3: 'Rediger opslag',
        },
        title: {
            0: 'Ny genstand',
            1: 'Bliv en hjælpsom nabo',
            2: 'Nyt opslag',
            3: 'Nyt opslag',
        },
        chooseType: 'Vælg type',
        messageCategory: {
            0: {
                title: 'Delbar genstand',
                desc: 'Noget du kan låne ud, f.eks. en stige, symaskine, skøjter eller sukker!',
            },
            1: {
                title: 'Bliv en hjælpsom nabo',
                desc: 'Fortæl dine naboer, hvad du kan hjælpe dem med',
            },
            2: {
                title: 'Opslag',
                desc: 'Lav et opslag til dine naboer, du kan f.eks. bede om hjælp eller informere dem om noget',
            },
            3: {
                title: 'Opslag fra {{boardNoun}}',
                desc: 'Lav et opslag til dine naboer, opslaget vil blive markeret som "fra {{boardNoun}}"',
            },
            4: {
                title: 'Samling',
                desc: 'Hvis du skal sidde i fællesområdet, er det rart at invitere naboer',
            },
        },
        addProduct: {
            title: 'Beskriv genstanden',
            image: 'Billede',
            textTitle: 'Titel',
            enterTitle: 'Skriv en beskrivende titel',
            textDesc: 'Beskrivelse',
            enterDesc:
                'Beskriv genstanden og hvordan den kan lånes. F.eks. står den let tilgængelig i kælderen, eller du skal kontaktes først',
        },
        requestHelp: {
            title: 'Opslag',
            textTitle: 'Titel',
            enterTitle: 'Indtast titel',
            textDesc: 'Beskrivelse',
            enterDesc: 'Indtast beskrivelse',
            send_notification: 'Send notifikation til naboer',
            sent_notification: 'Notifikation sendt',
            no_notification: 'Ingen notifikation sendt',
            noAccess: 'Notifikation ikke tilgængelig: Du kan kun gøre dette én gang om dagen',
            allow_comments: 'Tillad kommentarer på opslaget',
            attachments: 'Vedhæftninger',
            never_expires: 'Permanent opslag',
            never_expires_description: 'Opslaget vil automatisk blive fornyet hver 30. dag, så det ikke forsvinder',
        },
        helpNeighbour: {
            title: 'Fortæl hvad du kan hjælpe med',
            textTitle: 'Titel',
            enterTitle: 'Beskriv kort med stikord, hvad du kan hjælpe med',
            textDesc: 'Forklar hvorfor og hvordan du kan hjælpe',
            enterDesc: 'Skriv mere udførligt. F.eks. skriv erfaring eller eksempler på hjælp du kan give.',
            textLimit: '({{count}} / 40)',
        },
        activity: {
            explainer: 'En samling passer bedre som en aktivitet, vil du oprette en?',
            action: 'Gå til oprettelse af aktivitet',
        },
        publish: 'udgiv',
        saveError: 'Kunne ikke gemme',
        publishError: 'Kunne ikke oprette',
        publishErrorNotification:
            'Du kan ikke oprette flere opslag med notifikation. Du kan kun oprette ét opslag med notifikation per dag.',
        chooseApartmentGroups: 'Vælg boliger',
        chooseApartmentGroupsDesc: 'Ved at vælge ingen boliger vil opslaget blive synligt for alle i nabolaget',
        apartment_groups: 'Begræns til boliger',
    },
    profileEdit: {
        title: 'Redigér profil',
        submit: 'Gem ændringer',
        continue: 'Fortsæt',
        public: 'Offentlig',
        first_name: 'Fornavn',
        last_name: 'Efternavn',
        email: 'E-mail',
        phone: 'Telefonnummer',
        own_code: 'Personlig PIN',
        birthday: 'Fødselsdag',
        about_me: 'Lidt om mig',
        enterPhone: 'Indtast telefonnummer',
        enterFirstName: 'Indtast fornavn',
        enterSurname: 'Indtast efternavn',
        enterEmail: 'Indtast e-mail',
        hideEmailFromProfile: 'Skjul e-mail fra min profil',
        hideBirthdayFromProfile: 'Skjul fødselsdag fra min profil',
        hidePhoneFromProfile: 'Skjul telefonnummer fra min profil',
        aboutMePlaceHolder:
            'F.eks: Arbejder i butik. Elsker at være ude, men der er ikke meget, der slår et uldtæppe og et glas vin. Kan et korttrick.',
        updateMobileModal: {
            header: 'Er du sikker på, at du har indtastet det rigtige telefonnummer?',
            description: 'Hvis du laver en fejl, vil du ikke kunne logge ind på applikationen',
            back: 'Nej, tilbage',
            submit: 'Ja, helt sikkert',
        },
        upload_image: 'Upload et profilbillede',
        delete_image: 'Fjern profilbillede',
        facebookLoginErrorHeader: 'Kunne ikke logge ind med Facebook',
        facebookLoginCancelledHeader: 'Facebook-login blev afbrudt',
        facebookLoginErrorDescription: 'Hvis du ønsker at uploade et billede, kan du trykke på den samme knap igen',
        facebookPictureDownloadErrorHeader: 'Kunne ikke hente profilbillede fra Facebook',
        facebook_no_picture: 'Der blev ikke fundet noget profilbillede på Facebook',
        facebookTrackingPermissionErrorHeader:
            'Kunne ikke få de nødvendige tilladelser til at hente profilbillede fra Facebook',
        facebookTrackingPermissionErrorDescription:
            'Gå til indstillinger og giv tilladelse til Heime under "Anonymitet" => "sporing" for at uploade profilbillede fra Facebook',
        uploading_file: 'Vent venligst, mens billedet bliver uploadet',
        change_picture: 'Skift profilbillede',
        about_me_title: 'Tilføj "om mig"',
        aboutMeTips: 'Tips til hvad du kan skrive',
        aboutMeExamples: 'Eksempler fra dine naboer',
        about_me_tip_0: 'Vær dig selv',
        about_me_tip_description_0:
            'Skriv det, du ønsker selv. Der er ikke nogen rigtig eller forkert måde at skrive "om mig"-afsnittet på.',
        about_me_tip_1: 'Hvad arbejder du med?',
        about_me_tip_description_1:
            'Mange skriver, hvad de arbejder med til dagligt, eller måske noget, de har arbejdet med tidligere?',
        about_me_tip_2: 'Har du altid boet i området?',
        about_me_tip_description_2:
            'Du kan skrive, hvor du har boet tidligere, eller hvor du kommer fra, måske er du flyttet hertil for nylig?',
        about_me_tip_3: 'Har du nogen hobbyer eller interesser?',
        about_me_tip_description_3:
            'Skriv gerne, hvad du kan lide at lave, måske er der nogen i nabolaget med de samme interesser?',
        about_me_tip_4: 'Introducer dig selv',
        about_me_tip_description_4: 'Måske har du et kælenavn, eller noget, der gør dig let at huske?',
        about_me_tip_5: 'Svært at skrive?',
        about_me_tip_description_5: 'Det er ikke altid nemt, men stikord holder langt',
        about_me_tip_6: 'Hvem kan se det, der står her?',
        about_me_tip_description_6: 'Kun dine naboer kan se, hvad du skriver om dig selv',
    },
    reports: {
        header: 'Rapporter',
        search: 'Søg efter besked',
        reportToBoard: 'Send en ny rapport',
        noReports: 'Ingen rapporter her endnu.',
        noSearchResult: 'Ingen resultater på din søgning',
        yoursAndPublic: 'Dine og offentlige',
        open: 'Åben',
        closed: 'Lukkede',
        disabled: 'Denne funktionalitet er deaktiveret af bestyrelsen. De skal kontaktes via mail på',
    },
    reportSelected: {
        reportDetails: 'Besked',
        error: 'Der opstod en fejl',
        editReport: 'Redigér besked',
        deleteReport: 'Slet besked',
        comments: 'Kommentarer',
        addComment: 'Tilføj en kommentar',
        statusTypeNew: 'Ny',
        statusTypeProcessing: 'Under behandling',
        statusTypeDone: 'Løst',
        statusTypeWaiting: 'Venter',
        commentModal: {
            addComment: 'Tilføj en kommentar',
            message: 'Besked',
            enterMessage: 'Indtast besked',
            send: 'Send',
            error: 'Der opstod en fejl under lagring af kommentar',
            publicComment: 'Kommentarer vil være synlige for alle naboer, da beskeden er markeret som "offentlig"',
            privateComment:
                'Kommentar vil kun være synlig for {{user}} og andre i {{boardNoun}}, de vil også modtage en notifikation om kommentaren',
            privateCommentOwn:
                'Kommentar vil kun være synlig for dig og {{boardNoun}}, {{boardNoun}} vil også modtage en notifikation om kommentaren',
        },
        changeStatusModal: {
            errorUpdating: 'Kunne ikke opdatere status',
            changeStatus: 'Skift status',
            save: 'Gem',
        },
        noComments: 'Ingen kommentarer her endnu.',
        changeStatus: 'Skift status',
    },
    reportNew: {
        header: { edit: 'Redigér besked', new: 'Ny besked' },
        image: 'Billede',
        title: 'Titel',
        error: 'der opstod en fejl',
        enterTitle: 'Indtast titel',
        desc: 'Beskrivelse',
        enterDesc: 'Indtast beskrivelse',
        isPublic: 'er synlig for naboerne',
        publicContent: 'Ved at gøre rapporten synlig for naboerne, vil andre brugere af appen kunne se din rapport.',
        publish: 'Indsend',
        saveChanges: 'Gem ændringer',
    },
    transaction: {
        waiting: 'Venter på bekræftelse',
        toVipps: 'Gå til Vipps for at annullere',
        pay: 'Betal i Vipps',
        title: 'Betalingsstatus',
        cancelled: 'Betalingen blev annulleret',
        cancelledExplainerBooking:
            'Du er ikke blevet trukket penge, og din reservation er ikke bekræftet. Du kan prøve igen.',
        cancelledExplainerActivity:
            'Du er ikke blevet trukket penge, og din tilmelding er ikke bekræftet. Du kan prøve igen.',
        back: 'Gå tilbage',
        success: 'Betalingen blev gennemført',
        timeUntilStart: 'Om {{formattedDuration}}',
        started: 'Allerede startet',
        has_been_reservedPost: ' er blevet reserveret',
        reservations: 'Gå til reservation',
        explainLocks:
            'Produktet, du har reserveret, låses op ved hjælp af Heime-appen. For at låse op går du til din reservation under "Dine reservationer" efter den er startet og trykker "lås op"',
        has_been_signedup: 'Du er tilmeldt ',
        quantity_pre: 'Du har ',
        quantity_post_single: ' billet',
        quantity_post_multiple: ' billetter',
        toActivity: 'Gå til aktiviteten',
        bookingConfirmed: 'Reservationen er bekræftet',
    },
    board: {
        title: 'Kontakter',
        search: 'Søg',
        members: '{{count}} Medlem',
        members_plural: '{{count}} Medlemmer',
        call: 'Opkald',
        mail: 'E-mail',
        boardResponsibility: 'Ansvar:',
        reportToBoard: 'Send besked til {{boardNoun}}',
        noResult: 'Ingen resultater',
        noContent: 'Ingen kontakter',
    },
    documents: {
        title: 'Dokumenter',
        search: 'søg',
        folder: 'Mappe',
        files: '{{count}} fil',
        files_plural: '{{count}} filer',
        notFound: 'Ingen resultater',
        noResult: 'Der er ingen dokumenter',
        error: 'Kunne ikke hente filen',
        privateFolder: 'Denne mapp er kun synlig og tilgængelig for naboalgsadministratorer',
    },
    neighbours: {
        alphabetic: 'alfabetisk',
        apartment: 'adresse',
        searchPlaceholder: 'Søg efter navn',
        noUserFound: 'Ingen bruger fundet',
        noApartment: 'Ingen adresse',
    },
    reservations: {
        title: 'Dine reservationer',
        back: 'Tilbage',
        noReservations: 'Du har ingen reservationer, hvis du ønsker at leje noget, kan du trykke på knappen nedenfor',
        services: 'Tjenester',
        today: 'I dag',
        selectTimeRange: 'VÆLG TID',
        clearDates: 'NULSTIL',
        reserver: 'RESERVER',
        booked: 'Bestilt:',
        total: 'Total:',
        errorUpdate: 'Kunne ikke opdatere reservationen',
        errorCreate: 'Kunne ikke oprette reservationen',
        alreadyReserved_title: 'Allerede reserveret',
        alreadyReserved_description: 'Produktet er allerede reserveret i denne tidsramme',
        successUpdate: 'Reservationen blev opdateret',
        started: 'Startet',
        openLock: 'Åbn låsen',
        locks: { singular: 'Adgang via app', plural: 'Adgang via app' },
        until: 'Indtil',
        left: 'tilbage',
        onGoing: 'Startet',
        quantity: '{{quantity}} stk.',
        checkIn: 'Check ind',
    },
    services: {
        title: 'Reservationer',
        myReservations: 'Dine reservationer',
        oneOngoing: 'En aktiv reservation',
        ongoingReservations: 'aktive reservationer',
        oneReservation: 'En reservation',
        reservations: 'reservationer',
        empty: 'Der er ingen tjenester eller noget at reservere her',
    },
    expandedReservation: {
        unlock: 'Lås op',
        canUnlockIn: 'Kan åbnes om ',
        remote_unlock: 'Fjernåbn låsen',
        expired: 'Reservationen er udløbet',
        reservation_has_locks: 'Produktet har en lås',
        cancel: 'Annuller',
        errorCancel: 'Kunne ikke annullere reservationen',
        change: 'Ændre',
        lockModal: {
            lockUnlocked: 'Låsen er åben',
            lockboxUnlocked: 'Nøgleboksen er åbnet, du kan nu tage nøglerne',
            get_keys: 'Tag nøglerne og luk nøgleboksen',
            leave_keys: 'Læg nøglerne tilbage og luk nøgleboksen',
            lockOpened: 'låsen er åben',
            lock: 'Tryk for at låse',
            attemptingConnect: 'Søger efter låsen',
            lockConnecting: 'Søger efter låsen',
            noConnect: 'Kunne ikke finde låsen, er den i nærheden?',
            requestingUnlock: 'Åbner låsen',
            unlocking: 'Låser op',
            retry: 'Prøv igen',
            unlockFailed: 'Kunne ikke åbne låsen',
            unlocked: 'Låsen er åben',
            lockingIn: 'Låser om ',
            sec: 'sek',
            lockClosed: 'Låsen er lukket',
            pinClosedCanClose: 'Frakoblet, bekræft at låsen er lukket',
            unlockAgain: 'Åbn igen',
            lockDisconnected: 'Låsen er afbrudt',
            closeLock: 'Luk låsen for at fortsætte',
            noPermission: 'Kræver adgang til Bluetooth og placering',
            openSettings: 'Åbn indstillinger',
            close: 'Afbryd',
            noBluetooth: 'Bluetooth er ikke aktiveret',
            noWeb: 'Download appen for at låse op',
            pressLock: 'Aktiver låsen ved at trykke på knappen',
            wakeLock: 'Aktiver låsen for at fortsætte',
            noConnectRememberToWake: 'Kunne ikke finde låsen, har du aktiveret den?',
            noBluetoothPermission: 'Kræver Bluetooth-adgang for at åbne låsen',
            noLocationPermission: 'Kræver adgang til placering for at åbne låsen',
            pressLockExplainer:
                'Tryk på knappen på låsen, som hænger på væggen eller i nærheden af det reserverede. Uden dette kan appen ikke kommunikere med låsen.',
            closeFailed: 'Kunne ikke lukke låsen',
            closing: 'Lukker låsen',
            lockClosing: 'Låser...',
            waiting: 'Venter på låsen',
            remote_unlock: 'Du kan låse op hvor som helst fra',
            remote_unlocking: 'Har anmodet låsen om at låse op',
            lockDone: 'Lås åbnet',
            missingSecretKeyOrTokenHeader: 'Mangler oplysninger for at åbne låsen',
            missingSecretKeyOrTokenText:
                'Tilslut din telefon til internettet, tryk på prøv igen, og vent et minut før du prøver igen.',
        },
        bluetoothDenied: 'Appen kræver adgang til Bluetooth for at forbinde til låsen',
        bluetoothDisabledAndroid:
            'Du bliver nødt til at slå bluetooth til, klik på denne tekst for at åbne indstillinger',
        bluetoothDisabledIOS: 'Du bliver nødt til at aktivere bluetooth manuelt',
        noWeb: 'Låsen kan ikke åbnes via web, du bliver nødt til at downloade appen',
        cancelModal: {
            header: 'Er du sikker på, at du vil annullere reservationen?',
            refundable: 'Du vil få refunderet beløbet for reservationen',
            hours: '{{count}} time',
            hours_plural: '{{count}} timer',
            days: '{{count}} døgn',
            days_plural: '{{count}} døgn',
            nonRefundableHours: 'Du vil ikke få refunderet beløbet, da reservationen starter om mindre end {{hours}}',
            nonRefundableDaysHours:
                'Du vil ikke få refunderet beløbet, da reservationen starter om mindre end {{days}} og {{hours}}',
            nonRefundableDays: 'Du vil ikke få refunderet beløbet, da reservationen starter om mindre end {{days}}',
            nonRefundable: 'Du vil ikke få refunderet beløbet for reservationen',
            back: 'TILBAGE',
            approve: 'ANNULLER',
        },
        okNoCancel: 'OK',
        noCancel:
            'Du kan ikke annullere reservationen, da den allerede er startet. Du kan stadig ændre den til at afslutte tidligere',
        cannotCancel: 'Kan ikke annullere',
        productInfo: 'Se flere detaljer',
        bookingDetails: 'Start og slut',
        starting: 'Reservationen starter',
        ending: 'Reservationen slutter',
        ends: 'Til',
        successCancelHeader: 'Reservationen er blevet annulleret',
        changedClockNegative:
            'Din telefons tid afviger fra vores server. Indstil korrekt tid og genstart appen for at åbne låsen',
        serverAndPhoneOutOfSync:
            'Din telefons tid afviger fra vores server. Indstil korrekt tid og genstart appen for at åbne låsen',
        noDataToCompare:
            'På grund af manglende internetforbindelse kan tidspunktet ikke bekræftes. Tilslut til internettet og prøv igen',
        tryAgain: 'Prøv igen',
        more_options: 'Flere valg',
        pin_code: 'Pin-kode',
        copied_pin_code: 'Pin-kode kopieret',
        copy_pin_explainer: 'Tryk for at kopiere pin-koden',
        reason: 'Anledning',
        quantity: 'Antal - {{count}} stk.',
        previous_visitor: 'Forrige bruger',
        previous_visitor_action: 'Tryk for forklaring',
        previous_visitor_info:
            'Hvis der er noget der ikke fungerer med produktet, kan du kontakte personen der havde produktet før dig, og koordinere hvordan fejlen kan rettes',
        checkOut: 'Tjek ud',
        checkIn: 'Tjek ind',
        booked: 'Reserveret',
        all_day: 'Hele dagen',
        lock: '1 lås',
        lock_plural: '{{count}} låse',
        users: 'Bruger på reservationen',
        users_plural: 'Brugere på reservationen',
        you: 'Dig',
        edit: 'Rediger',
        edit_users: 'Rediger brugere',
        edit_users_subtitle: 'Tilføj eller fjern brugere. De får samme adgang som dig.',
        roommates: 'Forbundet til samme bolig',
        neighbours: 'Naboer',
        noUsers: 'Ingen brugere fundet',
        noUsersToAdd: 'Ingen brugere tilgængelige',
        continue: 'Fortsæt',
        remove_user: 'Tryk for at fjerne bruger',
        users_edited: 'Brugeradgang er opdateret',
    },
    serviceExpanded: {
        nAvailablePlural: 'Antal: {{amount}}',
        shortTerm: 'Timebaseret',
        longTerm: 'Døgnbaseret',
        price_type: {
            rate_per_booking: '',
            rate_per_night: '',
            rate_per_hour: '',
            rate_per_booking_capacity: '',
            rate_per_hour_per_capacity: 'per time',
            rate_per_night_per_capacity: 'per nat',
            rate_per_hour_with_night_price:
                'Mellem {{nighttime_end_hour}}-{{nighttime_start_hour}}: {{daytime_price}} kr, resten af døgnet: {{nighttime_price}} kr',
        },
        price_type_short: {
            rate_per_booking: 'reservation',
            rate_per_night: 'nat',
            rate_per_hour: 'time',
            rate_per_booking_capacity: 'stk.',
            rate_per_hour_per_capacity: 'stk.',
            rate_per_night_per_capacity: 'stk.',
        },
        maxBooking_hour: 'maks en time',
        maxBooking_hour_plural: 'maks {{count}} timer',
        maxBooking_day: 'maks en dag',
        maxBooking_day_plural: 'maks {{count}} dage',
        hours: '1 time',
        hours_plural: '{{count}} timer',
        days: '1 dag',
        days_plural: '{{count}} dage',
        noImage: 'billede ikke valgt',
        name: 'Navn',
        description: 'Beskrivelse',
        reserve: 'Reserver',
        chooseStart: 'Vælg dato for indtjekning',
        chooseEnd: 'Vælg dato for udtjekning',
        update: 'Opdater',
        price: 'Pris',
        kr: 'kr',
        ammount: 'Antal',
        i_want_this_one: 'Denne vil jeg have',
        max_booking_period: 'Bookingperiode',
        who_booked: 'Hvem har reserveret i denne periode?',
        close: 'Luk',
        availableAllDay: 'Tilgængelig hele dagen',
        availableSome: 'Ledige tider',
        notAvailable: 'Ikke tilgængelig',
        availableCount: '{{count}} stk. tilgængelig',
        availableSomeCapacityMultiple: 'Ledige tider ({{min}} - {{max}} stk.)',
        availableSomeCapacitySingle: 'Ledige tider ({{count}} stk.)',
        timeNow: 'Nu',
        n_available: '{{count}} stk.',
        available: 'Tilgængelig',
        checkin: 'Indtjekning',
        checkout: 'Udtjekning',
        selectAmount: 'Hvor mange vil du reservere?',
        reserveTime: 'Vælg tid',
        reserveDays: 'Vælg datoer',
        reserveDescriptionTime:
            'Vælg en eller flere perioder, du vil reservere. Tryk på første periode, derefter sidste periode.',
        reserveDescriptionDays: 'Vælg først dato for indtjekning, vælg derefter dato for udtjekning. Se tider ovenfor.',
        maxBookingStart: 'Du kan reservere for ',
        durationLabel: 'Booket:',
        sumLabel: 'Total:',
        clear: 'NULSTIL',
        today: 'I dag',
        clickOnBusy: 'Du kan trykke på et optaget tidspunkt for at se, hvem der har reserveret',
        maxBookedPerBooking: 'Der kan reserveres maks {{count}} stk. per reservation.',
        skipPaymentExplainer:
            'Det er muligt at reservere dette produkt uden at betale, hvis du ikke har brug for produktet til dig selv',
        skipPayment: 'Fælles reservation',
        paymentReservation: 'Privat reservation',
        isSkippingPayment: 'Tilgængelig for naboer',
        reserveReasonModalHeader: 'Angiv grund',
        reserveReasonModalLabel: 'Grund for reservation',
        reserveReasonModalButton: 'Reserver',
        reserveReasonModalTooLong: 'Grunden må ikke være længere end 255 tegn',
        reserveLicensePlateModalHeader: 'Indtast nummerplade',
        reserveLicensePlateModalText: 'Indtast nummerpladen på køretøjet, der skal parkeres',
        reserveLicensePlateModalButton: 'Bekræft',
        reserveLicensePlateModalDropdown: 'Se flere nummerplader',
        reserveLicensePlateModalManage: 'Rediger gemte nummerplader',
        reserveLicensePlateModalCreateNew: 'Tilføj ny nummerplade',
        reserveLicensePlateModalTooLong: 'Nummerpladen må ikke være længere end 20 tegn',
        showEarlier: 'Tidligere tidspunkter',
        localTime: 'Lokal tid',
        quantityLabel: 'Antal',
        pieces: '{{count}} stk',
        quantity: 'Antal {{quantity}}',
        open_explainer: 'Tryk for at få en forklaring',
        not_confirmed: 'Ikke betalt',
        change_not_confirmed: 'Ændring er ikke betalt',
        timeBasedPricing: '. Bemærk, at prisen kan variere afhængigt af tidspunktet, du reserverer til',
        hasBooking: 'Du har allerede en reservation af {{product}}',
        booking_details: 'Se reservation',
    },
    notifications: {
        title: 'Notifikationer',
        ago: 'siden',
        updateApp: {
            android: 'Play',
            ios: 'App',
            title: 'Ny version af Heime',
            content:
                'Vi anbefaler, at du opdaterer din app for at undgå fejl. For at opdatere, gå til "{{store}} Store" og klik på "Opdater".',
            buttonText: 'Opdater',
        },
        newChatMessage: {
            title: '{{name}} har sendt dig en besked',
            buttonText: 'Gå til chat',
        },
        activityInvite: { buttonText: 'Gå til aktivitet', content: 'Der er oprettet en ny aktivitet' },
        tfHoursBeforeActivity: {
            title: 'Påmindelse om {{name}}',
            content: 'Der er mindre end 24 timer til aktiviteten',
            buttonText: 'Gå til aktivitet',
        },
        tfHoursBeforeSignUpTerm: {
            title: 'Påmindelse om {{name}}',
            content: 'Der er mindre end 24 timer til tilmeldingsfristen udløber',
            buttonText: 'Gå til aktivitet',
        },
        activityInvitedUsers: { buttonText: 'Gå til aktivitet' },
        activityCustomNotify: {
            title: 'Besked relateret til {{name}}',
            content: 'Besked fra arrangør: {{name}}',
            buttonText: 'Gå til aktivitet',
        },
        cancelledActivity: { title: '{{name}} er aflyst' },
        supportCreated: {
            title: 'Ny besked til {{boardNoun}}, fra: {{name}}',
            buttonText: 'Gå til besked',
        },
        supportStatus: {
            title: '{{boardNounCapitalized}} har ændret status på beskeden "{{title}}" til "{{name}}"',
            buttonText: 'Gå til besked',
        },
        userGroupCreated: { buttonText: 'Gå til gruppen' },
        lowBattery: {
            title: 'Lås "{{lockName}}" har lavt batteri ({{percentage}} %)',
            content: 'Låsen tilhører {{productsName}}.',
            contentcable: 'Den kan oplades via et USB-kabel med Micro-B-kontakt.',
            contentcabinet: 'Batteriet er af typen CR123A, kan købes i byggemarked.',
            contentmasterLock5441: 'Den bruger et CR123A-batteri. Batterier fås købt i byggemarked.',
            contentmasterLock4401: 'Den bruger et CR2-batteri. Batterier fås købt i byggemarked.',
            contentmasterLock4400: 'Den bruger et CR2-batteri. Batterier fås købt i byggemarked.',
            contentdanalock: 'Den bruger 4 stk CR123-batterier, og kan købes i byggemarked.',
            contentPost: 'For at opdatere batteristatus, skal du åbne låsen med Heime-appen.',
        },
        newMessageComment: {
            title: 'Ny kommentar på opslaget "{{title}}"',
            buttonText: 'Gå til opslag',
            contentFile: 'En fil',
            contentImage: 'Et billede',
        },
        newSurvey: {
            title: 'Ny undersøgelse offentliggjort: "{{title}}"',
            buttonText: 'Gå til undersøgelsen',
        },
        reminderSurvey: {
            title: 'Påmindelse om undersøgelsen: "{{title}}"',
            buttonText: 'Gå til undersøgelsen',
        },
        unhandledCooperativeUserRegistration: {
            title: 'Der er en ny registrering, der venter på adgang til Heime',
            title_plural: 'Der er {{count}} nye registreringer, der venter på adgang til Heime',
            buttonText: 'Åbn adminpanelet',
        },
        arrangerAdded: {
            title: 'Du er blevet tilføjet som arrangør på "{{title}}"',
            buttonText: 'Gå til aktivitet',
        },
        arrangerRemoved: {
            title: 'Du er blevet fjernet som arrangør på "{{title}}"',
            buttonText: 'Gå til aktivitet',
        },
        activityRateUs: {
            title: 'Tak for deltagelsen, vil du vurdere Heime?',
            content: '',
            buttonText: 'Vurder Heime',
        },
        messageExpired: {
            prolonged: 'Opslaget er blevet forlænget',
            reactivated: 'Opslaget er blevet sat som aktivt',
            month_from_now: 'Opslaget vil blive deaktiveret om en måned',
            prolong_error: 'Kunne ikke forlænge opslaget',
            title_no_date: 'Opslaget er sat som permanent',
            description_no_date: 'Opslaget "{{title}}" udløber aldrig, dette kan ændres ved at redigere opslaget',
            title_expired: 'Dit opslag er ikke aktivt',
            title_expired_author: 'Dit opslag er markeret som inaktivt af {{actor_name}}',
            description_expired: 'Dit opslag "{{title}}" er ikke aktivt. Du kan aktivere det igen',
            title_expires_future: 'Dit opslag vil blive deaktiveret om {{timeString}}',
            title_expires_future_author:
                'Dit opslag er sat til at blive deaktiveret om {{timeString}} af {{actor_name}}',
            description_prolong: 'Dit opslag "{{title}}" vil blive deaktiveret. Du kan forlænge det med 1 måned',
            description_no_prolong: 'Dit opslag "{{title}}" vil blive deaktiveret. Vi giver dig besked, når dette sker',
            seeMessage: 'Gå til opslag',
            prolong: 'Forlæng',
            reactivate: 'Aktiver',
        },
        bookingNotification: {
            buttonText: 'Gå til reservation',
            start_future_title: 'Din reservation af {{name}} starter om {{timeString}}',
            start_past_title: 'Din reservation af {{name}} startede for {{timeString}} siden',
            end_future_title: 'Din reservation af {{name}} slutter om {{timeString}}',
            end_past_title: 'Din reservation af {{name}} sluttede for {{timeString}} siden',
            buttonText_start_procedure: 'Start check-in',
            buttonText_end_procedure: 'Start check-out',
            start_future_procedure_title: '{{name}} er klar til at tjekke ind om {{timeString}}',
            start_past_procedure_title: '{{name}} er klar til check-in',
            end_future_procedure_title: 'Du skal være færdig med check-out {{name}} om {{timeString}}',
        },
        userAddedToBooking: {
            title: '{{actor_name}} har tilføjet dig til reservationen af {{product_name}}',
            description_future: 'Reservationen starter om {{timeString}}',
            description_started_past: 'Reservationen startede for {{timeString}} siden',
            description_ended_past: 'Reservationen blev afsluttet for {{timeString}} siden',
            buttonText: 'Gå til reservation',
        },
        userRemovedFromBooking: {
            title: '{{actor_name}} har fjernet dig fra reservationen af {{product_name}}',
        },
        others: '{{others}} og {{last}}',
        no_notifications_found: 'Du har ingen notifikationer',
    },
    chat: {
        messageModal: {
            header: 'Dette link er fra en anden bruger og er måske ikke sikkert. Vil du stadig besøge det?',
            back: 'NEJ, TILBAGE',
            approve: 'JA, BESØG',
        },
        empty_message: 'Ingen beskeder her endnu. Skriv en for at starte chatten.',
        today: 'I dag',
        add_file: 'Tilføj fil',
        add_image: 'Tilføj billede',
        goToGroup: 'Besøg gruppen',
        shareProducts: 'Del Genstand: ',
        askForHelp: 'Bed om hjælp: ',
        helpRequest: 'Opslag: ',
        starReceived: 'Stjerne modtaget fra: ',
        starSended: 'Stjerne sendt til: ',
        addAll: 'TIL ALLE',
        removeAll: 'NULSTIL',
        search: 'Søg efter nabo',
        onlyYou: 'Kun dig',
        too_many_users_header: 'Rigtig mange brugere i chatten',
        too_many_users_text:
            'Der er meget mange naboer i denne chat; for at undgå mange notifikationer anbefaler vi, at den besked du sender bliver lagt ud som opslag i stedet. Der kan du uploade filer og billeder samt modtage kommentarer fra andre naboer. Du finder opslaget ved at gå tilbage og trykke på knappen i bunden af skærmen.',
        too_many_users_button: 'OK',
        pressInfo: 'Tryk her for info',
        add_attachment: 'Tilføj vedhæftning',
        write_message: 'Skriv chatbesked her',
        send_message: 'Send chatbesked og eventuelt vedhæftning',
        from: 'Fra: {{senderName}}',
        to_profile: 'Gå til afsenders profil',
        deleteMessageSuccess: 'Beskeden blev slettet',
        deleteMessageError: 'Kunne ikke slette beskeden',
        deleteModal: {
            header: 'Er du sikker på, at du vil slette beskeden?',
            back: 'Gå tilbage',
            approve: 'Slet',
        },
        edited: '(Redigeret)',
        deleted_message: 'Slettet besked',
        edit: 'Rediger',
        delete: 'Slet',
        copy: 'Kopier',
        copiedToClipboard: 'Besked kopieret',
        editing_message: 'Rediger besked',
        stop_editing: 'Stop med at redigere besked',
    },
    chatInfo: {
        members: 'Medlemmer',
        media: 'Billeder',
        files: 'Filer',
        no_media_message: 'Ingen billeder her endnu. Billeder sendt i chatten vil blive listet her.',
        no_files_message: 'Ingen filer her endnu. Filer sendt i chatten vil blive listet her.',
        nMembers: '{{count}} medlem',
        nMembers_plural: '{{count}} medlemmer',
        of: '{{current}} af {{length}}',
        admin: 'Du • Admin',
        nonAdmin: 'Admin',
        you: 'Du',
        leaveChat: 'Forlad chat',
        makeAdmin: 'Gør til admin',
        leaveModal: {
            header: 'Er du sikker på, at du vil forlade chatten?',
            back: 'NEJ, GÅ TILBAGE',
            approve: 'JA, FORLAD',
        },
        addMembers: 'Tilføj bruger',
        addMembers_plural: 'Tilføj brugere ({{count}})',
        addMember: 'Tilføj bruger',
        noUsers: 'Ingen brugere fundet',
        noUsersChat: 'Ingen brugere at chatte med',
        noUsersToAdd: 'Ingen brugere at tilføje',
    },
    global: {
        resetApp: 'Genstart appen',
        swipe_down_to_fetch_data: 'Træk ned på skærmen for at hente de nyeste data',
        warning: 'Advarsel',
        are_you_sure_delete_activity: 'Er du sikker på, at du vil slette aktiviteten?',
        yes: 'Ja',
        no: 'Nej',
        cancel: 'Annuller',
        ask_me_later: 'Spørg mig senere',
        permissions: {
            camera_permission: 'Kameraadgang',
            must_have_permission_camera: 'Appen skal have adgang til kameraet',
            camera_settings_permission:
                'For at kunne uploade et billede, skal Heime have adgang til kameraet. Hvis du tidligere valgte nej, kan du ændre dette i telefonens indstillinger',
            bluetoothLocationTitle: 'Adgang til Bluetooth og lokation',
            bluetoothLocationDescription:
                'Adgangene bruges kun til at låse op for låsen. Ved at låse op for låsen kan vi teoretisk set vide, at du er der på åbningstidspunktet.',
        },
        back: 'Tilbage',
        task_failed_header: 'Der opstod en fejl',
        task_failed_description: 'Fejlen er blevet gemt, prøv venligst igen',
        goBack: 'Gå tilbage',
        confirm: 'Bekræft',
        confirmWithLabel: 'Bekræft {{label}}',
        show_more: 'Vis mere',
        show_less: 'Vis mindre',
        optional: ' (Valgfri)',
        cameraPermission: {
            title: 'Kameraadgang',
            message: 'Appen har brug for adgang for at lade dig uploade ønskede billeder',
            neutral: 'Spørg senere',
            positive: 'Ok',
            negative: 'Ikke tillad',
        },
        selectImage: 'Vælg billede',
        and: 'og ',
        xOthers: 'en til',
        xOthers_plural: '{{count}} andre',
        enLang: 'English',
        noLang: 'Norsk',
        daLang: 'Dansk',
        svLang: 'Svenska',
        cancelModal: {
            cancelChanges: 'Er du sikker på, at du vil forkaste ændringerne?',
            cancelCreation: 'Er du sikker på, at du vil forkaste?',
            content: 'Det, du har skrevet, vil ikke blive gemt',
            contentEdited: 'Det, du har ændret, vil ikke blive gemt',
            back: 'NEJ, GÅ TILBAGE',
            confirmCancel: 'JA, FORKAST',
        },
        search: 'Søg',
        comments: 'Kommentarer',
        noComments: 'Ingen kommentarer',
        attachFile: 'Tryk her for at tilføje fil eller billede',
        add_photo: 'Vælg et billede',
        take_photo: 'Tag et billede',
        add_file: 'Vælg en fil',
        choose: 'Vælg',
        crop_image: 'Rediger billede',
        add_facebook: 'Hent profilbillede fra Facebook',
        remove_photo: 'Fjern billede',
        clock: 'kl',
        addImage: 'Tryk her for at tilføje billede',
        networkError_header: 'Netværksfejl',
        networkError_description: 'Kunne ikke sende, prøv igen',
        error_sending_header: 'Der opstod en fejl ved afsendelse',
        error_sending_description: 'Prøv igen',
        error_saving_header: 'Kunne ikke gemme ændringerne',
        error_saving_description: 'Prøv igen',
        tooManyRequests_header: 'Låst ude af serveren',
        tooManyRequests_text: 'Du har lavet for mange anmodninger, vent venligst 5 minutter før du prøver igen',
        noSearchResult: 'Ingen resultater for din søgning',
        close: 'Luk',
        loading: 'Indlæser information',
        more_choices: 'Tryk for flere valg',
        select_language: 'Vælg sprog',
        upload_failed: 'Kunne ikke uploade billede',
        permission_denied: 'Du skal give Heime adgang for at vælge et billede',
        open_settings: 'Åbn indstillinger',
        deleted_user_with_apartment_owner: 'Tidligere ejer af {{apartment}}',
        deleted_user_with_apartment_renting: 'Tidligere lejer af {{apartment}}',
        deleted_user_with_apartment_other: 'Tidligere bruger i {{apartment}}',
        deleted_user_with_apartment_livesWith: 'Tidligere beboer i {{apartment}}',
        deleted_user_no_apartment: 'Fjernet bruger',
        open_admin_error: 'Kunne ikke åbne admin panelet direkte',
        open_admin_error_text:
            'Gå til app.heime.com/admin i din browser for at håndtere anmodningen. Linket er også lagt på din udklipsholder',
        open_url_error_header: 'Kunne ikke åbne linket',
        open_url_error_text: 'Gå til {{url}} i din browser, linket er også lagt på din udklipsholder',
        open_contact_error_header: 'Kunne ikke åbne for dig',
        open_contact_error_text: 'Brug {{url}} til at kontakte, dette er også lagt på din udklipsholder',
        keyboard_done: 'Færdig',
        enter_url: 'Indtast din link her',
        insert_link: 'Indsæt',
        uploading_file: 'Vent til filen er lastet opp',
    },
    globalError: {
        header: 'Der opstod en fejl',
        networkHeader: 'Netværksfejl, tjek din internetforbindelse',
        subText: 'Undskyld, vi har noteret fejlen, prøv venligst igen. Hvis det ikke hjælper, kontakt os.',
        subTextNetwork: '',
        button: 'Prøv igen',
        too_big: '{{name}} kan ikke være så lang',
        title: 'Titel',
        phone: 'Telefonnummer',
    },
    networkAlert: {
        header: 'Ingen internetforbindelse',
        description:
            'Det ser ud til, at du ikke har internet. Heime kræver internet for at fungere. Sørg for, at Wi-Fi eller mobildata er slået til, og prøv igen.',
    },
    updateApp: {
        android: 'Play',
        ios: 'App',
        header: 'Ny version af Heime',
        description_recommended:
            'Vi anbefaler, at du opdaterer din app for at undgå fejl. For at opdatere, gå til "{{store}} Store" og klik derefter på "Opdater".',
        description_force_soon:
            'Hvis du ikke opdaterer, vil du på et tidspunkt blive tvunget til at opdatere, når den gamle version stopper med at virke.',
        description_force:
            'Din version af Heime-appen er så gammel, at den sandsynligvis ikke vil fungere, så du skal opdatere den. For at opdatere, gå til "{{store}} Store" og klik derefter på "Opdater".',
        goToButton: 'Gå til {{store}} Store',
        later: 'Senere',
    },
    onboarding: {
        setupPin: 'Opsæt PIN-kode',
        step: 'Trin {{step}}',
        description: 'Beskriv hvorfor du kan/vil hjælpe:',
        descriptionPlaceholder:
            'Her kan du skrive lidt om, hvad du kan hjælpe med, og hvorfor: F.eks. Jeg har en bil og er tryg ved dyr',
        helpfulNeighbourHeader: 'Hvad kan du hjælpe dine naboer med?',
        helpfulNeighbourDesciptions: 'Tryk på de ting, du kunne tænke dig at hjælpe en nabo med.',
        stepHeader: [
            '',
            'Privatlivspolitik.',
            'Modtag meddelelser.',
            'Bekræft info',
            'Beskriv dig selv',
            'Bliv medlem af en gruppe.',
        ],
        privacyPolicyHeader: 'Privatlivspolitik',
        privacyPolicyDesciptions: 'Læs venligst og godkend vores privatlivspolitik',
        privacyPolicyContent:
            'Heime er en tjenesteudbyder, der arbejder på at skabe gode nabolag. For at gøre dette bruger vi nogle personlige oplysninger om dig.\n\nPrimært bruges de til at fortælle om dig til dine naboer. Du vil altid have mulighed for at redigere dette samt bestemme, hvor meget dine naboer kan se.\n\nHeime sælger ikke dine data.',
        privacyPolicyAgree: 'Før du begynder at bruge Heime, skal du godkende',
        privacyPolicyLowerCase: 'Heimes privatlivspolitik',
        privacyPolicyAgreePost: 'Tryk videre for at godkende',
        continue: 'Fortsæt',
        notificationHeader: 'Modtag notifikationer',
        notificationDesciptions:
            'Ved at aktivere notifikationer, vil du modtage beskeder om vigtige meddelelser fra {{boardNoun}}, nye aktiviteter og chatbeskeder.',
        turnOnNotification: 'TILLAD NOTIFIKATIONER',
        changeSettings: 'ÆNDRE INDSTILLINGER',
        profileCreation: 'Fortæl dine naboer, hvem du er',
        pleaseAllowNotification: 'Venligst aktiver notifikationer for Heime i dine systemindstillinger',
        skipForNow: 'SPRING OVER',
        downloadAccountPhoto: 'Upload profilbillede',
        photoAdded: 'Billede uploadet',
        enterFirstName: 'Indtast fornavn',
        firstName: 'Fornavn',
        enterSurname: 'Indtast efternavn',
        lastName: 'Efternavn',
        enterEmail: 'Indtast e-mail',
        email: 'E-mail',
        phone: 'Telefon',
        birthday: 'Fødselsdag',
        hideFromProfile: 'Vis ikke til naboer',
        aboutMePlaceHolder:
            'F.eks: Arbejder i butik. Kan lide at være ude, men der er ikke meget, der slår et uldtæppe og et glas vin. Kan et korttrick.',
        aboutMe: 'Om mig',
        lastName_error: 'Du skal indtaste efternavn',
        firstName_error: 'Du skal indtaste fornavn',
        phone_error: 'Du skal indtaste telefonnummer',
        invalidEmail: 'Den e-mailadresse, du indtastede, er ikke gyldig',
        email_error: 'Du skal indtaste en e-mail',
        createPin: 'Opret din egen kode',
        repeatPin: 'Gentag koden',
        enterPinDesciption: 'For hurtigere login kan du oprette din egen kode i Heime. Indtast den ønskede kode',
        repeatPinDesciption: 'Indtast den samme kode igen for at bekræfte den',
        phone_length: 'Telefonnummeret kan ikke være så langt',

        profile_confirm_title: 'Bekræft dine kontaktoplysninger',
        profile_confirm_subtitle:
            'Oplysningerne bruges til at vise dine kontaktoplysninger til {{boardNoun}}. Du kan vælge, om dette også skal vises til dine naboer.',
        createProfile: 'Opret profil',
        upload_photo: 'Vælg et billede',
        upload_facebook: 'Brug billede fra Facebook',
        add_photo: 'Giv postkassen et ansigt, så kan du være med til at lære dine naboer at kende',
        describe_yourself: 'Fortæl lidt om dig selv, tekst og billede vises kun til naboer i dit boligfællesskab',
        upload_new_photo: 'Upload nyt billede',
    },
    group: {
        header: 'Grupper',
        title: 'Bliv medlem af nogle grupper',
        subTitle:
            'For nogle aktiviteter vil kun gruppemedlemmer modtage notifikationer. Meld dig ind i de grupper, du synes virker interessante',
        join: 'Bliv med',
        members: { plural: 'Medlemmer', singular: 'Ét medlem' },
        continue: 'Fortsæt',
        skip: 'Spring over',
        create_group: 'Opret gruppe',
        my_groups: 'Mine grupper',
        all_groups: 'Alle grupper',
        noGroups: {
            own: 'Du er ikke medlem af nogen grupper',
            all: 'Der findes ingen grupper',
            allWithRequest: 'Der findes ingen grupper, du bør oprette en!',
        },
        adminModal: {
            header: 'Er du sikker på, at du vil udnævne «{{user}}» som administrator?',
            yes: 'Ja',
            no: 'Nej',
        },
        groupSelected: {
            title: 'Grupper',
            open_chat: 'Åbn chat',
            create_activity: 'Opret aktivitet',
            join_group: 'Bliv medlem af gruppe',
            admin: 'Administrator',
            member: 'Medlem',
            members: 'Medlemmer',
            you: 'Dig',
            set_admin: 'Sæt {{name}} som administrator',
            leave_group: 'Forlad gruppen',
            add_image: 'Tilføj billede',
            add_file: 'Upload et dokument',
            images: 'Billeder',
            no_images: 'Der er ikke delt nogen billeder i denne gruppe endnu.',
            files: 'Filer',
            no_files: 'Der er ikke delt nogen filer i denne gruppe endnu.',
            add_files: 'Upload filer',
            files_description:
                'Billeder og filer, der uploades her, vil også blive sendt i chatten. De vil kun være synlige for gruppens medlemmer',
            upload: 'Upload',
        },
        createGroup: {
            title_create: 'Opret gruppe',
            title_edit: 'Rediger gruppeinformation',
            button_create: 'Opret gruppe',
            button_edit: 'Gem ændringer',
            image: 'Billede',
            title: 'Titel',
            description: 'Beskrivelse',
            created: 'Gruppe oprettet',
            updated: 'Gruppe opdateret',
            updateError: 'Der opstod en fejl under redigering',
            createError: 'Der opstod en fejl under oprettelsen',
            alertError: 'Der opstod en fejl under afsendelse af notifikation',
            fillAll: 'Udfyld venligst alle felterne',
        },
    },
    sharingSelected: {
        editItem: 'Rediger',
        deleteItem: 'Fjern',
        prolong: 'Forlæng aktiv periode',
        reactivate: 'Reaktiver opslaget',
        deactivate: 'Deaktiver opslaget',
        deactivated: 'Opslaget er deaktiveret',
        deactivated_description:
            'Opslaget vises ikke længere for naboer i opslaglisten. Men vil stadig generere notifikationer om nye kommentarer. Og kan stadig søges efter.',
        deactivated_failed: 'Kunne ikke deaktivere opslaget',
        error: 'Der opstod en fejl',
        messageTheBoard: 'Kontakt {{boardNoun}}',
        deleteModal: {
            header: 'Er du sikker på, at du vil slette dette?',
            back: 'NEJ, TILBAGE',
            approve: 'JA, SLET',
        },
        messageType: {
            Helpers: 'Hjælpsom nabo',
            Products: 'Genstand',
            Requests: 'Opslag',
            ByBoard: 'Opslag fra {{boardNoun}}',
        },
        productDetails: {
            button: 'Kontakt naboen',
        },
        requestDetails: {
            titleModal: 'Skriv en chatbesked til naboen',
            button: 'Kontakt naboen',
            expired: 'Opslaget er udløbet og ikke længere aktivt',
            expiredDescription:
                'Opretteren af opslaget har mulighed for at forlænge opslagets varighed, men alle interesserede vil stadig modtage notifikationer om nye kommentarer.',
        },
        contactNeighbor: {
            title: 'Kontakt naboen',
            button: 'Send besked',
            messageLabel: 'Besked',
            placeHolderMessage: 'Skriv besked',
        },
        helperProfile: {
            stars: '⭐️ {{count}} stjerne',
            stars_plural: '⭐️ {{count}} stjerner',
            no_star: '⭐️ Giv stjerne',
            button: 'Bed {{name}} om hjælp',
            sendStar: 'Send en stjerne',
            willBeAvailable: 'Vil være tilgængelig om {{count}} time',
            willBeAvailable_plural: 'Vil være tilgængelig om {{count}} timer',
            howCanIHelp: 'Hvordan kan jeg hjælpe',
            feedback: 'Feedback',
            noFeedback: 'Ingen feedback fundet',
            comment: 'Kommentar',
        },
        attachments: 'Vedhæftede filer',
        deleteCommentSuccess: 'Kommentaren blev slettet',
        deleteCommentError: 'Kunne ikke slette kommentaren',
        deleteCommentModal: {
            header: 'Er du sikker på, at du vil slette kommentaren?',
            back: 'Gå tilbage',
            approve: 'Slet',
        },
        edited: '(Redigeret) ',
        deleted_comment: 'Slettet kommentar',
        edit: 'Rediger',
        delete: 'Slet',
        copy: 'Kopier',
        copiedToClipboard: 'Kommentar kopieret',
        editing_comment: 'Rediger kommentar',
        stop_editing: 'Stop redigering af kommentar',
    },
    userMenu: {
        terms: {
            title: 'Salgsbetingelser',
            noAccounts: 'Ingen betalingskonti er tilknyttet dit nabolag',
            orgNum: 'Org nr.',
        },
        privacy: 'Persondatapolitik',
        language: 'Sprog',
        deleteAccount: 'Slett din bruger',
        deleteAccountModal: {
            header: 'Er du sikker på at du vil slette din bruger fra Heime?',
            content_danger: 'Denne handling kan ikke fortrydes',
            content_warning:
                'Det anbefales, at du i stedet tilføjer nye beboere, lejere eller ejere til din bolig. Og derefter, når de logger ind, kan de fjerne dig fra boligen.',
            content_consequenses:
                'Sletning af din bruger vil fjerne alle adgange til alle nabolag. Du vil ikke længere kunne logge ind på Heime eller modtage nødvendige notifikationer eller information.',
            back: 'Annuller',
            approve: 'Slet permanent',
            deleted: 'Din bruger er slettet',
        },
    },
    languageSelectModal: {
        header: 'Vælg dit sprog',
        cancel: 'Annuller',
        submit: 'Gem',
    },
    notFoundErrorScreen: {
        Activity: {
            header: 'Kunne ikke finde aktiviteten',
            subtext: 'Den er enten blevet aflyst/slettet, eller også er der en fejl hos os',
            button: 'Gå tilbage',
        },
        Group: {
            header: 'Kunne ikke finde gruppen',
            subtext: 'Den er enten blevet slettet, eller også er der en fejl hos os',
            button: 'Gå tilbage',
        },
        Chat: {
            header: 'Kunne ikke finde chatten',
            subtext: 'Du er måske ikke længere med i den, eller også er der en fejl hos os',
            button: 'Gå tilbage',
        },
        Product: {
            header: 'Kunne ikke finde produktet',
            subtext: 'Det er enten blevet slettet, eller også er der en fejl hos os',
            button: 'Gå tilbage',
        },
        Profile: {
            header: 'Kunne ikke finde brugeren',
            subtext: 'Den er enten blevet slettet, eller også er der en fejl hos os',
            button: 'Gå tilbage',
        },
        Reservation: {
            header: 'Kunne ikke finde reservationen',
            subtext: 'Den er enten blevet annulleret, er i fortiden, eller også er der en fejl hos os',
            button: 'Gå tilbage',
        },
        Support: {
            header: 'Kunne ikke finde beskeden',
            subtext: 'Den er enten blevet slettet, eller også er der en fejl hos os',
            button: 'Gå tilbage',
        },
        MemberBenefit: {
            header: 'Kunne ikke finde aftalen',
            subtext: 'Den er blevet fjernet',
            button: 'Gå tilbage',
        },
        Message: {
            header: 'Kunne ikke finde noget',
            subtext: 'Den er enten udløbet eller blevet fjernet',
            button: 'Gå tilbage',
        },
        Survey: {
            header: 'Kunne ikke finde undersøgelsen',
            subtext: 'Den er enten blevet lukket eller fjernet',
            button: 'Gå tilbage',
        },
    },
    memberBenefits: {
        empty: 'Der er ikke registreret nogen beboeraftaler',
        noResult: 'Ingen beboeraftaler fundet med dette navn',
        url: 'Hjemmeside',
    },
    memberBenefit: {
        title: 'Beboeraftale',
        promo_code: 'Rabattkode',
        visit_website: 'Besøg hjemmesiden',
        showProof: 'Vis medlemsbevis',
        memberProof: 'Medlemsbevis',
        copied: 'Rabattkode kopieret',
    },
    surveys: {
        title: 'Spørgeundersøgelser',
        empty: 'Der er ikke registreret nogen spørgeundersøgelser',
        reciptientApartment:
            'Undersøgelsen kan kun besvares én gang per bolig. Eventuelle brugere tilknyttet samme bolig vil kunne se og redigere dine svar',
        reciptientUsers: 'Undersøgelsen kan kun besvares én gang per bruger.',
        questions: '{{questions}} spørgsmål ({{requiredQuestions}} påkrævet)',
        addResponse: 'Svar på undersøgelsen',
        continueResponding: 'Fortsæt med at svare',
        question: 'Spørgsmål',
        nextQuestion: 'Gå videre',
        noMoreQuestions:
            "Du har svaret på alle spørgsmålene. Du kan nu enten gennemgå dine svar eller sende undersøgelsen ind. Du kan også gå tilbage og ændre dine svar. For at sende undersøgelsen ind, tryk på 'Send svar'",
        finishLater: 'Afslut senere',
        sendSurvey: 'Send svar',
        answerError: 'Der opstod en fejl under lagring af svaret',
        started: 'Der er allerede startet et svar, du kan fortsætte på det.',
        noAnswer: 'Ingen svar',
        sentIn: 'Dine svar er allerede sendt ind',
        selectApartment: 'Vælg bolig, som du ønsker at svare på undersøgelsen for',
        noApartment:
            'Du er ikke tilknyttet nogen bolig, og kan derfor ikke svare på denne undersøgelse. Hvis dette er en fejl, kan du kontakte {{boardNoun}} eller Heime',
        archived:
            'Undersøgelsen er arkiveret og kan ikke besvares længere. Hvis du har sendt svar, kan du se dem under',
    },
    plusMenu: {
        recommended: 'Anbefalet',
        other: 'Andre',
        activityNew: {
            title: 'Opret aktivitet',
            description:
                'Samle naboer og gør noget sammen. Nogle forslag er kaffemøder, fællesarbejde eller grillfest.',
        },
        groupNew: {
            title: 'Lav en gruppe',
            description:
                'Find din interesse eller del dine erfaringer i grupper. Her får I egen chat til diskussion, deling og planlægning af aktiviteter.',
        },
        noticeNew: {
            title: 'Del et opslag',
            description: 'Opslag kan være alt fra information til naboer, eller et spørgsmål du har brug for svar på.',
        },
        noticeByBoard: {
            title: 'Del et opslag fra {{boardNoun}}',
            description: 'Marker et opslag som værende fra {{boardNoun}}.',
        },
        helpfulNeighbour: {
            title: 'Bliv en hjælpsom nabo',
            description:
                'Giv et uforpligtende tilbud om at hjælpe naboer. Nogle forslag er hundepasning, babysitning, IT-hjælp eller håndværk.',
        },
        sharingNew: {
            title: 'Del en genstand',
            description:
                'Vi har alle noget i kælderen, som kan glæde andre. Ved at tage et billede og lægge det op, kan naboer kontakte dig for at låne det.',
        },
        reportsNew: {
            title: 'Send en besked til {{boardNoun}}',
            description:
                'Her kan du sende beskeder til {{boardNoun}}, og de bliver varslet. Du og de vil kunne kommentere på beskeden, og {{boardNoun}} kan opdatere beskedens status.',
        },
        reservations: {
            title: 'Lav en reservation',
            description: 'Ønsker du at reservere noget, kan du gøre det her.',
        },
        chatNew: {
            title: 'Chat privat med naboen',
            description:
                'Her kan du selv vælge naboer, du vil tale med, og dele billeder. Beskederne der sendes her, er helt private.',
        },
    },
    smallButtonNav: {
        board: 'Kontakter',
        documents: 'Filer',
        myHouse: 'Min bolig',
        neighbours: 'Naboer',
        groups: 'Grupper',
        myProfile: 'Min profil',
        memberBenefits: 'Beboer-aftaler',
        reports: 'Beskeder til {{boardNoun}}',
        surveys: 'Spørgeskemaer',
        chat: 'Chat',
    },
    typeSpecific: {
        joint_ownership: {
            coopTypeDefiniteArticle: 'boligselskabet',
            boardNounDefiniteArticle: 'bestyrelsen',
        },
        rental_project: {
            coopTypeDefiniteArticle: 'udlejningsprojektet',
            boardNounDefiniteArticle: 'udlejer',
        },
        suburban_housing: {
            coopTypeDefiniteArticle: 'velforeningen',
            boardNounDefiniteArticle: 'bestyrelsen',
        },
    },
    managePlates: {
        header: 'Rediger gemte registreringsnumre',
        save: 'Gem',
        noPlates: 'Ingen registreringsnumre gemt',
        deleted_confirmation: 'Registreringsnummeret er slettet',
        plate: 'Registreringsnummer',
        name: 'Navn',
        edit: 'Rediger',
        delete: 'Slet',
        undo: 'Fortryd',
    },
    rateUs: {
        header: 'Vurder Heime',
        title: 'Vurder Heime',
        subtitle: 'Giv os en vurdering, det hjælper os med at blive bedre. Vælg mellem 1-5 stjerner',
        cancel: 'Senere',
        rate: 'Vurder nu',
        submit: 'Send ind',
        goToStore_ios: 'Gå til App Store',
        goToStore_android: 'Gå til Play Store',
        feedback_title: 'Giv os din feedback',
        feedback_subtitle: 'Hvad kan vi gøre bedre? Vi sætter pris på din feedback',
        feedback_label: 'Feedback',
        feedback_placeholder: 'Skriv din feedback her',
        feedback_success: 'Tak for din feedback',
    },
    procedure: {
        check_in_start: 'Check-in',
        check_out_start: 'Check-out',
        start_check_in_title: '{{name}} er sat op med en check-in proces',
        start_check_out_title: '{{name}} er sat op med en check-out proces',
        start_procedure_explainer:
            'Det er obligatorisk og består af {{steps}} trin. Dette gøres for at sikre ansvaret mellem dem, der har brugt produktet. Du skal have en internetforbindelse gennem hele processen',
        start_procedure_check_out_explainer: 'Du skal gennemføre check-out processen, før reservationen er færdig',
        start_procedure_check_out_warning:
            'Når check-out er gennemført, vil du ikke længere have adgang til reservationen.',
        start_check_in_action: 'Start check-in',
        start_check_out_action: 'Start check-out',
        later: 'Senere',
        continue: 'Fortsæt',
        skip: 'Spring over',
        check_in_header: 'Check-in  - {{index}} af {{steps}}',
        check_out_header: 'Check-out - {{index}} af {{steps}}',
        completion_error: 'Kunne ikke gennemføre check-in. Prøv igen.',
        answer_error: 'Kunne ikke gemme dit svar. Prøv igen.',
        approve_cleaning_header: 'Godkend tidligere reservation',
        approve_cleaning_text:
            'Det er kun nu, du har mulighed for at godkende den tidligere reservation. Hvis du ikke godkender nu, vil det blive noteret. Du kan efter check-in finde kontaktoplysningerne til den, der reserverede før dig. \n Ved godkendelse vil du være ansvarlig for oprydning og rengøring efter din reservation.',
        approve_cleaning_disapproved:
            'Det er noteret, at du ikke godkender rengøringen, der har været. Det anbefales, at du tager kontakt med den, der reserverede før dig, og aftaler en løsning. \nFor at se hvem det var, afslutter du check-in processen og ser under "mine reservationer". Det kan også være en god idé at tage nogle billeder som dokumentation.',
        checklist_header: 'Bekræft venligst, at alle punkterne er gennemført',
        information_header: 'Bekræft venligst, at du har læst informationen nedenfor',
        open_door_check_in_masterLock5441: 'Åbn boksen og tag nøglen',
        open_door_check_in_other: 'Åbn låsen',
        open_door_check_out_open_to_close: 'Åbn låsen, og bekræft derefter, at den er lukket',
        open_door_check_out_danalock_relay: 'Åbn låsen',
        open_door_check_out_masterLock5441: 'Åbn boksen og læg nøglen tilbage',
        open_door_check_out_danalock: 'Luk låsen',
        open_door_check_in_permission_text_both:
            'Heime har brug for adgang til Bluetooth og din lokation for at åbne låsen. Tryk på "Lås op" nedenfor for at give adgang og låse op.',
        open_door_check_out_permission_text_both:
            'Heime har brug for adgang til Bluetooth og din lokation for at åbne og lukke låsen. Tryk på "Lås op" nedenfor for at give adgang og låse op.',
        open_door_check_in_unlock: 'Lås op',
        open_door_check_in_permission_text_both_settings:
            'Heime har brug for adgang til Bluetooth og din lokation for at åbne låsen. Tryk nedenfor for at ændre indstillingerne',
        open_door_check_out_permission_text_both_settings:
            'Heime har brug for adgang til Bluetooth og din lokation for at åbne / lukke låsen. Tryk nedenfor for at ændre indstillingerne',
        open_door_open_settings: 'Ændr indstillinger',
        open_door_check_in_permission_text_ble:
            'Heime har brug for adgang til Bluetooth for at åbne låsen. Tryk nedenfor for at ændre indstillingerne',
        open_door_check_out_permission_text_ble:
            'Heime har brug for adgang til Bluetooth for at åbne / lukke låsen. Tryk nedenfor for at ændre indstillingerne',
        open_door_check_in_permission_text_location:
            'Heime har brug for adgang til din lokation for at åbne låsen. Tryk nedenfor for at ændre indstillingerne',
        open_door_check_out_permission_text_location:
            'Heime har brug for adgang til lokation for at åbne / lukke låsen. Tryk nedenfor for at ændre indstillingerne',
        open_door_bluetooth_disabled: 'Bluetooth er slået fra. Tryk nedenfor for at tænde Bluetooth',
        open_door_enable_bluetooth: 'Tænd Bluetooth',
        open_door_check_in_select_lock: 'Vælg lås, der skal åbnes',
        open_door_check_out_select_lock: 'Vælg lås, der skal åbnes / lukkes',
        open_door_check_in_open_lock: 'Åbn låsen',
        open_door_check_in_unlock_press: 'Tryk på knappen nedenfor for at låse op',
        open_door_check_in_get_key_press: 'For at få nøglen, lås op for nøgleboksen',
        open_door_check_out_unlock_press: 'Tryk på knappen nedenfor for at låse op',
        open_door_check_out_open_lock: 'Åbn låsen',
        open_door_check_out_lock_press: 'Tryk på knappen nedenfor for at låse',
        open_door_check_out_close_lock: 'Lås',
        open_door_check_out_return_key_press: 'For at lægge nøglen tilbage, tryk på knappen nedenfor',
        open_door_not_supported: 'For at åbne døren',
        pictures_not_supported: 'For at tage billeder',
        pictures_give_camera_access: 'Giv adgang',
        pictures_open_settings: 'Åbn indstillinger',
        pictures_camera_access: 'Heime har brug for adgang til kameraet',
        pictures_camera_access_denied: 'Heime har ikke adgang til dit kamera',
        pictures_camera_access_explanation:
            "I dette trin skal du tage billeder af produktet. Vi har brug for adgang til dit kamera. Tryk 'Giv adgang' og derefter 'Tillad'",
        pictures_camera_access_denied_explanation:
            'For at gennemføre dette trin har vi brug for adgang til dit kamera. Gå til telefonens indstillinger og giv Heime adgang til kameraet',
        pictures_flash_off: 'Slå blitzen fra',
        pictures_flash_on: 'Tænd for blitz',
        pictures_take_picture: 'Tag billede',
        pictures_no_camera: 'Det ser ud til, at denne enhed ikke har et kamera',
        pictures_uploading: 'Uploader billede',
        pictures_approve_or_retake: 'Godkend billede eller tag et nyt',
        pictures_approve: 'Godkend',
        pictures_n_taken: 'Billeder taget',
        skip_confirm: 'Er du sikker på, at du vil springe over?',
        skip_confirm_text:
            'Ved at springe over fraskriver du dig eventuelt ansvar den forrige bruger havde, og du er ansvarlig for, hvordan produktet ser ud efter reservationen',
        skip_confirm_yes: 'Ja, spring over',
        skip_confirm_no: 'Nej, gå tilbage',
        not_supported: 'Denne funktion understøttes ikke her',
        not_supported_use_mobile: 'venligst brug mobilappen',
        reservation_ends: 'Reservationen slutter om {{time}}',
        reservation_ends_explainer: 'Du vil ikke kunne gennemføre efter dette tidspunkt',
        reservation_ended: 'Reservationen er afsluttet',
        reservation_ended_explainer:
            'Det betyder, at du ikke får mulighed for at gennemføre processen. For at få fornyet adgang, skal du reservere på ny eller kontakte den, der har reserveret efter dig.',
        go_back: 'Gå tilbage',
        completion_success_check_in: 'Du er checket ind',
        completion_success_text_check_in: 'Dine svar er noteret, og du kan tage produktet i brug',
        completion_success_check_out: 'Du er checket ud',
        completion_success_text_check_out: 'Dine svar er noteret, og du kan nu betragte reservationen som afsluttet',
    },
    countryCode: {
        af: 'Afghanistan',
        eg: 'Egypten',
        ax: 'Åland',
        al: 'Albanien',
        dz: 'Algeriet',
        as: 'Amerikansk Samoa',
        vi: 'De amerikanske Jomfruøer',
        um: 'USAs ydre småøer',
        ad: 'Andorra',
        ao: 'Angola',
        ai: 'Anguilla',
        aq: 'Antarktis',
        ag: 'Antigua og Barbuda',
        gq: 'Ækvatorial-Guinea',
        ar: 'Argentina',
        am: 'Armenien',
        aw: 'Aruba',
        az: 'Aserbajdsjan',
        et: 'Etiopien',
        au: 'Australien',
        bs: 'Bahamas',
        bh: 'Bahrain',
        bd: 'Bangladesh',
        bb: 'Barbados',
        by: 'Hviderusland',
        be: 'Belgien',
        bz: 'Belize',
        bj: 'Benin',
        bm: 'Bermuda',
        bv: 'Bouvetøya',
        bt: 'Bhutan',
        bo: 'Bolivia',
        bq: 'Bonaire, Sint Eustatius og Saba',
        ba: 'Bosnien-Hercegovina',
        bw: 'Botswana',
        br: 'Brasilien',
        vg: 'De britiske Jomfruøer',
        io: 'Det britiske territorium i Indiske Ocean',
        bn: 'Brunei',
        bg: 'Bulgarien',
        bf: 'Burkina Faso',
        bi: 'Burundi',
        cv: 'Kap Verde',
        cl: 'Chile',
        cn: 'Kina',
        ck: 'Cookøerne',
        cr: 'Costa Rica',
        ci: 'Elfenbenskysten',
        cw: 'Curaçao',
        dk: 'Danmark',
        de: 'Tyskland',
        dm: 'Dominica',
        do: 'Den Dominikanske Republik',
        dj: 'Djibouti',
        ec: 'Ecuador',
        sv: 'El Salvador',
        er: 'Eritrea',
        ee: 'Estland',
        fk: 'Falklandsøerne',
        fo: 'Færøerne',
        fj: 'Fiji',
        fi: 'Finland',
        fr: 'Frankrig',
        gf: 'Fransk Guyana',
        pf: 'Fransk Polynesien',
        tf: 'De franske sydterritorier',
        ga: 'Gabon',
        gm: 'Gambia',
        ge: 'Georgien',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gd: 'Grenada',
        gr: 'Grækenland',
        gl: 'Grønland',
        gp: 'Guadeloupe',
        gu: 'Guam',
        gt: 'Guatemala',
        gg: 'Guernsey',
        gn: 'Guinea',
        gw: 'Guinea-Bissau',
        gy: 'Guyana',
        ht: 'Haiti',
        hm: 'Heard Island og McDonaldøyene',
        hn: 'Honduras',
        in: 'Indien',
        id: 'Indonesien',
        iq: 'Irak',
        ir: 'Iran',
        ie: 'Irland',
        is: 'Island',
        im: 'Man',
        il: 'Israel',
        it: 'Italien',
        jm: 'Jamaica',
        jp: 'Japan',
        ye: 'Yemen',
        je: 'Jersey',
        jo: 'Jordan',
        ky: 'Caymanøerne',
        kh: 'Kambodja',
        cm: 'Kamerun',
        ca: 'Canada',
        kz: 'Kasakhstan',
        qa: 'Qatar',
        ke: 'Kenya',
        kg: 'Kirgisistan',
        ki: 'Kiribati',
        cc: 'Kokosøerne',
        co: 'Colombia',
        km: 'Komorerne',
        cg: 'Kongo-Brazzaville',
        cd: 'Kongo-Kinshasa',
        xk: 'Kosovo',
        hr: 'Kroatien',
        cu: 'Cuba',
        kw: 'Kuwait',
        la: 'Laos',
        ls: 'Lesotho',
        lv: 'Letland',
        lb: 'Libanon',
        lr: 'Liberia',
        ly: 'Libyen',
        li: 'Liechtenstein',
        lt: 'Litauen',
        lu: 'Luxembourg',
        mg: 'Madagaskar',
        mw: 'Malawi',
        my: 'Malaysia',
        mv: 'Maldiverne',
        ml: 'Mali',
        mt: 'Malta',
        ma: 'Marokko',
        mh: 'Marshalløerne',
        mq: 'Martinique',
        mr: 'Mauritanien',
        mu: 'Mauritius',
        yt: 'Mayotte',
        mx: 'Mexico',
        fm: 'Mikronesien',
        mc: 'Monaco',
        mn: 'Mongoliet',
        me: 'Montenegro',
        ms: 'Montserrat',
        mz: 'Mosambik',
        mm: 'Myanmar (Burma)',
        na: 'Namibia',
        nr: 'Nauru',
        np: 'Nepal',
        nc: 'Ny-Caledonia',
        nz: 'New Zealand',
        ni: 'Nicaragua',
        nl: 'Nederland',
        ne: 'Niger',
        ng: 'Nigeria',
        nu: 'Niue',
        kp: 'Nord-Korea',
        mp: 'Nord-Marianene',
        mk: 'Nord-Makedonia',
        nf: 'Norfolkøya',
        no: 'Norge',
        om: 'Oman',
        at: 'Østerrike',
        pk: 'Pakistan',
        ps: 'De palestinske territoriene',
        pw: 'Palau',
        pa: 'Panama',
        pg: 'Papua Ny-Guinea',
        py: 'Paraguay',
        pe: 'Peru',
        ph: 'Filippinene',
        pn: 'Pitcairnøyene',
        pl: 'Polen',
        pt: 'Portugal',
        pr: 'Puerto Rico',
        md: 'Moldova',
        re: 'Réunion',
        rw: 'Rwanda',
        ro: 'Romania',
        ru: 'Russland',
        sb: 'Salomonøyene',
        zm: 'Zambia',
        ws: 'Samoa',
        sm: 'San Marino',
        st: 'São Tomé og Príncipe',
        sa: 'Saudi-Arabia',
        se: 'Sverige',
        ch: 'Sveits',
        sn: 'Senegal',
        rs: 'Serbia',
        sc: 'Seychellene',
        sl: 'Sierra Leone',
        zw: 'Zimbabwe',
        sg: 'Singapore',
        sx: 'Sint Maarten',
        sk: 'Slovakia',
        si: 'Slovenia',
        so: 'Somalia',
        hk: 'Hongkong SAR Kina',
        mo: 'Macao SAR Kina',
        es: 'Spania',
        sj: 'Svalbard og Jan Mayen',
        lk: 'Sri Lanka',
        bl: 'Saint-Barthélemy',
        sh: 'St. Helena',
        kn: 'Saint Kitts og Nevis',
        lc: 'Saint Lucia',
        mf: 'Saint-Martin',
        pm: 'Saint-Pierre og Miquelon',
        vc: 'Saint Vincent og Grenadinene',
        za: 'Sør-Afrika',
        sd: 'Sudan',
        gs: 'Sør-Georgia og Sør-Sandwichøyene',
        kr: 'Sør-Korea',
        ss: 'Sør-Sudan',
        sr: 'Surinam',
        sz: 'Eswatini',
        sy: 'Syria',
        tj: 'Tadsjikistan',
        tw: 'Taiwan',
        tz: 'Tanzania',
        th: 'Thailand',
        tl: 'Øst-Timor',
        tg: 'Togo',
        tk: 'Tokelau',
        to: 'Tonga',
        tt: 'Trinidad og Tobago',
        td: 'Tsjad',
        cz: 'Tsjekkia',
        tn: 'Tunisia',
        tr: 'Tyrkia',
        tm: 'Turkmenistan',
        tc: 'Turks- og Caicosøyene',
        tv: 'Tuvalu',
        ug: 'Uganda',
        ua: 'Ukraina',
        hu: 'Ungarn',
        uy: 'Uruguay',
        uz: 'Usbekistan',
        vu: 'Vanuatu',
        va: 'Vatikanstaten',
        ve: 'Venezuela',
        ae: 'De forente arabiske emirater',
        us: 'USA',
        gb: 'Storbritannia',
        vn: 'Vietnam',
        wf: 'Wallis og Futuna',
        cx: 'Christmasøya',
        eh: 'Vest-Sahara',
        cf: 'Den sentralafrikanske republikk',
        cy: 'Kypros',
        ac: 'Ascension',
        ta: 'Tristan da Cunha',
    },
};
