export default {
    enterPhone: {
        title: 'Enter your phone number',
        subText: 'We will send you an sms if you have not set your own pin in Heime',
        inputPlaceholder: 'Enter your phone number',
        errorPhoneNotFound:
            'Sorry, the phone number entered is not connected to any neighborhood in Heime. Please contact us',
        errorGeneric: 'Sorry an error occurred, try again',
        errorNoNetwork: 'Network error, do you have an internet connection?',
        button: 'Continue',
        repeatNumber: 'Repeat phone number',
        repeatNumberSubText:
            "We could not find your phone number in our system. Please repeat it to confirm that '{{enteredNumber}}' is your phone number.",
    },
    enterPin: {
        back: 'BACK',
        titlePin: 'Please enter your Heime PIN code',
        titleSMS: 'Enter the code you received on sms',
        subTextPin: 'Forgot pin code?',
        subTextSMS: 'Not received sms?',
        sendMeSms: 'Send me sms',
        clickableTextSMS: 'Send again',
        wrongCodeSentSMS: 'You entered the wrong code several times, we have sent you an SMS with a new code.',
        wrongCode: 'You entered the wrong code, please try again.',
        error: 'An error occurred',
        button: 'Continue',
        noNetwork: 'Network error, do you have an internet connection?',
    },
    selectNumber: {
        title: 'You entered two different phone numbers, select the correct one',
        select: 'Select',
    },
    confirmUser: {
        title: 'Do you live or own an apartment in a Heime neighborhood?',
        subTitle: "If you should have access to Heime, press 'confirm'",
        negative: 'No',
        positive: 'Confirm',
        modalTitle: 'Contact us',
        modalText:
            "Only users connected to an Heime neighborhood, get access to Heime. If you're interested in getting started with Heime, feel free to reach out to us through any of the channels listed below.",
    },
    createRegistration: {
        title: 'Enter your information',
        subtitle:
            'We need this information about you to be able to connect you to your neighborhood, and to be able to verify that you should have access. By submitting this information you agree that we store and process it.',
        firstNameLabel: 'First name',
        lastNameLabel: 'Last name',
        phoneLabel: 'Phone number',
        emailLabel: 'Email',
        projectLabel: 'Neighborhood',
        apartmentLabel: 'Address / home identifier',
        send: 'Send',
        project_error: 'You must identify the neighborhood',
        apartment_error: 'You must identify the housing unit',
        projectPlaceholder: 'Name of the selling project, borettslag, sameie or the address',
        apartmentPlaceholder: 'Street name house number h-number',
        createError: 'Were not able to create the registration',
        createErrorText: 'An error occurred, please try again later. If the problem persists, contact Heime',
        updateError: 'We were not able to update the registration',
        updateErrorText: 'An error occurred, please try again later. If the problem persists, contact Heime',
    },
    registrationSelected: {
        status: 'Status:',
        waiting: 'Waiting',
        approved: 'Approved',
        rejected: 'Rejected',
        titlePending: 'Your registration is received',
        subTextPending:
            'Your registration will be handled by your neighborhood operators. You will receive an sms when you get an update. This can take up to 5 business days. This is done to ensure only neighbors get access to a neighborhood',
        titleApproved: 'Your registration is approved',
        subTextApproved: "You can now log into Heime and get access to your neighborhood's content",
        titleRejected: 'Your registration was not approved',
        subTextRejected:
            'Your registration for {{neighborhood}}, {{apartment}} was not approved, contact your neighborhood operators or Heime if this is a mistake',
        update: 'Update your information',
        cancel: 'Cancel registration',
        confirmCancel: 'Are you sure you want to cancel your registration?',
        proceed: 'Continue',
        contact: 'Contact us',
        tryAgain: 'Try again',
    },
    chooseCoop: {
        title: 'Pick a cooperative',
        noCoops: 'You are not connected to a cooperative in Heime, please contact support',
    },
    home: {
        notices: 'Notices',
        activities: 'Activities',
        services: 'Reservations',
        sharing: 'Neighbor sharing',
        next: 'What is happening next?',
        noActivities: 'There are no planned activities.',
        createActivitySuggestion: 'How about creating one?',
    },
    neighbor: {
        sharingForum: 'Sharing',
        chat: 'Chat',
        myNeighbors: 'My neighbors',
        resourcePersons: 'Helpful neighbors',
        title: 'Neighbor sharing',
    },
    profile: {
        title: 'My profile',
        titleNotOwnProfile: 'User profile',
        phone: 'Telephone:',
        email: 'Email:',
        groups: 'Groups',
        groupMembers: 'Member',
        birthDate: 'Birthday:',
        edit: 'Edit profile',
        setUpPin: 'Set up PIN',
        sendFeedback: 'Give a star',
        back: 'Chat with neighbor',
        chat: 'Chat',
        message: 'Message',
        call: 'Call',
        about_me: 'About Me:',
        updateInfoModal: {
            namesDescription: 'Have already described themselves to the neighbors',
            title: 'Let your neighbors get to know you better',
            description: 'Upload a photo of yourself and write a little text',
            namesDescription_no_about: 'Have already described themselves',
            description_no_about: 'Write about yourself, so that your neighbors have something to remember you by',
            namesDescription_no_avatar: 'Have already uploaded a photo',
            title_no_avatar: 'Share your smile',
            description_no_avatar: 'Upload a photo of yourself, you can also choose to use a photo from Facebook',
            continue: 'Continue',
            checkbox: 'Never ask me again',
            back: 'Later',
            and_more: ' and more',
        },
        owningApartmentWith: 'Owns {{code}} with:',
        owningWith: 'Owns with:',
        livingApartmentWith: 'Living in {{code}} with:',
        livingWith: 'Living with:',
        rentingApartmentFrom: 'Renting {{code}} from:',
        rentingFrom: 'Renting from:',
        rentingApartmentTo: 'Renting {{code}} to:',
        rentingTo: 'Renting to:',
        otherApartment: 'Connected to apartment {{code}}:',
        other: 'Connected to apartment:',
        rentingApartmentWith: 'Renting {{code}} with:',
        rentingWith: 'Renting with:',
        profileCompletion: 'Complete your profile',
        profileCompletionDescription: 'Fill out more details, so your neighbors can get to know you better',
        profileCompletionModalHeader: 'Complete your profile',
        profileCompletionModalDescription: 'Fill out more details, so your neighbors can get to know you better',
        profileCompletionModalAvatar: 'Add a profile picture',
        profileCompletionModalAboutMe: 'Write about yourself',
        profileCompletionModalEmail: 'Add your email',
        profileCompletionModalBirthday: 'Add your birthday',
        profileCompletionModalJoinGroup: 'Join a group',
        profileCompletionModalButton: 'close',
        parkingSpots: 'Parking spot:',
        parkingSpots_plural: 'Parking spots:',
        deletedUser_explainer: 'Denne brukeren er ikke lenger i nabolaget',
        deletedUser_explainer_description:
            "The details about this user are only available to neighborhood administrators like you. Other neighbors only see this user as '{{name}}'",
    },
    myHome: {
        title: 'My home',
        noResult: 'You are not connected to any home in this neighborhood',
        info: 'Info',
        documents: 'Documents',
        editInfo: 'Edit Info',
        project_name: 'Unit name:',
        legal_name: 'Andel/seksjons number',
        address: 'Address',
        type: 'Type: ',
        apartment: 'Apartment',
        detached_house: 'Detached house',
        semi_detached_house: 'Townhouse',
        room: 'Room',
        floor: 'Floor:',
        area: 'Area:',
        living_area: 'Usable Area:',
        bedrooms: 'Number of bedrooms:',
        area_value: '{{area}} m²',
        parking_spot: 'Parking spot:',
        apartment_groups: 'Member of these group(s):',
        tenants: 'Users',
        addTenant: 'Add user',
        role_owner: 'Owner',
        role_livesWith: 'Lives with',
        role_renting: 'Renter',
        role_other: 'Other',
        editUser: 'Edit user',
        removeUser: 'Remove user',
        removeTenantConfirmHeader: 'Are you sure you want to remove the user?',
        removeTenantConfirm: 'Remove',
        removeTenantCancel: 'Cancel',
        removeTenantConfirmText:
            'By removing the user from the home, the user will no longer have access to the neighborhood.',
        removeTenantConfirmText2:
            'By removing the user from the home, the user will be disconnected from the home in Heime.',
    },
    editHome: {
        title: 'Edit Info',
        save: 'Save Changes',
        codeExists: '{{param}} already exists, to change apartment contact the {{coopType}}',
    },
    tenantEdit: {
        addUser: 'Add User',
        addExplainer: "Start by entering the user's phone number",
        phone: 'Phone Number',
        phonePlaceholder: 'Enter phone number',
        continue: 'Continue',
        role: 'Role',
        owner: 'Owner',
        livesWith: 'Lives with',
        renting: 'Renter',
        other: 'Other',
        addDetails: 'Add more details',
        notify: 'Do you want to notify the user?',
        notifyNone: 'No',
        notifySms: 'Yes, with an SMS',
        notifyEmail: 'Yes, with an email',
        phoneTooLong: 'Phone number is too long',
    },
    navigation: {
        home: 'Home',
        groups: 'Groups',
        chat: 'Chat',
        more: 'More',
        bellExplainer: 'Open notifications',
        profileExplainer: 'Open your profile',
        plusExplainer: 'Press here to add something new',
        reservations: 'Reservations',
        go_home: 'Go back to the start screen',
        go_back: 'Go back',
    },
    moreMenu: {
        myProfile: 'My profile',
        myHome: 'My home',
        services: {
            title: 'Services',
            doReservation: 'Make a reservation',
            yourReservations: 'Your reservations',
        },
        resourcePersons: 'Helpful neighbors',
        neighbors: 'Neighbors',
        board: {
            title: '{{boardNounCapitalized}}',
            members: 'Contacts',
            documents: 'Documents',
            messageToBoard: 'Messages sent to {{boardNoun}}',
        },
        terms: 'Terms of sale',
        settings: 'Settings',
        contact: 'Support',
        selectCoop: 'Change neighborhood',
        logOut: 'Log out',
        helpfulNeighbors: 'Helpful neighbors',
        Products: 'Items',
        memberBenefits: 'Member benefits',
        surveys: 'Surveys',
    },
    footer: {
        label: 'Support:',
    },
    contacts: {
        title: 'CONTACT US',
        phoneTitle: 'Phone:',
        emailTitle: 'E-mail:',
        messageTitle: 'If you have questions regarding the neighbourhood, we recommend that you contact {{boardNoun}}.',
        messageLineOne: 'Found an error, or have other suggestions to what we can do?.\n',
        messageLineTwo: 'We would love to hear from you! You can send us an email by tapping the email adress below',
        boardButton: 'See info about {{boardNoun}}',

        support: 'Opening hours phone: \nMon-Fri 09:00 - 16:00',
    },

    activities: {
        tabOne: 'ALL',
        tabTwo: 'registered',
        tabThree: 'Earlier',
        signedUp: 'registered',
        add_activity: 'ADD ACTIVITY',
        title: 'Activities',
        signed_up: 'Signed up',
        not_signed_up: 'Not signed up',
        empty_future: 'There are no planned activities yet.\nCreate an activity or show your interest in suggestions',
        empty_futureOnlyAttending: 'You are not signed up to any activities.',
        empty_previous: 'There has not been any activities',
        empty_button_suggestion: 'See suggestions',
        empty_button_create: 'Create activity',
    },
    activity: {
        signedUp: 'Registration completed',
        signUpSelf: 'Sign yourself up',
        Kr: 'Kr',
        cancelModal: {
            header: 'Are you sure you want to cancel the activity?',
            header_plural: 'Are you sure you want to delete the activity?',
            description: 'Participants will receive a notification that the activity was cancelled',
            checkbox: 'Refund participants',
            back: 'NO, BACK',
            approve: 'YES, CANCEL',
            approve_plural: 'YES, DELETE',
        },
        error: 'An error occured',
        suggestions: {
            press_here_title: 'Activity suggestions',
            press_here_description: 'Press here to see suggestions for activities',
            press_here_title_other_neighborhood: 'Get inspiration from other neighborhoods',
            press_here_description_other_neighborhood: 'Press here to see popular activities',
            images_banner_cta: 'Explore',
            images_banner_top: 'Find your next activity',
            images_banner_title: 'Press here for suggestions',
            images_banner_cta_other_neighborhood: 'Explore',
            images_banner_top_other_neighborhood: 'Inspiration for activities',
            images_banner_title_other_neighborhood: 'Press here to see activities from other neighborhoods',
            direct_press_create: 'Create new activity',
            header: 'Activity suggestions',
            subtitle: 'Which activity would you like to participate in?',
            template_example: 'Example description',
            coffee_title: '☕ Coffee Chat',
            coffee_description: 'Join a friendly coffee chat in the common area, at home, or in a park.',
            coffee_arrange_description:
                'To arrange a coffee chat, all you need is a place to meet. You can decide if someone should brew a pot of coffee and if neighbors bring their own cups. The duration can for example be half an hour, or that neighbors drop by over a three-hour period.',
            coffee_arrange_description_example:
                "Let's get together for a coffee ☕️ bring your thermos and meet me downstairs ☺️ ",
            coffee_arrange_location_example: 'Common room, 1st floor',
            christmas_lighting_title: '🎄 Christmas Tree Lighting',
            christmas_lighting_description:
                'Experience the magic of Christmas together in the neighborhood. Gather around the tree and enjoy the atmosphere.',
            christmas_lighting_arrange_description:
                'To arrange a Christmas tree lighting, you can either buy a tree, ask the housing company for funds, or organize a potluck. Someone needs to hang up the lights and set up the tree. If desired, you can also bring Christmas cookies and mulled wine. Some children enjoy leading the Christmas carols around the tree once it is lit.',
            christmas_lighting_arrange_description_example:
                "Hey! Let's light up the Christmas-tree 🎄 bring your favorite cookies and mulled wine. Someone needs to hang up the lights and get a tree 🌟 contact me if you can help",
            christmas_lighting_arrange_location_example: 'In the courtyard by the playground',
            bbq_title: '🔥 BBQ Party',
            bbq_description: 'Fire up the grill and enjoy the good vibes. Bring something tasty to eat and drink.',
            bbq_arrange_description:
                'To arrange a BBQ party, you need a grill. You can organize a shared bill to buy food and drinks, or neighbors can bring their own. A speaker with some music also helps!',
            bbq_arrange_description_example:
                'I am going to BBQ 🍖 and would like some company. Bring your favorite food and drinks 🥩🫑🥤  I will light up the grill at 17:30. Contact me if you want to help',
            bbq_arrange_location_example: 'Roof terrace',
            quiz_title: '🧠 Quiz Night',
            quiz_description: 'Test your knowledge with your neighbors. Compete in groups.',
            quiz_arrange_description:
                'To arrange a quiz night, you can search for a quiz on the internet, or create your own quiz. Its nice to have a quizmaster, and split attendees into groups.',
            quiz_arrange_description_example:
                "Hey! Let's have a quiz night! Bring your favorite snacks 🍿 and I'll find a quiz with questions for everyone 📚💡Contact me if you want to help",
            quiz_arrange_location_example: 'Common room, 2nd floor',
            sport_title: '⚽ Sports on TV',
            sport_description: 'Gather in front of the TV to watch a sports event together with your neighbors.',
            sport_arrange_description:
                'To arrange a sports night on TV, you need a TV, a sports channel, and an event to watch. Perhaps a local football match, Premier League, athletics, or something else?',
            sport_arrange_description_example:
                "I am going to watch the next football match ⚽️ join me. I'll be there 15 minutes early to get things setup 📺",
            sport_arrange_location_example: 'TV room in the basement',
            common_dinner_title: '🍽️ Community Dinner',
            common_dinner_description: 'Cook dinner together and enjoy a potluck that creates a great atmosphere.',
            common_dinner_arrange_description:
                'To arrange a community dinner, you can plan what to serve and who brings what. It can be a three-course dinner, a tapas night, or a BBQ party. You can organize cost-sharing to buy food and drinks, or neighbors can bring different needed ingredients.',
            common_dinner_arrange_location_example: 'Common kitchen and dining area',
            common_dinner_arrange_description_example:
                "Let's have a community dinner. I want to cook a hearty stew 🍲 any help is very much appreciated. See you there! 👀",
            knitting_title: '🧶 Knitting Meetup',
            knitting_description: 'Meet up to knit together. Bring your knitting projects and enjoy some company.',
            knitting_arrange_description:
                'To arrange a knitting meetup, you need a place to sit and knit. You can organize share the bill for coffee and tea, or neighbors can bring their own drinks.',
            knitting_arrange_description_example:
                "Anyone interested in 🧶 knitting? I'm about to finish a sweater and need inspiration for my next piece",
            knitting_arrange_location_example: 'Cozy corner in the library',
            walk_title: '👟 Group Walk',
            walk_description: 'Take a walk in the neighborhood with your neighbors. Enjoy fresh air and good company.',
            walk_arrange_description:
                "To arrange a group walk, you need a route to walk and a time to meet. Remember to consider the walking pace and inform about it. It's fun to plan a break at a café or park along the way.",
            walk_arrange_description_example:
                "A walk in the local area for anyone wanting to walk with company. Bring your sneakers 👟 We'll meet up at 12 by the main doors",
            walk_arrange_location_example: 'Meet at the main entrance',
            bathing_title: '🏊 Swimming',
            bathing_description: 'Jump into the water. A year-round activity for some, a summer activity for others.',
            bathing_arrange_description:
                'To arrange a swimming session, you need a place to swim and a time to meet. This can also be combined with a sauna if available nearby.',
            bathing_arrange_description_example: 'Lets go for a swim 🏊‍♀️',
            bathing_arrange_location_example: 'By the water',
            party_title: '🎉 Neighborhood Party',
            party_description: 'Celebrate with your neighbors, with or without a special occasion.',
            party_arrange_description:
                'To arrange a neighborhood party, you need a place to be and a time to meet. You can organize a shared-cost to buy food and drinks, or neighbors can bring their own. Music and entertainment are possible. It can be helpful to have a small group responsible for cleaning up afterward.',
            party_arrange_description_example:
                "Let's get together 💃🕺 Come as you are, with what you want 🥤🍻🍷🍿 We need to clean up afterwards, so help is greatly appreciated 🙇",
            party_arrange_location_example: 'In the courtyard',
            community_effort_title: '🤝 Community Effort',
            community_effort_description: 'Get things done in the neighborhood. Gather a group and pitch in together.',
            community_effort_arrange_description:
                "To arrange a community effort, you need a task list and a meeting time. It's a good idea to combine this with some socializing before/after/during the effort.",
            community_effort_arrange_description_example:
                'Hey everyone! There are some parts of the common areas that could use a shining ✨ I will start by raking the leaves in the parking 🍂 at 13:00. Please call or message me if you could imagine yourself helping.',
            community_effort_arrange_location_example: 'Meet in front of the building',
            workout_title: '💪 Workout or sports',
            workout_description:
                "Work out with your neighbors, it's always nice to have someone to hold you accountable",
            workout_arrange_description:
                'Find a sport or workout you can do together, either outside or inside. Describe the level, or specify that everyone can come as they are.Some suggestions might be lifting weights, playing soccer or volleyball, or having a common yoga-session.',
            workout_arrange_description_example:
                'Yoga in the park, I have adjustable exercises for all levels, that we can do together 🧘 bring a towel if you want something to stand/sit/lay on',
            workout_arrange_location_example: 'In the park behind the building',
            boardgames_title: '🎲 Board game night',
            boardgames_description: 'Play old classics or brand new games.',
            boardgames_arrange_description:
                'Suggest your own board games or ask your neighbors for theirs. Set aside a few hours one evening and find a suitable place to play.',
            boardgames_arrange_description_example:
                "Let's have a board game night, bring your favorite snacks 🍬🍿 I will bring Trivial Pursuit, if you have other favorites, let me know 🎲",
            boardgames_arrange_location_example: 'Common room, 3rd floor',
            other_title: '💡 Other',
            other_description: 'Have another idea for an activity? Click here.',
            other_arrange_description:
                'You can post all types of activities in Heime. If you’re unsure about the interest among neighbors, you can gauge interest with a focus group or a notice.',
            interest: 'I am interested in participating',
            arrange: 'I want to arrange this',
            interested: 'Your interest has been noted',
            interested_ask_neighbors: 'Check interest among neighbors',
            one_interested: '{{fname}} is interested',
            two_interested: '{{fname1}} and {{fname2}} are interested',
            three_or_four_interested: '{{names}} and {{fname}} are interested',
            many_interested: '{{fname}} and {{count}} others are interested',
        },
        survey_activityNew: {
            header: "Why didn't you complete the activity?",
            content: 'Help us improve the process. What you have entered in the activity will not be saved.',
            survey_label: 'Feedback',
            survey_placeholder: 'What was unclear, difficult, or why did you cancel?',
            allow_contact: 'I agree to be contacted for follow-up',
            submit: 'Submit feedback',
            cancel: 'Skip, exit',
        },
        survey_activitySuggestion: {
            header: 'Why are you not interested in the activity?',
            content: 'We would appreciate your feedback to improve activities in your neighborhood.',
            survey_label: 'Feedback',
            survey_placeholder: "Describe why you don't want to express interest",
            allow_contact: 'I agree to be contacted for follow-up',
            submit: 'Submit feedback',
            cancel: 'Skip',
        },
    },
    activityDetail: {
        you_booked: 'You booked {{count}} ticket',
        you_booked_plural: 'You booked {{count}} tickets',
        activity_details: 'Activity Details',
        related_groups: 'Related Groups',
        press_to_add: 'Press here to add a description',
        date: 'Date',
        start_end_time: 'Start and End time',
        no_location: 'No location',
        location: 'Location',
        contact: 'Organizer',
        cost: 'Price',
        alert: 'Alerts',
        organizer: 'You • Organizer',
        nonOrganizer: 'Organizer',
        attendeesTitle: 'attendees',
        attendees: '{{count}} Attendee',
        attendees_plural: '{{count}} Attendees',
        tickets: '{{count}} ticket',
        tickets_plural: '{{count}} tickets',
        signup: '{{quantity}} signed up',
        edit_activity: 'Edit Activity',
        buy_for: 'Buy {{count}} ticket ({{totalAmount}} kr)',
        buy_for_plural: 'Buy {{count}} tickets ({{totalAmount}} kr)',
        buy_more_for: 'Buy {{count}} more ticket ({{totalAmount}} kr)',
        buy_more_for_plural: 'Buy {{count}} more tickets ({{totalAmount}} kr)',
        signup_for: 'Sign up {{count}}',
        signup_more_for: 'Sign up {{count}} more',
        cancel_for: 'Cancel {{count}} ticket',
        cancel_for_plural: 'Cancel {{count}} tickets',
        sign_off: 'Sign off {{count}}',
        noChanges: 'No changes',
        cantCancel: 'You can not cancel as the cancellation deadline has expired',
        noSpots: 'No more spots left',
        noSpotsToAdd: 'There is no capacity to add any more',
        signUpTimePast: 'Signup deadline has expired',
        invalidNum: 'Chosen number is invalid',
        notRefunded: 'You will not be refunded for cancelled tickets',
        willRefund: 'You will be refunded when cancelling tickets',
        sentAlertModal: {
            send_alert: 'Send alert now',
            title: 'Title',
            message: 'Message',
            send: 'Send',
            continue: 'Continue',
            success: 'Alert was sent',
        },
        delete_activity: 'Delete activity',
        cancel_activity: 'Cancel activity',
        send_out_alert: 'Send alert',
        signup_yourself: 'Sign up',
        change_own_signup: 'Change own attendance',
        cancel: 'Cancel',
        publish: 'Publish',
        publishEdit: 'Save changes',
        start_time: 'Start time',
        end_time: 'End time',
        no_groups: 'No groups',
        no_cost: 'Free',
        kr: 'KR',
        noticeOptions: {
            0: 'No one',
            1: 'All neighbors',
            3: 'Only neighbors that have signed up',
            4: 'Only neighbors who have not signed up',
            5: 'All members in connected groups',
        },
        quantity: 'Max amount of attendees',
        quantityExplainer: 'Max {{count}} attendee',
        quantityExplainer_plural: 'Max {{count}} attendees',
        no_quantity: 'No limit',
        cancellation: 'Cancellation deadline: {{ datetime }}',
        no_cancellation: 'No cancellation deadline',
        registrationDeadline: 'Registrationdeadline: {{ datetime }}',
        no_registrationDeadline: 'No registration deadline',
        errorLoadingAttendees: 'An error occured loading attendees',
        addDetails: 'Add details',
        paying_now: 'paying now',
        arrangers: 'Arrangers',
        arrangers_you_and: 'You and {{count}} other',
        arrangers_you_and_plural: 'You and {{count}} others',
        add_arranger: 'Add arranger',
    },
    newActivity: {
        title_create_activity: 'Create an activity',
        preview: 'See preview',
        previewEdit: 'Preview of changes',
        change_details: 'Change details',
        imageTitle: 'Add image',
        moreMenu: {
            addPicture: 'Edit pictures',
            addDescription: 'Edit description',
            addMaxAttendees: 'Set up max attendees',
            addCost: 'Set up cost of attendance',
            addRelatedGroups: 'Add related groups',
            addLatestPossibleSignupTime: 'Set up registration deadline',
            addLatestCancellationTime: 'Set up cancellation deadline',
            alerts: 'Set up alerts to neighbor',
            addArrangers: 'Add arranger',
        },
        receive_alert: 'Select recipients of alerts',
        send_out_alert: 'We send out an alert at creation, and 24 hours before start.',
        groups: 'Groups',
        about_activity: 'Describe the activity',
        title: 'Title',
        enter_title: 'Enter Title',
        location: 'Location',
        enter_location: 'Write location',
        time: 'Time for activity',
        start: 'Start',
        end: 'End',
        description: 'Description',
        enter_description: 'Enter description',
        add_group: 'Add groups to notify',
        add_group_explainer:
            'There will be a message in the group chat about the activity, and you will be able to alert the group members about the creation',
        when_will_activity: 'Time of the activity',
        choose_start: 'Set starttime for the activity',
        choose_end: 'Set endtime for the activity',
        signuptime: 'Signup and cancellation deadline',
        enableSignUpTime: 'has signup and cancellation deadline',
        registration: 'signup deadline',
        cancellation: 'cancellation deadline',
        cancellationSame: 'Cancellation deadline is the same as registration',
        choose_location: 'Please choose a location',
        add_photo: 'Please add a photo',
        first_image_will: 'The first image in the list will be used as the cover',
        image: 'Image',
        indicate_cost: 'Please indicate the cost',
        explainNoCost: 'You cannot set a price since your neighbourhood is not registered for vipps',
        paid_event: 'It is a paid event',
        price: 'Price (kr)',
        priceMVA: 'Total price (kr) (incl. MVA)',
        mva: 'MVA (%)',
        bookKeepingAccount: 'Bookkeeping account',
        tax_rate: 'Vipps account',
        attendees_limit: 'Maximum amount of attendees',
        enter_zero: 'Write nothing to provide no limit for attendees',
        paymentExplainer: 'Payment will be transfered to account selected above',
        continue: 'Go to preview',
        see_preview: 'See Preview',
        refundCancellations: 'Refund attendees that cancel their registration',
        cooperative_group_id: 'Available for',
        noCooperativeGroup: 'Only',
        cooperative_group: 'Decide wich neighbourhoods can participate in the activity',
        no_groups: 'You are not a member of any groups',
        arrangers: 'Arrangers',
        add_arranger: 'Add arrangers',
        add_arrangers_explainer:
            'Add someone to co-arrange the activity. They will get a notification about it and will be able to edit/cancel the activity and notify participants',
        no_users: 'No users in this cooperative',
        no_users_search: 'No users found',
        search_users: 'Search for users',
        activity_about_description: 'By going to preview you can change more settings and add pictures.',
        confirm_template: 'Do you want to use the template?',
        confirm_template_content: 'By using a template, you overwrite what you have written in the activity',
        confirm_template_cancel: 'Cancel',
        confirm_template_confirm: 'Yes',
    },
    chatAll: {
        createNewChat: 'Create new chat',
        searchPlaceholder: 'Search for chat',
        selectMembers: 'Create new chat',
        selectMembersSelected: 'Create chat ({{count}})',
        createChat: 'Create chat',
        noChats: 'You have no chats',
        sentByYou: 'You',
        sentMessage: 'sent a message',
        title: 'Chats',
    },
    sharingAll: {
        noSearchResult: 'No result',
        viewAll: 'View all',
        messageType: {
            0: 'Products',
            1: 'Helpers',
            2: 'Notices',
        },
        noMessageType: {
            Requests: 'No notices here yet. Posted notices will be stored here.',
            ByBoard: 'No notices from {{boardNoun}} yet. Notices from {{boardNoun}} will be stored here.',
            Products: 'No items here yet. Here items for sharing will appear; how about posting an item yourself?', //is this used? do we still post ourselves in products, not neighborsharing?
            Helpers:
                "No registered helpful neighbors here yet. If you register as a helpful neighbor, you'll appear here.",
            neighborSharing:
                'No sharing from neighbors here yet. Neighbors offering help or items for loan will be showed here.',
        },
        neighborSharingTitle: 'Neighborsharing',
        fromBoard: 'From {{boardNoun}}',
        new: 'New',
        stars: '⭐️ {{count}} stars',
        no_star: '⭐️ Give star',
        postRequest: 'Add new notice',
        postProduct: 'Add item',
        offerHelp: 'Offer your help',
        myProducts: 'My items',
        myRequests: 'My notices',
        yourProducts: 'Your items',
        yourRequests: 'Your notices',
        requests: { plural: 'notices', singular: 'One notice' },
        products: { plural: 'items', singular: 'One item' },
        searchResults: 'Search results',
        you: 'You',
        dot: ' • ',
        errorSaving_header: 'An error occurred',
        errorSaving_description: 'Where not able to save the settings, try again later',
        save: 'Save',
        userSettings: 'Alert settings',
        userSettingsShort: 'Alerts',
        settingsAllNotifications: 'Receive alerts about important notices',
        settingsOnlyBoard: 'Only receive alerts about important notices from {{boardNoun}}',
        settingsNoneNotifications: 'Do not receive alerts about notices',
        follow: 'Follow',
        unfollow: 'Stop following',
        comment: '1 comment',
        comments: '{{count}} comments',
        created: 'Created by',
        fromBoardBy: 'by',
        attachment: '1 attachment',
        attachments: '{{count}} attachments',
        all: 'Active',
        onlyFor: 'Visible only to ',
        open_settings: 'Open settings for notifications on notices',
    },
    sharingNew: {
        newMessage: 'Create new message',
        itemTitle: 'Title',
        itemText: 'Message',
        imageSelector: 'Add a picture',
        sendButton: 'Send message',
        titleEdit: {
            0: 'Edit item',
            1: 'Edit helpful neighbor',
            2: 'Edit notice',
            3: 'Edit notice',
        },
        title: {
            0: 'New item',
            1: 'Become a helpful neighbor',
            2: 'New notice',
            3: 'New notice',
        },
        chooseType: 'Choose type of item',
        messageCategory: {
            0: {
                title: 'Add item',
                desc: 'Something that you want to share',
            },
            1: {
                title: 'Help a neighbor',
                desc: 'Let your neighbors know how you can help them',
            },
            2: {
                title: 'Notice to the neighbors',
                desc: 'Add a notice, you can ask for help or just give out some information',
            },
            3: {
                title: 'From {{boardNoun}}',
                desc: 'Add a notice tagged with from {{boardNoun}}',
            },
        },
        addProduct: {
            title: 'Tell us about your item',
            image: 'Image',
            textTitle: 'Title',
            enterTitle: 'Enter title',
            textDesc: 'Description',
            enterDesc: 'Enter description',
        },
        requestHelp: {
            title: 'Write the notice',
            textTitle: 'Title',
            enterTitle: 'Enter title',
            textDesc: 'Description',
            enterDesc: 'Enter description',
            send_notification: 'Send notification to neighbors',
            sent_notification: 'Notification sent',
            no_notification: 'No notification sent',
            noAccess: 'Notification not available: You can only notify once every 24 hours',
            allow_comments: 'Allow comments on notice',
            attachments: 'Attached files',
            never_expires: 'Permanent notice',
            never_expires_description:
                'The notice will automatically be renewed every 30 days, so it does not disappear',
        },
        helpNeighbour: {
            title: 'Tell us how you can help',
            textTitle: 'Main specialization',
            enterTitle: 'Enter specialization',
            textDesc: 'How can you help your neighbors',
            enterDesc: 'Enter description',
            textLimit: '({{count}} / 40)',
        },
        publish: 'Publish',
        saveError: 'Not able to save',
        publishError: 'Not able to create',
        publishErrorNotification:
            'Not able to create notice with notification. You can only notify once every 24 hours',
        chooseApartmentGroups: 'Choose homes',
        chooseApartmentGroupsDesc:
            'By choosing no specific homes, the notice will be visible to everyone in the neighborhood.',
        apartment_groups: 'Limit recipients',
    },
    profileEdit: {
        title: 'Edit profile',
        submit: 'Save changes',
        continue: 'Continue',
        public: 'Public',
        first_name: 'First name',
        last_name: 'Surname',
        email: 'Email',
        own_code: 'Personal PIN',
        phone: 'Phone number',
        birthday: 'Birthday',
        about_me: 'About Me',
        enterFirstName: 'Enter first name',
        enterSurname: 'Enter surname',
        enterEmail: 'Enter email',
        enterPhone: 'Enter phone number',
        hideEmailFromProfile: 'Hide email from my profile',
        hideBirthdayFromProfile: 'Hide birthday from my profile',
        hidePhoneFromProfile: 'Hide phone number from my profile',
        aboutMePlaceHolder:
            'Example: I work as a truck driver and enjoy taking walks with my dog. Always available for a chat.',
        updateMobileModal: {
            header: 'Are you sure that you entered correct phone number',
            description: 'if you make a mistake, you will not be able to log into the application',
            back: 'No, back',
            submit: 'yes,sure',
        },
        upload_image: 'Upload profile picture',
        delete_image: 'Remove profile picture',
        facebookLoginErrorHeader: 'Not able to log in with facebook',
        facebookLoginCancelledHeader: 'Login with facebook was cancelled',
        facebookLoginErrorDescription: 'If you still want to upload photo from facebook, try again',
        facebookPictureDownloadErrorHeader: 'Not able to download photo from facebook',
        facebook_no_picture: 'Did not find any profile picture on facebook',
        facebookTrackingPermissionErrorHeader: 'Did not get the right permissions to get profile picture from Facebook',
        facebookTrackingPermissionErrorDescription:
            'Go to settings and give permission for Heime under "privacy" => "tracking" to upload profile picture from Facebook',
        uploading_file: 'Wait until picture is uploaded',
        change_picture: 'Change profile picture',
        about_me_title: 'Add about me',
        aboutMeTips: 'Tips for the "about me" section',
        aboutMeExamples: 'Examples from your neighbors',
        about_me_tip_0: 'Be yourself',
        about_me_tip_description_0:
            'Write whatever you wish, there\'s no right or wrong way to write the "about me" section',
        about_me_tip_1: 'What do you work with?',
        about_me_tip_description_1:
            'Many write about what they work with on a daily basis, or maybe something they have worked with in the past?',
        about_me_tip_2: 'Have you always lived in the area?',
        about_me_tip_description_2:
            'You can write where you have lived before or where you come from, maybe you have moved here recently?',
        about_me_tip_3: 'Do you have any hobbies or interests?',
        about_me_tip_description_3:
            'Feel free to write about what you like to do, maybe there are people in the neighborhood with the same interests?',
        about_me_tip_4: 'Introduce yourself',
        about_me_tip_description_4: 'Maybe you have a nickname, or something that makes you easy to remember?',
        about_me_tip_5: 'Difficult to write?',
        about_me_tip_description_5: "It's not always easy, but keywords are more than enough",
        about_me_tip_6: 'Who can see what you write',
        about_me_tip_description_6: "Don't worry only your neighbors can see what you write about yourself",
    },
    reports: {
        header: 'Messages',
        search: 'Search after messages',
        reportToBoard: 'Create new message',
        noReports: 'No messages here yet.',
        noSearchResult: 'No results from your search',
        yoursAndPublic: 'Yours and public',
        open: 'Open',
        closed: 'Closed',
        disabled: 'This functionality is disabled by the board. They should be contacted via email at ',
    },
    reportSelected: {
        reportDetails: 'Message details',
        error: 'An error occured',
        editReport: 'Edit Message',
        deleteReport: 'Delete Message',
        comments: 'Comments',
        addComment: 'Add a comment',
        statusTypeNew: 'New',
        statusTypeProcessing: 'Processing',
        statusTypeDone: 'Solved',
        statusTypeWaiting: 'Waiting',
        commentModal: {
            addComment: 'Add a comment',
            message: 'Message',
            enterMessage: 'Enter Message',
            send: 'Send',
            error: 'An error occured while creating comment',
            publicComment: 'The comment will be visible to all neighbors as the message is marked as public',
            privateComment:
                'The comment will only be visible to {{user}} and people in {{boardNoun}}. They will also be notified.',
            privateCommentOwn:
                'The comment will only be visible to yourself and {{boardNoun}}. They will also be notified.',
        },
        changeStatusModal: {
            errorUpdating: 'Failure when updating status',
            changeStatus: 'Change status',
            save: 'Save',
        },
        noComments: 'No comments here yet.',
        changeStatus: 'Change status',
    },
    reportNew: {
        header: { edit: 'Edit message', new: 'Add message' },
        image: 'Image',
        title: 'Title',
        error: 'an error occured',
        enterTitle: 'Enter Title',
        desc: 'Description',
        enterDesc: 'Enter Description',
        isPublic: 'Visible to neighbors',
        publicContent:
            'By making the message visible, other neighbors will be able to see your message, avoiding duplicate reporting.',
        publish: 'Send',
        saveChanges: 'Update',
    },
    transaction: {
        waiting: 'Waiting for payment confirmation',
        toVipps: 'Go to Vipps to cancel',
        pay: 'Pay in Vipps',
        title: 'Status of payment',
        cancelled: 'Payment cancelled',
        cancelledExplainerBooking: 'You have not been charged, your booking is not confirmed. You can try again.',
        cancelledExplainerActivity: 'You have not been charged, your tickets are not confirmed. You can try again.',
        back: 'Go back',
        success: 'Payment completed',
        timeUntilStart: 'In {{formattedDuration}}',
        started: 'Already started',
        has_been_reservedPost: ' has been reserved',
        reservations: 'Go to reservation',
        explainLocks:
            'The product you have reserved can be unlocked with the Heime App, to open it go to the reservation under "Your reservations" after the reservation starts and tap "Open lock"',
        has_been_signedup: 'You are signed up for ',
        quantity_pre: 'You have ',
        quantity_post_single: ' ticket',
        quantity_post_multiple: ' tickets',
        toActivity: 'To activity',
        bookingConfirmed: 'Reservation is confirmed',
    },
    board: {
        title: 'Contacts',
        search: 'Search',
        members: '{{count}} member',
        members_plural: '{{count}} members',
        call: 'Call',
        mail: 'Mail',
        boardResponsibility: 'Responsibility:',
        reportToBoard: 'Send a message to {{boardNoun}}',
        noResult: 'No results',
        noContent: 'No contacts',
    },
    documents: {
        title: 'Documents',
        search: 'Search',
        folder: 'Folder',
        files: '{{count}} file',
        files_plural: '{{count}} files',
        notFound: 'No results',
        noResult: 'There are no documents',
        error: 'Not able to dowload file',
        privateFolder: 'This folder is only visible and accessible to neighborhood admins',
    },
    neighbours: {
        alphabetic: 'alphabetic',
        apartment: 'address',
        searchPlaceholder: 'search  by name',
        noUserFound: 'No user found',
        noApartment: 'No address',
    },
    reservations: {
        title: 'Your reservations',
        back: 'Back',
        noReservations: 'You have no present or future reservations',
        services: 'Services',
        today: 'Today',
        selectTimeRange: 'SELECT TIME RANGE',
        clearDates: 'CLEAR DATES',
        reserver: 'RESERVER',
        booked: 'Booked:',
        total: 'Total:',
        errorUpdate: 'Could not update reservation',
        errorCreate: 'Could not create reservation',
        alreadyReserved_title: 'Already reserved',
        alreadyReserved_description: 'The product is already reserved for this period',
        successUpdate: 'Reservation was updated',
        started: 'Started',
        openLock: 'Open lock',
        locks: { singular: 'Access via app', plural: 'Access via app' },
        until: 'until',
        left: 'left',
        onGoing: 'Ongoing',
        quantity: 'Booked: {{quantity}}',
        checkIn: 'Check in',
    },
    services: {
        title: 'Services',
        myReservations: 'Your reservations',
        oneOngoing: 'One active reservation',
        ongoingReservations: 'active reservations',
        oneReservation: 'One reservation',
        reservations: 'reservations',
        empty: 'There are no services or reservations available here',
    },
    expandedReservation: {
        unlock: 'Unlock',
        canUnlockIn: 'Can be unlocked in',
        remote_unlock: 'Remote unlock',
        expired: 'Booking has expired',
        reservation_has_locks: 'The product has a lock',
        errorCancel: 'Could not cancel reservation',
        cancel: 'Cancel',
        change: 'Change',
        lockModal: {
            lockUnlocked: 'Lock is open',
            lockboxUnlocked: 'The keybox is unlocked, you can now open it',
            get_keys: 'Take out the keys, and close the keybox',
            leave_keys: 'Leave the keys in the keybox, and close it',
            lockOpened: 'Lock opened',
            lock: 'Close lock',
            attemptingConnect: 'Searching for the lock',
            lockConnecting: 'Searching for the lock',
            noConnect: 'Could not find the lock',
            requestingUnlock: 'Opening the lock',
            unlocking: 'Unlocking lock',
            retry: 'Try again',
            unlockFailed: 'Could not open the lock',
            unlocked: 'Lock is open',
            lockingIn: 'Closing lock in ',
            sec: 'sec',
            lockClosed: 'Lock is closed',
            pinClosedCanClose: 'Disconnected, verify that the lock is locked',
            unlockAgain: 'Open again',
            lockDisconnected: 'Lock is disconnected',
            closeLock: 'Close the lock to continue',
            noPermission: 'Needs access to Bluetooth and location',
            openSettings: 'Open settings',
            close: 'Close',
            noBluetooth: 'Bluetooth is not activated',
            noWeb: 'Download the app to unlock the lock',
            pressLock: 'Press the lock to wake it up',
            wakeLock: 'Wake the lock to continue',
            noConnectRememberToWake: 'Could not find lock, have you woken it?',
            noBluetoothPermission: 'Needs access to Bluetooth to open the lock',
            noLocationPermission: 'Needs access to location to open the lock',
            pressLockExplainer:
                'You have to press the button on the lock to wake it up, the lock is probably on the wall next to the reserved object. Otherwise the app is not able to open it.',
            closeFailed: 'Not able to close the lock',
            closing: 'Closing the lock',
            lockClosing: 'Closing...',
            waiting: 'Waiting for the lock',
            remote_unlock: 'You can unlock the lock from anywhere',
            remote_unlocking: 'Have requested unlock',
            lockDone: 'Lock opened',

            missingSecretKeyOrTokenHeader: 'Missing information to open the lock',
            missingSecretKeyOrTokenText:
                'Connect the phone to the internet, then press try again followed by waiting up to 1 minute before unlocking.',
        },
        cancelModal: {
            header: 'Are you sure you want to cancel the reservation?',
            refundable: 'You will be refunded for the reservation',
            hours: '{{count}} hour',
            hours_plural: '{{count}} hours',
            days: '{{count}} day',
            days_plural: '{{count}} days',
            nonRefundableHours:
                'You will not be refunded for the reservation, because it starts in less than {{hours}}',
            nonRefundableDaysHours:
                'You will not be refunded for the reservation, because it starts in less than {{days}} and {{hours}}',
            nonRefundableDays: 'You will not be refunded for the reservation, because it starts in less than {{days}}',
            nonRefundable: 'You will not be refunded for the reservation',
            back: 'GO BACK',
            approve: 'YES, CANCEL',
        },
        bluetoothDenied: 'Heime needs permission to use bluetooth to connect to the lock',
        bluetoothDisabledAndroid:
            'You have to manually turn on your bluetooth connection, click this text to open settings',
        bluetoothDisabledIOS: 'You have to manually turn on your bluetooth connection',
        noWeb: 'The lock can not be unlocked from the web, you have to download the app',

        okNoCancel: 'OK',
        noCancel:
            'You cannot cancel this reservation as it has already started. You may still change it to finish earlier or extend it.',
        cannotCancel: 'Unable to cancel reservation',
        productInfo: 'More info',
        bookingDetails: 'Booking details',
        starting: 'Booking starting',
        ending: 'Booking ending',
        ends: 'Ends',
        successCancelHeader: 'Reservation is cancelled',
        changedClockNegative:
            'The time has been changed on this device. Change the time back, and restart the app to unlock the lock.',
        serverAndPhoneOutOfSync:
            'The clock on your phone is not correct. Set the time correctly and restart the app to open the lock',
        noDataToCompare:
            'Because of a missing internet connection, we are not able to compare the time on your phone with our clock. Please connect to the internet and try again.',
        tryAgain: 'Try again',
        more_options: 'More options',
        pin_code: 'Pincode',
        copied_pin_code: 'Pincode copied',
        copy_pin_explainer: 'Press to copy pin',
        reason: 'Reason',
        quantity: 'Quantity - {{count}} item',
        previous_visitor: 'Previous user',
        previous_visitor_action: 'Press for explanation',
        previous_visitor_info:
            'If there is something wrong with the product, you can contact the person who had the product before you, and coordinate how the error can be corrected',
        checkOut: 'Check out',
        checkIn: 'Check in',
        booked: 'Booked',
        all_day: 'All day',
        lock: '1 lock',
        lock_plural: '{{count}} locks',
        users: 'User on reservation',
        users_plural: 'Users on reservation',
        you: 'You',
        edit: 'Edit',
        edit_users: 'Edit users',
        edit_users_subtitle: 'Add or remove users from the reservation, they will have the same access as you',
        roommates: 'Connected to the same apartment',
        neighbours: 'Neighbours',
        noUsers: 'No users found',
        noUsersToAdd: 'No users to add',
        continue: 'Continue',
        remove_user: 'Press to remove user',
        users_edited: 'Users with access have been updated',
    },
    serviceExpanded: {
        nAvailablePlural: 'Quantity: {{amount}}',
        shortTerm: 'By the hour',
        longTerm: 'Overnight booking',
        price_type: {
            rate_per_booking: '',
            rate_per_night: '',
            rate_per_hour: '',
            rate_per_booking_capacity: '',
            rate_per_hour_per_capacity: 'per hour',
            rate_per_night_per_capacity: 'per night',
            rate_per_hour_with_night_price:
                'Between {{nighttime_end_hour}}-{{nighttime_start_hour}}: {{daytime_price}} NOK, rest of the day: {{nighttime_price}} NOK',
        },
        price_type_short: {
            rate_per_booking: 'booking',
            rate_per_night: 'night',
            rate_per_hour: 'hour',
            rate_per_booking_capacity: 'item',
            rate_per_hour_per_capacity: 'item',
            rate_per_night_per_capacity: 'item',
        },
        maxBooking_hour: 'max one hour',
        maxBooking_hour_plural: 'max {{count}} hours',
        maxBooking_day: 'max a day',
        maxBooking_day_plural: 'max {{count}} days',
        hours: '1 hour',
        hours_plural: '{{count}} hours',
        days: '1 day',
        days_plural: '{{count}} days',
        noImage: 'image not selected',
        name: 'Name',
        description: 'Description',
        reserve: 'Reserve',
        chooseStart: 'Choose date for check-in',
        chooseEnd: 'Choose date for check-out',
        update: 'Update',
        price: 'Price',
        kr: 'kr',
        ammount: 'Quantity',
        i_want_this_one: 'I want this one',
        max_booking_period: 'Ordering period',
        who_booked: 'Who booked this time range?',
        close: 'Close',
        availableAllDay: 'Available all day',
        availableSome: 'Available times',
        notAvailable: 'Not available',
        availableCount: '{{count}} items available',
        availableSomeCapacityMultiple: 'Available times ({{min}} - {{max}} items)',
        availableSomeCapacitySingle: 'Available times ({{count}} item(s))',
        timeNow: 'Now',
        n_available: '{{count}} items ',
        available: 'Available',
        checkin: 'check-in',
        checkout: 'check-out',
        selectAmount: 'How many to reserve?',
        reserveTime: 'Choose time',
        reserveDays: 'Choose dates',
        reserveDescriptionTime: 'Choose one or more slots to reserve. Select first slot then last. ',
        reserveDescriptionDays: 'Select first slot for check-in, then slot for check-out. see times above. ',
        maxBookingStart: 'You can order it for ',
        durationLabel: 'Ordered:',
        sumLabel: 'Total:',
        clear: 'CLEAR',
        today: 'Today',
        clickOnBusy: 'You can tap on a taken slot to see who has booked it.',
        maxBookedPerBooking: 'You can book a maximum of {{count}} items per reservation. ',
        skipPaymentExplainer:
            'It is a possibility to skip payment for this reservation, if you do not need the product for yourself.',
        skipPayment: 'Open reservation',
        paymentReservation: 'Own reservation',
        isSkippingPayment: 'Available for others',
        reserveReasonModalHeader: 'Provide reason',
        reserveReasonModalLabel: 'Reason for reservation',
        reserveReasonModalButton: 'Reserve',
        reserveReasonModalTooLong: 'The reason can not be longer than 255 characters',
        reserveLicensePlateModalHeader: 'Enter license plate',
        reserveLicensePlateModalText: 'Write the license plate of the vehicle to be parked',
        reserveLicensePlateModalButton: 'Confirm',
        reserveLicensePlateModalDropdown: 'See more options',
        reserveLicensePlateModalManage: 'Manage saved plates',
        reserveLicensePlateModalCreateNew: 'Add new license plate',
        reserveLicensePlateModalTooLong: 'The license plate can not be longer than 20 characters',
        showEarlier: 'See earlier',
        localTime: 'Local time',
        quantityLabel: 'Amount',
        pieces: '{{count}} pcs',
        quantity: 'Quantity {{quantity}}',
        open_explainer: 'Press to get an explanation',
        not_confirmed: 'Not paid',
        change_not_confirmed: 'Change not paid',
        timeBasedPricing: '. Note that the price may vary depending on the time you reserve for',
        hasBooking: 'You already have a booking of {{product}}',
        booking_details: 'See booking',
    },
    notifications: {
        title: 'Notifications',
        ago: 'ago',
        updateApp: {
            android: 'Play',
            ios: 'App',
            title: 'New version of Heime',
            content:
                'We recommend that you update your app to avoid errors. To update, go to "{{store}} Store" and then "Update".',
            buttonText: 'Update',
        },
        newChatMessage: {
            title: '{{name}} has sent you a message',
            buttonText: 'Go to chat',
        },
        activityInvite: { buttonText: 'Go to activity', content: 'There has been created a new activity' },
        tfHoursBeforeActivity: {
            title: 'Reminder about {{name}}',
            content: "It's less than 24 hours until this activity",
            buttonText: 'Go to activity',
        },
        tfHoursBeforeSignUpTerm: {
            title: 'Reminder about {{name}}',
            content: "There's less than 24 hours left to signup for this activity",
            buttonText: 'Go to activity',
        },
        activityInvitedUsers: { buttonText: 'Go to activity' },
        activityCustomNotify: {
            title: 'Message related to {{name}}',
            content: 'Message from organizer: {{name}}',
            buttonText: 'Go to activity',
        },
        cancelledActivity: { title: '{{name}} is cancelled' },
        supportCreated: {
            title: 'New message to {{boardNoun}}, from: {{name}}',
            buttonText: 'Go to message',
        },
        supportStatus: {
            title: '{{boardNounCapitalized}} has changed the status of message "{{title}}" to: "{{name}}"',
            buttonText: 'Go to message',
        },
        userGroupCreated: { buttonText: 'Go to group' },
        lowBattery: {
            title: 'Lock "{{lockName}}" has a low battery ({{percentage}} %)',
            content: 'The lock belongs to {{productsName}}.',
            contentcable: 'It can be charged with a Micro-usb-b cable.',
            contentcabinet: 'Battery is of the type CR123A, can be bought in a hardware store.',
            contentmasterLock5441: 'It uses a CR123A battery. Batteries can be bought in hardware stores',
            contentmasterLock4401: 'It uses a CR2 battery. Batteries can be bought in hardware stores',
            contentmasterLock4400: 'It uses a CR2 battery. Batteries can be bought in hardware stores',
            contentdanalock: 'It uses 4 CR123 batteries, they can be bought in hardware stores.',
            contentPost: 'To update battery status, open the lock via the app.',
        },
        newMessageComment: {
            title: 'New comment on notice "{{title}}"',
            buttonText: 'Go to notice',
            contentFile: 'A file',
            contentImage: 'A image',
        },
        newSurvey: {
            title: 'A new survey has been published: "{{title}}"',
            buttonText: 'Go to survey',
        },
        reminderSurvey: {
            title: 'Reminder about survey: "{{title}}"',
            buttonText: 'Go to survey',
        },
        unhandledCooperativeUserRegistration: {
            title: 'There is a new registration waiting for access to Heime',
            title_plural: 'There are {{count}} new registrations waiting for access to Heime',
            buttonText: 'Go to admin ui',
        },
        arrangerAdded: {
            title: 'You have been added as a arranger for "{{title}}"',
            buttonText: 'Go to activity',
        },
        arrangerRemoved: {
            title: 'You have been removed as an arranger for "{{title}}"',
            buttonText: 'Go to activity',
        },
        activityRateUs: {
            title: 'Thank you for your participation',
            content: 'Do you want to rate heime?',
            buttonText: 'Rate Heime',
        },
        messageExpired: {
            prolonged: 'Notice has been prolonged',
            reactivated: 'Notice has been activated',
            month_from_now: 'Your notice will be deactivated in a month',
            prolong_error: 'Not able to prolong the notice',
            title_no_date: 'Your notice will never be deactivated',
            description_no_date: 'The notice "{{title}}" never expires, this can be changed by editing the notice',
            title_expired: 'Your notice is deactivated',
            title_expired_author: 'Your notice was deactivated by {{actor_name}}',
            description_expired: 'Your notice "{{title}}" has expired. You can activate it again',
            title_expires_future: 'You notice will be deactivated in {{timeString}}',
            title_expires_future_author: 'You notice is set to be deactivated in {{timeString}} by {{actor_name}}',
            description_prolong: 'Your notice "{{title}}" will be deactivated. You can prolong it for 1 month',
            description_no_prolong:
                'Your notice "{{title}}" will be deactivated. We will notify you here when this occurs',
            seeMessage: 'Go to notice',
            prolong: 'Prolong',
            reactivate: 'Activate',
        },
        bookingNotification: {
            buttonText: 'Go to booking',
            start_future_title: 'Your booking of {{name}} starts in {{timeString}}',
            start_past_title: 'Your booking of {{name}} started {{timeString}} ago',
            end_future_title: 'Your booking of {{name}} ends in {{timeString}}',
            end_past_title: 'Your booking of {{name}} ended {{timeString}} ago',
            buttonText_start_procedure: 'Start check-in',
            buttonText_end_procedure: 'Start check-out',
            start_future_procedure_title: 'Check-in for {{name}} can be started in {{timeString}}',
            start_past_procedure_title: '{{name}} is ready for check-in',
            end_future_procedure_title: 'You must be checked out of {{name}} in {{timeString}}',
        },
        userAddedToBooking: {
            title: '{{actor_name}} has added you to the booking of {{product_name}}',
            description_future: 'The booking starts in {{timeString}}',
            description_started_past: 'The booking started {{timeString}} ago',
            description_ended_past: 'The booking ended {{timeString}} ago',
            buttonText: 'Go to booking',
        },
        userRemovedFromBooking: {
            title: '{{actor_name}} has removed you from the booking of {{product_name}}',
        },
        others: '{{others}} and {{last}}',
        no_notifications_found: 'No notifications',
    },
    chat: {
        messageModal: {
            header: 'This URL is from another user and might not be safe. Do you want to continue to the website?',
            back: 'NO, BACK',
            approve: 'YES, SURE',
        },
        empty_message: 'No message here yet. Please type something to start the chat.',
        today: 'Today',
        add_file: 'Add File',
        add_image: 'Add Image',
        goToGroup: 'Go to group',
        shareProducts: 'Sharable item: ',
        askForHelp: 'Ask for help:',
        helpRequest: 'Notice:',
        starReceived: 'Star received from: ',
        starSended: 'Star sent to: ',
        addAll: 'ADD ALL',
        removeAll: 'RESET',
        search: 'Search for a neighbour',
        onlyYou: 'Only you',
        too_many_users_header: 'A lot of users in chat',
        too_many_users_text:
            'There are a lot of neighbors in this chat; to avoid too many notifications we recommend using notices instead. There you can send images and files, and receive comments from neighbors. To find notices go back and then press the button in the middle at the bottom.',
        too_many_users_button: 'OK',
        pressInfo: 'Press for more info',
        add_attachment: 'Add attachment',
        write_message: 'Write a chat message',
        send_message: 'Send a chat message and attachments',
        from: 'From :senderName',
        to_profile: 'Go to authors profile',
        deleteMessageSuccess: 'The message was deleted',
        deleteMessageError: 'Was not able to delete the message',
        deleteModal: {
            header: 'Are you sure you want to delete the message?',
            back: 'Go back',
            approve: 'Delete',
        },
        edited: '(Edited) ',
        deleted_message: 'Deleted message',

        edit: 'Edit',
        delete: 'Delete',
        copy: 'Copy',
        copiedToClipboard: 'Message copied',
        editing_message: 'Edit message',
        stop_editing: 'Stop editing message',
    },
    chatInfo: {
        members: 'Members',
        media: 'Images',
        files: 'Files',
        no_media_message: 'No images here yet. Images sent will be stored here.',
        no_files_message: 'No files  here yet. Files sent will be stored here.',
        nMembers: '{{count}} member',
        nMembers_plural: '{{count}} members',
        admin: 'You • Admin',
        nonAdmin: 'Admin',
        you: 'You',
        leaveChat: 'Leave chat',
        makeAdmin: 'Make admin',
        addMember: 'Add user',
        of: '{{current}} of {{length}}',
        leaveModal: {
            header: 'Are you sure you want to leave group?',
            back: 'NO, BACK',
            approve: 'YES, SURE',
        },
        addMembers: 'Add user',
        addMembers_plural: 'Add users ({{count}})',
        noUsers: 'Found no users',
        noUsersChat: 'No users to chat with',
        noUsersToAdd: 'No users to add',
    },
    global: {
        resetApp: 'Restart app',
        swipe_down_to_fetch_data: 'Drag the screen down to retrieve the latest data',
        warning: 'Warning',
        are_you_sure_delete_activity: 'Are you sure you want to delete the activity?',
        yes: 'Yes',
        no: 'No',
        cancel: 'Cancel',
        ask_me_later: 'Ask me later',
        permissions: {
            camera_permission: 'Camera Permission',
            must_have_permission_camera: 'The application must have access to the camera',
            camera_settings_permission:
                'To be able to upload an image, Heime needs access to the camera. If you have selected "no" to this before, this is done in the phone\'s settings',
            bluetoothLocationTitle: 'Access to location and bluetooth services',
            bluetoothLocationDescription:
                'These are needed only to unlock the lock, unlocking a lock can theoretically tell us that you are in the area at the point of connecting',
        },
        back: 'Back',
        task_failed_header: 'An error occured',
        task_failed_description: 'The error has been noted, please try again later',
        goBack: 'Go back',
        confirm: 'Confirm',
        confirmWithLabel: 'Confirm {{label}}',
        show_more: 'Show more',
        show_less: 'View less',
        optional: '(optional)',
        cameraPermission: {
            title: 'Access to camera',
            message: 'The app needs access to allow you to upload your images',
            neutral: 'Ask later',
            positive: 'Ok',
            negative: 'Do not allow',
        },
        selectImage: 'Select image',
        and: 'and ',
        xOthers: 'one more',
        xOthers_plural: '{{count}} others',
        enLang: 'English',
        noLang: 'Norsk',
        daLang: 'Dansk',
        svLang: 'Svenska',
        cancelModal: {
            cancelChanges: 'Are you sure you want to discard the changes?',
            cancelCreation: 'Are you sure you want to discard?',
            content: 'The information you have entered will not be saved',
            contentEdited: 'The information you have changed will not be saved',
            back: 'NO, GO BACK',
            confirmCancel: 'YES, DISCARD',
        },
        search: 'Search',
        comments: 'Comments',
        noComments: 'No comments',
        attachFile: 'Tap here to add file or image',
        add_photo: 'Select an image',
        take_photo: 'Take a picture',
        add_file: 'Choose a file',
        add_facebook: 'Get facebook picture',
        remove_photo: 'Remove picture',
        choose: 'Choose',
        crop_image: 'Edit photo',
        clock: 'at',
        addImage: 'Press here to add image',
        networkError_header: 'Network error',
        networkError_description: 'Not able to send, please try again',
        error_sending_header: 'An error occured while sending',
        error_sending_description: 'Please try again',
        error_saving_header: 'Failed to save changes',
        error_saving_description: 'Please try again',
        tooManyRequests_header: 'Locked out of the server',
        tooManyRequests_text: 'You have made too many requests, please try again in 5 minutes',
        noSearchResult: 'No results from your search',
        close: 'Close',
        loading: 'Loading more information',
        more_choices: 'Press for more choices',
        select_language: 'Switch language',
        upload_failed: 'Not able to upload picture',
        permission_denied: 'You have to give Heime access to be able to upload a picture',
        open_settings: 'Open settings',
        deleted_user_with_apartment_owner: 'Previous owner of {{apartment}}',
        deleted_user_with_apartment_renting: 'Previous renter of {{apartment}}',
        deleted_user_with_apartment_other: 'Previous user of {{apartment}}',
        deleted_user_with_apartment_livesWith: 'Previously tenant of {{apartment}}',
        deleted_user_no_apartment: 'Removed user',
        open_admin_error: 'Unable to open the admin panel directly',
        open_admin_error_text:
            'Go to app.heime.com/admin in your browser to manage the request. It is also added to your clipboard',
        open_url_error_header: 'Unable to open the link',
        open_url_error_text: 'Go to {{url}} in your browser, the link is also added to your clipboard',
        open_contact_error_header: 'Not able to open for you',
        open_contact_error_text: 'Use {{url}} to contact, this is also added to your clipboard',
        keyboard_done: 'Done',
        enter_url: 'Enter your link here',
        insert_link: 'Insert',
        uploading_file: 'Wait until file(s) is finished uploading',
    },
    globalError: {
        header: 'An error occured',
        networkHeader: 'Network error occured, please verify your network connection',
        subText: 'Sorry, we  have reported the error, please try again. If this does not help, contact us.',
        subTextNetwork: '',
        button: 'Try again',
        too_big: '{{name}} has to be shorter',
        title: 'Title',
        phone: 'Phone number',
    },
    networkAlert: {
        header: 'No internet connection',
        description:
            'No internet connection available, make sure that Wi-Fi or mobile data is turned on, then try again ',
    },
    updateApp: {
        android: 'Play',
        ios: 'App',
        header: 'New version of Heime',
        description_recommended:
            'We recommend that you update to avoid errors. To update, go to "{{store}} Store" and then "Update".',
        description_force_soon:
            'If you decide not to update, your app may act unexpectedly. Eventually you will be forced to update when your app is too old to work.',
        description_force:
            'Your app version is so old that it will not work as expected, so you\'ll have to update it. To update go to "{{store}} Store" then "Update".',
        goToButton: 'Go to {{store}} Store',
        later: 'Later',
    },
    onboarding: {
        setupPin: 'Set up PIN',
        step: 'Step {{step}}',
        description: 'Description',
        descriptionPlaceholder:
            "Write something you can help with and why: E.g. I have a car and it's said that im good with pets",
        helpfulNeighbourHeader: 'What can you help your neighbors with?',
        privacyPolicyAgreePost: 'Press continue to confirm',
        helpfulNeighbourDesciptions: 'You can select a couple things you could help a neighbor with.',
        stepHeader: ['', 'Privacy.', 'Notification.', 'Info', 'Describe', 'Join Groups.'],
        privacyPolicyHeader: 'Privacy Policy',
        privacyPolicyDesciptions: 'Please read the privacy terms\nand give your consent',
        privacyPolicyContent:
            'Heime is a service vendor whose mission is to create good neighborhoods. To do this we need some personal information about you \n\nThe main purpose for gathering these is to inform your neighbors who you are. You will always have the opportunity to edit this and also decide what your neighbors see.\n\n Heime does not sell your data',
        privacyPolicyAgree: 'By clicking continue you agree with',
        privacyPolicyLowerCase: "Heime's privacy policy",
        continue: 'Continue',
        notificationHeader: 'Be notified',
        notificationDesciptions:
            'By turning on notifications, you will be able to receive messages in chats. Be one of the first to know about updates in activities.',
        turnOnNotification: 'TURN ON NOTIFICATIONS',
        changeSettings: 'CHANGE SETTINGS',
        profileCreation: 'Profile creation',
        pleaseAllowNotification: 'Please allow notifications from Heime in your system settings',
        skipForNow: 'SKIP FOR NOW',
        downloadAccountPhoto: 'Upload profile photo',
        photoAdded: 'Photo added',
        enterFirstName: 'Enter First Name',
        firstName: 'First Name',
        enterSurname: 'Enter Surname',
        lastName: 'Last Name',
        enterEmail: 'Enter Email',
        email: 'Email',
        phone: 'Telefon',
        birthday: 'Birthday',
        hideFromProfile: 'Hide from My profile',
        aboutMePlaceHolder: 'I work as a truck driver and enjoy taking walks with my dog. Always available for a chat.',
        aboutMe: 'About me',
        lastName_error: 'You have to provide a last name',
        firstName_error: 'You have to provide a first name',
        phone_error: 'You have to provide phone number',
        invalidEmail: 'Invalid email address',
        email_error: 'You must enter an email',
        createPin: 'Create PIN',
        repeatPin: 'Repeat PIN',
        enterPinDesciption: 'To be able to log in quicker, please enter a new pin.',
        repeatPinDesciption: 'Enter the same pin to confirm it.',
        phone_length: 'Phone number has to be shorter',
        profile_confirm_title: 'Confirm your information',
        profile_confirm_subtitle:
            'The information is used to show your information to {{boardNoun}}. You can decide if this is also shown to your neighbors.',
        createProfile: 'Create profile',
        upload_photo: 'Choose a picture',
        upload_facebook: 'Use picture from Facebook',
        add_photo: 'Give your mailbox a face, and your neighbors will recognize you',
        describe_yourself:
            'Tell something about yourself, text and image will only be shown to neighbors in your housing association',
        upload_new_photo: 'Upload a new photo',
    },
    group: {
        header: 'Groups',
        title: 'Join some groups',
        subTitle: 'Some activities only notify group members, therefore join the groups you find interesting.',
        join: 'Join',
        members: { plural: 'Members', singular: 'One member' },
        continue: 'Continue',
        skip: 'Skip for now',
        create_group: 'Create Group',
        my_groups: 'My Groups',
        all_groups: 'All Groups',
        noGroups: {
            own: 'You are not a member of any groups',
            all: 'There are no groups',
            allWithRequest: 'There are no groups, you should make one!',
        },
        adminModal: {
            header: 'Are you sure you want to appoint “{{user}}” as administrator?',
            yes: 'Yes',
            no: 'No',
        },
        groupSelected: {
            title: 'Groups',
            open_chat: 'Open Chat',
            create_activity: 'Create Activity',
            join_group: 'Join Group',
            you: 'You',
            members: 'Members',
            admin: 'Admin',
            member: 'Member',
            set_admin: 'Set {{name}} as administrator',
            leave_group: 'Leave group',
            add_image: 'Upload an image',
            add_file: 'Upload a file',
            images: 'Images',
            no_images: 'There has not been shared any images in this group yet.',
            files: 'Files',
            no_files: 'There has not been shared any files in this group yet.',
            add_files: 'Add files',
            files_description:
                'Images and files uploaded here will also be sent in the chat for this group. It will only be visible for the group members',
            upload: 'Upload',
        },
        createGroup: {
            title_create: 'Create Group',
            title_edit: 'Edit Group Info',
            button_create: 'Create Group',
            button_edit: 'Apply Changes',
            image: 'Image',
            title: 'Title',
            description: 'Description',
            created: 'Group Created',
            updated: 'Group Updated',
            updateError: 'Error occured when editing',
            createError: 'Error occured when creating',
            alertError: 'Error occured when sending alert',
            fillAll: 'Please fill all the fields',
        },
    },
    sharingSelected: {
        editItem: 'Edit item',
        deleteItem: 'Delete item',
        prolong: 'Prolong notice',
        reactivate: 'Reactivate notice',
        deactivate: 'Deactivate notice',
        deactivated: 'Notice is deactivated',
        deactivated_description:
            'Notice is no longer visible for neighbors in the list of notices. But will still produce notifications about new comments. And can be searched for',
        deactivated_failed: 'Not able to deactivate the notice',
        error: 'An error occured',
        messageTheBoard: 'Message {{boardNoun}}',
        deleteModal: {
            header: 'Are you sure you want to delete the sharing?',
            back: 'NO, BACK',
            approve: 'YES, DELETE',
        },
        messageType: {
            Helpers: 'Helpful neighbors',
            Products: 'Sharable items',
            Requests: 'Notice',
            ByBoard: 'Notice from {{boardNoun}}',
        },
        productDetails: {
            button: 'Contact neighbor',
        },
        contactNeighbor: {
            title: 'Contact Neighbor',
            button: 'Send',
            messageLabel: 'Message',
            placeHolderMessage: 'Enter message',
        },
        requestDetails: {
            titleModal: 'Send a chatmessage to this neighbor',
            button: 'Send chatmessage',
            expired: 'Notice is expired and no longer active',
            expiredDescription:
                'The creator of the notice has the possibility to extend the duration of the notice, but all interested parties will still receive notifications about new comments.',
        },
        helperProfile: {
            stars: '⭐️ {{count}} star',
            stars_plural: '⭐️ {{count}} stars',
            no_star: '⭐️ Give star',
            button: 'Ask {{name}} for help',
            sendStar: 'Send a star',
            willBeAvailable: 'will be available in {{count}} hour',
            willBeAvailable_plural: 'will be available in {{count}} hours',
            howCanIHelp: 'How can i help',
            feedback: 'Feedback',
            noFeedback: 'No feedback found',
            comment: 'Comment',
        },
        attachments: 'Attached files',
        deleteCommentSuccess: 'The comment was deleted',
        deleteCommentError: 'Was not able to delete the comment',
        deleteCommentModal: {
            header: 'Are you sure you want to delete the comment?',
            back: 'Go back',
            approve: 'Delete',
        },
        edited: '(Edited) ',
        deleted_comment: 'Deleted comment',
        edit: 'Edit',
        delete: 'Delete',
        copy: 'Copy',
        copiedToClipboard: 'Comment copied',
        editing_comment: 'Edit comment',
        stop_editing: 'Stop editing comment',
    },
    userMenu: {
        terms: { title: 'Terms of Sale', noAccounts: 'No payment accounts in your neighborhood', orgNum: 'Orgnum' },
        privacy: 'Privacy',
        language: 'Language',
        deleteAccount: 'Delete your account',
        deleteAccountModal: {
            header: 'Are you sure you want to permanently delete your account from Heime?',
            content_danger: 'This action is irreversible',
            content_warning:
                'It is recommended that you instead add any new residents, tenants or owners to your Home. And then after they log in, they remove you from the Home',
            content_consequenses:
                'Deleting your account will remove all your accesses for all neighbourhoods. You will not be able to log in to Heime anymore, or receive any necessary notifications or information.',
            back: 'Cancel',
            approve: 'Permanently delete',
            deleted: 'Your account has been deleted',
        },
    },
    languageSelectModal: {
        header: 'Select language',
        cancel: 'Cancel',
        submit: 'Save',
    },
    notFoundErrorScreen: {
        Activity: {
            header: 'Could not find the activity',
            subtext: 'Either its been cancelled/deleted or there is an error on our side',
            button: 'Go back',
        },
        Group: {
            header: 'Could not find the group',
            subtext: 'Either its been deleted or their is an error on our side',
            button: 'Go back',
        },
        Chat: {
            header: 'Could not find the chat',
            subtext: 'You are either not a member of it anymore or it is an error on our side',
            button: 'Go back',
        },
        Product: {
            header: 'Could not find the Product',
            subtext: 'Either its been deleted or there is an error on our side',
            button: 'Go back',
        },
        Profile: {
            header: 'Could not find the user',
            subtext: 'Either its been removed or there is an error on our side',
            button: 'Go back',
        },
        Reservation: {
            header: 'Could not find the reservation',
            subtext: 'Either it has been cancelled, or it is in the past, or there is an error on our side',
            button: 'Go back',
        },
        Support: {
            header: 'Could not find the support',
            subtext: 'Either it has been deleted or there is an error on our side',
            button: 'Go back',
        },
        MemberBenefit: {
            header: 'Could not find the benefit',
            subtext: 'Either it has been removed or there is an error on our side',
            button: 'Go back',
        },
        Message: {
            header: 'Could not find anything',
            subtext: 'Either it has been removed or there is an error on our side',
            button: 'Go back',
        },
        Survey: {
            header: 'Could not find the survey',
            subtext: 'It has either been closed or removed',
            button: 'Go back',
        },
    },
    memberBenefits: {
        empty: 'No registered benefits',
        noResult: 'Search term matches no benefits',
        url: 'Webpage',
    },
    memberBenefit: {
        title: 'Member benefit',
        promo_code: 'Promo code',
        visit_website: 'Visit webpage',
        showProof: 'Show member proof',
        memberProof: 'Proof of membership',
        copied: 'Promo code copied',
    },
    surveys: {
        title: 'Surveys',
        empty: 'There are no registered surveys',
        reciptientApartment:
            'The survey can only be answered once per home. Every user connected to same home will be able to see and edit your responses.',
        reciptientUsers: 'The survey can only be answered once per user.',
        questions: '{{questions}} question(s) ({{requiredQuestions}} required)',
        addResponse: 'Respond to survey',
        continueResponding: 'Continue answering',
        question: 'Question',
        nextQuestion: 'Next question',
        noMoreQuestions:
            "You have answered all the questions. Now you can either look through your questions or submit them. You can also go back and change your answers. To submit the survey press 'Send response'",
        finishLater: 'Finish later',
        sendSurvey: 'Send response',
        answerError: 'There was an error when sending your answers',
        started: 'An answer has already been started, you can continue answering',
        noAnswer: 'No answer',
        sentIn: 'Your response has already been sent in',
        selectApartment: 'Choose home to answer for',
        noApartment:
            'You are not connected to any home, you can not answer this survey. If this is a mistake you can contact {{boardNoun}} or Heime',
        archived:
            'The survey has been archived and you can not send a response. If you have provided one earlier you can see it below',
    },
    plusMenu: {
        recommended: 'Recommended',
        other: 'Other',
        activityNew: {
            title: 'Create Activity',
            description: 'Gather neighbors and do something together. Anything from a neighborhood party to a meetup.',
        },
        groupNew: {
            title: 'Create Group',
            description:
                'Find your interest or share your experience in groups. You get your own chat for discussion, sharing and planning activities.',
        },
        noticeNew: {
            title: 'Create Notice',
            description: 'A notice can be anything from information to the neighbors or a question you need answered. ',
        },
        noticeByBoard: {
            title: 'Add a notice from {{boardNoun}}',
            description: 'Mark a notice as "from {{boardNoun}}". ',
        },
        helpfulNeighbour: {
            title: 'Become a helpful neighbor',
            description:
                "Make a non-committal offer to help neighbours. Some suggestions are dog sitting, babysitting, IT help or crafts. It's fun to help people! By signing up you can describe what you can help them with, and neighbors can contact you.",
        },
        sharingNew: {
            title: 'Share an item',
            description:
                'Everyone has something that they rarely use. By taking a picture of it and posting it here, your neighbors will be able to contact you and ask to borrow it.',
        },
        reportsNew: {
            title: 'Send a message to {{boardNoun}}',
            description:
                'Here you can submit messages to {{boardNoun}}, and they will be notified about it. You and {{boardNoun}} are able to comment it, and {{boardNoun}} can update its status.',
        },
        reservations: {
            title: 'Make a Reservation',
            description: "If you'd like to reserve a product, you can do it here.",
        },
        chatNew: {
            title: 'Private chat with neighbor',
            description:
                'You choose which neighbors to chat with and send photos. The messages sent here are completely private.',
        },
    },
    smallButtonNav: {
        board: 'Contacts',
        documents: 'Documents',
        myHouse: 'My Home',
        neighbours: 'Neighbors',
        groups: 'Groups',
        myProfile: 'My Profile',
        memberBenefits: 'Member Benefits',
        reports: 'Reported Cases',
        surveys: 'Surveys',
        chat: 'Chat',
    },
    typeSpecific: {
        joint_ownership: {
            coopTypeDefiniteArticle: 'the cooperative',
            boardNounDefiniteArticle: 'the board',
        },
        rental_project: {
            coopTypeDefiniteArticle: 'the residential complex',
            boardNounDefiniteArticle: 'the landlord',
        },
        suburban_housing: {
            coopTypeDefiniteArticle: 'the homeowners association',
            boardNounDefiniteArticle: 'the board',
        },
    },
    managePlates: {
        header: 'Manage saved plates',
        save: 'Save',
        noPlates: 'No stored plates',
        deleted_confirmation: 'License plate has been deleted',
        plate: 'License plate',
        name: 'Label',
        edit: 'Edit',
        delete: 'Delete',
        undo: 'Undo',
    },
    rateUs: {
        header: 'Rate Heime',
        title: 'Rate Heime',
        subtitle: 'Give us a rating, it helps us to get better. Choose between 1-5 stars',
        cancel: 'Later',
        rate: 'Rate now',
        submit: 'Submit',
        goToStore_ios: 'Go to App Store',
        goToStore_android: 'Go to Play Store',
        feedback_title: 'Give us your feedback',
        feedback_subtitle: 'What can we do better? We appreciate your feedback',
        feedback_label: 'Feedback',
        feedback_placeholder: 'Write your feedback here',
        feedback_success: 'Thank you for your feedback',
    },

    procedure: {
        check_in_start: 'Check-in',
        check_out_start: 'Check-out',
        start_check_in_title: '{{name}} is configured with a check-in process',
        start_check_out_title: '{{name}} is configured with a check-out process',
        start_procedure_explainer:
            'It is required and consists of {{steps}} steps. This is done to ensure seamless transitions between those who have used the product. You need to have an internet connection throughout the process',
        start_procedure_check_out_explainer:
            'You need to complete the check-out process before the reservation is finished',
        start_procedure_check_out_warning: "Once checkout is completed, you won't have access to this booking anymore.",
        start_check_in_action: 'Start check-in',
        start_check_out_action: 'Start check-out',
        later: 'Later',
        continue: 'Continue',
        skip: 'Skip',
        check_in_header: 'Check-in  - {{index}} of {{steps}}',
        check_out_header: 'Check-out - {{index}} of {{steps}}',
        completion_error: 'Failed to complete the check-in. Please try again.',
        answer_error: 'Failed to save your answer. Please try again.',
        approve_cleaning_header: 'Approve the previous reservation',
        approve_cleaning_text:
            'It is only now you have the opportunity to approve the previous reservation. If you do not approve now, it will be noted. After check-in, you can find the contact information of the person who reserved before you. \n By approving, you will be responsible for cleaning after your reservation.',
        approve_cleaning_disapproved:
            'It is noted that you do not approve the cleaning that has been. It is recommended that you contact the person who reserved before you, and agree on a solution. \nTo see who this was, complete the check-in process, and look under "my reservations". It may also be wise to take some pictures as documentation',
        checklist_header: 'Please confirm that all points are completed',
        information_header: 'Please confirm that you have read the information below',
        open_door_check_in_masterLock5441: 'Open the box and take out the key',
        open_door_check_in_other: 'Open the lock',
        open_door_check_out_open_to_close: 'Open the lock, and then verify that it is closed',
        open_door_check_out_danalock_relay: 'Open lock',
        open_door_check_out_masterLock5441: 'Open the box and place the key inside',
        open_door_check_out_danalock: 'Close the lock',
        open_door_check_in_permission_text_both:
            'Heime needs access to Bluetooth and your location to open the lock. Press unlock below to give access and unlock.',
        open_door_check_out_permission_text_both:
            'Heime needs access to Bluetooth and your location to open and close the lock. Press unlock below to give access and unlock.',
        open_door_check_in_unlock: 'Unlock',
        open_door_check_in_permission_text_both_settings:
            'Heime needs access to Bluetooth and your location to open the lock. Press below to open settings.',
        open_door_check_out_permission_text_both_settings:
            'Heime needs access to Bluetooth and your location to open and close the lock. Press below to change settings.',
        open_door_open_settings: 'Change settings',
        open_door_check_in_permission_text_ble:
            'Heime needs access to Bluetooth to open the lock. Press below to change settings.',
        open_door_check_out_permission_text_ble:
            'Heime needs access to Bluetooth to open and close the lock. Press below to change settings.',
        open_door_check_in_permission_text_location:
            'Heime needs access to your location to open the lock. Press below to change settings.',
        open_door_check_out_permission_text_location:
            'Heime needs access to your location to open and close the lock. Press below to change settings.',
        open_door_bluetooth_disabled: 'Bluetooth is turned off. Press below to turn on Bluetooth',
        open_door_enable_bluetooth: 'Turn on Bluetooth',
        open_door_check_in_select_lock: 'Select lock to open',
        open_door_check_out_select_lock: 'Select lock to open / close',
        open_door_check_in_open_lock: 'Open lock',
        open_door_check_in_unlock_press: 'Press the button below to open',
        open_door_check_in_get_key_press: 'To get the key, unlock the key box',
        open_door_check_out_unlock_press: 'Press the button below to open',
        open_door_check_out_open_lock: 'Open the lock',
        open_door_check_out_lock_press: 'Press the button below to lock',
        open_door_check_out_close_lock: 'Lock',
        open_door_check_out_return_key_press: 'To return the key, press the button below',
        open_door_not_supported: 'To open the door',
        pictures_not_supported: 'To take pictures',
        pictures_give_camera_access: 'Give access',
        pictures_open_settings: 'Open settings',
        pictures_camera_access: 'Heime needs access to your camera',
        pictures_camera_access_denied: 'Heime does not have access to your camera',
        pictures_camera_access_explanation:
            "In this step you will take pictures of the product. We need access to your camera. Press 'give access' and then 'allow'",
        pictures_camera_access_denied_explanation:
            'To perform this step, we need access to your camera. Go to settings on your phone and give Heime access to the camera',
        pictures_flash_off: 'Turn off flash',
        pictures_flash_on: 'Turn flash on',
        pictures_take_picture: 'Take picture',
        pictures_no_camera: 'It seems that this device does not have a camera',
        pictures_uploading: 'Uploading picture',
        pictures_approve_or_retake: 'Approve picture or take a new one',
        pictures_approve: 'Approve',
        pictures_n_taken: 'Pictures taken',
        skip_confirm: 'Are you sure you want to skip?',
        skip_confirm_text:
            'By skipping, you are waiving any responsibility the previous user had, and you are responsible for the condition of the product after the booking',
        skip_confirm_yes: 'Yes, skip',
        skip_confirm_no: 'No, go back',
        not_supported: 'This functionality is not supported',
        not_supported_use_mobile: 'please use the mobile app',
        reservation_ends: 'The reservation ends in {{time}}',
        reservation_ends_explainer: 'You will not be able to complete the process after this time',
        reservation_ended: 'Reservation has ended',
        reservation_ended_explainer:
            'This means that you will not be able to complete the process. To get renewed access, you must reserve again, or contact the person who has reserved after you.',
        go_back: 'Go back',
        completion_success_check_in: 'You are checked in',
        completion_success_text_check_in: 'Your answers are noted, and you can now use the product',
        completion_success_check_out: 'You are checked out',
        completion_success_text_check_out:
            'Your answers are noted, and you can now consider your reservation as completed',
    },

    countryCode: {
        af: 'Afghanistan',
        al: 'Albania',
        dz: 'Algeria',
        as: 'American Samoa',
        ad: 'Andorra',
        ao: 'Angola',
        ai: 'Anguilla',
        aq: 'Antarctica',
        ag: 'Antigua and Barbuda',
        ar: 'Argentina',
        am: 'Armenia',
        aw: 'Aruba',
        au: 'Australia',
        at: 'Austria',
        az: 'Azerbaijan',
        bs: 'Bahamas (the)',
        bh: 'Bahrain',
        bd: 'Bangladesh',
        bb: 'Barbados',
        by: 'Belarus',
        be: 'Belgium',
        bz: 'Belize',
        bj: 'Benin',
        bm: 'Bermuda',
        bt: 'Bhutan',
        bo: 'Bolivia (Plurinational State of)',
        bq: 'Bonaire, Sint Eustatius and Saba',
        ba: 'Bosnia and Herzegovina',
        bw: 'Botswana',
        bv: 'Bouvet Island',
        br: 'Brazil',
        io: 'British Indian Ocean Territory (the)',
        bn: 'Brunei Darussalam',
        bg: 'Bulgaria',
        bf: 'Burkina Faso',
        bi: 'Burundi',
        cv: 'Cabo Verde',
        kh: 'Cambodia',
        cm: 'Cameroon',
        ca: 'Canada',
        ky: 'Cayman Islands (the)',
        cf: 'Central African Republic (the)',
        td: 'Chad',
        cl: 'Chile',
        cn: 'China',
        cx: 'Christmas Island',
        cc: 'Cocos (Keeling) Islands (the)',
        co: 'Colombia',
        km: 'Comoros (the)',
        cd: 'Congo (the Democratic Republic of the)',
        cg: 'Congo (the)',
        ck: 'Cook Islands (the)',
        cr: 'Costa Rica',
        hr: 'Croatia',
        cu: 'Cuba',
        cw: 'Curaçao',
        cy: 'Cyprus',
        cz: 'Czechia',
        ci: "Côte d'Ivoire",
        dk: 'Denmark',
        dj: 'Djibouti',
        dm: 'Dominica',
        do: 'Dominican Republic (the)',
        ec: 'Ecuador',
        eg: 'Egypt',
        sv: 'El Salvador',
        gq: 'Equatorial Guinea',
        er: 'Eritrea',
        ee: 'Estonia',
        sz: 'Eswatini',
        et: 'Ethiopia',
        fk: 'Falkland Islands (the) [Malvinas]',
        fo: 'Faroe Islands (the)',
        fj: 'Fiji',
        fi: 'Finland',
        fr: 'France',
        gf: 'French Guiana',
        pf: 'French Polynesia',
        tf: 'French Southern Territories (the)',
        ga: 'Gabon',
        gm: 'Gambia (the)',
        ge: 'Georgia',
        de: 'Germany',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gr: 'Greece',
        gl: 'Greenland',
        gd: 'Grenada',
        gp: 'Guadeloupe',
        gu: 'Guam',
        gt: 'Guatemala',
        gg: 'Guernsey',
        gn: 'Guinea',
        gw: 'Guinea-Bissau',
        gy: 'Guyana',
        ht: 'Haiti',
        hm: 'Heard Island and McDonald Islands',
        va: 'Holy See (the)',
        hn: 'Honduras',
        hk: 'Hong Kong',
        hu: 'Hungary',
        is: 'Iceland',
        in: 'India',
        id: 'Indonesia',
        ir: 'Iran (Islamic Republic of)',
        iq: 'Iraq',
        ie: 'Ireland',
        im: 'Isle of Man',
        il: 'Israel',
        it: 'Italy',
        jm: 'Jamaica',
        jp: 'Japan',
        je: 'Jersey',
        jo: 'Jordan',
        kz: 'Kazakhstan',
        ke: 'Kenya',
        ki: 'Kiribati',
        kp: "Korea (the Democratic People's Republic of)",
        kr: 'Korea (the Republic of)',
        kw: 'Kuwait',
        kg: 'Kyrgyzstan',
        la: "Lao People's Democratic Republic (the)",
        lv: 'Latvia',
        lb: 'Lebanon',
        ls: 'Lesotho',
        lr: 'Liberia',
        ly: 'Libya',
        li: 'Liechtenstein',
        lt: 'Lithuania',
        lu: 'Luxembourg',
        mo: 'Macao',
        mg: 'Madagascar',
        mw: 'Malawi',
        my: 'Malaysia',
        mv: 'Maldives',
        ml: 'Mali',
        mt: 'Malta',
        mh: 'Marshall Islands (the)',
        mq: 'Martinique',
        mr: 'Mauritania',
        mu: 'Mauritius',
        yt: 'Mayotte',
        mx: 'Mexico',
        fm: 'Micronesia (Federated States of)',
        md: 'Moldova (the Republic of)',
        mc: 'Monaco',
        mn: 'Mongolia',
        me: 'Montenegro',
        ms: 'Montserrat',
        ma: 'Morocco',
        mz: 'Mozambique',
        mm: 'Myanmar',
        na: 'Namibia',
        nr: 'Nauru',
        np: 'Nepal',
        nl: 'Netherlands (the)',
        nc: 'New Caledonia',
        nz: 'New Zealand',
        ni: 'Nicaragua',
        ne: 'Niger (the)',
        ng: 'Nigeria',
        nu: 'Niue',
        nf: 'Norfolk Island',
        mp: 'Northern Mariana Islands (the)',
        no: 'Norway',
        om: 'Oman',
        pk: 'Pakistan',
        pw: 'Palau',
        ps: 'Palestine, State of',
        pa: 'Panama',
        pg: 'Papua New Guinea',
        py: 'Paraguay',
        pe: 'Peru',
        ph: 'Philippines (the)',
        pn: 'Pitcairn',
        pl: 'Poland',
        pt: 'Portugal',
        pr: 'Puerto Rico',
        qa: 'Qatar',
        mk: 'Republic of North Macedonia',
        ro: 'Romania',
        ru: 'Russian Federation (the)',
        rw: 'Rwanda',
        re: 'Réunion',
        bl: 'Saint Barthélemy',
        sh: 'Saint Helena, Ascension and Tristan da Cunha',
        kn: 'Saint Kitts and Nevis',
        lc: 'Saint Lucia',
        mf: 'Saint Martin (French part)',
        pm: 'Saint Pierre and Miquelon',
        vc: 'Saint Vincent and the Grenadines',
        ws: 'Samoa',
        sm: 'San Marino',
        st: 'Sao Tome and Principe',
        sa: 'Saudi Arabia',
        sn: 'Senegal',
        rs: 'Serbia',
        sc: 'Seychelles',
        sl: 'Sierra Leone',
        sg: 'Singapore',
        sx: 'Sint Maarten (Dutch part)',
        sk: 'Slovakia',
        si: 'Slovenia',
        sb: 'Solomon Islands',
        so: 'Somalia',
        za: 'South Africa',
        gs: 'South Georgia and the South Sandwich Islands',
        ss: 'South Sudan',
        es: 'Spain',
        lk: 'Sri Lanka',
        sd: 'Sudan (the)',
        sr: 'Suriname',
        sj: 'Svalbard and Jan Mayen',
        se: 'Sweden',
        ch: 'Switzerland',
        sy: 'Syrian Arab Republic',
        tw: 'Taiwan (Province of China)',
        tj: 'Tajikistan',
        tz: 'Tanzania, United Republic of',
        th: 'Thailand',
        tl: 'Timor-Leste',
        tg: 'Togo',
        tk: 'Tokelau',
        to: 'Tonga',
        tt: 'Trinidad and Tobago',
        tn: 'Tunisia',
        tr: 'Turkey',
        tm: 'Turkmenistan',
        tc: 'Turks and Caicos Islands (the)',
        tv: 'Tuvalu',
        ug: 'Uganda',
        ua: 'Ukraine',
        ae: 'United Arab Emirates (the)',
        gb: 'United Kingdom of Great Britain and Northern Ireland (the)',
        um: 'United States Minor Outlying Islands (the)',
        us: 'United States of America (the)',
        uy: 'Uruguay',
        uz: 'Uzbekistan',
        vu: 'Vanuatu',
        ve: 'Venezuela (Bolivarian Republic of)',
        vn: 'Viet Nam',
        vg: 'Virgin Islands (British)',
        vi: 'Virgin Islands (U.S.)',
        wf: 'Wallis and Futuna',
        eh: 'Western Sahara',
        ye: 'Yemen',
        zm: 'Zambia',
        zw: 'Zimbabwe',
        ax: 'Åland Islands',
        xk: 'Kosovo',
        ac: 'Ascension Island',
        ta: 'Tristan da Cunha',
    },
};
