import { useCallback, useEffect, useMemo, useState } from 'react';
import { BackHandler } from 'react-native';

type screen =
    | 'create'
    | 'preview'
    | 'more'
    | 'files'
    | 'description'
    | 'dateTime'
    | 'cost'
    | 'alert'
    | 'location'
    | 'groups'
    | 'cooperative_group'
    | 'arrangers';

const useActivityNewNavigation = (initialScreen: screen, goBack: () => void) => {
    const [page, setCurrentPage] = useState<[screen, screen | undefined]>([initialScreen, undefined]);

    const handleNextPage = useCallback(() => {
        setCurrentPage(([curr]) => {
            switch (curr) {
                case 'create':
                    return ['preview', curr];
            }
            return ['preview', undefined];
        });
    }, []);

    const handleGoBack = useCallback(() => {
        switch (page[0]) {
            case 'create':
                if (page[1]) {
                    setCurrentPage([page[1], undefined]);
                } else {
                    goBack();
                }
                return;
            case 'preview':
                if (page[1]) {
                    setCurrentPage([page[1], undefined]);
                } else {
                    goBack();
                }
                return;
            case 'more':
                setCurrentPage(['preview', undefined]);
                return;
            case 'files':
            case 'description':
            case 'dateTime':
            case 'cost':
            case 'alert':
            case 'location':
            case 'groups':
            case 'cooperative_group':
            case 'arrangers':
                setCurrentPage([page[1] as screen, undefined]);
                return;
        }
    }, [goBack, page]);

    useEffect(() => {
        const handler = () => {
            handleGoBack();
            return true;
        };
        BackHandler.addEventListener('hardwareBackPress', handler);
        return () => BackHandler.removeEventListener('hardwareBackPress', handler);
    }, [handleGoBack]);

    const goToPage = useCallback(
        (screen: screen) => {
            setCurrentPage([screen, page[0]]);
        },
        [page],
    );

    return useMemo(
        () => ({
            page,
            goToPage,
            handleGoBack,
            handleNextPage,
        }),
        [page, goToPage, handleGoBack, handleNextPage],
    );
};

export default useActivityNewNavigation;
