import { useCallback } from 'react';
import { useImageSelectorType } from './useImageSelectorExport';
import DocumentPicker from '../../_dependencies/documentPicker.web';

const useImageSelector: useImageSelectorType = (onAddImages: (images: Array<File>) => void, onClose: () => void) => {
    const onSelect = useCallback(() => {
        (async () => {
            const file = await DocumentPicker.pickSingle({ type: ['.png,.jpg,.jpeg,.webp,.gif,.ico'] });

            onAddImages([file as unknown as File]);

            onClose();
        })();
    }, [onAddImages, onClose]);

    return [onSelect, null];
};

export default useImageSelector;
