import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentsView } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { screenMargin } from '_utils/sizes';
import { QueryView, EmptyMessage } from 'Components';
import FileIcon from 'Components/Icon/FileIcon';
import { File, Folder } from 'types/files';
import { ArrayElement } from 'types/Utility';
import { DocumentItem } from './DocumentItem';

interface DocumentListProps {
    data?: Folder;
    searchedText: string;
}

function getMatchingFiles(doc: Folder, searchedText: string): (Folder | File)[] {
    const result = [];
    if (doc.name.toLowerCase().includes(searchedText.toLowerCase())) {
        result.push(doc);
    }
    doc.files.forEach((file) => {
        if (file.name.toLocaleLowerCase().includes(searchedText.toLowerCase())) {
            result.push(file);
        }
    });
    doc.sub_folders.forEach((folder) => {
        result.push(...getMatchingFiles(folder, searchedText));
    });

    return result;
}

const countFiles = (acc: number, curr: Folder): number =>
    acc + curr.files.length + curr.sub_folders.reduce(countFiles, 0);

const DocumentList = ({ data, searchedText }: DocumentListProps): ReactElement => {
    const { t } = useTranslation();
    const paddingBottom = useBottomSpacer();

    const [loader, handlePress] = useDocumentsView();

    const filteredData = useMemo(() => {
        if (!data) {
            return [];
        }
        if (!data.files) {
            return [];
        }
        const addId = (item: File | Folder) => ({ ...item, id: Math.random() });
        const sort = (a: File | Folder, b: File | Folder) => {
            const aIsShared = 'source' in a && a.source === 'cooperative_group';
            const bIsShared = 'source' in b && b.source === 'cooperative_group';
            const aIsPrivate = 'source' in a && a.source === 'private';
            const bIsPrivate = 'source' in b && b.source === 'private';
            if (aIsShared && !bIsShared) {
                return -1;
            }
            if (!aIsShared && bIsShared) {
                return 1;
            }
            if (aIsPrivate && !bIsPrivate) {
                return -1;
            }
            if (!aIsPrivate && bIsPrivate) {
                return 1;
            }
            return a.name.localeCompare(b.name);
        };
        if (searchedText !== '') {
            return getMatchingFiles(data, searchedText).map(addId).sort(sort);
        }
        return [...(data.sub_folders ?? []), ...(data.files ?? [])].map(addId).sort(sort);
    }, [data, searchedText]);

    const renderItem = ({ item }: { item: ArrayElement<typeof filteredData> }) => {
        const isFolder = 'files' in item;
        const isSharedFolder = 'source' in item && item.source === 'cooperative_group';
        const isPrivateFolder = 'source' in item && item.source === 'private';
        const folder = item as Folder;
        const file = item as File;

        return (
            <DocumentItem
                icon={
                    <FileIcon
                        {...(isFolder
                            ? { folder: isSharedFolder ? 'shared' : isPrivateFolder ? 'private' : true }
                            : { fileName: item.name })}
                    />
                }
                onPress={() => handlePress(item)}
                title={item.name}
                desc={
                    isFolder
                        ? t('documents:files', {
                              count: folder.files.length + folder.sub_folders.reduce(countFiles, 0),
                          })
                        : file.size
                }
                chevron={isFolder}
            />
        );
    };

    return (
        <>
            <QueryView
                keyboardShouldPersistTaps
                emptyList={
                    <EmptyMessage
                        containerStyle={{ marginTop: screenMargin }}
                        message={searchedText ? t('documents:notFound') : t('documents:noResult')}
                    />
                }
                data={filteredData}
                renderItem={renderItem}
                containerStyle={{ paddingBottom }}
            />
            {loader}
        </>
    );
};

export default DocumentList;
